import React, { useState, useEffect, useRef } from 'react'
import { useParams } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import {Backdrop, Snackbar, CircularProgress} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import { Select, MenuItem ,Typography, IconButton} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import '../../constant.css';
import '../manageclubs/manageclubs.css';
import './createpromotions.css';
import { config } from '../../config';
import PromotionMap from './promotionmap';
import { useQuery, gql, useMutation ,useLazyQuery} from '@apollo/client';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import ReactPlayer from 'react-player/lazy';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from "@material-ui/core/styles";
import PromoQuiz from './promoQuiz'
import AvatarDefault from '../../assets/ic_avatar-default.svg';
import BillBoardLogo from '../../assets/ic-default-logo.svg';
import CamerLogo from '../../assets/ic_camera.svg';
import Loader from '../../components/Loader/loader';
import AvatarEditor from 'react-avatar-editor';
import mergeImages from 'merge-images';
import borderImage from '../../assets/border-frame-billboard.png'
import Slider from '@material-ui/core/Slider';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { tooltipClasses } from '@mui/material';
import { styled } from '@material-ui/core/styles'
import axios from "axios";
import { AccountBalanceWallet, AddCircle, Delete, PlusOne, RemoveCircle } from "@material-ui/icons";
const formatCurrency = require('format-currency');
const opt = { minFraction: 0 };

interface Props {
    onOpen: boolean,
    handleCloseClick: (index: any) => void,
    promoId?: any,
    promotionHuntType?: any,
    code: any,
    copyPromoId?: any,
    copyPromo: string,
    currentTab: any
}

interface MutationFun {
    createPromo: () => void;
}

interface ParamTypes {
    orgId: string;
}

interface IFormAddPromo {
    promoCodePrefix?: string | undefined;
    promoCodePoints: string;
    promoType: string | undefined;
    validity: string | undefined;
    validityDay: string;
    promoTotalCount: string;
    sponsorId: string | undefined;
    pointsAvailable?: number | undefined;
    noPointsPerPromo?: number;
    promoUrl: string;
    file: string;
    promoUrl_ios: string;
    promoSentToMail?: string | null;
    poiKeyword?: string | null;
    poiRadius?: string | null;
    promoTitle?: string | undefined;
    promoMessage?: string | undefined;
    promoSentTOClub?: string | undefined;
    notifyMessage?: string | undefined;
    huntLocation?: string | undefined;
    videoLink?: string | undefined;
    promoQuiz?: string
}

let defaultValues = {
    promoCodePrefix: "",
    promoCodePoints: "",
    promoType: "",
    validity: "",
    validityDay: "",
    promoTotalCount: "1",
    sponsorId: "",
    pointsAvailable: 100,
    noPointsPerPromo: 10,
    promoUrl: "",
    promoUrl_ios: "",
    promoSentToMail: "",
    poiKeyword: "",
    poiRadius : "200",
    promoTitle: "Promo Title!",
    promoMessage: "Promo Message",
    promoSentTOClub: "",
    notifyMessage: "Promo Notification",
    huntLocation: "",
    videoLink: "",
    promoQuiz: "No"
};

export const GET_SPONSOR_QUERY = gql`
    query GetSponsorInventory($clubId : String!) {
        getSponsorWithOrgId (clubId : $clubId) {
            id
            sponsorName
            sponsorLogo
            availablePoints
            availableTokens
        }
    }
`;

export const GET_NEARBY_POI_LOCATION_QUERY = gql`
    query getNearByPoiLocation($radius:Float!, $keyword:String!, $latlng:String!) {
        getNearByPoiLocation (radius: $radius, keyword:$keyword, latlng:$latlng){
        lat
        lng
        address
        name
        }
    }
`;

const CREATE_PROMO = gql`
    mutation createPromo($data : NewPromoInput!){
        createPromo(data:$data) {
            id
        }
    }
`;

const CREATE_HUNT = gql`
    mutation createHunt($data : NewHuntInput!){
        createHunt(data:$data) {
            id
        }
    }
`;

const CREATE_BILLBOARD = gql`
    mutation createBillboard($data : NewBillboardInput!){
        createBillboard(data:$data) {
            id
        }
    }
`;

const CREATE_POI = gql`
    mutation createPoi($data : NewPoiInput!){
        createPoi(data:$data) {
            id
        }
    }
`;

const UPDATE_PROMO = gql`
    mutation updatePromos($id : String!,$data: UpdatePromoInput! ){
        updatePromo(id: $id, data: $data ){
            promoType
        }
    }
`;

const PROMOTION_BY_ID = gql`
    query getPromoFindOne($id: String!) {
        getPromoWithId(id: $id) {
            promoCodePrefix
            promoCodePoints
            promoType
            validity
            validityDay
            promoTotalCount
            sponsorId
            id
            promoTitle
            sponsorLogo
            pointsAvailable
            noPointsPerPromo
            promoSentToMail
            promoMessage
            promoSentTOClub,
            videoLink
            promoUrl
            promoUrl_ios
            promoQuiz
            promoQuizList
        }
    }
`;

const POI_WITH_ID = gql`
    query getPoiWithId($id: String!) {
        getPoi(id: $id) {
            poiCodePrefix
            poiCodePoints
            validity
            validityDay
            sponsorId
            noPointsPerPoi
            locationName
            mainLat
            mainLng
            id
            keyword
            radius
            poiPoints {
                id
                pointsValue
                lat
                lng
                locationName
            }
            mapProperties
        }
    }
`;

const HUNT_WITH_ID = gql`
    query getHuntWithId($id: String!) {
        getHunt(id: $id) {
            huntCodePrefix
            huntCodePoints
            validity
            validityDay
            sponsorId
            noPointsPerHunt
            locationName
            mainLat
            mainLng
            id
            points{
                lat
                lng
                pointsValue
            }
            mapProperties
        }
    }
`;

const BILLBOARD_WITH_ID = gql`
    query getBillboardWithId($id: String!) {
        getBillboard(id: $id) {
            billboardCodePrefix
            billboardCodePoints
            validity
            validityDay
            sponsorId
            noPointsPerBillboard
            locationName
            mainLat
            mainLng
            id
            billboardPoints{
                lat
                lng
                pointsValue
            }
            mapProperties
            billboardImageUrl
            billboardUrl
            billboardUrl_ios
        }
    }
`;

const GET_PRE_SIGNED_URL = gql`
    query getPreSignedUrl($fileName: String!){
        getPreSignedUrl(fileName: $fileName){
            preSignedUrl
        }
    }
`;

const useStyles = makeStyles({
    paper: {
        padding: "0",
        fontFamily: "Graphik Regular !important",
        fontSize: "14px !important",
        color: "#8A8D9D !important"
    }
});

export default function CreatePromotions({ handleCloseClick, onOpen, promoId, promotionHuntType, code, copyPromoId, copyPromo, currentTab }: Props) {
    const setEditorRef: any = useRef();
    const [avatarImageToCrop, setAvatarImageToCrop] = React.useState<any>([]);
    const [zoomValue, setZoomValue] = React.useState(0.8);
    const [mergedImage, setMergedImage] = useState('');
    const promotionId = promoId ? promoId : "";
    const copyPromotionId = copyPromoId ? copyPromoId : "";
    const open = onOpen;
    let { orgId } = useParams<ParamTypes>();
    let isCopyPromo = copyPromo;
    const fieldEnabled = promotionId ? (copyPromo ? false : true) : false;
    const [error, setError] = useState<any>("");
    const [totalCoins, setTotalCoins] = useState(0);
    const [placedCoins, setPlacedCoins] = useState(0);
    const [promoTitle, setPromoTitle] = useState("PromoTitle");
    const [videoLink, setVideoLink] = useState("");
    const [pointsRemain, setPointsRemain] = useState(0);
    const [tokensRemain, setTokensRemain] = useState(0);
    const [promoCodePrefix, setPromoCodePrefix] = useState("");
    const [noofPromo, setNoofPromo] = useState(0);
    const [locationSelected, setLocationSelected] = useState(false);
    const [isSubmit, setIsSubmit] = useState(true);
    const [value, setValueLocation] = useState("");
    // confirmation states
    const [confirmMessage, setConfirmMessage] = useState<any>("Are you sure with the Point Requested value?");
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [confirmed, setConfirmed] = React.useState(false);
    const [confirmResetMap, setConfirmResetMap] = React.useState(false);
    const inputRef: any = useRef();
    const [promoType, setPromoType] = useState(0);
    const [promoQuiz, setPromoQuiz] = useState("");
    const [mapRadiusConst, setMapRadiusConst] = useState("200");
    const [mapRadiusChange, setMapRadiusChange] = useState(false);
    const [autoDistributeSelected, setAutoDistributeSelected] = React.useState<any>(false);
    const [openToast, setOpenToast] = React.useState<any>(false);
    const [tooltipTitle, setTooltipTitle] = React.useState('Copy');
    const [load, setLoad] = useState(false);
    // const [avatarImageToCrop, setAvatarImageToCrop] = React.useState<any>([]);
    const [loader, setLoader] = useState<any>(false);
    const [billBoardImgUrl, setBillBoardImgUrl] = React.useState<any>('');
    const [defaultLogoUrl, setDefaultLogoUrl] = useState<any>(BillBoardLogo);         // Default logo
    const [poiRadius, setPoiRadius] = useState("200");
    const [mapProperties, setMapProperties] = React.useState<any>({
        mapLatLng: { lat: 26.02628761374811, lng: -80.20744120866775 },
        location: '',
        zoomPoint: 17,
        mapRadius: poiRadius || 200,
        coinDistributionRadius: 30
    })

    const [quizList, setQuizList] = React.useState<any>([{
        questionId: Math.floor(100000 + Math.random() * 900000),
        questionTitle: "",
        keyList: [
            { id: Math.floor(100000 + Math.random() * 900000), keyName: '' }
        ],
        answer: null
    }])

    const { loading: sponsorLoading, data: getSponsor, refetch: refetchSponsor } = useQuery<any>(GET_SPONSOR_QUERY, { variables: { clubId: orgId } });
    const { loading: promoLoading, data: PromoDetails, refetch: refetchPromoWithID } = useQuery<any>(PROMOTION_BY_ID, { variables: { id: isCopyPromo && promotionHuntType !== 'Hunt' && promotionHuntType !== 'BillBoard' ? copyPromotionId : promotionId } });
    const { loading: huntLoading, data: huntData, refetch: refetchHunt } = useQuery<any>(HUNT_WITH_ID, { variables: { id: isCopyPromo && promotionHuntType === 'Hunt' ? copyPromotionId : promotionId } });
    const { loading: billboardLoading, data: billboardData, refetch: refetchBillboard } = useQuery<any>(BILLBOARD_WITH_ID, { variables: { id: isCopyPromo && promotionHuntType === 'BillBoard' ? copyPromotionId : promotionId } });
    const { loading: poiLoading, data: poiApiData, refetch: refetchPoi } = useQuery<any>(POI_WITH_ID, { variables: { id: isCopyPromo && promotionHuntType === 'Poi' ? copyPromotionId : promotionId } });

    // Below code to uplaod files to S3 bucket using presigned url.
    const [uploadFileName, setUploadFileName] = useState<any>('')
    const [targetFile, setTargetFile] = useState<any>([])
    const { loading: urlLoading, data: urlData, refetch: urlRefetch } = useQuery<any>(GET_PRE_SIGNED_URL, { skip: uploadFileName.length > 0 ? false : true, variables: { fileName: uploadFileName } });

    const Alert = (props: AlertProps) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const hasToken = localStorage.getItem('org_has_token');
    const pointstoken = hasToken === 'true' ? 'Tokens' : 'Points';
    const [promoTotalCountValidation, setPromoTotalCountValidation] = useState<any>('');
    const [promotionType, setPromotionType] = React.useState<any>("");
    const [promotion, setPromotion] = React.useState<any>(defaultValues);
    const [noofFieldCaption, setNoofFieldCaption] = React.useState("Unique Users");
    const [validityDate, setValidityDate] = React.useState(moment().format("D MMM YYYY"));
    const { handleSubmit, register, reset, clearErrors, control, errors, setValue, getValues } = useForm<IFormAddPromo>({ defaultValues, shouldUnregister: false });
    // const [promoInfo,setPromoInfo] = useState<any>({});
    const [sponsorLogo, setSponsorLogo] = useState<any>("");
    const [latlng, setLatlng] = useState({ lat: 26.02628761374811, lng: -80.20744120866775 });
    const [markerImage, setMarkerImage] = useState("");
    const [locationName, setLocationName] = useState("");
    const [noCoinsValue, setNoCoinsValue] = useState(0);
    const [promoCodePoints, setPromoCodePoints] = useState({ title: "1", _id: "1" });
    const [points, setPoints] = useState<any[]>([]);
    const [distArr, setDistArr] = useState<any>([]);
    const [disableDist, setDisableDist] = useState(false);
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackMessage, setSnackMessage] = useState({ info: 'Place coins before you save...', severity: 'warning' })
    const [huntLocation, setHuntLocation] = useState("");
    const [poiMarkersResponse, setPoiMarkersResponse] = useState<any>("");
    const [PoiKeyword, setPoiKeyword] = useState("");
    const [promoMessageValidation, setPromoMessageValidation] = useState(false);
    // const [totalPoints, setTotalPoints] = useState(0);
    const [pointsOptions, setPointsOptions] = useState([{ title: "1", _id: "1" }, { title: "10", _id: "10" }, { title: "20", _id: "20" }, { title: "100", _id: "100" }, { title: "500", _id: "500" }, { title: "1000", _id: "1000" }])
    const [tabIndex, setTabIndex] = useState(0);
    const promoInputValues = (promoInputValues: any) => {
        setValue("promoCodePrefix", promoInputValues.promoCodePrefix);
        setValue("promoCodePoints", promoInputValues.promoCodePoints);
        setPromoCodePoints({ title: promoInputValues.promoCodePoints.toString(), _id: promoInputValues.promoCodePoints.toString() });
        setValue("promoType", promoInputValues.promoType);
        setValue("validity", promoInputValues.validity);
        setValue("validityDay", promoInputValues.validityDay);
        setValue("promoTotalCount", promoInputValues.promoTotalCount.toString());
        setValue("sponsorId", promoInputValues.sponsorId);
        setValue("pointsAvailable", promoInputValues.pointsAvailable);
        setValue("promoUrl", promoInputValues.promoUrl);
        setValue("promoUrl_ios", promoInputValues.promoUrl_ios);
        setValue("noPointsPerPromo", promoInputValues.noPointsPerPromo);
        setValue("promoSentToMail", promoInputValues.promoSentToMail);
        setValue("poiKeyword", promoInputValues.poiKeyword);
        setValue("poiRadius", promoInputValues.poiRadius);
        setValue("promoTitle", promoInputValues.promoTitle);
        setValue("promoMessage", promoInputValues.promoMessage);
        setValue("promoSentTOClub", promoInputValues.promoSentTOClub);
        setValue("notifyMessage", promoInputValues.notifyMessage);
        setValue("huntLocation", promoInputValues.huntLocation);
        setValue("videoLink", promoInputValues.videoLink);

        if (promotionId || copyPromotionId) {
            setValue("promoQuiz", promoInputValues.promoQuiz ? 'Yes' : 'No');
            let tempPromoQuiz: any = promoInputValues.promoQuiz ? 'Yes' : 'No'
            setPromoQuiz(tempPromoQuiz);
        } else {
            setValue("promoQuiz", "No");
            setPromoQuiz("");
        }

        setPromotionType(defaultValues.promoType);
        setVideoLink(defaultValues.videoLink);
        setSponsorLogo(promoInputValues.sponsorLogo);

        let selectedSponsor = getSponsor.getSponsorWithOrgId.find((i: any) => i.id === promoInputValues.sponsorId);
        if (selectedSponsor) {
            // setSponsorLogo(selectedSponsor.sponsorLogo);
            setMarkerImage(selectedSponsor.sponsorLogo);
            setPointsRemain(selectedSponsor.availablePoints);
            setTokensRemain(selectedSponsor.availableTokens);
        }

        if (isCopyPromo) {
            setValidityDate(moment().add(defaultValues.validityDay.toString(), 'days').format("D MMM YYYY"));
        } else {
            setValidityDate(defaultValues.validity);
        }
    }
    function calculatePoiPoints(poiList:any, points:number) {
        var list = JSON.parse(JSON.stringify(poiList));
        list.map((l:any, idx:number) => {
            l.points = points
        });  
        Object.preventExtensions(list);
        console.log("list",list);
        return list;

        // logic of create pois as per points
        // const list:any = [];
        // let remaining_points_token = hasToken === 'true' ? tokensRemain : pointsRemain;
        // poiList.map((l:any, idx:number) => {
        //     if(remaining_points_token >= points) {
        //         l.points = points;  
        //         list.push(l);  
        //         remaining_points_token = remaining_points_token - points
        //     }
        // });  
        // return list;
    }
    const [getPoisAPI,{  }] = useLazyQuery<any>(
        GET_NEARBY_POI_LOCATION_QUERY,{
        onCompleted: async (response) => { 
            if(response?.getNearByPoiLocation.length > 0) {
                let point = parseInt(getValues("promoCodePoints"));
                const pointsPerPOI = await calculatePoiPoints(response?.getNearByPoiLocation, point);
                setPoiMarkersResponse(pointsPerPOI);
            }
            else {
                setError("No POI is found on given location. Try different keyword or location.");
                setOpenToast(true);
            }
         },onError: (data) => {
        setError(data.message);
        setOpenToast(true);
        } 
    });
    
    async function getPois(LatLong: any, poiKeyword: any, poiRadius: any) {     
        let Lat = (LatLong.lat).toFixed(7);
        let Long = (LatLong.lng).toFixed(7);
        let point = parseInt(getValues("promoCodePoints"));
        if(!poiKeyword) {
            setError("POI keyword is required");
                setOpenToast(true);
        }
        else if(!poiRadius) {
            setError("Radius is required");
                setOpenToast(true);
        }
        else if(!point) {
            setError("Points per promo is required");
                setOpenToast(true);
        }
        else {
            getPoisAPI({
                variables:
                     {radius: parseFloat(poiRadius) , keyword: poiKeyword , latlng: `${LatLong.lat},${LatLong.lng}`}         
            })
        }
    }


    const handleConfirmationBox: SubmitHandler<IFormAddPromo> = (data: any) => {
        if(data.promoType == "Poi" ) {
            const points_tokens_Remain  = hasToken === 'true' ? (tokensRemain - getTotalPoints(getValues('promoCodePoints'), getValues('promoTotalCount'))) : (pointsRemain - getTotalPoints(getValues('promoCodePoints'), getValues('promoTotalCount')));
            if(poiMarkersResponse.length <= 0) {
                setError("No POI is Show");
                setOpenToast(true);
                return;
            }
            else if(points_tokens_Remain < 0) {
                setError("Please check available points in sponsor");
                setOpenToast(true);
                return;
            }
            const confirmMsg = hasToken === 'true' ? "Do you want to confirm with this tokens " : "Do you want to confirm with this points ";
            setConfirmMessage(confirmMsg + getTotalPoints(getValues('promoCodePoints'), getValues('promoTotalCount')) + " ?");
            setOpenConfirm(true);  
        }
       else  if (distArr.length > 0 && locationName !== "") {
            const confirmMsg = hasToken === 'true' ? "Do you want to confirm with this tokens " : "Do you want to confirm with this points ";
            setConfirmMessage(confirmMsg + getTotalPoints(getValues('promoCodePoints'), getValues('promoTotalCount')) + " ?");
            setOpenConfirm(true);
        } else {          
            if(data.promoType === 'BillBoard'){
                setError("Location and BIllboard distribution fields are mandatory");
            }else{
                setError("Location and Coin distribution fields are mandatory");
            }
            setOpenToast(true);
        }
    }

    const clickCanceled = () => {
        setConfirmed(false);
        setOpenConfirm(false);
        setPoiMarkersResponse([]);
        setValue("promoCodePoints", promoCodePoints.title);
        setPromoCodePoints({ title: promoCodePoints.title, _id: promoCodePoints.title });
        setAutoDistributeSelected(false);
        setMapProperties({
            mapLatLng: { lat: 26.02628761374811, lng: -80.20744120866775 },
            location: '',
            zoomPoint: 17,
            mapRadius: 200,
            coinDistributionRadius: 30
        })
        //inputRef.current.focus();
    }
    useEffect(() => {
        //show poi warnining when zoom in or out in map

        if(mapRadiusChange && promotionType === "Poi"){
            setOpenToast(false);
            setMapRadiusChange(false);
            setError("Please click on show poi to refresh poi on map");
            setOpenToast(true);
            setPoiRadius(mapRadiusConst)
            setValue("poiRadius",mapRadiusConst)
            setPoiMarkersResponse([]);
        }

    }, [mapRadiusChange]);
    useEffect(() => {
        clearErrors();
        // setPointsRemain(0);
        if (promotionType !== "Hunt" && promotionType !== "BillBoard" && promotionType !== "Poi" && promotionHuntType !== "Hunt" && promotionHuntType !== "BillBoard" && promotionHuntType !== "Poi") {
            setPointsRemain(0);
            setTokensRemain(0);
            refetchSponsor();
            if ((!promoLoading && promotionId) || (!promoLoading && copyPromotionId)) {
                // if (isCopyPromo) refetchSponsor();
                if (PromoDetails) {
                    defaultValues = { ...PromoDetails.getPromoWithId };
                    setPromotion(PromoDetails.getPromoWithId);
                    promoInputValues(defaultValues);
                    setPromotion(defaultValues);
                    setQuizList(PromoDetails.getPromoWithId.promoQuizList ? JSON.parse(PromoDetails.getPromoWithId.promoQuizList) : PromoDetails.getPromoWithId.promoQuizList);
                }
            } else {
                defaultValues = {
                    promoCodePrefix: "",
                    promoCodePoints: "",
                    promoType: "",
                    validity: "",
                    validityDay: "",
                    promoTotalCount: "1",
                    sponsorId: "",
                    pointsAvailable: 100,
                    noPointsPerPromo: 10,
                    promoUrl: "",
                    promoSentToMail: "",
                    promoTitle: "Promo Title!",
                    promoMessage: "Promo Message",
                    promoSentTOClub: "",
                    notifyMessage: "Promo Notification",
                    huntLocation: "",
                    videoLink: "",
                    promoQuiz: ""
                };
                setPromoQuiz("");
                promoInputValues(defaultValues);
                setPromotion(defaultValues);
                setPromoCodePrefix('');
            }
        } else if (promotionType === "Hunt" || promotionHuntType === "Hunt") {
            setPointsRemain(0);
            setTokensRemain(0);
            refetchSponsor();
            if ((!huntLoading && promotionId) || (!huntLoading && copyPromotionId)) {
                const data: any = huntData.getHunt;

                defaultValues = { ...huntData.getHunt };
                let latlng: any = { lat: data.mainLat, lng: data.mainLng };
                // setPromotion(PromoDetails.getPromoWithId);
                setValue("promoCodePrefix", data.huntCodePrefix);
                setValue("promoCodePoints", data.huntCodePoints);
                setPromoCodePoints({ title: data.huntCodePoints.toString(), _id: data.huntCodePoints.toString() });
                setValue("promoType", data.__typename);
                setValue("validity", data.validity);
                setValue("validityDay", data.validityDay);
                setValue("promoTotalCount", data.noPointsPerHunt.toString());
                setValue("sponsorId", data.sponsorId);
                // setDistArr(data.points);
                setLatlng(latlng);
                // setValue("pointsAvailable",promoInputValues.pointsAvailable);
                // setValue("noPointsPerPromo",promoInputValues.noPointsPerPromo);
                // setValue("promoSentToMail",promoInputValues.promoSentToMail);
                // setValue("promoTitle",promoInputValues.promoTitle);
                // setValue("promoMessage",promoInputValues.promoMessage);
                // setValue("promoSentTOClub",promoInputValues.promoSentTOClub);
                // setValue("notifyMessage",promoInputValues.notifyMessage);
                // setValue("huntLocation",promoInputValues.huntLocation);
                setPromotionType(huntData.getHunt.__typename);
                setNoofFieldCaption("Unique Users");
                setHuntLocation(data.locationName);
                setAutoDistributeSelected(false);

                if (data.mapProperties.length > 0) {
                    let tempMapProp = JSON.parse(data.mapProperties);
                    setMapProperties(tempMapProp);
                } else {
                    setMapProperties({
                        mapLatLng: { lat: 26.02628761374811, lng: -80.20744120866775 },
                        location: '',
                        zoomPoint: 19,
                        mapRadius: 50,
                        coinDistributionRadius: 30
                    })
                }

                if (data && data.points) {
                    let tempDisArr: any = []
                    data.points.forEach((val: any) => {
                        tempDisArr.push(val.pointsValue);
                    });
                    setDistArr(tempDisArr);

                    let coinPoints: any = []
                    data.points.forEach((element: any) => {
                        coinPoints.push({ lat: element.lat, lng: element.lng, pointsValue: element.pointsValue })
                    });
                    setPoints(coinPoints);
                    setDisableDist(true);
                    setTotalCoins(data.huntCodePoints);
                }

                if (isCopyPromo) {
                    // if (data && data.points) {
                    //     let tempDisArr:any = []
                    //     data.points.forEach((val:any) => {
                    //         tempDisArr.push(val.pointsValue);
                    //     });
                    //     setDistArr(tempDisArr);
                    // }

                    setValueLocation(data.locationName);
                    setLocationName(data.locationName);
                    // setTotalCoins(data.huntCodePoints);
                    setNoCoinsValue(data.huntCodePoints);
                    setLocationSelected(true);

                    setValidityDate(moment().add(data.validityDay.toString(), 'days').format("D MMM YYYY"));

                    // let coinPoints:any = []
                    // data.points.forEach((element:any) => {
                    //     coinPoints.push({ lat: element.lat, lng: element.lng, pointsValue: element.pointsValue })
                    // });
                    // setPoints(coinPoints);
                    // setDisableDist(true);
                }

                //setValidityDate(defaultValues.validity);
                setPromotion(defaultValues);
                setVideoLink(data.videoLink);
                let selectedSponsor = getSponsor.getSponsorWithOrgId.find((i: any) => i.id === data.sponsorId);
                if (selectedSponsor) {
                    setMarkerImage(selectedSponsor.sponsorLogo);
                    setPointsRemain(selectedSponsor.availablePoints);
                    setTokensRemain(selectedSponsor.availableTokens);
                }
            } else {
                defaultValues = {
                    promoCodePrefix: "",
                    promoCodePoints: "",
                    promoType: "",
                    validity: "",
                    validityDay: "",
                    promoTotalCount: "1",
                    sponsorId: "",
                    pointsAvailable: 100,
                    noPointsPerPromo: 10,
                    promoUrl: "",
                    promoSentToMail: "",
                    promoTitle: "Promo Title!",
                    promoMessage: "Promo Message",
                    promoSentTOClub: "",
                    notifyMessage: "Promo Notification",
                    huntLocation: "",
                    videoLink: "",
                    promoQuiz: ""
                };
                promoInputValues(defaultValues);
                setPromotion(defaultValues);
                setPromoCodePrefix('');
                setPointsRemain(0);
                setTokensRemain(0);
                setTotalCoins(0);
                setLocationName('');
                setDistArr([]);
                setDisableDist(false);
                setNoCoinsValue(0);
                setPoints([]);
                setAutoDistributeSelected(false);
                setMapProperties({
                    mapLatLng: { lat: 26.02628761374811, lng: -80.20744120866775 },
                    location: '',
                    zoomPoint: 19,
                    mapRadius: 50,
                    coinDistributionRadius: 30
                })
                setPromoQuiz("");
                setQuizList([{
                    questionId: Math.floor(100000 + Math.random() * 900000),
                    questionTitle: "",
                    keyList: [
                        { id: Math.floor(100000 + Math.random() * 900000), keyName: '' }
                    ],
                    answer: null
                }])
            }
        } else if (promotionType === "BillBoard" || promotionHuntType === "BillBoard") {
            setPointsRemain(0);
            setTokensRemain(0);
            refetchSponsor();
            if ((!billboardLoading && promotionId) || (!billboardLoading && copyPromotionId)) {
                const data: any = billboardData.getBillboard;

                defaultValues = { ...billboardData.getBillboard };
                let latlng: any = { lat: data.mainLat, lng: data.mainLng };
                // setPromotion(PromoDetails.getPromoWithId);
                setValue("promoCodePrefix", data.billboardCodePrefix);
                setValue("promoCodePoints", data.billboardCodePoints);
                setPromoCodePoints({ title: data.billboardCodePoints.toString(), _id: data.billboardCodePoints.toString() });
                setBillBoardImgUrl(data.billboardImageUrl)
                setValue("promoUrl", data.billboardUrl);
                setValue("promoUrl_ios", data.billboardUrl_ios);
                if (data.__typename === 'Billboard') setValue("promoType", "BillBoard");
                else setValue("promoType", data.__typename);

                setValue("validity", data.validity);
                setValue("validityDay", data.validityDay);
                setValue("promoTotalCount", data.noPointsPerBillboard.toString());
                setValue("sponsorId", data.sponsorId);
                // setDistArr(data.points);
                setLatlng(latlng);

                if (billboardData.getBillboard.__typename === 'Billboard') setPromotionType('BillBoard');
                else setPromotionType(billboardData.getBillboard.__typename);

                setNoofFieldCaption("Unique Users");
                setHuntLocation(data.locationName);
                setAutoDistributeSelected(false);

                if (data.mapProperties.length > 0) {
                    let tempMapProp = JSON.parse(data.mapProperties);
                    setMapProperties(tempMapProp);
                } else {
                    setMapProperties({
                        mapLatLng: { lat: 26.02628761374811, lng: -80.20744120866775 },
                        location: '',
                        zoomPoint: 19,
                        mapRadius: 50,
                        coinDistributionRadius: 30
                    })
                }

                if (data && data.billboardPoints) {
                    let tempDisArr: any = []
                    data.billboardPoints.forEach((val: any) => {
                        tempDisArr.push(val.pointsValue);
                    });
                    setDistArr(tempDisArr);

                    let coinPoints: any = []
                    data.billboardPoints.forEach((element: any) => {
                        coinPoints.push({ lat: element.lat, lng: element.lng, pointsValue: element.pointsValue })
                    });
                    setPoints(coinPoints);
                    setDisableDist(true);
                    setTotalCoins(data.billboardCodePoints);
                }

                if (isCopyPromo) {
                    setValueLocation(data.locationName);
                    setLocationName(data.locationName);
                    // setTotalCoins(data.huntCodePoints);
                    setNoCoinsValue(data.billboardCodePoints);
                    setLocationSelected(true);

                    setValidityDate(moment().add(data.validityDay.toString(), 'days').format("D MMM YYYY"));
                }

                //setValidityDate(defaultValues.validity);
                setPromotion(defaultValues);
                setVideoLink(data.videoLink);
                let selectedSponsor = getSponsor.getSponsorWithOrgId.find((i: any) => i.id === data.sponsorId);
                if (selectedSponsor) {
                    setMarkerImage(selectedSponsor.sponsorLogo);
                    setPointsRemain(selectedSponsor.availablePoints);
                    setTokensRemain(selectedSponsor.availableTokens);
                }
            } else {
                defaultValues = {
                    promoCodePrefix: "",
                    promoCodePoints: "",
                    promoType: "",
                    validity: "",
                    validityDay: "",
                    promoTotalCount: "1",
                    sponsorId: "",
                    pointsAvailable: 100,
                    noPointsPerPromo: 10,
                    promoUrl: "",
                    promoUrl_ios: "",
                    promoSentToMail: "",
                    promoTitle: "Promo Title!",
                    promoMessage: "Promo Message",
                    promoSentTOClub: "",
                    notifyMessage: "Promo Notification",
                    huntLocation: "",
                    videoLink: "",
                    promoQuiz: ""
                };
                promoInputValues(defaultValues);
                setPromotion(defaultValues);
                setPromoCodePrefix('');
                setPointsRemain(0);
                setTokensRemain(0);
                setTotalCoins(0);
                setLocationName('');
                setDistArr([]);
                setDisableDist(false);
                setNoCoinsValue(0);
                setPoints([]);
                setAutoDistributeSelected(false);
                setMapProperties({
                    mapLatLng: { lat: 26.02628761374811, lng: -80.20744120866775 },
                    location: '',
                    zoomPoint: 19,
                    mapRadius: 50,
                    coinDistributionRadius: 30
                })
                setBillBoardImgUrl('');
                setPromoQuiz("");
                setQuizList([{
                    questionId: Math.floor(100000 + Math.random() * 900000),
                    questionTitle: "",
                    keyList: [
                        { id: Math.floor(100000 + Math.random() * 900000), keyName: '' }
                    ],
                    answer: null
                }])
            }
        } else if (promotionType === "Poi" || promotionHuntType === "Poi") {
            setPointsRemain(0);
            setTokensRemain(0);
            refetchSponsor();
            if ((!poiLoading && promotionId) || (!poiLoading && copyPromotionId)) {
                const data: any = poiApiData.getPoi;

                defaultValues = { ...poiApiData.getPoi };
                let latlng: any = { lat: data.mainLat, lng: data.mainLng };
                // setPromotion(PromoDetails.getPromoWithId);
                setValue("promoCodePrefix", data.poiCodePrefix);
                setValue("promoCodePoints", data.poiCodePoints);
                setPromoCodePoints({ title: data.poiCodePoints.toString(), _id: data.poiCodePoints.toString() });
                setValue("promoType", "Poi");
                setValue("validity", data.validity);
                setValue("validityDay", data.validityDay);
                setValue("promoTotalCount", data.noPointsPerPoi.toString());
                setValue("sponsorId", data.sponsorId);
                setValue("poiKeyword", data.keyword);
                setPoiKeyword(data.keyword)
                setPoiRadius(data.radius)
                // setDistArr(data.points);
                setLatlng(latlng);
                // setValue("pointsAvailable",promoInputValues.pointsAvailable);
                // setValue("noPointsPerPromo",promoInputValues.noPointsPerPromo);
                // setValue("promoSentToMail",promoInputValues.promoSentToMail);
                // setValue("promoTitle",promoInputValues.promoTitle);
                // setValue("promoMessage",promoInputValues.promoMessage);
                // setValue("promoSentTOClub",promoInputValues.promoSentTOClub);
                // setValue("notifyMessage",promoInputValues.notifyMessage);
                // setValue("huntLocation",promoInputValues.huntLocation);
                setPromotionType("Poi");
                setNoofFieldCaption("Unique Users");
                // setHuntLocation(data.locationName);
                setAutoDistributeSelected(false);

                if (data.mapProperties.length > 0) {
                    let tempMapProp = JSON.parse(data.mapProperties);
                    setMapProperties(tempMapProp);
                    getPois({lat:data.mainLat, lng:data.mainLng}, data.keyword, data.radius);
                } else {
                    setMapProperties({
                        mapLatLng: { lat: 26.02628761374811, lng: -80.20744120866775 },
                        location: '',
                        zoomPoint: 19,
                        mapRadius: 50,
                        coinDistributionRadius: 30
                    })
                }


                if (isCopyPromo) {
                    // if (data && data.points) {
                    //     let tempDisArr:any = []
                    //     data.points.forEach((val:any) => {
                    //         tempDisArr.push(val.pointsValue);
                    //     });
                    //     setDistArr(tempDisArr);
                    // }

                    setValueLocation(data.locationName);
                    setLocationName(data.locationName);
                    // setTotalCoins(data.huntCodePoints);
                    setNoCoinsValue(data.poiCodePoints);
                    setLocationSelected(true);

                    setValidityDate(moment().add(data.validityDay.toString(), 'days').format("D MMM YYYY"));

                    // let coinPoints:any = []
                    // data.points.forEach((element:any) => {
                    //     coinPoints.push({ lat: element.lat, lng: element.lng, pointsValue: element.pointsValue })
                    // });
                    // setPoints(coinPoints);
                    // setDisableDist(true);
                }

                //setValidityDate(defaultValues.validity);
                setPromotion(defaultValues);
                let selectedSponsor = getSponsor.getSponsorWithOrgId.find((i: any) => i.id === data.sponsorId);
                if (selectedSponsor) {
                    // setMarkerImage(selectedSponsor.sponsorLogo);
                    setPointsRemain(selectedSponsor.availablePoints);
                    setTokensRemain(selectedSponsor.availableTokens);
                }
            } else {
                defaultValues = {
                    promoCodePrefix: "",
                    promoCodePoints: "",
                    promoType: "",
                    validity: "",
                    validityDay: "",
                    promoTotalCount: "1",
                    sponsorId: "",
                    pointsAvailable: 100,
                    noPointsPerPromo: 10,
                    promoUrl: "",
                    promoSentToMail: "",
                    promoTitle: "Promo Title!",
                    promoMessage: "Promo Message",
                    promoSentTOClub: "",
                    notifyMessage: "Promo Notification",
                    huntLocation: "",
                    videoLink: "",
                    promoQuiz: ""
                };
                promoInputValues(defaultValues);
                setPromotion(defaultValues);
                setPromoCodePrefix('');
                setPointsRemain(0);
                setTokensRemain(0);
                setTotalCoins(0);
                setLocationName('');
                setDistArr([]);
                setDisableDist(false);
                setNoCoinsValue(0);
                setPoints([]);
                setAutoDistributeSelected(false);
                setMapProperties({
                    mapLatLng: { lat: 26.02628761374811, lng: -80.20744120866775 },
                    location: '',
                    zoomPoint: 19,
                    mapRadius: 50,
                    coinDistributionRadius: 30
                })
                setPromoQuiz("");
                setQuizList([{
                    questionId: Math.floor(100000 + Math.random() * 900000),
                    questionTitle: "",
                    keyList: [
                        { id: Math.floor(100000 + Math.random() * 900000), keyName: '' }
                    ],
                    answer: null
                }])
            }
        }
    }, [open, PromoDetails, huntData, billboardData,poiApiData, setValue]);

    useEffect(()=>{
        if(promotionType) {
            if(promotionType === "Email" || promotionType === "Notification") {
                setTabIndex(1)
            }
            else if(promotionType === "Hunt") {
                setTabIndex(2)
            }
            else if(promotionType === "video") {
                setTabIndex(3)
            }
            else if(promotionType === "BillBoard") {
                setTabIndex(4)
            }
            else if(promotionType === "Poi") {
                setTabIndex(5)
            }
        }
    },[promotionType])
    // to get presigned url from backend to upload a image.
    useEffect(() => {
        clearErrors();

        if (!urlLoading && uploadFileName.length > 0) {
            urlRefetch()
            if (urlData) {
                setUploadFileName('')
                fileUploadToS3(urlData.getPreSignedUrl.preSignedUrl)
            }
        }

    }, [uploadFileName, urlData]);

    // to upload a image to S3 using presigned url.
    const fileUploadToS3 = (objUrl: any) => {
        if (objUrl) {
            fetch(objUrl, {
                method: 'PUT',
                headers: { 'Content-Type': 'image/*' },
                body: targetFile[0]
            }).then((response) => {
                if (response && response.url) {
                    setBillBoardImgUrl(response.url.split('?')[0])
                    setLoader(false);
                }
            })
        }
    }

    const [createPromo] = useMutation(CREATE_PROMO, {
        // after updating the post, we go to the home page
        onCompleted: () => {handleCloseClick(tabIndex); setLoad(false)},
        onError: (data) => {
            setError(data.message);
            setOpenToast(true);
            setLoad(false);
        }
    });

    const handleClosed = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenToast(false);
        setLoad(false);

    };

    const [createHunt] = useMutation(CREATE_HUNT, {
        // after updating the post, we go to the home page
        onCompleted: () => {handleCloseClick(tabIndex); setLoad(false)},
        onError: (data) => {
            setError(data.message);
            setOpenToast(true);
            setLoad(false);

        }
    });

    const [createBillboard] = useMutation(CREATE_BILLBOARD, {
        // after updating the post, we go to the home page
        onCompleted: () => {handleCloseClick(tabIndex); setLoad(false)},
        onError: (data) => {
            setError(data.message);
            setOpenToast(true);
            setLoad(false);

        }
    });

    const [createPoi] = useMutation(CREATE_POI, {
        // after updating the post, we go to the home page
        onCompleted: () => {handleCloseClick(tabIndex); setLoad(false)},
        onError: (data) => {
            setError(data.message);
            setOpenToast(true);
            setLoad(false);

        }
    });

    const [updatePromo] = useMutation(UPDATE_PROMO, {
        // after updating the post, we go to the home page
        onCompleted: () => {handleCloseClick(tabIndex); setLoad(false)},
        onError: (data) => {
            setError(data.message);
            setOpenToast(true);
            setLoad(false);

        }
    });

    const onSubmit: SubmitHandler<IFormAddPromo> = (data: any) => {
        setLoad(true);
        if(parseInt(data.promoCodePoints) <=0) {
            setError(pointstoken+" per promo should be grater than 0");
            setOpenToast(true);
            return;
        }
        if (data.promoType === 'BillBoard') {

            const formData = {
                billboardCodePrefix: data.promoCodePrefix.toUpperCase(),
                billboardCodePoints: parseInt(data.promoCodePoints),
                validity: validityDate,
                validityDay: parseInt(data.validityDay),
                billboardTotalCount: (parseInt(data.promoCodePoints) * parseInt(data.promoTotalCount)),
                noPointsPerBillboard: parseInt(data.promoTotalCount),
                sponsorId: data.sponsorId,
                orgId: orgId,
                mainLat: latlng.lat,
                mainLng: latlng.lng,
                billboardImageUrl: billBoardImgUrl,
                billboardUrl: data.promoUrl,
                billboardUrl_ios: data.promoUrl_ios,
                locationName: locationName,
                billboardPoints: points,
                mapProperties: JSON.stringify(mapProperties),
                isToken : hasToken == 'true' ? true : false,
            }

            let getTotalbillboardPoints: any = getTotalPoints(getValues('promoCodePoints'), getValues('promoTotalCount'))
            const pointstoken = hasToken === 'true' ?  tokensRemain : pointsRemain;
            if (pointstoken >= getTotalbillboardPoints) {
                if (distArr.length > 0 && locationName !== "") {
                    if (noCoinsValue === totalCoins) {
                        if (isSubmit) {
                            if (points.length === distArr.length) {
                                setConfirmed(true);
                                setOpenConfirm(false);
                                createBillboard({
                                    variables:
                                        { data: { ...formData } }
                                });
                            }
                        }
                    } else {
                        setError("You missed some to place the coins.");
                        setOpenToast(true);
                    }
                } else {
                    setError("Location and Billboard distribution fields are mandatory");
                    setOpenToast(true);
                }
            } else {
                setError("Sponsor have no sufficient points balance");
                setOpenToast(true);
                setOpenConfirm(false);
            }
        } else if (data.promoType === 'Hunt') {

            const formData = {
                huntCodePrefix: data.promoCodePrefix.toUpperCase(),
                huntCodePoints: parseInt(data.promoCodePoints),
                validity: validityDate,
                validityDay: parseInt(data.validityDay),
                huntTotalCount: (parseInt(data.promoCodePoints) * parseInt(data.promoTotalCount)),
                noPointsPerHunt: parseInt(data.promoTotalCount),
                sponsorId: data.sponsorId,
                orgId: orgId,
                mainLat: latlng.lat,
                mainLng: latlng.lng,
                locationName: locationName,
                points: points,
                mapProperties: JSON.stringify(mapProperties),
                isToken : hasToken == 'true' ? true : false,
            }

            let getTotalHuntPoints: any = getTotalPoints(getValues('promoCodePoints'), getValues('promoTotalCount'))
            const pointstoken = hasToken === 'true' ?  tokensRemain : pointsRemain;
            if (pointstoken >= getTotalHuntPoints) {
                if (distArr.length > 0 && locationName !== "") {
                    if (noCoinsValue === totalCoins) {
                        if (isSubmit) {
                            if (points.length === distArr.length) {
                                setConfirmed(true);
                                setOpenConfirm(false);
                                createHunt({
                                    variables:
                                        { data: { ...formData } }
                                });
                            }
                        }
                    } else {
                        setError("You missed some to place the coins.");
                        setOpenToast(true);
                    }
                } else {
                    setError("Location and Coin distribution fields are mandatory");
                    setOpenToast(true);
                }
            } else {
                setError("Sponsor have no sufficient points balance");
                setOpenToast(true);
                setOpenConfirm(false);

            }

            // }else{
            //     setError("Please place the coin properly");
            //     setOpenToast(true);
            // }
        } else if (data.promoType === 'Poi') {
            const formData = {
                poiCodePrefix: data.promoCodePrefix.toUpperCase(),
                poiCodePoints: parseInt(data.promoCodePoints),
                validity: validityDate,
                validityDay: parseInt(data.validityDay),
                poiTotalCount: (parseInt(data.promoCodePoints) * parseInt(data.promoTotalCount)),
                noPointsPerPoi: parseInt(data.promoTotalCount),
                sponsorId: data.sponsorId,
                orgId: orgId,
                mainLat: latlng.lat,
                mainLng: latlng.lng,
                locationName: locationName,
                points: points,
                mapProperties: JSON.stringify(mapProperties),
                isToken : hasToken == 'true' ? true : false,
                keyword : data.poiKeyword,
                radius  : parseFloat(poiRadius)
            }
            console.log("formData",formData)
            
            if (isSubmit) {
                    setConfirmed(true);
                    setOpenConfirm(false);
                    console.log("submit....")
                    createPoi({
                        variables:
                            { data: { ...formData } }
                    });
            }
            // let getTotalPoiPoints: any = getTotalPoints(getValues('promoCodePoints'), getValues('promoTotalCount'))
            // const pointstoken = hasToken === 'true' ?  tokensRemain : pointsRemain;
            // if (pointstoken >= getTotalPoiPoints) {
            //     if (distArr.length > 0 && locationName !== "") {
            //         if (noCoinsValue === totalCoins) {
            //             if (isSubmit) {
            //                 if (points.length === distArr.length) {
            //                     setConfirmed(true);
            //                     setOpenConfirm(false);
            //                     createHunt({
            //                         variables:
            //                             { data: { ...formData } }
            //                     });
            //                 }
            //             }
            //         } else {
            //             setError("You missed some to place the coins.");
            //             setOpenToast(true);
            //         }
            //     } else {
            //         setError("Location and Coin distribution fields are mandatory");
            //         setOpenToast(true);
            //     }
            // } else {
            //     setError("Sponsor have no sufficient points balance");
            //     setOpenToast(true);
            //     setOpenConfirm(false);

            // }

            // end
            // }else{
            //     setError("Please place the coin properly");
            //     setOpenToast(true);
            // }
        }  else if (promoId) {
            setPromoType(data.promoType === 'Email' || data.promoType === 'Notification' ? 0 : 2);
            updatePromo({
                variables:
                {
                    id: promoId,
                    data:
                    {
                        promoCodePrefix: data.promoCodePrefix.toUpperCase(),
                        promoCodePoints: parseInt(data.promoCodePoints),
                        promoType: data.promoType,
                        validity: validityDate,
                        validityDay: parseInt(data.validityDay),
                        promoTotalCount: parseInt(data.promoTotalCount),
                        sponsorId: data.sponsorId,
                        orgId: orgId,
                        pointsAvailable: parseInt(data.promoTotalCount),
                        noPointsPerPromo: parseInt(data.promoTotalCount),
                        promoSentToMail: data.promoSentToMail ? data.promoSentToMail : "",
                        // promoTitle:data.promoTitle?data.promoTitle:"",
                        promoTitle: data.promoType === 'Email' ? data.promoTitle : data.promoMessage,
                        promoMessage: data.promoMessage ? data.promoMessage : "",
                        // promoSentTOClub : data.promoSentTOClub ? data.promoSentTOClub : "",
                        promoSentTOClub: "all",
                        videoLink: data.videoLink ? data.videoLink : "",
                        promoUrl: data.promoUrl,
                        promoQuiz: (promoQuiz === 'Yes' ? true : false),
                        promoQuizList: JSON.stringify(quizList),
                        isToken : hasToken == 'true' ? true : false,
                        tokensAvailable: parseInt(data.promoTotalCount),
                        // notifyMessage : data.notifyMessage,
                        // huntLocation : data.huntLocation
                    }
                }
            })
        } else {

            if (parseInt(data.promoTotalCount) > 0 && !promoMessageValidation) {
                setPromoType(data.promoType === 'Email' || data.promoType === 'Notification' ? 0 : 2);

                let validation: boolean = true;
                if (promoQuiz === 'Yes') validation = validatPromoQuiz();
                let promodata:any=
                {
                    promoCodePrefix: data.promoCodePrefix.toUpperCase(),
                    promoCodePoints: parseInt(data.promoCodePoints),
                    promoType: data.promoType,
                    validity: validityDate,
                    validityDay: parseInt(data.validityDay),

                    promoTotalCount: parseInt(data.promoTotalCount),
                    sponsorId: data.sponsorId,
                    sponsorLogo: sponsorLogo,
                    orgId: orgId,
                    pointsAvailable: parseInt(data.promoTotalCount),
                    noPointsPerPromo: parseInt(data.promoTotalCount),

                    promoSentToMail: data.promoSentToMail ? data.promoSentToMail : "",
                    promoTitle: data.promoType === 'Email' ? data.promoTitle : "",
                    promoMessage: data.promoMessage ? data.promoMessage : "",
                    // promoSentTOClub : data.promoSentTOClub ? data.promoSentTOClub : "",
                    promoSentTOClub: "all",
                    videoLink: data.videoLink ? data.videoLink : "",
                    promoUrl: data.promoUrl,
                    promoQuiz: (promoQuiz === 'Yes' ? true : false),
                    promoQuizList: JSON.stringify(quizList),
                    isToken : hasToken == 'true' ? true : false,
                    tokensAvailable: parseInt(data.promoTotalCount),

                    // notifyMessage : data.notifyMessage,
                    // huntLocation : data.huntLocation
                }

                if (validation) {
                    createPromo({
                        variables:
                        {
                            data:
                            {
                                promoCodePrefix: data.promoCodePrefix.toUpperCase(),
                                promoCodePoints: parseInt(data.promoCodePoints),
                                promoType: data.promoType,
                                validity: validityDate,
                                validityDay: parseInt(data.validityDay),

                                promoTotalCount: parseInt(data.promoTotalCount),
                                sponsorId: data.sponsorId,
                                sponsorLogo: sponsorLogo,
                                orgId: orgId,
                                pointsAvailable: parseInt(data.promoTotalCount),
                                noPointsPerPromo: parseInt(data.promoTotalCount),

                                promoSentToMail: data.promoSentToMail ? data.promoSentToMail : "",
                                promoTitle: data.promoType === 'Email' ? data.promoTitle : "",
                                promoMessage: data.promoMessage ? data.promoMessage : "",
                                // promoSentTOClub : data.promoSentTOClub ? data.promoSentTOClub : "",
                                promoSentTOClub: "all",
                                videoLink: data.videoLink ? data.videoLink : "",
                                promoUrl: data.promoUrl,
                                promoQuiz: (promoQuiz === 'Yes' ? true : false),
                                promoQuizList: JSON.stringify(quizList),
                                isToken : hasToken == 'true' ? true : false,
                                tokensAvailable: parseInt(data.promoTotalCount),

                                // notifyMessage : data.notifyMessage,
                                // huntLocation : data.huntLocation
                            }
                        }
                    })
                } else {
                    setError("Promo quiz questions and answers should not be empty. Minimum 2 answers required for each question with 1 correct answer");
                    setOpenToast(true);
                }
            } else {
                !promoMessageValidation && setPromoTotalCountValidation("It should be greater then 0");
            }
        }
    }

    const validatPromoQuiz = () => {
        let validation: boolean = true;

        if (quizList && quizList.length > 0) {
            quizList.forEach((item: any, index: any) => {
                if (item.questionTitle.trim() === '' || item.answer === null) validation = false;

                if (item.keyList && item.keyList.length > 0) {
                    if (item.keyList.length < 2) validation = false;
                    item.keyList.forEach((key: any, idx: any) => {
                        if (key.keyName.trim() === '') validation = false;
                    });
                }
            });
        } else validation = false;

        return validation;
    }

    const handleClose = () => {
        setLocationName("");
        setNoCoinsValue(0)
        setPoints([]);
        setPromoQuiz("");
        setDistArr([]);
        setPoiMarkersResponse([]);
        setDisableDist(true);
        setBillBoardImgUrl('');
        handleCloseClick(currentTab);
        setMergedImage('');
    }

    const handleMapReset = () => {
        setConfirmed(true);
        setNoCoinsValue(parseInt(getValues('promoCodePoints')));
        setPromoCodePoints({ title: getValues('promoCodePoints').toString(), _id: getValues('promoCodePoints').toString() });
        if (promotionType !== 'BillBoard') {
            setDistArr([]);
            setTotalCoins(0);
        }
        setPoints([]);
        setDisableDist(false);
        setOpenConfirm(false);
        setConfirmResetMap(false);
    }

    const handleChange = (e: React.ChangeEvent<any>) => {
        const targetType = (e.target as HTMLInputElement).value;
        const targetName: string = (e.target as HTMLInputElement).name;

        setValue(targetName, targetType);
        setMergedImage('');
        setBillBoardImgUrl('');
        if (targetName !== "promoQuiz") setPromotionType(targetType);

        setNoofFieldCaption("Unique Users");

        if (targetName === "promoType") {
            clearErrors(targetName);
        }

        if (targetName === "promoQuiz") {
            setPromoQuiz(targetType);
            setQuizList([{
                questionId: Math.floor(100000 + Math.random() * 900000),
                questionTitle: "",
                keyList: [
                    { id: Math.floor(100000 + Math.random() * 900000), keyName: '' }
                ],
                answer: null
            }])
        }

        if (targetName === "Hunt" || targetType === "Hunt" || targetName === "Poi" || targetType === "Poi") {
            setLatlng({ lat: 26.02628761374811, lng: -80.20744120866775 });
            setNoofFieldCaption("Unique Users");
        }

        if ((targetName === "Video" || targetType === "video") && !promotionId && !copyPromotionId) {
            setVideoLink(" ");
            setVideoLink("");
        }

        // if(targetName !== "BillBoard") setAvatarImageToCrop([])
    };

    const handleChangeRadius =(type:string) => {
        const newRadius = type === 'add' ? parseInt(poiRadius) * 2 : parseInt(poiRadius) / 2;
        if(newRadius >= 200) {
            setPoiRadius(newRadius.toString());
            let newmapProperties = mapProperties
            newmapProperties.zoomPoint= type === 'add' ? newmapProperties.zoomPoint-1 : newmapProperties.zoomPoint+1
           setMapProperties(newmapProperties) 
        }
    }
    const handleChangeField = (e: React.ChangeEvent<any>) => {
        const targetValue = (e.target as HTMLInputElement).value;
        const targetName: string = (e.target as HTMLInputElement).name;
        setValue(targetName, targetValue);

        //const clearField:string[] = targetName !="undefined" ? targetName : "";
        //clearErrors(clearField);

        if (targetName === "sponsorId") {
            clearErrors(targetName);

            let selectedSponsor = getSponsor.getSponsorWithOrgId.find((i: any) => i.id === targetValue);
            if (selectedSponsor) {
                setSponsorLogo(selectedSponsor.sponsorLogo);
                setMarkerImage(selectedSponsor.sponsorLogo);
                setPointsRemain(selectedSponsor.availablePoints);
                setTokensRemain(selectedSponsor.availableTokens);
            } else {
                setSponsorLogo('');
                setMarkerImage('');
                setPointsRemain(0);
                setTokensRemain(0);
            }
        }

        if (targetName === "promoCodePrefix") {
            let value = targetValue.toUpperCase();
            setPromoCodePrefix(value);
            clearErrors(targetName);
        }

        // if(targetName === "promoCodePoints"){
        //     clearErrors(targetName);

        //     // to show confirmation message for Hunt promo.
        //     if (distArr.length > 0) {
        //         setConfirmMessage("Changing this value will reset the points to coin distribution. Would you like to proceed ?");
        //         setOpenConfirm(true);
        //         setConfirmResetMap(true);
        //     } else {
        //         setNoCoinsValue(parseInt(targetValue));
        //     }
        // }

        if (targetName === "promoTotalCount") {
            clearErrors(targetName);

            if (parseInt(targetValue) <= 0) {
                setIsSubmit(false);
                setPromoTotalCountValidation("It should be greater than 0");
            } else {
                setIsSubmit(true);
                setPromoTotalCountValidation("");
            }

            setNoofPromo(parseInt(targetValue));
        }

        if (targetName === "validityDay") {
            setValidityDate(moment().add(targetValue, 'days').format("D MMM YYYY"));
            clearErrors(targetName);
        }

        if (targetName === "promoMessage") {
            if (targetValue.length >= 100) {
                setPromoMessageValidation(true);
            } else {
                setPromoMessageValidation(false);
            }
            setPromoTitle(targetValue)
            clearErrors(targetName);
        }

        if (targetName === "videoLink") {
            setVideoLink(targetValue);
            clearErrors(targetName);
        }

        if (targetName === "poiKeyword") {
            setPoiKeyword(targetValue);
            clearErrors(targetName);
            setPoiMarkersResponse([]);
        }

        if (targetName === "poiRadius") {
            setPoiRadius(targetValue);
            clearErrors(targetName);
        }

        setPromotion({ ...promotion, [targetName]: targetValue });
    };
    useEffect(() => {
        let properties = {...mapProperties}
        properties.mapRadius = poiRadius
    setMapProperties(properties)
    setPoiMarkersResponse([]);
    },[poiRadius])
    const handleBlurPointsPerPromo = (e: any) => {
        const targetValue = (e.target as HTMLInputElement).value;

        if (!isNaN(parseInt(targetValue))) {
            if (distArr.length > 0) {
                let showConfirmMsg = false;
                if (promotionType === 'BillBoard') {
                    if (distArr[0] !== parseInt(targetValue)) {
                        showConfirmMsg = true;

                        // below code for billboard feature.
                        let tempArr = []
                        tempArr.push(parseInt(targetValue));
                        setDistArr(tempArr)
                        setTotalCoins(parseInt(targetValue));
                    }
                } else if (promotionType !== 'BillBoard') showConfirmMsg = true;

                if (showConfirmMsg) {
                    setConfirmMessage("Changing this value will reset the points to coin distribution. Would you like to proceed ?");
                    setOpenConfirm(true);
                    setConfirmResetMap(true);
                }
            } else {
                setNoCoinsValue(parseInt(targetValue));
                setPromoCodePoints({ title: targetValue.toString(), _id: targetValue.toString() });

                // below code for billboard feature.
                if (promotionType === 'BillBoard') {
                    let tempArr = []
                    tempArr.push(parseInt(targetValue));
                    setDistArr(tempArr)
                    setTotalCoins(parseInt(targetValue));
                }
            }
        }
    }

    const handleChangePointsPerPromo = (e: any, source = "elm") => {
        if (source === "elm") {
            const targetValue = (e.target as HTMLInputElement).value;
            const targetName: string = (e.target as HTMLInputElement).name;
            setValue(targetName, targetValue);
        } else {
            setValue("promoCodePoints", e);

            if (!isNaN(parseInt(e))) {
                // to show confirmation message for Hunt promo.
                if (distArr.length > 0) {
                    // setConfirmMessage("Changing this value will reset the points to coin distribution. Would you like to proceed ?");
                    // setOpenConfirm(true);
                    // setConfirmResetMap(true);
                } else {
                    setNoCoinsValue(parseInt(e));

                    // below code for billboard feature.
                    if (promotionType === 'BillBoard') {
                        let tempArr = []
                        tempArr.push(parseInt(e));
                        setDistArr(tempArr)
                        setTotalCoins(parseInt(e));
                    }
                }
            }
        }
        if(promotionType === "Poi") {
            setPoiMarkersResponse([]);
        }
    }

    const distribution = (value: any) => {
        var sum = distArr.reduce(function (a: number, b: number) {
            return a + b;
        }, 0);
        if (sum + value <= noCoinsValue) {
            setTotalCoins(sum + value);
            setDistArr([...distArr, value]);
            if (sum + value === noCoinsValue) {
                setDisableDist(true);
            }

        } else if (sum >= noCoinsValue) {
            setDisableDist(true);
        }
    }

    // const resetDist = () => {
    //     setDistArr([]);
    // }

    const getOccurrence = (value: number) => {
        return distArr.filter((v: number) => (v === value)).length;
    }

    const plusDistri = (value: number) => {
        if (!promotionId) {
            //points array should be empty
            var sum = distArr.reduce(function (a: number, b: number) {
                return a + b;
            }, 0);
            if (sum + value <= noCoinsValue) {
                setPoints([]);
                setAutoDistributeSelected(false);
                setTotalCoins(sum + value);
                setDistArr([...distArr, value]);
                if (sum + value === noCoinsValue) {
                    setDisableDist(true);
                }

            } else if (sum >= noCoinsValue) {
                setDisableDist(true);
            }
        }
    }

    const minusDistri = (value: number) => {
        if (!promotionId) {
            var sum = distArr.reduce(function (a: number, b: number) {
                return a + b;
            }, 0);

            if (getOccurrence(value) > 0) {
                for (var i = 0; i < distArr.length; i++) {
                    if (distArr[i] === value) {
                        distArr.splice(i, 1);
                        setPoints([]);
                        setAutoDistributeSelected(false);
                        setDistArr([...distArr]);
                        setTotalCoins(sum - value);
                        setDisableDist(false);
                        return false;
                    }
                }
            }
        }
    }

    // const handleSnackClose = (event?: React.SyntheticEvent, reason?: string) => {
    //     if (reason === 'clickaway') {
    //         return;
    //     }
    //     setOpenSnack(false);
    // };

    const handleDistributedCoinsCheckBox = () => {
        setAutoDistributeSelected(!autoDistributeSelected)
        setPoints([]);
    }

    const getTotalPoints = (a: any, b: any) => {
        if(promotionType == "Poi") {
            return a * b * poiMarkersResponse.length;            
        }
        return a * b;
    }

    const classes = useStyles();

    const handleResetGMap = (obj: any) => {
        setAutoDistributeSelected(false);
        setPoints([]);
        setMapProperties(obj);
        setLatlng(obj.mapLatLng);
        if (obj.location.length > 0) {
            setLocationName(obj.location);
            setValueLocation(obj.location);
        }
    }

    const handleUpdateQuiz = (obj: any) => {
        setQuizList(obj);
    }

    // to upload selected images to S3.
    const uploadImage = (e: React.ChangeEvent<any>) => {
        const targetFiles: any = e.target.files;

        if (targetFiles.length === 0) return;

        if (targetFiles[0].type === "image/png" || targetFiles[0].type === "image/jpeg") {
            setLoader(true);
            if (targetFiles.length > 0) {
                // After passing targetFiles and file name then, upload logic to S3 using presigned url written in useeffect.
                setUploadFileName(targetFiles[0].name)
                setTargetFile(targetFiles)

                if (promotionType === 'BillBoard') {
                    handleMapReset();
                    setMergedImage('');
                    setAvatarImageToCrop(targetFiles);
                }
            }
        } else {
            setError("Please upload image format as png or jpg.");
            setOpenToast(true);
        }
    }

    const handleMergeImages = async () => {
        let canvas = setEditorRef.current.getImage().toDataURL('image/png', 1.0);
        let convertedImage: any = await getResizedUrl(canvas);

        const mImage = await mergeImages([{ src: convertedImage }, { src: borderImage }], { width: 430, height: 185 }).then((b64: any) => b64);

        setMergedImage(mImage);
    }

    const handleZoomChange = async (event: any, newValue: any) => {
        setZoomValue(newValue);

        handleMergeImages();
    };

    const getResizedUrl = (base64url: any) => {
        // to get base64 url for selected org logo position.
        return new Promise((resolve) => {
            let img = new Image()
            img.src = base64url;

            img.onload = () => {
                let canvas = document.createElement('canvas');
                canvas.width = 430;
                canvas.height = 185;
                let ctx: any = canvas.getContext('2d');
                ctx.drawImage(img, 19, 10, 380, 165); // here as => 39+39+138 = 216 is coin image actual width and height.
                resolve(canvas.toDataURL());
            }
        })
    }

    //ToolTip
    const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
        },
    }));

    const handleCopy = (promoCodePrefix:string)=> {

        setTooltipTitle("Copied")
        navigator.clipboard.writeText(code + promoCodePrefix);
        setTimeout(()=> {
            setTooltipTitle("Copy")
        },2000)

    }

    return (
        <Dialog open={onOpen} onClose={handleClose} aria-labelledby="form-dialog-title" className="common-dialog-container org-container" disableBackdropClick disableEscapeKeyDown>
            <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
                <DialogContent className="pad0">
                    <Grid className="form-grid" container sm={12} spacing={2}>
                        <Grid item sm={12}>
                            <h5 id="form-dialog-title" className="section-heading pl-10">{promotionId ? "VIEW" : "CREATE"} Promotions</h5>
                        </Grid>
                        <Grid container className="borderR padd-20 pt-0" sm={6} xs={12} spacing={3}>
                            <Grid container sm={12} spacing={3}>
                                <Grid item sm={6} className='pl-0'>
                                    <div className="input-group  mt-0">
                                        <label className="input-label">Promotion Type</label>
                                        <Controller
                                            name="promoType"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ value }) => (
                                                <Select displayEmpty name="promoType" value={value} disabled={fieldEnabled} className={`custom-select form-select  ${errors.promoType && 'error'}`} onChange={(e) => handleChange(e)}>
                                                    <MenuItem value="">Select</MenuItem>
                                                    {/* <MenuItem value={"Notification"}>App Notification</MenuItem> */}
                                                    <MenuItem value={"Email"}>Email Coupon</MenuItem>
                                                    <MenuItem value={"video"}>Video Quiz</MenuItem>
                                                    <MenuItem value={"Hunt"}>AR Hunt</MenuItem>
                                                    <MenuItem value={"BillBoard"}>Virtual Billboard</MenuItem>
                                                    <MenuItem value={"Poi"}>POI</MenuItem>
                                                </Select>
                                            )}
                                        />
                                        {errors.promoType && errors.promoType.type === "required" && (<label className="error-message">Field is required</label>)}
                                    </div>
                                </Grid>
                                <Grid item sm={6} className='pr-0'>
                                    <div className="input-group mt-0">
                                        <label className="input-label">Sponsor</label>
                                        <Controller
                                            name="sponsorId"
                                            rules={{ required: true }}
                                            control={control}
                                            render={({ value }) => (
                                                <Select displayEmpty name="sponsorId" disabled={fieldEnabled} className={`custom-select form-select  ${errors.sponsorId && 'error'}`} value={value} onChange={(e) => handleChangeField(e)}
                                                >
                                                    <MenuItem value="">Select</MenuItem>
                                                    {sponsorLoading ? (
                                                        <MenuItem value={"Loading"}>Loading ...</MenuItem>
                                                    ) : (
                                                        getSponsor.getSponsorWithOrgId.map((sponsor: any) => (
                                                            <MenuItem key={sponsor.id} data-img={sponsor.sponsorLogo} value={sponsor.id}>{sponsor.sponsorName}</MenuItem>
                                                        ))
                                                    )}
                                                </Select>
                                            )
                                            }

                                        />
                                        {errors.sponsorId && errors.sponsorId.type === "required" && (<label className="error-message">Field is required</label>)}
                                    </div>
                                </Grid>
                                <Grid item sm={6} className='pl-0'>
                                    <div className="input-group">
                                        <label className="input-label">{promotionType === 'Hunt' ? 'Hunt Code' : promotionType === 'BillBoard' ? 'Bill Board Code' : 'Promo Code'}</label>
                                        <span className="orgCodePrefix">{code}</span>
                                        <Controller control={control} name="promoCodePrefix" rules={{ required: true, }} render={({ value }) => (<TextField autoComplete='off' name="promoCodePrefix" disabled={fieldEnabled} value={getValues('promoCodePrefix')} placeholder={promotionType === 'Hunt' ? 'Hunt Code' : 'Promo Code'} onChange={(e) => handleChangeField(e)} className={`promoCodePrefix form-fields ${errors.promoCodePrefix && 'error'}`} />)} />
                                        {errors.promoCodePrefix && errors.promoCodePrefix.type === "validate" && (<label className="error-message">Code should be Max 12 Characters</label>)}
                                        {errors.promoCodePrefix && errors.promoCodePrefix.type === "required" && <label className="error-message">Field is required</label>}
                                    </div>
                                </Grid>
                                <Grid item sm={6} className='pr-0'>
                                    <div className="input-group">
                                        <label className="input-label" >{promotionType === 'Hunt' ? pointstoken+' per Hunt' : promotionType === 'BillBoard' ? pointstoken+' per Bill Board' : promotionType === 'Poi' ? pointstoken+' per Poi' : pointstoken+' per Promo'}</label>
                                        <Controller
                                            name="promoCodePoints"
                                            control={control}
                                            rules={{ required: true,
                                                pattern: /^[0-9]*$/,
                                                validate: (value) => value > 0,
                                             }}
                                            render={({ value }) => (
                                                <Autocomplete
                                                    freeSolo
                                                    id="free-solo-demo"
                                                    classes={{ paper: classes.paper }}
                                                    value={promoCodePoints.title}
                                                    options={pointsOptions.map((option) => option.title)}
                                                    disabled={fieldEnabled}
                                                    getOptionLabel={option => option}
                                                    className={`form-fields ${errors.promoCodePoints && "error"}`}
                                                    // getOptionSelected={(option, value) => option._id === value._id}
                                                    onChange={(e, value: any) => handleChangePointsPerPromo(value, "auto")}
                                                    renderOption={(option, { selected }) => (
                                                        <React.Fragment>{formatCurrency(option, opt)}</React.Fragment>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField {...params} type="text" name="promoCodePoints" placeholder="Select" value={promoCodePoints.title} required onChange={(e) => handleChangePointsPerPromo(e, "elm")} onBlur={(e) => handleBlurPointsPerPromo(e)} />
                                                    )}
                                                />
                                            )}
                                        />
                                        {errors && errors.promoCodePoints && errors.promoCodePoints.type === "validate" && (<label className="error-message">Value should be more than 0</label>)}
                                        {errors && errors.promoCodePoints && errors.promoCodePoints.type === "pattern" && (<label className="error-message">Only number is allow</label>)}
                                        {errors.promoCodePoints && errors.promoCodePoints.type === "required" && (<label className="error-message">Field is required</label>)}
                                    </div>
                                </Grid>
                                <Grid item sm={6} className='pl-0'>
                                <div className="input-group">
                                        <label className="input-label" >No.of {noofFieldCaption}</label>
                                        <Controller control={control}
                                          rules={{ 
                                            required: true, 
                                            pattern: /^[0-9]*$/,
                                            validate: (value) => value.length > 0
                                         }} 
                                         name="promoTotalCount" 
                                         type="text" 
                                         render={({ value }) => (
                                         <TextField 
                                         type="text" 
                                        //  disabled={fieldEnabled}
                                          value={value} 
                                          name="promoTotalCount" 
                                          placeholder={"No of " + noofFieldCaption} 
                                          onChange={(e) => { 
                                            handleChangeField(e);
                                          }} 
                                          className={`form-fields ${errors.promoTotalCount && 'error'
                                            }`} 
                                          />
                                          )} 
                                          />
                                        {errors.promoTotalCount && errors.promoTotalCount.type === "required" && (<label className="error-message">Field is required</label>)}
                                        {promoTotalCountValidation && (<label className="error-message">{promoTotalCountValidation}</label>)}
                                        {errors.promoTotalCount && errors.promoTotalCount.type === "pattern" && (<label className="error-message">Only number is allow</label>)}
                                    </div>
                                </Grid>
                                <Grid item sm={6} className='pr-0'>
                                    <div className="input-group">
                                        <label className="input-label">Validity</label>
                                        <Controller
                                            name="validityDay"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ value }) => (
                                                <Select displayEmpty name="validityDay" disabled={fieldEnabled} value={value} className={`custom-select form-select  ${errors.validityDay && 'error'}`} onChange={(e) => handleChangeField(e)}
                                                >
                                                    <MenuItem value={""}>Select</MenuItem>
                                                    <MenuItem value={"1"}>1 Day</MenuItem>
                                                    <MenuItem value={"7"}>7 Days</MenuItem>
                                                    <MenuItem value={"30"}>30 Days</MenuItem>
                                                    <MenuItem value={"90"}>90 Days</MenuItem>
                                                    <MenuItem value={"180"}>180 Days</MenuItem>
                                                    <MenuItem value={"360"}>360 Days</MenuItem>
                                                </Select>
                                            )
                                            }
                                        />
                                        {errors.validityDay && errors.validityDay.type === "required" && (<label className="error-message">Field is required</label>)}
                                    </div>
                                </Grid>
                                {promotionType === "Notification"
                                    ? (
                                        <Grid item sm={12} className='pl-0  pr-0'>
                                            <div className="input-group">
                                                <label className="input-label" >URL (Optional)</label>
                                                <Controller control={control} rules={{ required: false, pattern: /(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/ }} name="promoUrl" render={({ value }) => (<TextField type="text" value={value} disabled={fieldEnabled} name="promoUrl" placeholder="https://www.pointsville.com/" className={`form-fields  ${errors.promoUrl && 'error'}`} onChange={(e) => handleChangeField(e)} />)} />
                                                {errors.promoUrl && errors.promoUrl.type === "pattern" && (<label className="error-message">Field should be in url format like https://www.pointsville.com/</label>)}
                                            </div>
                                        </Grid>
                                    ) : ''
                                }
                            </Grid>

                            <Grid container className="promo-dynfield-container" sm={12} xs={12}>
                                    {
                                        promotionType !== "Poi" ?
                                            <React.Fragment>
                                                <Grid item sm={6}>
                                                    <label className="input-label cust-pts">Promotion Budget <br /><span>{getTotalPoints(getValues('promoCodePoints'), getValues('promoTotalCount'))} {pointstoken}</span></label>
                                                </Grid>
                                                <Grid item sm={6}>
                                                    {
                                                        hasToken === 'true' ?
                                                            <label className="input-label ml-12 cust-pts">Tokens Remaining <br /><span>{tokensRemain > 0 ? formatCurrency(tokensRemain - getTotalPoints(getValues('promoCodePoints'), getValues('promoTotalCount')), opt) : 0}</span></label>
                                                        : <label className="input-label ml-12 cust-pts">Points Remaining <br /><span>{pointsRemain > 0 ? formatCurrency(pointsRemain - getTotalPoints(getValues('promoCodePoints'), getValues('promoTotalCount')), opt) : 0}</span></label>
                                                        }

                                                </Grid>
                                            </React.Fragment>
                                        :''
                                    }

                                {promotionType === "BillBoard" &&
                                    <Grid container item sm={12} xs={12} className='pad0'>
                                        <Grid item sm={12} className='pl-0  pr-0'>
                                            <div className="input-group mt-0">
                                                <label className="input-label" >Bill Board Image (Recommended Size: 430px by 185px)</label>
                                                <div className='bb-disp-flex'>
                                                    {billBoardImgUrl.length > 0
                                                        ? (
                                                            // Display bill board image
                                                            <div className='bb-thumbnail-block' style={{display:"block"}}>
                                                                {loader && <Loader size="60px" />}
                                                                <>
                                                                    <div className={avatarImageToCrop.length > 0 ? 'avatar-block crop' : 'avatar-block border'}>
                                                                        <AvatarEditor
                                                                            ref={setEditorRef}
                                                                            className='avatar-make-up'
                                                                            image={avatarImageToCrop[0]}
                                                                            border={10}
                                                                            color={[0, 0, 0, 0.36]} // RGBA
                                                                            scale={zoomValue}
                                                                            // rotate={0}
                                                                            borderRadius={10}
                                                                            onPositionChange={handleMergeImages}
                                                                            onImageReady={handleMergeImages}
                                                                            height={185}
                                                                            style={{width : "100%", height : "185px"}}
                                                                        />

                                                                        {/* <div className="borderImage"></div> */}

                                                                     </div>

                                                                        <div className='avatar-default-block zero-width'>
                                                                            <img className='camera-img' src={CamerLogo} alt='Avatar default logo' />
                                                                            <input type="file" className="default-file-type zero-height" onChange={(e) => uploadImage(e)} />
                                                                        </div>
                                                                </>

                                                            </div>
                                                        ) : (
                                                            <div className='bb-img-block'>
                                                                {/* create bill board image */}
                                                                    <>
                                                                        <img className='default-avatar-img' src={AvatarDefault} alt='Avatar default logo' />
                                                                        <div className='title'>Upload Image</div>
                                                                        {/* <input type="file" className="default-file-type" onChange={(e) => uploadImage(e)} /> */}
                                                                        <Controller control={control} rules={{ required: true}} name="file" render={({ value }) => (<input type="file" className="default-file-type zero-height" onChange={(e) => uploadImage(e)} />)} />
                                                                    </>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                {
                                                    billBoardImgUrl.length > 0 ?
                                                <div className="zoom-container">
                                                                <Typography variant="caption" id="range-slider" gutterBottom>Zoom</Typography>
                                                                <Slider value={zoomValue} onChange={handleZoomChange} step={0.1} min={.5} max={5} aria-labelledby="continuous-slider" />
                                                            </div>
                                                    :<div></div>
                                                }
                                                
                                                {errors.file && errors.file.type === "required" && (<label className="error-message">Field is required</label>)}

                                            </div>
                                        </Grid>

                                        <Grid item sm={12} className='pl-0  pr-0'>
                                            <div className="input-group">
                                                <label className="input-label" >URL for Android devices (Optional)</label>
                                                <Controller control={control} rules={{ required: false, pattern: /(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/ }} name="promoUrl" render={({ value }) => (<TextField type="text" value={value} disabled={fieldEnabled} name="promoUrl" placeholder="https://www.pointsville.com/" className={`form-fields  ${errors.promoUrl && 'error'}`} onChange={(e) => handleChangeField(e)} />)} />
                                                {errors.promoUrl && errors.promoUrl.type === "pattern" && (<label className="error-message">Field should be in url format like https://www.pointsville.com/</label>)}
                                                {errors.promoUrl && errors.promoUrl.type === "required" && (<label className="error-message">Field is required</label>)}
                                            </div>
                                        </Grid>
                                        <Grid item sm={12} className='pl-0  pr-0'>
                                            <div className="input-group">
                                                <label className="input-label" >URL for Apple devices (Optional)</label>
                                                <Controller control={control} rules={{ required: false, pattern: /(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/ }} name="promoUrl_ios" render={({ value }) => (<TextField type="text" value={value} disabled={fieldEnabled} name="promoUrl_ios" placeholder="https://www.pointsville.com/" className={`form-fields  ${errors.promoUrl_ios && 'error'}`} onChange={(e) => handleChangeField(e)} />)} />
                                                {/* {errors.promoUrl_ios && errors.promoUrl_ios.type === "pattern" && (<label className="error-message">Field should be in url format like https://www.pointsville.com/</label>)} */}
                                            </div>
                                        </Grid>
                                    </Grid>
                                }

                                <Grid item sm={12}>
                                    {/* Email Promo Info Block */}
                                    {promotionType === "Email" &&
                                        <div className="info-section email-info">
                                            <label className="section-heading sub-heading">Email info</label>
                                            <Grid item sm={12}>
                                                <div className="input-group">
                                                    <label className="input-label" >Email</label>
                                                    <Controller control={control} rules={{ required: true, pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ }} name="promoSentToMail" render={({ value }) => (<TextField type="text" value={value} disabled={fieldEnabled} name="promoSentToMail" placeholder="example@gmail.com" className="form-fields" onChange={(e) => handleChangeField(e)} />)} />
                                                    {errors.promoSentToMail && errors.promoSentToMail.type === "required" && (<label className="error-message">Field is required</label>)}
                                                    {errors.promoSentToMail && errors.promoSentToMail.type === "pattern" && (<label className="error-message">Field should be in mail format</label>)}
                                                </div>
                                            </Grid>
                                            <Grid item sm={12}>
                                                <div className="input-group">
                                                    <label className="input-label" >Title</label>
                                                    <Controller control={control} name="promoTitle" render={({ value }) => (<TextField type="text" disabled={fieldEnabled} value={value} name="promoTitle" placeholder="Promo Title" className="form-fields" onChange={(e) => handleChangeField(e)} />)} />
                                                </div>
                                            </Grid>
                                            <Grid item sm={12}>
                                                <div className="input-group textarea-group">
                                                    <label className="input-label">Message</label>
                                                    <Controller control={control} name="promoMessage" rules={{ required: false }} render={({ value }) => (<TextareaAutosize style={{resize: "vertical",minHeight:"20px"}} value={value} disabled={fieldEnabled} aria-label="minimum height" rowsMin={3} placeholder="Address" name="promoMessage" className="form-fields textarea-field MuiInputBase-input MuiInput-input" onChange={(e) => handleChangeField(e)} />)} />
                                                    {promoMessageValidation && (<label className="error-message">Message should be Max 100 Characters</label>)}
                                                </div>
                                            </Grid>
                                            <Snackbar open={openToast} autoHideDuration={3000} onClose={handleClosed}>
                                               <Alert onClose={handleClosed} severity="error">{error}</Alert>
                                            </Snackbar>
                                            <Backdrop className="loader-signup" open={load}>
                                                    <CircularProgress color="inherit" />
                                            </Backdrop>
                                        </div>
                                    }

                                    {/* Notification Promo Info Block */}
                                    {promotionType === "Notification" &&
                                        <div className="info-section notification-info">
                                            <label className="section-heading sub-heading">Notification info</label>
                                            {/* <Grid item sm={12}>
                                                <div className="input-group">
                                                    <label className="input-label" >Notify to</label>
                                                    <Controller
                                                        name="promoSentTOClub"
                                                        control={control}
                                                        rules={{ required: true}}
                                                        render = {({value}) => (
                                                            <Select displayEmpty disabled={fieldEnabled} name="promoSentTOClub" value={value}  className={`custom-select form-select  ${errors.promoSentTOClub && 'error'}`}  onChange={(e) => handleChangeField(e)}
                                                                >
                                                                <MenuItem value="">Select</MenuItem>
                                                                <MenuItem value={"all"}>All Org</MenuItem>

                                                            </Select>
                                                            )
                                                        }

                                                    />
                                                {errors.promoSentTOClub && errors.promoSentTOClub.type === "required" && (<label className="error-message">Field is required</label>)}
                                                </div>
                                            </Grid> */}
                                            <Grid item sm={12}>
                                                <div>
                                                    <label className="input-label">Message</label>
                                                    <Controller control={control} name="promoMessage" rules={{ required: false }} render={({ value }) => (<TextField type="text" disabled={fieldEnabled} name="promoMessage" value={value} placeholder="Message" className="form-fields" onChange={(e) => handleChangeField(e)} />)} />
                                                    {promoMessageValidation && (<label className="error-message">Message should be Max 100 Characters</label>)}
                                                </div>
                                            </Grid>
                                        </div>
                                    }

                                    {/* Video Promo Info Block */}
                                    {promotionType === "video" &&
                                        <div className="info-section notification-info">
                                            <label className="section-heading sub-heading">Video Info</label>
                                            {/* <Grid item sm={12}>
                                                <div className="input-group">
                                                    <label className="input-label" >Notify to</label>
                                                    <Controller
                                                        name="promoSentTOClub"
                                                        control={control}
                                                        rules={{ required: true}}
                                                        render = {({value}) => (
                                                            <Select displayEmpty disabled={fieldEnabled} name="promoSentTOClub" value={value}  className={`custom-select form-select  ${errors.promoSentTOClub && 'error'}`}  onChange={(e) => handleChangeField(e)}
                                                                >
                                                                <MenuItem value="">Select</MenuItem>
                                                                <MenuItem value={"all"}>All Org</MenuItem>

                                                            </Select>
                                                            )
                                                        }

                                                    />
                                                {errors.promoSentTOClub && errors.promoSentTOClub.type === "required" && (<label className="error-message">Field is required</label>)}
                                                </div>
                                            </Grid> */}
                                            <Grid item sm={12}>
                                                <div>
                                                    <label className="input-label">Video Link</label>
                                                    <Controller control={control} rules={{ required: true, pattern: /^(http(s)?:\/\/)?((w){3}.)?(youtu(be|.be)|vimeo)?(\.com)?\/.+/ }} name="videoLink" render={({ value }) => (<TextField type="text" disabled={fieldEnabled} name="videoLink" value={value} placeholder="https://www.youtube.com/xyz" className="form-fields" onChange={(e) => handleChangeField(e)} />)} />
                                                    {errors.videoLink && errors.videoLink.type === "required" && (<label className="error-message">Field is required</label>)}
                                                    {errors.videoLink && errors.videoLink.type === "pattern" && (<label className="error-message">Please enter valid YouTube or Vimeo link</label>)}
                                                </div>
                                            </Grid>
                                            <Grid item sm={12}>
                                                <div className="input-group">
                                                    <label className="input-label">Message</label>
                                                    <Controller control={control} name="promoMessage" rules={{ required: false }} render={({ value }) => (<TextField type="text" disabled={fieldEnabled} name="promoMessage" value={value} placeholder="Message" className="form-fields" onChange={(e) => handleChangeField(e)} />)} />
                                                    {promoMessageValidation && (<label className="error-message">Message should be in less than 100 characters</label>)}

                                                </div>
                                            </Grid>
                                            <Grid item sm={12} className='pl-0'>
                                                <div className="input-group">
                                                    <label className="input-label">Video Promotion Quiz</label>

                                                    <Controller
                                                        name="promoQuiz"
                                                        control={control}
                                                        rules={{ required: true }}
                                                        render={({ value }) => (
                                                            <Select displayEmpty name="promoQuiz" value={value} disabled={fieldEnabled} className={`custom-select form-select  ${errors.promoQuiz && 'error'}`} onChange={(e) => handleChange(e)}>
                                                                {/* <MenuItem value="">Select</MenuItem> */}
                                                                <MenuItem value={"No"}>No</MenuItem>
                                                                <MenuItem value={"Yes"}>Yes</MenuItem>
                                                            </Select>
                                                        )}
                                                    />
                                                    {errors.promoQuiz && errors.promoQuiz.type === "required" && (<label className="error-message">Field is required</label>)}
                                                </div>
                                            </Grid>
                                            <Snackbar open={openToast} autoHideDuration={3000} onClose={handleClosed}>
                                               <Alert onClose={handleClosed} severity="error">{error}</Alert>
                                            </Snackbar>
                                            <Backdrop className="loader-signup" open={load}>
                                                    <CircularProgress color="inherit" />
                                            </Backdrop>
                                        </div>
                                    }

                                    {/* Hunt Promo Info Block */}
                                    {promotionType === "Hunt" || promotionType === "BillBoard" || promotionType === "Poi"
                                        ? (
                                            <div className="info-section hunt-info">
                                            {/* <label className="section-heading sub-heading">{promotionType === 'Hunt' ? 'Hunt Info' : promotionType === 'BillBoard' ? 'Bill Board Info' : ''}</label>  */}
                                                {(!promotionId || !copyPromotionId) && promotionType !== "BillBoard"
                                                    ? (
                                                        promotionType === "Poi" ? null :
                                                        <Grid item sm={12}>
                                                            <div className="input-group">
                                                                <label className="input-label" >{pointstoken} to Coin Distribution : {totalCoins}</label>
                                                                <ul className="point-distribution">
                                                                    <li><label className={disableDist ? 'selected' : ''} onClick={() => distribution(1)}>1 X {getOccurrence(1)}</label><p className="actions"><span onClick={(e) => plusDistri(1)}>+</span> <span onClick={(e) => minusDistri(1)}>-</span></p></li>
                                                                    <li><label className={disableDist ? 'selected' : ''} onClick={() => distribution(5)}>5 X {getOccurrence(5)}</label><p className="actions"><span onClick={(e) => plusDistri(5)}>+</span> <span onClick={(e) => minusDistri(5)}>-</span></p></li>
                                                                    <li><label className={disableDist ? 'selected' : ''} onClick={() => distribution(10)}>10 X {getOccurrence(10)}</label><p className="actions"><span onClick={(e) => plusDistri(10)}>+</span> <span onClick={(e) => minusDistri(10)}>-</span></p></li>
                                                                    <li><label className={disableDist ? 'selected' : ''} onClick={() => distribution(20)}>20 X {getOccurrence(20)}</label><p className="actions"><span onClick={(e) => plusDistri(20)}>+</span> <span onClick={(e) => minusDistri(20)}>-</span></p></li>
                                                                    <li><label className={disableDist ? 'selected' : ''} onClick={() => distribution(100)}>100 X {getOccurrence(100)}</label><p className="actions"><span onClick={(e) => plusDistri(100)}>+</span> <span onClick={(e) => minusDistri(100)}>-</span></p></li>
                                                                </ul>
                                                            </div>
                                                            <Snackbar open={openToast} autoHideDuration={3000} onClose={handleClosed}>
                                                                <Alert onClose={handleClosed} severity="error">{error}</Alert>
                                                            </Snackbar>
                                                            <Backdrop className="loader-signup" open={load}>
                                                                    <CircularProgress color="inherit" />
                                                            </Backdrop>
                                                        </Grid>
                                                    ) : null
                                                }
                                                {/* <label className="section-heading sub-heading">{promotionType === 'Hunt' ? 'Hunt Info' : promotionType === 'BillBoard' ? 'Bill Board Info' : ''}</label>  */}
                                                {promotionType === "Poi"
                                                    ? (
                                                        <Grid container item>
                                                            <Grid item sm={6} className='pl-0'>
                                                                <div className="input-group" style={{width:"90%"}}>
                                                                    <label className="input-label" >Poi Keyword</label>
                                                                    <Controller control={control} rules={{ required: true}} name="poiKeyword" render={({ value }) => (<TextField type="text" value={value} disabled={fieldEnabled} name="poiKeyword" placeholder="POI Keyword" className="form-fields" onChange={(e) => handleChangeField(e)} />)} />
                                                                    {errors.poiKeyword && errors.poiKeyword.type === "required" && (<label className="error-message">Field is required</label>)}
                                                                </div>
                                                            </Grid>
                                                            <Grid item sm={6} className='pr-0'>
                                                                <div className="input-group">
                                                                    <label className="input-label" >Radius (Meters)</label>
                                                                    {/* <Controller control={control} rules={{ required: true}} name="poiRadius" render={({ value }) => (<TextField type="text" value={value} disabled={fieldEnabled} name="poiRadius" placeholder="Radius" className="form-fields" onChange={(e) => handleChangeField(e)} />)} />
                                                                    {errors.poiRadius && errors.poiRadius.type === "required" && (<label className="error-message">Field is required</label>)} */}
                                                                    
                                                                        <Grid container>
                                                                            <Grid item md={4}>
                                                                                <IconButton style={{ padding: 0 }}  onClick={() => { handleChangeRadius("minus") }}>
                                                                                    <RemoveCircle color={poiRadius &&  poiRadius <= "200" ? "disabled" : "primary"} fontSize='large' />
                                                                                </IconButton>
                                                                            </Grid>
                                                                            <Grid item md={4}>
                                                                                <Typography variant="subtitle1" component="div">
                                                                                    {poiRadius} 
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item md={4}>
                                                                                <IconButton style={{ padding: 0 }}  onClick={() => { handleChangeRadius("add") }}>
                                                                                    <AddCircle color= "primary" fontSize='large' />
                                                                                </IconButton>
                                                                            </Grid>
                                                                        </Grid>
                                                                        
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    ) : null
                                                }
                                                <Grid item sm={12}>
                                                    <div className="input-group location-field">
                                                        <label className="input-label mt-20" >Location</label>
                                                        {promotionId
                                                            ? <label className="hunt-location-name">{huntLocation}</label>
                                                            : (
                                                                <GooglePlacesAutocomplete
                                                                    selectProps={{
                                                                        isClearable: true,
                                                                        clearValue: () => { setValueLocation("") },
                                                                        defaultInputValue: isCopyPromo ? value : "",
                                                                        defaultValues: value,
                                                                        inputAutocompleteValue: value,
                                                                        onChange: async (place: any) => {
                                                                            place &&
                                                                            geocodeByPlaceId(place.value.place_id)
                                                                                .then(response => getLatLng(response[0]))
                                                                                .then(({ lat, lng }) => {
                                                                                    let latlng:any ={ lat: lat, lng: lng }
                                                                                    setValueLocation(value);
                                                                                    setLocationName(place.label);
                                                                                    setLocationSelected(true);
                                                                                    setPoints([]);
                                                                                    setPoiMarkersResponse([]);
                                                                                    setAutoDistributeSelected(false);
                                                                                    setLatlng({ lat: lat, lng: lng })
                                                                                    // getPois(latlng, PoiKeyword, poiRadius)
                                                                                });
                                                                        }
                                                                    }}
                                                                />
                                                            )
                                                        }
                                                    </div>
                                                </Grid>
                                            </div>
                                        ) : ''
                                    }

                                    
                                </Grid>
                                {
                                        promotionType === "Poi" ?
                                            <React.Fragment>
                                                <Grid item sm={6}>
                                                    <label className="input-label cust-pts">Promotion Budget <br /><span>{getTotalPoints(getValues('promoCodePoints'), getValues('promoTotalCount'))} {pointstoken}</span></label>
                                                </Grid>
                                                <Grid item sm={6}>
                                                    {
                                                        hasToken === 'true' ?
                                                            <label className="input-label ml-12 cust-pts">Tokens Remaining <br /><span>{tokensRemain > 0 ? formatCurrency(tokensRemain - getTotalPoints(getValues('promoCodePoints'), getValues('promoTotalCount')), opt) : 0}</span></label>
                                                        : <label className="input-label ml-12 cust-pts">Points Remaining <br /><span>{pointsRemain > 0 ? formatCurrency(pointsRemain - getTotalPoints(getValues('promoCodePoints'), getValues('promoTotalCount')), opt) : 0}</span></label>
                                                        }

                                                </Grid>
                                            </React.Fragment>
                                        :''
                                    }
                            </Grid>
                        </Grid>

                        {/* Promotion Preview */}
                        <Grid item sm={6} xs={12} className="pt-0">
                            <label className="section-heading sub-heading mrgb-20 txt-center  mt-0">Promotion Preview</label>
                            {promotionType === "Email" &&
                                <div className="email-preview">
                                    <Grid container sm={12}>
                                        <div className="preview-wrapper">
                                            <div className="preview-head">
                                                <Grid container className="flx-align-center" sm={12}>
                                                    <Grid className="align-center" item sm={3}>
                                                        <img src={sponsorLogo || "https://us.v-cdn.net/6022045/uploads/defaultavatar.png"} alt="sponsorlogo" className="sponsor-logo" />
                                                    </Grid>
                                                    <Grid item sm={9}>
                                                        <label className="section-heading sub-heading email-title">{promotion.promoTitle ? promotion.promoTitle : getValues('promoTitle')}</label>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div className="preview-body">
                                                <Grid container sm={12}>
                                                    <Grid item sm={12}>
                                                        <p className="message-preview sub-caption">{promotion.promoMessage}</p>
                                                    </Grid>
                                                    <Grid className="promo-code-sec" item sm={12}>
                                                        <label className="promocode-preview">{code + (promotion.promoCodePrefix ? promotion.promoCodePrefix : getValues('promoCodePrefix'))}
                                                            <BootstrapTooltip placement='top' title={tooltipTitle}><img src="https://furtaev.ru/preview/copy_small.png" onClick={()=>handleCopy(promotion.promoCodePrefix)} style={{ width: "19px",paddingLeft:'5px',marginBottom:"-2px" }} /></BootstrapTooltip>
                                                        </label>
                                                        <label className="update-date-txt">Valid till {validityDate}</label>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </div>
                                    </Grid>
                                </div>
                            }

                            {/* Video Preview */}
                            {promotionType === "video" &&
                                <div className='video-preview-block'>
                                    <div className="video-preview">
                                        <Grid container sm={12}>
                                            <div className="preview-wrapper">
                                                <div>
                                                    <Grid container className="flx-align-center videoContainer" sm={12}>
                                                        <ReactPlayer
                                                            width='435px'
                                                            height='200px'
                                                            url={videoLink}
                                                            config={{
                                                                youtube: {
                                                                    playerVars: { showinfo: 0, controls: 1, fs: 0, modestbranding: 1 }
                                                                },
                                                            }}
                                                        />
                                                        <div className="align-center sponsorlogoforvideo" >
                                                            <img src={sponsorLogo || "https://us.v-cdn.net/6022045/uploads/defaultavatar.png"} alt="sponsorlogo" className="sponsor-logo-video" />
                                                        </div>
                                                    </Grid>
                                                </div>
                                            </div>
                                        </Grid>
                                    </div>
                                    <div className="preview-body">
                                        <Grid container sm={12} className="video-disp-flex">
                                            <Grid item sm={6}>
                                                <div className="video-message-preview sub-caption" title={promotion.promoMessage ? promotion.promoMessage : getValues('promoMessage')}>{promotion.promoMessage ? (promotion.promoMessage.length > 30 ? promotion.promoMessage.substr(0, 30) + "..." : promotion.promoMessage) : (getValues('promoMessage').length > 30 ? getValues('promoMessage').substr(0, 30) + "..." : getValues('promoMessage'))}</div>
                                                <label className="update-date-txt">Valid till {validityDate}</label>
                                            </Grid>
                                            <Grid className="promo-code-sec" item sm={6}>
                                                <label className="promocode-preview">{code + (promotion.promoCodePrefix ? promotion.promoCodePrefix : getValues('promoCodePrefix'))}</label>
                                            </Grid>
                                        </Grid>
                                    </div>

                                    {/* Quiz Block */}
                                    {promoQuiz === "Yes" && <PromoQuiz fieldEnabled={fieldEnabled} handleQuizList={quizList} updateQuiz={(obj: any) => handleUpdateQuiz(obj)} setError={setError} setOpenToast={setOpenToast} />}
                                </div>

                            }

                            {/* Notification Preview */}
                            {promotionType === "Notification" &&
                                <div className="appnotification-preview p-relative ">
                                    <Grid container sm={12}>
                                        <div className="preview-wrapper">
                                            <div className="preview-body">
                                                <Grid container className="flx-align-center" sm={12} spacing={2}>
                                                    <img src={sponsorLogo || "https://us.v-cdn.net/6022045/uploads/defaultavatar.png"} alt="sponsorlogo" className="sponsor-logo" />
                                                    <div className="title-container">
                                                        <label className="section-heading sub-heading notify-title">{promotion.promoMessage ? promotion.promoMessage : getValues('promoMessage')}&nbsp;</label>
                                                        <label className="update-date-txt">Valid till {validityDate}</label>
                                                    </div>
                                                    <label className="promocode">{code + (promotion.promoCodePrefix ? promotion.promoCodePrefix : getValues('promoCodePrefix'))}</label>

                                                </Grid>
                                            </div>
                                        </div>
                                    </Grid>
                                </div>
                            }

                            {/* Hunt/Billboard Preview */}
                            {(promotionType === "Hunt" || promotionType === "BillBoard" || promotionType === "Poi") &&
                                <div className="map-preview">
                                    <Grid container sm={12}>
                                    <PromotionMap poiMarkers={poiMarkersResponse} autoDistributeSelected={autoDistributeSelected} locationSelected={locationSelected} placedCoins={placedCoins} viewType={promotionId ? "Edit" : (copyPromotionId ? "Copy" : "Create")} markerImage={markerImage} coords={latlng} coinsNvalue={noCoinsValue} pointsArray={(copyPromotionId || promotionId) ? points : distArr} distArray={distArr} mapProperties={mapProperties} promotionType={promotionType} coinUrl={billBoardImgUrl} updatePoints={(point: any) => { points.push(point); }} resetGMap={(obj: any) => handleResetGMap(obj)} mapRadiusConst={(obj: any) => setMapRadiusConst(obj)} mapRadiusChange={(obj: any) => setMapRadiusChange(obj)} />
                                        {!promotionId && promotionType !== "BillBoard"
                                            ? 
                                                !promotionId && promotionType !== "Poi" ?
                                                    <div className={autoDistributeSelected ? 'auto-distribute checked' : 'auto-distribute'} onClick={handleDistributedCoinsCheckBox}><span>Automatically place distributed coins on selected location.</span></div>
                                                : 
                                                    <div style={{
                                                        width: '100%',
                                                        marginLeft: '40%',
                                                        marginRight: '60%',
                                                        marginTop: '2%'
                                                    }}>
                                                        <Button onClick={()=>{getPois(latlng, PoiKeyword, poiRadius)}} className="btn btn-primary" color="primary">Show Poi</Button>
                                                    </div>
                                            : ''
                                        }
                                        { mergedImage ? <img src={mergedImage}  alt="sponsorlogo" className="sponsor-logo" style={{paddingTop:"5%"}} /> : '' }
                                    </Grid>
                                </div>
                            }
                        </Grid>

                    </Grid>

                    {openConfirm &&
                        <div className="confim-model-container hunt-confirmation">
                            <Paper className="confirmation-wrapper padd-20">
                                <Grid container sm={12}>
                                    <div className="confirmation-sec">
                                        <p>{confirmMessage}</p>
                                        {confirmResetMap
                                            ? (
                                                <DialogActions>
                                                    <Button className="btn btn-secondary" onClick={clickCanceled} color="primary">CANCEL</Button>
                                                    <Button color="primary" onClick={handleMapReset} className="btn btn-primary button">PROCEED</Button>
                                                </DialogActions>
                                            ) : (
                                                <DialogActions>
                                                    <Button className="btn btn-secondary" onClick={clickCanceled} color="primary">CANCEL</Button>
                                                    <Button color="primary" onClick={handleSubmit(onSubmit)} className="btn btn-primary button">CONFIRM</Button>
                                                </DialogActions>
                                            )
                                        }
                                    </div>
                                </Grid>
                            </Paper>
                        </div>
                    }
                </DialogContent>

                {/* Create / Copy promo view */}
                <DialogActions className="edit-member-profile-actions" style={{ display: (fieldEnabled) ? "none" : "flex" }}>
                    <Button onClick={handleClose} className="btn btn-secondary" color="primary">CANCEL</Button>
                    {(promotionType === "Hunt" || promotionType === "BillBoard"  || promotionType === "Poi")
                       ? <Button color="primary" className="btn btn-primary button" onClick={handleSubmit(handleConfirmationBox)}>{promotionType === "Hunt" ? 'CREATE HUNT' : promotionType === "Poi" ? 'CREATE POI' : 'CREATE BILL BOARD'}</Button>
                        : <Button color="primary" className="btn btn-primary button" onClick={handleSubmit(onSubmit)}>SEND PROMOTION</Button>
                    }
                </DialogActions>

                {/* Preview promo view */}
                {fieldEnabled &&
                    <DialogActions className="edit-member-profile-actions" style={{ display: "flex" }}>
                        <Button onClick={handleClose} className="btn btn-secondary" color="primary">CLOSE</Button>
                    </DialogActions>
                }

                <Snackbar open={openSnack} autoHideDuration={3000} onClose={handleClosed}>
                    <Alert onClose={handleClosed} severity='warning'>{snackMessage.info}</Alert>
                </Snackbar>
                <Backdrop className="loader-signup" open={load}>
                        <CircularProgress color="inherit" />
                </Backdrop>
            </form>

            <Snackbar open={openToast} autoHideDuration={3000} onClose={handleClosed}>
                <Alert onClose={handleClosed} severity="error">{error}</Alert>
            </Snackbar>
        </Dialog>
    )
}
