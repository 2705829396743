import React, { useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Loader from '../../components/Loader/loader';
import NetworkManager from '../../utilities/NetworkManager';
import EmptyOrg from '../../assets/org-empty@2x.svg';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { tooltipClasses } from '@mui/material';
import { styled } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
const formatCurrency = require('format-currency');
const opt = { minFraction: 0 };
export default function RewardEngine() {
    const [transactionList, setTransactionList] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const getAllTokensBalanceREP = async () => {
        setLoading(true);
        let dataToSend = {}
        await NetworkManager.getAllTokensBalanceREP('Avalanche', dataToSend).then((response: any) => {
            setLoading(false);
            if (response && response.resp) {
                const transations = response.resp.filter((t:any) => { return t.is_active === true });
                setTransactionList(transations)
            }
        })
        .catch((error:any) => {
            setLoading(false);
            console.log('error',error)
          });

    }

    function popupWindow(url:any, windowName:any, win:any, w:any, h:any) {
        const y = win.top.outerHeight / 2 + win.top.screenY - ( h / 2);
        const x = win.top.outerWidth / 2 + win.top.screenX - ( w / 2);
        return win.open(url, windowName, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
    }
     //ToolTip
     const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
        },
    }));

    useEffect(() => {
        getAllTokensBalanceREP();
    },[])
    const columns: any = [
        {
            name: "organization_id",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "organization_id") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={0} onClick={() => updateDirection(0)}>
                            <a className="sort-by">Organization</a>
                        </th>
                    )
                },
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (<div style={{display:'flex'}}><img className='profile-img' src={tableMeta.tableData[tableMeta.rowIndex].sponsorLogo || EmptyOrg} /><label className='membername'>{tableMeta.tableData[tableMeta.rowIndex].sponsorName}</label></div>)
                }
            }
        },
        {
            name: "token_symbol",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "token_symbol") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(1)} >
                            <a className="sort-by">Token Symbol</a>
                        </th>
                    )
                }
            }
        },
        {
            name: "decimals",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "decimals") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(1)} >
                            <a className="sort-by">Decimals</a>
                        </th>
                    )
                },
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (<label className='membername'>{formatCurrency(value,opt)}</label>)
                }
            }
        },
        {
            name: "wallet_balance",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "wallet_balance") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(1)} >
                            <a className="sort-by">Wallet Balance</a>
                        </th>
                    )
                },
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (<label className='membername'>{formatCurrency(value,opt)} AVAX</label>)
                }
            }
        },
        {
            name: "total_supply",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "total_supply") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(1)} >
                            <a className="sort-by">Total Supply</a>
                        </th>
                    )
                },
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (<label className='membername'>{formatCurrency(value,opt)}</label>)
                }
            }
        },
        {
            name: "token_balance",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "token_balance") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(1)} >
                            <a className="sort-by">Token Balance</a>
                        </th>
                    )
                },
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (<label className='membername'>{formatCurrency(value,opt)}</label>)
                }
            }
        },
        {
            name: "reward_engine_pool_balance",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "reward_engine_pool_balance") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(1)} >
                            <a className="sort-by">Reward Engine Pool Balance</a>
                        </th>
                    )
                },
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (<label className='membername'>{formatCurrency(value,opt)}</label>)
                }
            }
        },
        {
            name: "users_balance",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "users_balance") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(1)} >
                            <a className="sort-by">User Balance</a>
                        </th>
                    )
                },
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (<label className='membername'>{formatCurrency(value,opt)}</label>)
                }
            }
        },
        {
            name: "contract_link",
            options: {
                filter: false,
                sort: false,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "price") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting" +orderName} key={3} style={{display:'flex'}} >
                            <a >Contract Address</a>
                        </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    return (
                        <React.Fragment>
                            <BootstrapTooltip placement='bottom' title="Click to show contract data"><a onClick={() => {popupWindow(value, 'Contract', window, 1000, 700)}} ><Chip className="status sky-blue" variant="outlined" label="Contract" style={{cursor:"pointer"}} /></a></BootstrapTooltip>&nbsp;
                            <BootstrapTooltip placement='bottom' title="Click to show token data"><a onClick={() => {popupWindow(tableMeta.tableData[tableMeta.rowIndex].token_link, 'Token', window, 1000, 700)}} ><Chip className="status blue" variant="outlined" label="Token" style={{cursor:"pointer"}}  /></a></BootstrapTooltip>&nbsp;
                            <BootstrapTooltip placement='bottom' title="Click to show wallet data"><a onClick={() => {popupWindow(tableMeta.tableData[tableMeta.rowIndex].wallet_link, 'Wallet', window, 1000, 700)}} ><Chip className="status yellow" variant="outlined" label="Wallet" style={{cursor:"pointer"}}  /></a></BootstrapTooltip>&nbsp;
                        </React.Fragment>
                    )
                }
            },
        }
    ];

    const options: any = {
        filter: false,
       // count: 10,
        filterType: "dropdown",
        responsive: "standard",
        print: false,
        download: false,
        viewColumns: false,
        selectableRowsHideCheckboxes: true,
        fixedSelectColumn: false,
        selectToolbarPlacement: "none",
        selectableRowsHeader: false,
        selectableRows: "none",
        selectableRowsOnClick: false,
        fixedHeader: true,
        tableBodyHeight: '450px',
        search: false,
        pagination: true,
        onTableChange: (action:any, state:any) => {
            console.dir(state);
        }
    }
    return (
        <React.Fragment>
            <div className="list-page transaction-list-wrapper">
                <Grid item sm={12} xs={12}>
                    <Paper className="table-wrapper padd-0">
                        <div className="table-container muidata-table trans-cust-body">
                            {loading ?
                                (
                                    <Loader size="90px" />
                                ) : (
                                    <MUIDataTable
                                        title={"Rewards"}
                                        data={transactionList}
                                        columns={columns}
                                        options={options}
                                    />)
                            }
                        </div>
                    </Paper>
                </Grid>
            </div>
        </React.Fragment>
    );
}