import React, { useEffect, useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, TableContainer, Paper, Table, TableRow, TableCell, DialogActions, TableHead, TableBody, Grid, Select, MenuItem, Backdrop, CircularProgress, Chip, IconButton, TextField } from '@material-ui/core'
import { Button } from 'aws-amplify-react'
import { gql, useMutation, useQuery } from '@apollo/client';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import './sponsordetails.css';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from "@material-ui/core/styles";

const formatCurrency = require('format-currency');
const opt = { minFraction: 0 };
interface Props {
    onOpen: boolean,
    handleCloseClick: () => void,
    id?: any,
    rate: any
}

const SPONSOR_LEDGER_BY_ID = gql`
    query getSponsorLedger($id: String!) {
        getSponsorLedgerById(id: $id) {
            stripeInvoiceId
            id
            sponsorId
            points
            paymentStatus
            unitAmount
            createdAt
        }
    }
`;

const REQUEST_SPONSOR_POINTS = gql`
    mutation newPointsRequest($data: NewPointsRequestInput!) {
        newPointsRequest(data: $data) {
            id
        }
    }
`;

interface IFormRequestSponsorPoints {
    points: string;
    mode: string;
    paymentCycle: string;
}

let defaultValues: any = {
    points: "",
    mode: "",
    paymentCycle: ""
}

const useStyles = makeStyles({
    paper: {
        padding: "0",
        fontFamily: "Graphik Regular !important",
        fontSize: "14px !important",
        color: "#8A8D9D !important"
    }
});

export default function SponsorDetails({ handleCloseClick, onOpen, id, rate}: Props) {

    const open = onOpen;
    const [openToast,setOpenToast] = React.useState<any>(false);
    const { loading, data, refetch } = useQuery<any>(SPONSOR_LEDGER_BY_ID, { variables: { id: id }, fetchPolicy: "network-only" });
    const [message,setMessage] = useState<any>("");
    const [points, setPoints] = useState<any>('');
    const hasToken = localStorage.getItem('org_has_token');
    const [newPointsRequest, { error, loading: resLoading }] = useMutation(REQUEST_SPONSOR_POINTS, {});
    const [pointsRequestOptions, setPointsRequestOptions] = useState([{title: "100", _id: "100"}, {title: "1000", _id: "1000"}, {title: "10000", _id: "10000"}, {title: "100000", _id: "100000"}, {title: "1000000", _id: "1000000"}, {title: "10000000", _id: "10000000"} ])

    const { register, handleSubmit, errors, control, reset, formState, setValue ,clearErrors} = useForm<IFormRequestSponsorPoints>({ defaultValues, shouldUnregister: false });
    const Alert = (props: AlertProps)  => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }


    const onSubmit: SubmitHandler<IFormRequestSponsorPoints> = async (fieldData) => {


       await newPointsRequest({
            variables:
            {
                data:
                {
                    sponsorId: id, pointRequest: parseInt(fieldData.points)
                }
            }});

            setMessage("Please check your email to complete the payment");
        setOpenToast(true);


        refetch();

        reset();

        setPoints(undefined);
        setValue("points", '');

    }





    const handleChange = (e: any, source = "elm") => {
        if (source === "elm") {
            const targetValue = (e.target as HTMLInputElement).value;
            // const targetName:string = (e.target as HTMLInputElement).name;
            // setPointsRequest(targetValue);
            setValue("points", targetValue);
            setPoints(targetValue)
        } else {
        setValue("points", e);
        setPoints(e)
        }
        clearErrors("points");
    }


    const dateFormater = (dateString: any) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]
    }
    const handleClosed = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenToast(false);
        setPoints('');
        setValue("points", '');
    };

    const classes = useStyles();

    return (
        <Dialog
                open={open}
                onClose={handleCloseClick}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">{"Transaction Summary"}
                <IconButton aria-label="close" className="closeButton" onClick={handleCloseClick}>
          <CloseIcon />
        </IconButton></DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                    <div className="sponsor-request-form">
                    <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
                            <Grid container alignItems="center">
                            <Grid item xs>
                            <div className="input-group">
                                        <label className="input-label" >Points Request *</label>
                                        <Controller
                                            name="points"
                                            control={control}
                                            rules={{ required: true,pattern: /^[0-9]*$/,validate: (value) => value > 0}}
                                            render = {({value}) => (
                                                // <Select displayEmpty name="points"  value={value} className={`custom-select form-select  ${errors.points && 'error'}`} onChange={(e) => handleChange(e)} >
                                                //     <MenuItem value="">Select</MenuItem>
                                                //     <MenuItem value={100}>{formatCurrency(100,opt)}&nbsp;(${ formatCurrency(100 * rate, opt)})</MenuItem>
                                                //     <MenuItem value={1000}>{formatCurrency(1000,opt)}&nbsp;(${ formatCurrency(1000 * rate, opt)})</MenuItem>
                                                //     <MenuItem value={10000}>{formatCurrency(10000,opt)}&nbsp;(${ formatCurrency(10000 * rate, opt)})</MenuItem>
                                                //     <MenuItem value={100000}>{formatCurrency(100000,opt)}&nbsp;(${ formatCurrency(100000 * rate, opt)})</MenuItem>
                                                //     <MenuItem value={1000000}>{formatCurrency(1000000,opt)}&nbsp;(${ formatCurrency(1000000 * rate, opt)})</MenuItem>
                                                //     <MenuItem value={10000000}>{formatCurrency(10000000,opt)}&nbsp;(${ formatCurrency(10000000 * rate, opt)})</MenuItem>
                                                // </Select>
                                                <Autocomplete
                                                    freeSolo
                                                    classes={{ paper: classes.paper }}
                                                    options={pointsRequestOptions.map((option) => option.title)}
                                                    // getOptionLabel={option => option.title}
                                                    // getOptionSelected={(option, value) => option.title === value.title}
                                                    onChange={(e, value: any) => handleChange(value, "auto")}
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    renderOption={(option, { selected }) => (
                                                        <React.Fragment>{formatCurrency(option, opt)}&nbsp;(${formatCurrency(parseInt(option) * rate, opt)})</React.Fragment>
                                                    )}
                                                    renderInput={(params) => (
                                                    <TextField {...params} name="points" value={value} required onChange={(e) => handleChange(e, "elm")} />
                                                    )}
                                                />
                                              )
                                            }
                                        />
                                        {points ? `Amount $${points*rate}` : '' }
                                        <br />
                                        <label className="input-label" >* 5% Service Fee will be applied</label>
                                        {errors.points && errors.points.type === "required" && (<label className="error-message">Field is required</label>)}
                                        {errors.points && errors.points.type === "pattern" && (<label className="error-message">Only number is allow</label>)}
                                        {errors.points && errors.points.type === "validate" && (<label className="error-message">Value should be more than 0</label>)}
                                    </div>
                            </Grid>
                            <Grid item xs>
                                {/* <div className="input-group">
                                        <label className="input-label" >Payment Mode: Credit Card</label>
                                </div> */}
                            </Grid>
                            <Grid item xs>
                            <Button color="primary" onClick={handleSubmit(onSubmit)} className="btn btn-primary button">REQUEST PAYMENT</Button>
                            </Grid>

                        </Grid>
                    </form>
                    </div>

                        <div className="pv-email-template" >
                            <div className="email-body-block" >
                                <div className="zig-zag-top-bdr" >
                                    <div className="summary-block" >

                                        <TableContainer component={Paper}>
                                            <Table className="order-table" aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Subscription date</TableCell>
                                                        <TableCell>Stripe Transaction ID</TableCell>
                                                        <TableCell >{hasToken === "true" ? 'Tokens' : 'Points'} Requested</TableCell>
                                                        <TableCell >Payment Status</TableCell>
                                                        <TableCell >Amount</TableCell>
                                                        <TableCell >Service Charge</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {loading && <p>Loading ...</p>}
                                                    {!loading && data?.getSponsorLedgerById.length > 0 && data?.getSponsorLedgerById.map((row: any) => (
                                                        <TableRow key={row.id}>
                                                            <TableCell component="th"  scope="row">
                                                            {dateFormater(row.createdAt)}
                                                            </TableCell>
                                                            <TableCell component="th"  scope="row">
                                                            {row.stripeInvoiceId}
                                                            </TableCell>
                                                            <TableCell component="th"  scope="row">
                                                            {row.points}
                                                            </TableCell>
                                                            <TableCell ><Chip color={row.paymentStatus === "PAID" ? "primary" : "secondary"} size="small" label={row.paymentStatus} /></TableCell>
                                                            {/* <TableCell component="th"  scope="row">
                                                             ${(row.unitAmount*rate)*10}
                                                            </TableCell> */}
                                                            <TableCell component="th"  scope="row">
                                                            ${row.points * rate}
                                                            </TableCell>
                                                            <TableCell component="th"  scope="row">
                                                            {`$${((5/100)*(row.points*rate)).toFixed(2)}`}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContentText>
                <Snackbar open={openToast} autoHideDuration={3000}  onClose={handleClosed}>
                    <Alert onClose={handleClosed} severity="success">
                        {message}
                    </Alert>
                </Snackbar>
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={handleClose} color="primary" autoFocus>
                        Done
                    </Button> */}
                </DialogActions>
                <Backdrop className="loader-signup" open={resLoading || loading}>
                <CircularProgress color="inherit" />

            </Backdrop>
            </Dialog>
    )
}
