import axios from "axios";
import Amplify, { Auth } from "aws-amplify"
import { config } from "../config";

const axiosApiInstance = axios.create();

const API_URL = config.baseBlockChainUrl;

axiosApiInstance.interceptors.request.use(
    async config => {
         const token = localStorage.getItem("blockchainToken");
        if (token) {
            config.headers["authorization"] = token;
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });

axiosApiInstance.interceptors.response.use((response) => {
    return response.data
}, async (error) => {
    const originalRequest = error.config;

    if (error.response) {
        if (error.response.status === 400) {
            return Promise.reject(error);
        }

        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            // return (
            // let success = await authenticate()
            // if(success){
            //     return axiosApiInstance(originalRequest);
            // }
            // )

        }
        return Promise.reject(error);
    }
})

const NetworkManager = {
    bearerToken: (type: string) => {
        const path = API_URL +'/auth/GetBearerToken'
        return axiosApiInstance.post(path,"shubham")
    },
    createWallet: (type: string) => {
        const path = API_URL +'/v1/' + type +'/ImportAdminWallet'
        return axiosApiInstance.post(path)
    },
    importWallet: (type: string,data: any) => {
        const path = API_URL +'/v1/' + type + '/ImportAdminWallet/'
        return axiosApiInstance.post(path,data)
    },
    createToken: (type: string,data: any) => {
        const path = API_URL + '/v1/'+ type + '/CreateTokenTransaction/';
        return axiosApiInstance.post(path,data)
    },
    registerToken: (type: string,data: any) => {
        const path = API_URL + '/v1/'+ type + '/SaveTokenToDB/';
        return axiosApiInstance.post(path,data)
    },
    saveToken: (type: string,data: any) => {
        const path = API_URL + '/v1/'+ type + '/SaveTokenToDB/';
        return axiosApiInstance.post(path,data)
    },
    transferTokenTransaction: (type: string,data: any) => {
        const path = API_URL + '/v1/'+ type + '/TransferTokenTransactionToRewardEnginePool/';
        return axiosApiInstance.post(path,data)
    },
    mintAndTransferTokenTransaction: (type: string,data: any) => {
        const path = API_URL + '/v1/'+ type + '/MintAndTransferTransactionToRewardEnginePool/';
        return axiosApiInstance.post(path,data)
    },
    supplyToken: (type: string,data: any) => {
        const path = API_URL +'/v1/' + type + '/GetSupplyTokenTransaction/';
        return axiosApiInstance.post(path,data)
    },
    burnToken: (type: string,data: any) => {
        const totalAmount = data.amount * 10;
        const path = API_URL +'/' + type + '/BurnToken/?amount=' + totalAmount + '&token_name=' + data.token_name + '&wallet_id='+ data.wallet_id + '&token_id='+ data.token_id;
        return axiosApiInstance.post(path)
    },
    transferToken: (type: string,data: any) => {
        const totalAmount = data.amount * 10;
        const path = API_URL +'/' + type + '/TransferToken/?amount=' + totalAmount + '&to_address=' + data.to_address + '&wallet_id='+ data.wallet_id + '&token_id='+ data.token_id;
        return axiosApiInstance.post(path)
    },
    getTokenID: (type: string,data: any) => {
        const path = API_URL +'/v1/' + type + '/GetTokenID/';
        return axiosApiInstance.post(path,data)
    },
    getTransactionDetail: (type: string, data: any) =>{
        const path = API_URL + '/v1/' + type + '/GetTransactionDetail/';
        return axiosApiInstance.post(path, data)
    },
    getAllTokensBalanceREP: (type: string, data: any) =>{
        const path = API_URL + '/v1/' + type + '/GetAllTokensBalanceREP/';
        return axiosApiInstance.post(path, data)
    },
}

export default NetworkManager