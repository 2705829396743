import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";

import MUIDataTable from "mui-datatables";
import {
    FormGroup,
    FormLabel,
    FormControl,
    ListItemText,
    TextField,
    Checkbox,
    FormControlLabel,
    Select,
    InputLabel,
    MenuItem
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import EmptyOrg from '../../assets/org-empty@2x.svg';
import SponsorEmty from '../../assets/sponsor-empty@2x.svg';
import ExportToCSV from './exportToCSV';
import { useQuery, gql, useLazyQuery } from '@apollo/client';
import Loader from '../../components/Loader/loader';


import Grid from '@material-ui/core/Grid';
import DropdownSelect from '../../components/Dropdown/dropdownselect';
import moment from 'moment';
import Button from "@material-ui/core/Button";
import profileImage from "../../assets/profile-img.png";
import '../../constant.css';
import './transactions.css';
import { createSecureContext } from 'tls';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import VisibilityIcon from '@material-ui/icons/Visibility';
// interface transactionInventory {
//     id: string;
//     sponsor_name: string;
//     contact_name: string;
//     contact_email: string;
// }

// interface transactionInventoryData {
//     transactionInventory: transactionInventory[];
// }

// interface TransactionInventoryVars {
//     year: number;
// }
// interface ParamTypes {
//     // orgId: string;
// }

// export const GET_TRANSACTION_QUERY = gql`
// query getTransactions($offset: Int, $limit: Int) {
//   getAllTransactions(offset: $offset, limit: $limit) {
//     data {
//       id
//       username
//       userProfilePic
//       walletId
//       orgName
//       organization
//       orgLogo
//       price
//       sponsorName
//       sponsorLogo
//       transactionDate
//       transactionType
//       points
//     }
//     count
//   }
// }
// `;

export const GET_TRANSACTION_QUERY = gql`
    query getTransactionsByClub {
        getAllTransactions {
          data {
            id
            transactionRef
            user
            organization
            walletId
            tdc_patronid
            price
            sponsor
            transactionDate
            transactionType
            points
            transactionStatus
            refund_amount
            username
          }
        }
    }
`;


// export const GET_ORDER_DETAILS = gql`
//     query getOrderDetailsbyTrans ($transId: String!){
//         getOrderDetailsbyTrans (transId: $transId){
//             bookedQtyByUserCart
//             merchandise_imageurl
//             merchandise_name
//             referenceCode
//         }
//     }
// `;

export const GET_ORDER_DETAILS = gql`
    query getTransactionOrderDetails ($transId: String!){
        getTransactionOrderDetails (transId: $transId){
            transaction_id
            transactionDate
            transactionRef
            transactionType
            transactionPoints
            transactionPrice
            transactionServiceCharge
            org_rate
            txsListing
        }
    }
`;

export default function TransactionLists() {
    // let { orgId } = useParams<ParamTypes>();

    // const { refetch, loading, data, fetchMore } = useQuery<any>(GET_TRANSACTION_QUERY);
    const history = useHistory();
    let offset = 0;
    let limit = 10;

    const {refetch, loading, data, fetchMore } = useQuery<any>(
        GET_TRANSACTION_QUERY,{variables : {offset: offset, limit: limit}}
    );
    const [getOrderDetails, { loading: orderLoading, data: orderData }] = useLazyQuery(GET_ORDER_DETAILS);
    const [pageNumber, setPageNumber] = useState(1);
    // const [ageFilterChecked, setAgeFilterChecked] = React.useState(false);
    const [transactionList, setTransactionList] = useState([]);
    const [transfer, setTransfer] = useState(0);
    const [redeem, setRedeem] = useState(0);
    const [promotions, setPromotions] = useState(0);
    const [hunt, setHunt] = useState(0);
    const [orderDetails, setOrderDetails] = useState([]);
    const transTypes = ["REDEEM", "STRIPE", "PARTIAL"];

    const [open, setOpen] = React.useState(false);
    const [openExport, setOpenExport] = React.useState(false);

    const handleClickOpen = (transId: any) => {
        getOrderDetails({ variables: { transId: transId.id } });
        setOpen(true);
    };

    useEffect(() => {
        if (!orderLoading && orderData) {
            let tempOrderDetails: any = orderData?.getTransactionOrderDetails[0]

            let tempTxsListing = JSON.parse(tempOrderDetails.txsListing);

            let SubTotal: any = 0;
            tempTxsListing.forEach((item: any) => {
                SubTotal += item.bookedPointsConsumed;
            });

            let txsDetails: any = [{
                transaction_id: tempOrderDetails.transaction_id,
                transactionDate: moment(parseInt(tempOrderDetails.transactionDate)).format('MMM DD, YYYY'),
                transactionRef: tempOrderDetails.transactionRef,
                transactionType: tempOrderDetails.transactionType,
                transactionPoints: (tempOrderDetails.transactionPoints < 0 ? tempOrderDetails.transactionPoints * -1 : tempOrderDetails.transactionPoints),
                transactionPrice: tempOrderDetails.transactionPrice,
                org_rate: tempOrderDetails.org_rate,
                transactionServiceCharge: tempOrderDetails.transactionServiceCharge,
                txsListing: tempTxsListing,
                txsSubTotal: SubTotal
            }];

            setOrderDetails(txsDetails);
        }
    }, [orderData])

    const handleClose = () => {
        setOpen(false);
        setOpenExport(false);
    };

    useEffect(() => {
        refetch();
        if (!loading) {
            var promoTransactions = data.getAllTransactions.data.filter(function (transaction: any) { return transaction.transactionType == "PROMO"; });
            let promoTotal = promoTransactions.reduce((total: any, current: any) => { return total + current.points }, 0);
            setPromotions(promoTotal);

            var transactions = data.getAllTransactions.data.filter(function (transaction: any) { return transaction.transactionType == "RECEIVED"; });
            let transferTotal = transactions.reduce((total: any, current: any) => { return total + current.points }, 0);
            setTransfer(transferTotal);

            var redeemTransactions = data.getAllTransactions.data.filter(function (transaction: any) { return transaction.transactionType == "REDEEM"; });
            let redeemTotal = redeemTransactions.reduce((total: any, current: any) => { return total + current.points }, 0);
            setRedeem(redeemTotal);

            var huntTransactions = data.getAllTransactions.data.filter(function (transaction: any) { return transaction.transactionType == "HUNT"; });
            let huntTotal = huntTransactions.reduce((total: any, current: any) => { return total + current.points }, 0);
            setHunt(huntTotal);

            setTransactionList(data.getAllTransactions.data);
        }
    }, [data]);

    const memberprofileClick = (userId:string) => {
        history.push({pathname:'/memberprofile',state:{userId: userId}});
    }

    const columns: any = [
        {
            name: "organization",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "organization") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={0} onClick={() => updateDirection(0)} >
                            <a className="sort-by">Organization</a>
                        </th>
                    )
                },
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    let fieldValues = JSON.parse(value);
                    return (<div><img className='profile-img' src={fieldValues.orgLogo || EmptyOrg} /><label className='membername'>{fieldValues.orgName}</label></div>)
                }
            }
        },
        {
            name: "sponsor",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "sponsor") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(1)} >
                            <a className="sort-by">Sponsors</a>
                        </th>
                    )
                },
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    let fieldValues = JSON.parse(value);
                    return (<div><img className='profile-img' src={fieldValues.sponsorLogo || SponsorEmty} /><label className='membername'>{fieldValues.sponsorName}</label></div>)
                }
            }
        },
        {
            name: "transactionType",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "transactionType") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(2)} >
                            <a className="sort-by">Category</a>
                        </th>
                    )
                },
            }
        },
        {
            name: "user",
            options: {
                filter: false,
                sortThirdClickReset: true,
                sortDescFirst: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "user") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={3} onClick={() => updateDirection(3)} >
                            <a className="sort-by">Members</a>
                        </th>
                    )
                },
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    let fieldValues = JSON.parse(value);
                    let userName: string = fieldValues.name;
                    let userId:string = `${tableMeta.tableData[tableMeta.rowIndex].username}`;
                    return (<div className="pointer" onClick={() => memberprofileClick(userId)}><img className='profile-img' src={fieldValues.profileImg || "https://png.pngtree.com/png-vector/20190321/ourmid/pngtree-vector-users-icon-png-image_856952.jpg"} /><label className='membername'>{userName ? userName.replace("&", " ") : " "}</label></div>)
                }
            }
        },
        {
            name: "walletId",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "walletId") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={4} onClick={() => updateDirection(4)} >
                            <a className="sort-by">Wallet ID</a>
                        </th>
                    )
                }
            }
        },
        // {
        //     name:"tdc_patronid",
        //     options:{
        //         filter:false,
        //         sortThirdClickReset: true,
        //         customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
        //             let orderName = (sortOrder.name == "tdc_patronid")?sortOrder.direction:"";
        //             return(
        //             <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={4} onClick={() => updateDirection(4)} >
        //               <a className="sort-by">TDC Patron Id</a>
        //             </th>
        //             )
        //         }
        //     }
        // },
        {
            name: "points",
            label: "Points",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "points") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={5} onClick={() => updateDirection(5)} >
                            <a className="sort-by">Balance</a>
                        </th>
                    )
                }
            }
        },

        {
            name: "price",
            label: "Price",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "price") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={5} onClick={() => updateDirection(5)} >
                            <a className="sort-by">Price</a>
                        </th>
                    )
                }
            }
        },
        {
            name: "transactionStatus",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "transactionStatus") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(2)} >
                            <a className="sort-by">Status</a>
                        </th>
                    )
                },
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (<div><label className="membername">{value === "REFUND" ? `Refunded ($${tableMeta.tableData[tableMeta.rowIndex].refund_amount})` : `Successful`}</label></div>)
                }
            }
        },
        {
            name: "transactionRef",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "transactionRef") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={6} onClick={() => updateDirection(6)} >
                            <a className="sort-by">Transaction Ref. Code</a>
                        </th>
                    )
                },

            }

        },
        {
            name: 'transactionDate',
            label: "Date & Time",
            options: {
                filter: false,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "transactionDate") ? sortOrder.direction : "";

                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={7} onClick={() => updateDirection(7)} >
                            <a className="sort-by">Date & Time</a>
                        </th>
                    )
                },
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {

                    return (<div><label className="membername">{moment(parseInt(value)).format('DD MMM YYYY HH:mm')}</label></div>)
                }
            }
            // options: {
            //   filter: true,
            //   filterType: 'custom',

            //   // if the below value is set, these values will be used every time the table is rendered.
            //   // it's best to let the table internally manage the filterList
            //   //filterList: [25, 50],

            //   customFilterListOptions: {
            //     render: (v:any) => {
            //       if (v[0] && v[1] && ageFilterChecked) {
            //         return [`Min Age: ${v[0]}`, `Max Age: ${v[1]}`];
            //       } else if (v[0] && v[1] && !ageFilterChecked) {
            //         return `Min Age: ${v[0]}, Max Age: ${v[1]}`;
            //       } else if (v[0]) {
            //         return `Min Age: ${v[0]}`;
            //       } else if (v[1]) {
            //         return `Max Age: ${v[1]}`;
            //       }
            //       return [];
            //     },
            //     update: (filterList:any, filterPos:any, index:any) => {

            //       if (filterPos === 0) {
            //         filterList[index].splice(filterPos, 1, '');
            //       } else if (filterPos === 1) {
            //         filterList[index].splice(filterPos, 1);
            //       } else if (filterPos === -1) {
            //         filterList[index] = [];
            //       }

            //       return filterList;
            //     },
            //   },
            //   filterOptions: {
            //     names: [],
            //     logic(age:any, filters:any) {
            //       if (filters[0] && filters[1]) {
            //         return age < filters[0] || age > filters[1];
            //       } else if (filters[0]) {
            //         return age < filters[0];
            //       } else if (filters[1]) {
            //         return age > filters[1];
            //       }
            //       return false;
            //     },
            //     display: (filterList:any, onChange:any, index:any, column:any) => (
            //       <div>
            //         <FormLabel>Age</FormLabel>
            //         <FormGroup row>
            //           <TextField

            //             type="date"

            //             value={filterList[index][0] || ''}
            //             onChange={event => {
            //               filterList[index][0] = event.target.value;
            //               onChange(filterList[index], index, column);
            //             }}
            //             style={{ width: 'auto', marginRight: '3%' }}
            //           />
            //           <TextField
            //             type="date"

            //             value={filterList[index][1] || ''}
            //             onChange={event => {
            //               filterList[index][1] = event.target.value;
            //               onChange(filterList[index], index, column);
            //             }}
            //             style={{ width: 'auto' }}
            //           />
            //           {/* <FormControlLabel
            //             control={
            //               <Checkbox
            //                 checked={ageFilterChecked}
            //                 onChange={event => setAgeFilterChecked(event.target.checked)}
            //               />
            //             }
            //             label='Separate Values'
            //             style={{ marginLeft: '0px' }}
            //           /> */}
            //         </FormGroup>
            //       </div>
            //     ),
            //   },
            //   print: false,
            // },
        },
        // {
        //     name:"kycStatus",
        //     label:"KYC Status",
        //     options:{filter:false}
        // },
        // {
        //     name:"status",
        //     label:"Status",
        //     options:{filter:false}
        // }
        {
            name: "Actions",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
                    <>
                        {transTypes.indexOf(tableMeta.tableData[tableMeta.rowIndex].transactionType) > -1 && <Button variant="outlined" color="primary" onClick={() => handleClickOpen(tableMeta.tableData[tableMeta.rowIndex])}>
                            <VisibilityIcon fontSize="small" />
                        </Button>}
                    </>
                )
            }

        },
    ];

    const options: any = {
        filter: false,
        // count: data && data.getAllTransactions && data.getAllTransactions.count ? data.getAllTransactions.count : 10,
        filterType: "dropdown",
        responsive: "standard",
        print: false,
        download: false,
        viewColumns: false,
        selectableRowsHideCheckboxes: true,
        fixedSelectColumn: false,
        selectToolbarPlacement: "none",
        selectableRowsHeader: false,
        selectableRows: "none",
        selectableRowsOnClick: false,
        fixedHeader: true,
        tableBodyHeight: '450px',
        search: false,
        pagination : true,
        // serverSide: true,
        onTableChange: (action:any, tableState:any) => {

        switch (action) {
          case 'changePage':
            refetch({offset: tableState.page * tableState.rowsPerPage, limit: tableState.rowsPerPage})
            // count = data && data.getPointsHolders && data.getPointsHolders[0] && data.getPointsHolders[0].userCount ? data.getPointsHolders[0].userCount : 10;
            // changePage(tableState);
            break;
          case 'sort':
            if (tableState.sortOrder.direction !== 'none')
            {
                refetch({sort: tableState.sortOrder})
            }
            else
            {
                refetch({sort: null})
            }
            // this.sort(tableState.page, tableState.sortOrder);
            // count = data && data.getPointsHolders && data.getPointsHolders[0] && data.getPointsHolders[0].userCount ? data.getPointsHolders[0].userCount : 10;
            break;
          default:
            console.log('action not handled.');
        }

            //     let {refetch, loading, data } = useQuery<any>(
            //     GET_POINTHOLDERS_QUERY,{variables : {clubId : orgId, offset: offset, limit: limit}}
            // );

        // this.xhrRequest('my.api.com/tableData', result => {
        //   this.setState({ data: result });
        },
    }

    // const paginationClick = () => {
    //     fetchMore({ variables: { pageNumber: pageNumber + 1 } }).then((fetchMoreResult: any) => {
    //         const transactionMergedList: any = [...transactionList, ...fetchMoreResult.data.getAllTransactions.data];
    //         setTransactionList(transactionMergedList);
    //     });
    //     setPageNumber(pageNumber + 1);
    // }

    return (
        <>
            <div className="list-page transaction-list-wrapper">
                <h2 className="page-title">TRANSACTIONS OVERVIEW</h2>
                <Grid container xs={12} sm={12} className='txns-hdr-block'>
                    <div>
                        <Grid container className="item-status transaction-list-status" xs={12}>
                            {/* <Grid item sm={1} xs={2}>
                        <span className="data-bold pending">4000</span><label className="sub-caption">Pending</label>
                    </Grid>
                    <Grid item sm={1} xs={2}>
                        <span className="data-bold failed">500</span><label className="sub-caption">Failed</label>
                    </Grid> */}
                            {/* <Grid item sm={1} xs={2}>
                            <span className="data-bold transfers">{transfer}</span><label className="sub-caption">Transfers</label>
                        </Grid>
                        <Grid item sm={1} xs={2}>
                            <span className="data-bold redeemed" >{redeem}</span><label className="sub-caption">Redeemed</label>
                        </Grid> */}
                            <Grid item sm={1} xs={2}>
                                <span className="data-bold promotions">{promotions}</span><label className="sub-caption">Promotions</label>
                            </Grid>

                            {/* <Grid item sm={1} xs={2}>
                            <span className="data-bold hunt">{hunt}</span><label className="sub-caption">Hunt</label>
                        </Grid> */}

                        </Grid>
                        {/* <button onClick={() => paginationClick()}>Pagination</button> */}
                    </div>

                    {/* Export button */}
                    <div className='import-box flx-align-center cust-bg-clr' onClick={() => { setOpenExport(true); }}>
                        <div className='import-flx-box flx-align-center'>
                            <span className='export-btn-tile'>EXPORT TO CSV</span>
                        </div>
                    </div>

                    <Grid item sm={12} xs={12}>
                        <Paper className="table-wrapper padd-0">
                            {/* <Grid container sm={12} className="mrgb-20">
                        <Grid item sm={8}>
                            <label className="table-heading">TRANSACTIONS LIST</label>
                        </Grid>
                        <Grid  item sm={4} className="select-with-label">
                            <label>Sort By</label>
                            <DropdownSelect/>
                        </Grid>
                    </Grid> */}
                            {/* <TableContainer className="table-container" style={{ height: 450, width: '100%' }} component={Paper}>
                        <Table stickyHeader className="" aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Organizations</TableCell>
                                    <TableCell align="left">Sponsors</TableCell>
                                    <TableCell align="left">Category</TableCell>
                                    <TableCell align="left">Members</TableCell>
                                    <TableCell align="left">Wallet ID</TableCell>
                                    <TableCell align="left">Points</TableCell>
                                    <TableCell align="left">Transaction ID</TableCell>
                                    <TableCell align="left">Date & Time</TableCell>
                                    {/* <TableCell align="left">KYC</TableCell> */}
                            {/* <TableCell align="left">Status</TableCell>
                                </TableRow>
                            </TableHead>
                            { loading ? (
                            <TableBody>
                                <TableCell colSpan={6}>
                                    <Loader size="90px"/>
                                </TableCell>
                            </TableBody>
                            ): (
                            <TableBody>
                            {data.getAllTransactions.data.map((transaction:any) => {
                               return (
                                <TableRow >
                                  <TableCell className="label-parent" padding="none" >
                                    <img className="profile-img " src={transaction.orgLogo || EmptyOrg} />
                                    <label className="membername">{transaction.orgName}</label>
                                </TableCell>
                                <TableCell className="label-parent" padding="none" >
                                    <img className="profile-img " src={transaction.sponsorLogo || SponsorEmty}/>
                                    <label className="membername">{transaction.sponsorName}</label>
                                </TableCell>
                                <TableCell className="label-parent" padding="none" >
                                    <label className="membername">{transaction.transactionType}</label>
                                </TableCell>
                                <TableCell className="label-parent" padding="none" >
                                    <img className="profile-img " src={transaction.userProfilePic || "https://png.pngtree.com/png-vector/20190321/ourmid/pngtree-vector-users-icon-png-image_856952.jpg"} />
                                    <label className="membername">{transaction.username}</label>
                                </TableCell>
                                <TableCell className="label-parent" padding="none" >
                                    <label className="">{transaction.walletId || "-"}</label>
                                </TableCell>
                                <TableCell className="label-parent" padding="none" >
                                      <label className="">{transaction.points}</label>
                                </TableCell>
                                <TableCell className="label-parent" padding="none" >
                                      <label className="">{transaction.id}</label>
                                </TableCell>
                                <TableCell className="label-parent" padding="none" >
                                  <label className="">{moment(parseInt(transaction.transactionDate)).format('DD MMM YYYY HH:mm')}</label>
                                </TableCell>

                                 <TableCell className="label-parent" padding="none" >
                                    <label className="">Completed</label>
                                </TableCell>
                                </TableRow>
                            )})}
                            </TableBody>
                            )}
                        </Table>
                    </TableContainer> */}
                            <div className="table-container muidata-table trans-cust-body">
                                {loading ?
                                    (
                                        <Loader size="90px" />
                                    ) : (
                                        <MUIDataTable
                                            title={"Transaction List (MOST RECENT 100 TRANSACTIONS)"}
                                            data={transactionList}
                                            columns={columns}
                                            options={options}
                                        />)
                                }
                            </div>
                        </Paper>
                    </Grid>
                </Grid>

                <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" disableBackdropClick={true} disableEscapeKeyDown={true}>
                    <DialogTitle id="alert-dialog-title" className='cust-dlg-title'>{"Transaction Summary"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div className="pv-email-template" >
                                <div className="email-body-block" >
                                    <div className="zig-zag-top-bdr">
                                        <div className="summary-block" >
                                            {orderLoading && <p>Loading ...</p>}
                                            {!orderLoading && orderDetails.length > 0 && orderDetails.map((item: any, idx: any) => (
                                                <>
                                                    <div className='txs-tile-block'><span className='txs-sum-title'>Reference No</span> : {item.transactionRef}</div>
                                                    <div className='txs-tile-block'><span className='txs-sum-title'>Order Date</span> : {item.transactionDate}</div>
                                                    <div className='txs-tile-block'><span className='txs-sum-title'>Order Total</span> : {item.transactionType === 'REDEEM' ? `${item.transactionPoints} Pts` : item.transactionType === 'STRIPE' ? `${(item.transactionPrice).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}` : `${(item.transactionPrice).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} + ${item.transactionPoints} Pts`}</div>
                                                    <div className='txs-tile-block'><span className='txs-sum-title'>Payment Mode</span> : {item.transactionType === 'REDEEM' ? 'Wallet Points' : item.transactionType === 'STRIPE' ? 'Credit Card' : 'Credit Card + Wallet Points'}</div>
                                                    <div className='txs-divider' />
                                                    <TableContainer component={Paper}>
                                                        <Table className="order-table" aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>ITEMS ORDERED</TableCell>
                                                                    <TableCell align="right">QTY</TableCell>
                                                                    <TableCell align="right">{item.transactionType === 'STRIPE' ? 'PRICE' : 'POINTS'}</TableCell>
                                                                </TableRow>
                                                            </TableHead>

                                                            <TableBody>
                                                                {item.txsListing.length > 0
                                                                    ? (
                                                                        item.txsListing.map((value: any) => (
                                                                            <TableRow key={value.ml_id}>
                                                                                <TableCell component="th" scope="row" className='txs-disp-flx pl-5 no-border-btm'><img src={JSON.parse(value.merchandise_imageurl)[0].imageUrl} className="merch-image" alt="" />{`${value.merchandise_name} - ${value.ml_type_subtype}`}</TableCell>
                                                                                <TableCell align="right" className='no-border-btm'>{value.bookedQtyByUserCart}</TableCell>
                                                                                <TableCell align="right" className='pr-5 no-border-btm'>{item.transactionType === 'STRIPE' ? `${(value.bookedPointsConsumed * item.org_rate).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}` : value.bookedPointsConsumed}</TableCell>
                                                                            </TableRow>
                                                                        ))
                                                                    ) : ''
                                                                }
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>

                                                    {item.transactionType === 'PARTIAL' &&
                                                        <>
                                                            <div className='txs-divider mt-10' />
                                                            <div className='disp-flx-box'>
                                                                <div className=''>Sub Total</div>
                                                                <div className=''>{item.txsSubTotal}</div>
                                                            </div>
                                                            <div className='disp-flx-box'>
                                                                <div className=''>Wallet Points</div>
                                                                <div className=''>{item.transactionPoints}</div>
                                                            </div>
                                                            <div className='disp-flx-box'>
                                                                <div className=''>Card Amount (Including Service Charge)</div>
                                                                <div className=''>{`$${item.transactionPrice} (${item.txsSubTotal - item.transactionPoints} Pts)`}</div>
                                                            </div>
                                                            {/* {item.transactionServiceCharge > 0 &&
                                                            <div className='disp-flx-box'>
                                                                <div className=''>Service Charge</div>
                                                                <div className=''>{`$${item.transactionServiceCharge}`}</div>
                                                            </div>
                                                        } */}
                                                        </>
                                                    }
                                                    {item.transactionType === 'STRIPE' &&
                                                        <>
                                                            <div className='txs-divider mt-10' />
                                                            <div className='disp-flx-box'>
                                                                <div className=''>Sub Total</div>
                                                                <div className=''>{`${(item.txsSubTotal * item.org_rate).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`}</div>
                                                            </div>
                                                            {item.transactionServiceCharge > 0 &&
                                                                <div className='disp-flx-box'>
                                                                    <div className=''>Service Charge</div>
                                                                    <div className=''>{`${(item.transactionServiceCharge).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`}</div>
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                    <div className='txs-divider mt-10' />
                                                    <div className='disp-flx-box pt-10'>
                                                        <div className='txs-total-title'>Total</div>
                                                        <div className='txs-total-title'>{item.transactionType === 'STRIPE' ? `${(item.transactionPrice).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}` : item.txsSubTotal}</div>
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleClose} color="primary" autoFocus className='txs-btn-color'>Close</Button>
                    </DialogActions>
                </Dialog>
            </div>

            <ExportToCSV onOpen={openExport} handleCloseClick={handleClose} />
        </>
    )
}
