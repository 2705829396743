import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import { Auth } from 'aws-amplify';

import { Backdrop, CircularProgress, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {
    BrowserRouter as Router,
    Switch,
    useLocation
  } from "react-router-dom";
import Logo from '../../assets/logo-big.png';
import PasswordEye from '../../assets/ic_eye.svg';
import NoPasswordEye from '../../assets/eye-slash-solid.webp';
import './forgotpassword.css';

interface IFormForgotPwd {
    userName: string,
    code: string,
    newPassword: string,
    retypeNewPassword: string
}

let defaultValues: any = {
    userName: '',
    code: '',
    newPassword: '',
    retypeNewPassword: ''
}

function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CustomForgotPassword() {
    let location = useLocation();
    const [showCode, setShowCode] = React.useState<any>(false);
    const [showNewPassword, setShowNewPassword] = React.useState<any>(false);
    const [showRetypePassword, setShowRetypePassword] = React.useState<any>(false);

    const [userName, setUserName] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    const { register, handleSubmit, errors, control, reset, formState, setValue } = useForm<IFormForgotPwd>({ defaultValues, shouldUnregister: false });
    
    useEffect(() => {
        if(location.search != ""){
            setPage(1);
            let Params = location.search.split("?")[1];
            setValue("code",Params.split("&")[1]);
            setUserName(Params.split("&")[0])
        }
    },[])


    const onSubmit: SubmitHandler<IFormForgotPwd> = async (fieldData) => {
        setLoading(true);

        if (page === 0) {
            await Auth.forgotPassword(fieldData.userName).then(data => {
                setUserName(fieldData.userName);
                setLoading(false);
                setPage(1);
            }).catch(err => {
                setErrorMessage(err.message);
                setOpen(true);
                setLoading(false);
            })
        } else if (page === 1) {
            if (fieldData.newPassword !== fieldData.retypeNewPassword) {
                setErrorMessage('New password and retype new password do not match');
                setOpen(true);
                setLoading(false);
                return;
            }

            await Auth.forgotPasswordSubmit(userName, fieldData.code, fieldData.newPassword).then(data => {
                setLoading(false);
                setPage(2);
            }).catch(async (err) => {
                if(err.message === "Cannot reset password for the user as there is no registered/verified email or phone_number") {
                    await Auth.resendSignUp(userName);
                    setErrorMessage("Your account not verified, we have sent a verification mail please check the mail box");
                    setOpen(true);
                    setLoading(false);
                } else {
                setErrorMessage(err.message);
                setOpen(true);
                setLoading(false);
                }
            })
        } else if (page === 2) {
            history.push('/')
        }
    }

    // to change input text values here.
    const handleChangeField = (e: React.ChangeEvent<any>) => {
        const targetValue = (e.target as HTMLInputElement).value;
        const targetName: string = (e.target as HTMLInputElement).name;
        setValue(targetName, targetValue);
    }

    const handleClickShowHide = (obj:string) => {
        if (obj === '0') setShowCode(!showCode)
        else if (obj === '1') setShowNewPassword(!showNewPassword)
        else if (obj === '2') setShowRetypePassword(!showRetypePassword)
    };

    const handleClose = (event: any, reason: any) => {
        setOpen(false);
    };

    return (
        <div className='fotgot-pwd-container'>
            <div className='flex-block'>
                {/* pv logo */}
                <div className='pv-logo-block'>
                    <img src={Logo} alt='logo' className='signin-logo-img' />
                </div>

                {/* form */}
                <div className='form-block'>
                    <div className='title-block'>FORGOT PASSWORD</div>

                    {page === 0 ? (
                        <>
                            <div className='msg-title pt-30'>Don’t worry, we have covered it for you</div>
                            <div className='msg-desc pt-10'>Enter your registered email id and we will send you the instructions to reset your password.</div>

                            <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
                                {/* Email */}
                                <Grid item className="flx-align-center pt-30" sm={12} xs={12}>
                                    <Grid item sm={12} xs={12}>
                                        <div className="input-group">
                                            <label className="input-label">Email *</label>
                                            <Controller as={TextField} rules={{ required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ }} type="text" name="userName" placeholder="example@xyz.com" className={`custom-input form-fields  ${errors.userName && 'error'}`} control={control} defaultValue="" onChange={(e: any) => handleChangeField(e)} />
                                            {errors.userName && errors.userName.type === "required" && (<label className="error-message">Field is required</label>)}
                                            {errors.userName && errors.userName.type === "pattern" && (<label className="error-message">Please enter a valid business email</label>)}
                                        </div>
                                    </Grid>
                                </Grid>

                                <div className='btn-flex-box'>
                                    <Button type="submit" className='btn-submit' variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>SUBMIT</Button>
                                </div>
                            </form>
                        </>
                    ) : page === 1 ? (
                        <>
                            <div className='msg-title pt-30'>Please Check Your Email</div>
                            <div className='msg-desc confirm pt-10'>We have sent you the password reset verification code to <br />your email id <span>{userName}</span></div>

                            <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
                                {/* New Password */}
                                <Grid item className="flx-align-center" sm={12} xs={12}>
                                    <Grid item sm={12} xs={12}>
                                        <div className="input-group">
                                            <label className="input-label">Verification Code *</label>
                                            <Controller as={TextField} rules={{ required: true }} type={showCode ? "text" : "password"} name="code" placeholder="Verification code here" className={`custom-input pr-40 form-fields  ${errors.code && 'error'}`} control={control} defaultValue="" onChange={(e: any) => handleChangeField(e)} />
                                            <span onClick={() => handleClickShowHide('0')}><img className='password-eye' src={showCode ? PasswordEye : NoPasswordEye} alt='Password Eye' /></span>
                                            {errors.code && errors.code.type === "required" && (<label className="error-message">Field is required</label>)}
                                        </div>
                                    </Grid>
                                </Grid>

                                {/* New Password */}
                                <Grid item className="flx-align-center" sm={12} xs={12}>
                                    <Grid item sm={12} xs={12}>
                                        <div className="input-group">
                                            <label className="input-label">New Password *</label>
                                            <Controller as={TextField} rules={{ required: true , pattern:/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/}} type={showNewPassword ? "text" : "password"} name="newPassword" placeholder="New password here" className={`custom-input pr-40 form-fields  ${errors.newPassword && 'error'}`} control={control} defaultValue="" onChange={(e: any) => handleChangeField(e)} />
                                            <span onClick={() => handleClickShowHide('1')}><img className='password-eye' src={showNewPassword ? PasswordEye : NoPasswordEye} alt='Password Eye' /></span>
                                            {errors.newPassword && errors.newPassword.type === "required" && (<label className="error-message">Field is required</label>)}
                                            {errors.newPassword && errors.newPassword.type === "pattern" && (<label className="error-message">Password should be at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character</label>)}
                                        </div>
                                    </Grid>
                                </Grid>

                                {/* Confirm New Password */}
                                <Grid item className="flx-align-center" sm={12} xs={12}>
                                    <Grid item sm={12} xs={12}>
                                        <div className="input-group">
                                            <label className="input-label">Retype New Password *</label>
                                            <Controller as={TextField} rules={{ required: true }} type={showRetypePassword ? "text" : "password"} name="retypeNewPassword" placeholder="Retype new password here" className={`custom-input pr-40 form-fields  ${errors.retypeNewPassword && 'error'}`} control={control} defaultValue="" onChange={(e: any) => handleChangeField(e)} />
                                            <span onClick={() => handleClickShowHide('2')}><img className='password-eye' src={showRetypePassword ? PasswordEye : NoPasswordEye} alt='Password Eye' /></span>
                                            {errors.retypeNewPassword && errors.retypeNewPassword.type === "required" && (<label className="error-message">Field is required</label>)}
                                        </div>
                                    </Grid>
                                </Grid>


                                <div className='btn-flex-box'>
                                    <Button type="submit" className='btn-submit' variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>SUBMIT</Button>
                                </div>
                            </form>
                        </>
                    ) : page === 2 ? (         
                        <>                                
                            <div className='msg-title pt-30'>Password Successfully Changed</div>
                            <div className='msg-desc confirm pt-10'>Please continue to sign in with your new password.</div>

                            <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
                                <div className='btn-flex-box'>
                                    <Button type="submit" variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>SIGN IN</Button>
                                </div>
                            </form>
                        </>
                    ) : '' }
                </div>
            </div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning">
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Backdrop className="loader-signup" open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}
