import React ,{useState,useEffect}from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../../constant.css';
import { useQuery, gql } from '@apollo/client';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';

interface Props {
    onOpen : boolean,
    handleCloseClick: () => void,
}

const GET_EXPORT_TO_CSV = gql`
    query exportTransactionDataToCSV($fromDate: String!, $toDate: String!) {
        exportTransactionDataToCSV(fromDate: $fromDate, toDate: $toDate) {
            transId
            transDate
            transCode
            transType
            itemDescription
            itemCategory
            itemFulfillment
            orgName
            userName
            userEmail
            shippingAddress
            walletId
            transPoints
            transPrice
        }
    }
`;

export default function ExportToCSV({handleCloseClick,onOpen}:Props) {

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [startDateISO, setStartDateISO] = useState(new Date().toISOString().split('T')[0] + 'T00:00:00.000Z');
    const [endDateISO, setEndDateISO] = useState(new Date().toISOString().split('T')[0] + 'T23:59:59.000Z');
    const [isExportClicked, setIsExportClicked] = React.useState(false);

    const [errorMsg, setErrorMsg] = useState<any>("");
    const [openToast, setOpenToast] = React.useState<any>(false);
    const [isSkiped, setIsSkiped] = React.useState<any>(true);

    const { loading, data, refetch } = useQuery<any>(GET_EXPORT_TO_CSV, { skip: isSkiped, variables: { fromDate: startDateISO, toDate: endDateISO } });

    const Alert = (props: AlertProps) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleCancel = () => {
        setStartDate(new Date());
        setEndDate(new Date());
        setStartDateISO(new Date().toISOString().split('T')[0] + 'T00:00:00.000Z');
        setEndDateISO(new Date().toISOString().split('T')[0] + 'T23:59:59.000Z');

        handleCloseClick();
    }


    useEffect(() => {
        if(!loading){
            if (data){

                setIsExportClicked(false);
                setIsSkiped(true);

                var headers = {
                    transDate: "Transaction Date",
                    transCode: "Transaction Code",
                    orgName: "Organization",
                    itemCategory: "Item Category",
                    itemDescription: "Item Description",
                    transPoints: "Points",
                    transPrice: "Price",
                    transType: "Transaction Type",
                    walletId: "Customer Wallet Id",
                    userName: "Customer Name",
                    userEmail: "Customer Email",
                    shippingAddress: "Customer Shipping Address",
                    itemFulfillment: "Fulfillment",
                };

                let csvFileData:any = [];
                data.exportTransactionDataToCSV.forEach((item:any) => {
                    csvFileData.push({
                        transDate: new Date(parseInt(item.transDate)).toISOString(),
                        transCode: item.transCode,
                        orgName: item.orgName,
                        itemCategory: item.itemCategory,
                        itemDescription: item.itemDescription,
                        transPoints: item.transPoints,
                        transPrice: item.transPrice,
                        transType: item.transType,
                        walletId: item.walletId,
                        userName: item.userName,
                        userEmail: item.userEmail,
                        shippingAddress: item.shippingAddress.replace(/,/g, ''),
                        itemFulfillment: item.itemFulfillment
                    });
                });

                let fileTitle = 'PV_ExportToCSV_' + new Date().toISOString();

                // call the exportCSVFile() function to process the JSON and trigger the download
                if (csvFileData.length > 0) {
                    exportCSVFile(headers, csvFileData, fileTitle);
                } else {
                    setErrorMsg('No transactions available in this date range');
                    setOpenToast(true);
                }
            }
        }
    },[data]);

    // to avoid key press event for date selection.
    const handleDateChangeRaw = (e:any) => {
        e.preventDefault();
    }

    // to close snackbar.
    const handleClosed = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenToast(false);
    };

    const handleExportToCSVDates = (objDate:any, objKey:string) => {
        if (objDate) {
            if (objKey === 'SD') {
                setStartDate(objDate);
                let tempStDate:any = objDate.toISOString().split('T')[0] + 'T00:00:00.000Z';
                setStartDateISO(tempStDate);
            } else if (objKey === 'ED') {
                setEndDate(objDate);
                let tempEnDate:any = objDate.toISOString().split('T')[0] + 'T23:59:59.000Z';
                setEndDateISO(tempEnDate);
            }
        }
    }

    // to get transactions from db using this method.
    const handleExportToCSVDownload = () => {
        if (isExportClicked) return false;
        else {
            setIsExportClicked(true);

            if (startDate && endDate) {
                if (startDate <= endDate) {
                    setIsSkiped(false);
                    refetch();
                } else {
                    setErrorMsg('End date should be greater than Start date.');
                    setOpenToast(true);
                }
            } else {
                setErrorMsg('Please select start and end date to proceed.');
                setOpenToast(true);
            }
        }
    }

    // to convert JSON to CSV format.
    const convertToCSV = (objArray:any) => {
        var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        var str = '';

        for (var i = 0; i < array.length; i++) {
            var line = '';
            for (var index in array[i]) {
                if (line != '') line += ','

                line += array[i][index];
            }
            str += line + '\r\n';
        }
        return str;
    }

    // to download csv file after converting json data.
    const exportCSVFile = (headers:any, items:any, fileTitle:any) => {
        if (headers) {
            items.unshift(headers);
        }

        // Convert Object to JSON
        var jsonObject = JSON.stringify(items);

        var csv = convertToCSV(jsonObject);

        var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

        var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

        // if (navigator.msSaveBlob) { // IE 10+
            // navigator.msSaveBlob(blob, exportedFilenmae);
        // } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        // }
    }

    return (
        <Dialog open={onOpen} onClose={handleCancel} aria-labelledby="form-dialog-title" className="common-dialog-container export-to-csv-container">
            <form className="form-container">
                <DialogContent>
                    <h5 id="form-dialog-title" className="section-heading">Export To CSV</h5>
                    <Grid className="form-grid" container sm={12} spacing={2}>
                        <Grid item sm={6} xs={12}>
                            <div className="input-group">
                                <label className="input-label">Start Date</label>
                                <DatePicker maxDate={new Date()} selected={startDate} onChange={(date:any) => { handleExportToCSVDates(date, 'SD');}} placeholderText='MM/DD/YYYY' onChangeRaw={handleDateChangeRaw} />
                            </div>
                        </Grid>

                        <Grid item className="padr0" sm={6} xs={12}>
                            <div className="input-group">
                                <label className="input-label">End Date</label>
                                <DatePicker maxDate={new Date()} selected={endDate} onChange={(date:any) => { handleExportToCSVDates(date, 'ED');}} placeholderText='MM/DD/YYYY' onChangeRaw={handleDateChangeRaw} />
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions className="export-to-csv-actions">
                    <Button className="btn btn-secondary" color="primary" onClick={handleCancel}>CANCEL</Button>
                    <div className= 'txns-hdr-block dail-box' >
                        <div className='import-box flx-align-center cust-bg-clr' onClick={handleExportToCSVDownload}>
                            <div className='import-flx-box flx-align-center'>
                                <div className={isExportClicked ? 'ic-import rotate' : 'ic-import'} /> <span className='export-btn-tile'>DOWNLOAD</span>
                            </div>
                        </div>
                    </div>
                </DialogActions>
            </form>

            <Snackbar open={openToast} autoHideDuration={5000} onClose={handleClosed}>
                <Alert onClose={handleClosed} severity="error">{errorMsg}</Alert>
            </Snackbar>
        </Dialog>
    )
}
