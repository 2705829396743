import React, { useEffect } from 'react'
import Button from '@mui/material/Button';
import { useEthers, useEtherBalance } from "@usedapp/core";
import Metamask from "../../assets/metamask.svg";
import { Backdrop, CircularProgress, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
interface Props {
  handleChangePubKey: (pubkey:any) => void,
}
function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function ConnectWallet(props: Props) {
  const { activateBrowserWallet, account, deactivate, error } = useEthers();
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleClose = (event: any, reason: any) => {
    setOpen(false);
};
  useEffect(() => {
    if (error && !account) {
      console.log(error?.message, "msg")
      // setOpen(true);
      // setMessage(error?.message);
      // setType("error");

    }
  }, [error])

  useEffect(() => {
    if (account) {
      let pubkey:any = JSON.stringify(account);
      localStorage.setItem("metamask_wallet_key", pubkey);
      props.handleChangePubKey(account);
    }
  }, [account])

  const handleConnectWallet = async () => {

      await deactivate();

      try {
        await activateBrowserWallet();

        if ((window as any).ethereum) {

//          localStorage.setItem("metamask_wallet_key", JSON.stringify(account));

          if ((window as any).ethereum.selectedAddress) {

          }

        } else {
            setOpen(true);
            setErrorMessage('Please install metamask chrome extensions ');
        }


      } catch (e) {
        console.log(e,)
      }

  };

  return (
    <React.Fragment>
      <Button variant="outlined" style={{ width: "95%" }} onClick={handleConnectWallet} ><img src={Metamask} style={{width:"5%", marginRight:"10px"}} /> Connect to Metamask</Button>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
              {errorMessage}
          </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
