import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardHeader, Avatar } from "@mui/material";
import { Button, IconButton } from "@material-ui/core";

interface Props {
  title: string;
  message: string;
  show: boolean;
  closeNotification :  () => void 
}
export default function NotificationComponent(props:Props) {

  return (
    <Card
      sx={{ maxWidth: 345, display: props.show ? "block" : "none" }}
      className="bottomright"
    >
      <CardHeader
        sx={{ padding: "10px", borderBottom:"1px solid #0000001a"}}
        action={<IconButton aria-label="settings" onClick={props.closeNotification} size="small">X</IconButton>}
        // title="Shrimp and Chorizo Paella"
        subheader={props.title}
      />
      <CardActionArea>
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {props.message}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
