import React, {useRef} from "react";
import { Card, CardContent, Typography, IconButton, TextField, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import './widgetsetting.css';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { widgetUrlMapping } from "../../config";
import { useQuery, gql,useMutation } from '@apollo/client';
import Loader from '../../components/Loader/loader';
import { Snackbar } from '@material-ui/core';
interface ParamTypes {
    orgId: string;
}
function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function useDebounce(value: string | undefined, delay: number) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = React.useState(value)
    React.useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value)
        }, delay)
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler)
        }
      },
      [value, delay] // Only re-call effect if value or delay changes
    )
    return debouncedValue
}
const GET_PRE_SIGNED_URL = gql`
    query getPreSignedUrl($fileName: String!){
        getPreSignedUrl(fileName: $fileName){
            preSignedUrl
        }
    }
`;

const GET_WIDGET_DETAILS = gql`
    query($id: String!) {
        getOrgWidgetDetails(id: $id){
            widgetImageUrl,
            widgetColorCode
        }
    }
`;

const UPDATE_WIDGET_DETAILS = gql`
    mutation updateOrgWidget($id: String!, $data: WidgetClubInput!) {
        updateOrgWidget(id: $id, data: $data) {
            id
            widgetImageUrl
        }
    }
    `;
    export default function WidgetSetting() {
    const inputRef = useRef(null);
    const headText = "<head> ";
    let { orgId } = useParams<ParamTypes>();
    const [colorCode, setColorCode] = React.useState('#000');
    const [open, setOpen] = React.useState(false);
    const host = window.location.host.split(".")[1] ? window.location.host.split(".")[0] : window.location.hostname;
    const env = host.split("-")[0];
    const [loader,setLoader] = React.useState<any>(false);
    const [targetFile, setTargetFile] = React.useState<any>([])
    const [uploadFileName, setUploadFileName] = React.useState<any>('')
    const [logoUrl, setlogoUrl] = React.useState<any>('https://pointsville.com/images/logo/pointsville-small-logo.svg');
    const { loading: urlLoading, data: urlData, refetch: urlRefetch } = useQuery<any>(GET_PRE_SIGNED_URL, { skip: uploadFileName.length > 0 ? false : true , variables: { fileName: uploadFileName }});
    const widgetUrl = (widgetUrlMapping && widgetUrlMapping[env]) ? widgetUrlMapping[env]  : "https://pv-images-alpha.s3.us-west-2.amazonaws.com/widget/index.js";
    const textScript = "<script id='pvWidget' type='text/javascript' data-pvButtonColor='"+ colorCode +"' data-pvOrgId='" + orgId + "'data-pvLogoUrl='"+ logoUrl +"' src='"+widgetUrl+"' defer></script>";
    const { loading: widgetLoading, data: widgetData, refetch: widgetRefetch } = useQuery<any>(GET_WIDGET_DETAILS, {variables: { id: orgId },fetchPolicy: "network-only"});
    const [errorMessage,setErrorMsg] = React.useState('');
    const [openToast,setOpenToast] = React.useState(false);
    const widgetBtnStyle = {
        border: "none",
        outline: "none",
        backgroundColor: colorCode,
        padding: "15px",
        borderRadius: "10px",
        fontSize: "18px",
        width: "250px"
    }
    
    const [updateWidget] = useMutation(UPDATE_WIDGET_DETAILS, {
        // after updating the post, we go to the home page
        onCompleted: (data:any) => {
            setlogoUrl(data?.updateOrgWidget?.widgetImageUrl)
        },
        onError: (data) => {
            // setErrorMsg(data.message);
            // setOpenToast(true);
        }
    });
    const handleClose = () => {
        setOpen(false);
        setOpenToast(false);
    };
    const handleCopy = ()=> {
        navigator.clipboard.writeText(textScript);
            setOpen(true);
    }
    React.useEffect(() => {
        if(!urlLoading && uploadFileName.length > 0) {
            urlRefetch()
            if (urlData) {
                console.log("hello 6767", urlData);
                setUploadFileName('')
                fileUploadToS3(urlData.getPreSignedUrl.preSignedUrl)
                console.log("URL IMAGR", urlData.getPreSignedUrl);
                urlRefetch();
            }
        }
    },[uploadFileName, urlData]);
    
    React.useEffect(() => {
        if(widgetData){
            setColorCode(widgetData?.getOrgWidgetDetails.widgetColorCode);
            setlogoUrl(widgetData?.getOrgWidgetDetails.widgetImageUrl);
        }
    },[widgetData]);

    const debouncedSearchTerm = useDebounce(colorCode, 500)
    React.useEffect(() => {
        if (debouncedSearchTerm !== undefined && colorCode !== undefined) {
            updateWidget({
                variables:
                {
                    id: orgId,
                    data: {
                        widgetColorCode: colorCode
                    }
                }
            }) 
        }
    },[debouncedSearchTerm])

    const fileUploadToS3 = (objUrl:any) => {
        if (objUrl) {
            fetch(objUrl, {
                method: 'PUT',
                headers: { 'Content-Type': 'image/*' },
                body: targetFile[0]
            }).then((response) => {
                if (response && response.url) {
                    setlogoUrl(response.url.split('?')[0]);
                    updateWidget({
                        variables:
                        {
                            id: orgId,
                            data: {
                                widgetImageUrl: response.url.split('?')[0],
                                widgetColorCode: colorCode
                            }
                        }
                    })
                    setLoader(false);
                    
                }
            })
        }
    }

    const uploadImage = (e:React.ChangeEvent<any>) =>{
        const targetFiles:any = e.target.files;
        const fileName:any = orgId+'_widget.png';
        if (targetFiles.length === 0) return;
        if(targetFiles[0].type === "image/png" || targetFiles[0].type === "image/jpeg"){
            setLoader(true);

            if (targetFiles.length > 0) {
                targetFiles[0].fileName = fileName
                setUploadFileName(fileName)
                setTargetFile(targetFiles)
            }
        }else{
            setUploadFileName('')
            setErrorMsg("Image format should be png, jpg");
            setOpenToast(true);
        }
    }
    const handleClick = (e:any) => {
        inputRef.current.click();
    }
    return (
<div className="walletSettingContainer" style={{ minWidth: "1000px" }}>
            <Card sx={{ margin: "0 auto", width: "50%" }}>
                <CardContent>
                {
                    widgetLoading ? 
                        <Loader size="90px"/> 
                    :      
                        <React.Fragment>
                            <Typography className="blocktitle" color="text.secondary" gutterBottom>
                                            Widget Style
                            </Typography>
                            <Grid container>
                                <Grid sx={{ width: "50%" }}>
                                    <Typography className="subtitle" color="text.secondary" gutterBottom>
                                        Color picker
                                    </Typography>
                                    <div className="color-code-container">
                                        <input value={colorCode} type="color" className="color-code-input" onChange={(e) => { setColorCode(e.target.value) }}  />
                                        <label className="color-code-text">{colorCode}</label>
                                    </div>
                                    <br />
                                    <Typography className="subtitle" color="text.secondary" gutterBottom>
                                        Logo picker
                                    </Typography>
                                    
                                    <div className="color-code-container" style={{overflow:"hidden"}}>
                                        <input type="file"  ref={inputRef} onChange={(e) => uploadImage(e)} accept="image/png,image/jpeg" style={{display:'none'}} />
                                        <button className="btn btn-primary add-btn" onClick={handleClick}>Change Image</button>
                                    </div>
                                </Grid>
                                <Grid sx={{ width: "50%"}}>
                                    <Typography className="subtitle" color="text.secondary" gutterBottom>
                                        Preview
                                    </Typography>
                                    <button id="widgetBtn" style={widgetBtnStyle}  >{loader ? <Loader size="30px"/> :<img src={logoUrl+'?'+Math.random()} width="186px" height="72px" />}</button><br /><br />
                                    <Typography className="subtitle" style={{color :"black",fontStyle:"italic",fontWeight:"bold"}} >Note : Size should be 186 * 72</Typography>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                }

                </CardContent>
            </Card>
            <br />
            <Card sx={{ margin: "0 auto", width: "50%" }}>
                {
                    widgetLoading ? 
                        <Loader size="90px"/> 
                    :      
                        <React.Fragment>
                <CardContent>
                    <Typography className="blocktitle" color="text.secondary" gutterBottom>
                        Widget Script
                    </Typography>
                    <Typography className="codeHeighlightContainer" color="text.secondary" gutterBottom>
                        To install the Pointsville widget on your website, copy and paste this code snippet in the <var style={{ color: "red" }}>{headText}</var>
                        of all HTML pages where you want to display the Pointsville widget.
                    </Typography>
                    <div className="codeContainer">

                        <Typography color="text.secondary" gutterBottom>

                            <code>{"<!-- Copy into the head of all HTML pages you wish to display the widget -->"}</code> <br /><br />
                            <code>{"<!-- Pointsville-widget - copy into the head -->"}</code><br /><br />
                            <code id="pv_copy_text">{textScript}</code>
                            <IconButton
                                onClick={handleCopy}
                                sx={{ margin: "0px", width: "10%", float: "right", borderRadius: "5px", padding: "0px" }} size="small">
                                <img src="https://furtaev.ru/preview/copy_small.png" style={{ width: "60%" }} />
                            </IconButton>
                        </Typography>
                    </div>
                </CardContent>
                </React.Fragment>}
            </Card>
            <Snackbar open={openToast} autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">{errorMessage}</Alert>
            </Snackbar>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Copied"
                anchorOrigin={{ vertical:"bottom", horizontal:"right" }}
                />
        </div>
    )
}