import React ,{ useEffect ,useState}from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory,useParams } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useQuery, gql } from '@apollo/client';
import Loader from '../../components/Loader/loader';
import MUIDataTable from "mui-datatables";
export const GET_MEMBER_QUERY = gql`
    query GetUserData {
        getUser{
            id
            user
            username
            email
            phoneNumber
            isActive
            walletId
            walletPoints
            balance
            createdAt
            tdc_patronid
            tdc_walletbalance

        }
    }
`;

export const GET_MEMBERS_COUNT = gql`
    query {
        getUsersCount {
            totalMembers
            activeMembers
            inActiveMembers
        }
    }
`;

const useStyles = makeStyles({
  
});

function createData(organization: string, walletId: string,status:string) {
  return { organization, walletId,status };
}


export default function KYCOverview() {
  const classes = useStyles({
      container : {
          padding:0,
      }
  });
  const [total,setTotal] = useState(0);
    const [active,setActive] = useState(0);
    const [inActive,setInActive] =  useState(0);

  const {data, loading, refetch} = useQuery<any>(GET_MEMBER_QUERY);
  const {data: usersData, loading: usersLoading} = useQuery<any>(GET_MEMBERS_COUNT);


  useEffect(() => {   
    refetch();
    if(!usersLoading){   
        setActive(usersData.getUsersCount[0].activeMembers);
        setInActive(usersData.getUsersCount[0].inActiveMembers);
        setTotal(usersData.getUsersCount[0].totalMembers);
    }
},[usersData]);
const history = useHistory();

const memberListAll = () => history.push('/managemembers');

const columns:any = [
    {
      name:"user",
      options:{
          filter:false,
          sortThirdClickReset: true,
          sort: true,
          customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
            let orderName = (sortOrder.name == "user")?sortOrder.direction:"";
            return(
            <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={0} onClick={() => updateDirection(0)} >
              <a className="sort-by">Member</a>
            </th>
            )
          },
          customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
            let fieldValues = JSON.parse(value);
              return (<div><img className='profile-img' src={fieldValues.profileImage || "https://png.pngtree.com/png-vector/20190321/ourmid/pngtree-vector-users-icon-png-image_856952.jpg"} /><label style={{whiteSpace: 'nowrap'}}>{fieldValues.givenName}</label></div>)
            }
          
      }
    },
    {
      name:"email",
      options:{
          filter:false,
          sortThirdClickReset: true,
           sort: true,
          customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
              let orderName = (sortOrder.name == "email")?sortOrder.direction:"";
  
              return(
              <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={0} onClick={() => updateDirection(1)} >
                <a className="sort-by">Email ID</a>
              </th>
              )
          }
      }
    },
    {
      name:"walletPoints",
      options:{
          filter:false,
          sortThirdClickReset: true,
          sort: true,
          customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
              let orderName = (sortOrder.name == "walletPoints")?sortOrder.direction:"";
  
              return(
              <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={0} onClick={() => updateDirection(2)} >
                <a className="sort-by">Wallet Balance</a>
              </th>
              )
          },
          customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
            return (parseInt(value ? value : 0))
          }
      }
    }
  ];
  const options:any = {
    filter: false,
    count: data && data.getUser && data.getUser.length ? data.getUser.length : 10,
    filterType: "dropdown",
    responsive:"standard",
    print:false,
    download : false,
    pagination : false,
    serverSide: false,
    viewColumns : false,
    selectableRowsHideCheckboxes: true,
    fixedSelectColumn: false,
    selectToolbarPlacement: "none",
    selectableRowsHeader: false,
    selectableRows: "none",
    selectableRowsOnClick: false,
    fixedHeader : true,
    tableBodyHeight:"275px",
    search:false,
  }
  return (
    <div className="table-wrapper pad0">
       
        <div>
            <Grid container className="transaction-status-wrapper" xs={12}>
            <Grid item sm={1} xs={2}>
                <span className="data-bold active-member">{active}</span><label className="sub-caption">Active</label>
            </Grid>
            <Grid item sm={1} xs={2}>
                <span className="data-bold inactive-member">{inActive}</span><label className="sub-caption">Inactive</label>
            </Grid>
            </Grid>
        </div>
        <div className="table-container muidata-table pad0" style={{ height: 270, width: '100%' }}>
            {loading 
                ? <Loader size="90px"/>
                : <MUIDataTable title={""} data={data.getUser} columns={columns} options={options} /> 
            }
        </div>
        <Grid container sm={12} >
            <Grid className="btn-section view-all-btn" item  xs={12}>
                <Button onClick={memberListAll} className="btn" variant="contained">View All</Button>
            </Grid>
        </Grid>
    </div>
  );
}