import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link,
//   useRouteMatch,
//   useParams
// } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Amplify, { Auth } from 'aws-amplify';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {config} from './config';
import { DAppProvider } from '@usedapp/core';

Amplify.configure(config.amplifyConfig);


const httpLink = createHttpLink({ uri: config.baseURL });

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  let user:any = await Auth.currentAuthenticatedUser();


  let token = user.signInUserSession.accessToken.jwtToken;
  // const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    }
  }
});


const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});


ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <DAppProvider config={{}}>
        <Router>
          <App />
        </Router>
      </DAppProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
