export const config = {
    baseURL : "https://api-prod-sponsors.pointsville.com/graphql",
    stripeKey: "pk_live_51IpuzuFUzKDVFSz6axza5MyTc9hQdt58hMVrfgRvk9hyGxcjKiEtVwF5xOFjwnIHfodm7uXYH7Tk8tF3mQYBHUr900HIFLjZoI",
    baseBlockChainUrl: 'https://api-ledger.pointsville.com/api',
    googleAPIKEY : "AIzaSyCgJ4oOmnhZpz9nVObkO-OOy6JL_5U1ugE",
    googleCaptchaKey: "6Lexgg8cAAAAANyk0uUxypjALjcbDoPH-tQUlGp8",
    amplifyConfig : {
        "aws_project_region": "us-east-1",
        "aws_cognito_identity_pool_id": "us-east-1:7b567ec2-2b65-4c8e-bfbe-b3001b4e6dfe",
        "aws_cognito_region": "us-east-1",
        "aws_user_pools_id": "us-east-1_uLXbqQncT",
        "aws_user_pools_web_client_id": "3ekdcqlhsj639dviajf0gbeva1",
        "oauth": {
        "domain": "pv-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": `${window.location.protocol}//${window.location.host}/`,
        "redirectSignOut": `${window.location.protocol}//${window.location.host}/signout/`,
        "responseType": "code"
    },
        "federationTarget": "COGNITO_USER_POOLS"
    },
    orgId : "cdd3ea64-4ba1-4f9f-9042-233e1db03604",
    firebase : {
        apiKey: "AIzaSyCk3sVpNA9792dNMjOr2QjJ7GD0wACOGJA",
        authDomain: "amazing-plateau-293910.firebaseapp.com",
        databaseURL: "https://amazing-plateau-293910.firebaseio.com",
        projectId: "amazing-plateau-293910",
        storageBucket: "amazing-plateau-293910.appspot.com",
        messagingSenderId: "1033187384588",
        appId: "1:1033187384588:web:3de577a0e0f87d09dbfd29",
        measurementId: "G-CJ7TKNC7X3",
        vapidKey: 'BDIRGnmfyrUKqit8XiRQePXvztZpe_doJkWlS9rSdZegdz-0hgG7eXZisdsE_nSwB9WvHT-8ijgZxOybY1X7gT8'
    },
}