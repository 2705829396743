import React, { useRef, useState, useEffect } from "react";
import {
    Card,
    CardContent,
    Typography,
    IconButton,
    TextField,
    Grid,
    Paper,
    Divider,
    InputBase,
    Button,
} from "@mui/material";
import { useParams } from "react-router-dom";
import "./whiteLabel.css";
import SubDomainImage from "../../assets/custom-domain-min.jpg";
import { useForm } from "react-hook-form";
import { useQuery, gql, useMutation, useLazyQuery } from "@apollo/client";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { FileCopy } from "@material-ui/icons";
import Tooltip from "@mui/material/Tooltip";
import {
    Snackbar,
    Backdrop,
    CircularProgress,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
} from "@material-ui/core";
import OrgLogo from "../../assets/toyota-logo.png";
import Loader from "../../components/Loader/loader";
import "./widgetsetting.css";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PvLogo from '../../assets/pointsville-small-logo.svg';
export const GET_WHITE_LABEL = gql`
  query getWhiteLabel($orgId: String!) {
    getWhiteLabel(orgId: $orgId) {
      subDomain
      whiteLabelImage
      isActive
    }
  }
`;
export const GET_ALL_WHITE_LABEL = gql`
  query getAllWhiteLabel($orgId: String!) {
    getAllWhiteLabel(orgId: $orgId) {
      subDomain
      whiteLabelImage
      isActive
    }
  }
`;
const GET_PRE_SIGNED_URL = gql`
  query getPreSignedUrl($fileName: String!) {
    getPreSignedUrl(fileName: $fileName) {
      preSignedUrl
    }
  }
`;

const CREATE_WHITE_LABEL = gql`
  mutation createWhiteLabel($subDomain: String!, $orgId: String!) {
    createWhiteLabel(subDomain: $subDomain, orgId: $orgId)
  }
`;
const UPDATE_WHITELABLE_IMAGE = gql`
  mutation updateWhiteLabelImage($filename: String!, $orgId: String!) {
    updateWhiteLabelImage(filename: $filename, orgId: $orgId)
  }
`;
interface ParamTypes {
    orgId: string;
}

interface IFormAddPromo {
    subDomain?: string;
}

let defaultValues = {
    subDomain: "",
};
const initialState = { alt: "", src: "" };
const useStyles = makeStyles((theme) => ({
    inputField: {
        textTransform: "lowercase",
    },
}));
export default function WhiteLabel() {
    const inputRef = useRef(null);
    const classes = useStyles();

    const { clearErrors, errors } = useForm<IFormAddPromo>({
        defaultValues,
        shouldUnregister: false,
    });
    let { orgId } = useParams<ParamTypes>();
    const [subDomainName, setSubDomainName] = React.useState("");
    const [error, setError] = useState<any>("");
    const [alertType, setAlertType] = useState<any>("");
    const [load, setLoad] = useState(false);
    const [openToast, setOpenToast] = React.useState<any>(false);
    const [showButtonLoading, setShowButtonLoading] = React.useState<any>(false);
    const [subDomainAlert, setSubDomainAlert] = useState<any>(false);
    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isSubdomain, setIsSubdomain] = useState(false);
    const [avatarImg, setAvatarImg] = useState(OrgLogo);
    const [file, setFile] = useState();
    const [loader, setLoader] = React.useState<any>(false);
    const [colorCode, setColorCode] = React.useState("#2b2e4c");
    const [logoUrl, setlogoUrl] = React.useState<any>(
        "https://pointsville.com/images/logo/pointsville-small-logo.svg"
    );
    const [targetFile, setTargetFile] = React.useState<any>([]);
    const [uploadFileName, setUploadFileName] = React.useState<any>("");
    const [errorMessage, setErrorMsg] = React.useState("");
    const [whiteLabelLogo, setWhiteLabelLogo] = React.useState(PvLogo);
    const [openCopy, setOpenCopy] = React.useState(false);

    const [copyFeedback, setCopyFeedback] = React.useState("");
    const handleCloseCopy = () => {
        setOpenCopy(false);
    };

    const copyToClipBoard = async (text: any) => {
        try {
            await navigator.clipboard.writeText(text);
            setCopyFeedback("Copied Successfully");
            setOpenCopy(true);
        } catch (err) {
            console.log("INSIDE ", { openCopy }, err);
            setCopyFeedback("Failed to copy. Please check browser persmissions");
            setOpenCopy(true);
        }
    };

    const [{ alt, src }, setPreview] = useState(initialState);
    const widgetBtnStyle = {
        border: "none",
        outline: "none",
        backgroundColor: colorCode,
        padding: "10px 7px 10px",
        borderRadius: "10px",
        fontSize: "18px",
        width: "225px",
    };
    const Alert = (props: AlertProps) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    };

    const [updateWhiteLabelImage] = useMutation(UPDATE_WHITELABLE_IMAGE, {
        // after updating the post, we go to the home page
        onCompleted: (data: any) => {
            refetchAllWhiteLabel();
            console.log("URL2", data?.updateWhiteLabelImage)
            // setlogoUrl(data?.updateWhiteLabelImage?.filename);
        },
        onError: (data) => {
            // setErrorMsg(data.message);
            // setOpenToast(true);
        },
    });
    // const {
    //     loading: getAllWhiteLabelLoading,
    //     data: getWhiteLabel,
    //     refetch: refetchWhiteLabel,
    // } = useQuery<any>(GET_WHITE_LABEL, { variables: { orgId: orgId } });


    const {
        loading: getAllWhiteLabelLoading,
        data: getAllWhiteLabel,
        refetch: refetchAllWhiteLabel,
    } = useQuery<any>(GET_ALL_WHITE_LABEL, { variables: { orgId: orgId } });

    const {
        loading: urlLoading,
        data: urlData,
        refetch: urlRefetch,
    } = useQuery<any>(GET_PRE_SIGNED_URL, {
        skip: uploadFileName.length > 0 ? false : true,
        variables: { fileName: uploadFileName },
    });
    useEffect(() => {
        // refetchWhiteLabel();
        if (!getAllWhiteLabelLoading) {
            console.log("getAllWhiteLabel ", getAllWhiteLabel.getAllWhiteLabel);
            console.log("getAllWhiteLabel Length ", getAllWhiteLabel?.getAllWhiteLabel?.length);

            for (var i = 0; i < getAllWhiteLabel?.getAllWhiteLabel?.length; i++) {
                console.log("getAllWhite Label ", getAllWhiteLabel?.getAllWhiteLabel[i]);
                let whiteLabelData = getAllWhiteLabel?.getAllWhiteLabel[i];
                if (whiteLabelData.isActive) {
                    setSubDomainName(whiteLabelData.subDomain);
                    setIsSubdomain(true);
                    if (whiteLabelData.whiteLabelImage) {
                        console.log("whiteLabelData.whiteLabelImage If", whiteLabelData.whiteLabelImage)
                        setWhiteLabelLogo(whiteLabelData.whiteLabelImage);
                    } else {
                        console.log("whiteLabelData.whiteLabelImage else", whiteLabelData.whiteLabelImage)
                        setWhiteLabelLogo(PvLogo);
                    }

                } else {
                    setSubDomainName("");
                    setWhiteLabelLogo(PvLogo);
                    // setWhiteLabelLogo("https://pointsville.com/images/logo/pointsville-small-logo.svg");
                }
            }
        }
    }, [getAllWhiteLabel]);
    const [createWhiteLabel] = useMutation(CREATE_WHITE_LABEL, {
        // after updating the post, we go to the home page
        onCompleted: () => {
            updateWhiteLabelImage({
                variables:{
                    orgId: orgId,
                    filename: whiteLabelLogo,

                },
            });
            setAlertType("success");
            setError("Subdomain created");
            setLoad(false);
            setShowButtonLoading(false);
            setOpenToast(true);
            refetchAllWhiteLabel();
        },
        onError: (data) => {
            setAlertType("error");
            setError(data.message);
            setLoad(false);
            setShowButtonLoading(false);
            setOpenToast(true);
            refetchAllWhiteLabel();
        },
    });

    const uploadImage = (e: React.ChangeEvent<any>) => {
        const { files } = e.target;
        setPreview(
            files.length
                ? {
                    src: URL.createObjectURL(files[0]),
                    alt: files[0].name,
                }
                : initialState
        );
        const targetFiles: any = e.target.files;
        const fileName: any = orgId + "_whitelabel.png";
        if (targetFiles.length === 0) return;
        if (
            targetFiles[0].type === "image/png" ||
            targetFiles[0].type === "image/jpeg"
        ) {
            setLoader(true);
            if (targetFiles.length > 0) {
                targetFiles[0].fileName = fileName;
                setAlertType("success");
                setError("white label image hase been uploaded");
                setUploadFileName(fileName);
                setTargetFile(targetFiles);
                setLoad(false)
                setOpenToast(true)
            }
        } else {
            setAlertType("error");
            setUploadFileName("");
            setError("Image format should be png, jpg");
            setLoad(false)
            setOpenToast(true);
        }
    };
    const handleClickImage = (e: any) => {
        inputRef.current.click();
    };

    const fileUploadToS3 = (objUrl: any) => {
        if (objUrl) {
            fetch(objUrl, {
                method: "PUT",
                headers: { "Content-Type": "image/*" },
                body: targetFile[0],
            }).then((response) => {
                if (response && response.url) {
                    console.log("URL!", response.url.split("?")[0])
                    setWhiteLabelLogo(response.url.split("?")[0]);
                    if(isSubdomain === true)
                    {
                    updateWhiteLabelImage({
                        variables: {
                            orgId: orgId,
                            filename: response.url.split("?")[0],
                        },
                    });
                }
                    setLoader(false);
                }
            });
        }
    };

    React.useEffect(() => {
        if (!urlLoading && uploadFileName.length > 0) {
            urlRefetch();
            if (urlData) {
                console.log("hello 6767", urlData);
                setUploadFileName("");
                fileUploadToS3(urlData.getPreSignedUrl.preSignedUrl);
                console.log("URL IMAGR", urlData.getPreSignedUrl);
                urlRefetch();
            }
        }
    }, [uploadFileName, urlData]);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setSubDomainName(value);

        if (value === "") {
            setError("subdomain can not be empty");
            setSubDomainAlert(true);
        } else {
            setError("");
            setSubDomainAlert(false);
        }
        const regex = /^[a-zA-Z0-9\-/]+$/;
        if (value.length && regex.test(value) === false) {
            setSubDomainAlert(true);
            setError("Subdomain only allows alphabet, numbers and -");
            return false;
        }
        if (value.length && regex.test(value) === true) {
            setSubDomainAlert(false);
            setError("");
            return true;
        }
    };

    const handleClick = () => {
        if (subDomainName === "") {
            setError("subdomain can not be empty");
            setSubDomainAlert(true);
        } else {
            setError("");
            setSubDomainAlert(false);
            setShowButtonLoading(true);
            createWhiteLabel({
                variables: {
                    subDomain: subDomainName,
                    orgId: orgId,
                },
            });
        }
        // alert(subDomainName + '.pointsville.com domain is created !!!');
    };

    const handleClosed = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenToast(false);
        setLoad(false);
    };
    return (
<div className="whitelablecontainer" style={{ minWidth: "1000px" }}>
            {getAllWhiteLabelLoading ? (
                <Backdrop className="loader-signup" open={true}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
                <React.Fragment>
                    <Card sx={{ margin: "0 auto" }}>
                        <CardContent>
                            <Typography
                                className="blocktitle"
                                color="text.secondary"
                                gutterBottom
                            >
                                Create Your own Loyalty System using our <b>WhiteLabel</b>{" "}
                                solution
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid xs={6}>
                                    <img src={SubDomainImage} />
                                </Grid>

                                <Grid xs={6}>
                                    <div
                                        className="subdomaincontainer"
                                        style={{ marginTop: "10px", marginLeft: "4%" }}
                                    >
                                        <Grid container>
                                            <Grid sx={{ width: "50%" }}>
                                                <div>
                                                    <FormControl
                                                        style={{
                                                            bottom: "20px",
                                                            marginTop: "60px",
                                                            marginLeft: "-7%",
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <InputLabel
                                                            style={{
                                                                marginLeft: "10px",
                                                                fontSize: 14,
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                bottom: "20px",
                                                            }}
                                                        >
                                                            Upload logo here
                                                        </InputLabel>
                                                        <OutlinedInput
                                                            disabled
                                                            id="API-KEY"
                                                            style={{
                                                                top: "11px",
                                                                height: "41px",
                                                            }}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <Button
                                                                        onClick={handleClickImage}
                                                                        sx={{
                                                                            ":hover": {
                                                                                bgcolor: "#2b2e4c",
                                                                                color: "white",
                                                                            },
                                                                            backgroundColor: "#2b2e4c",
                                                                            color: "white",
                                                                            zIndex: "modal",
                                                                            fontSize: 10,
                                                                            height: "24px",
                                                                        }}
                                                                    >
                                                                        {" "}
                                                                        <input
                                                                            type="file"
                                                                            ref={inputRef}
                                                                            onChange={(e) => uploadImage(e)}
                                                                            accept="image/png,image/jpeg"
                                                                            hidden
                                                                        />
                                                                        change image
                                                                    </Button>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </FormControl>
                                                </div>
                                                {/* <div
                      className="color-code-container subdomaincontainer"
                      style={{ overflow: "hidden" }}
                    >
                      <label className="color-code-text">
                        Upload your logo here
                      </label>

                      <input
                        type="file"
                        ref={inputRef}
                        onChange={(e) => uploadImage(e)}
                        accept="image/png,image/jpeg"
                        style={{ display: "none" }}
                      />

                      <button
                        className="btn btn-primary add-btn"
                        onClick={handleClickImage}
                      >
                        Change Image
                      </button>
                    </div> */}
                                            </Grid>
                                            <Grid sx={{ width: "50%", marginBottom: 5 }}>
                                                <div style={{ float: "right", textAlign: "center" }}>
                                                    <Typography
                                                        className="subtitle"
                                                        gutterBottom
                                                        style={{
                                                            color: "black",
                                                            fontWeight: 500,
                                                            fontSize: 15,
                                                        }}
                                                    >
                                                        Preview
                                                    </Typography>
                                                    <button id="widgetBtn" style={widgetBtnStyle}>
                                                        {loader ? (
                                                            <Loader size="30px" />
                                                        ) : (
                                                            <img
                                                                src={whiteLabelLogo + "?" + Math.random()}
                                                                width="150px"
                                                                height="72px"
                                                            />
                                                        )}
                                                    </button>
                                                    <br />
                                                    <br />

                                                    <Typography
                                                        className="subtitle"
                                                        style={{
                                                            color: "black",
                                                            fontWeight: 500,
                                                            fontSize: 15,
                                                        }}
                                                    >
                                                        Note : Size should be 186 * 72
                                                    </Typography>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Paper
                                            component="form"
                                            sx={{
                                                p: "2px 4px",
                                                display: "flex",
                                                margin: "auto",
                                                alignItems: "center",
                                                border: subDomainAlert
                                                    ? "1px solid #FF0000"
                                                    : "1px solid #2B2E4C",
                                            }}
                                        >
                                            <InputBase
                                                sx={{ ml: 1, flex: 1 }}
                                                value={subDomainName.substring(0, 25)}
                                                placeholder="Enter Sub-domain"
                                                inputProps={{
                                                    "aria-label": "Domain name",
                                                    className: classes.inputField,
                                                }}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                }}
                                            />
                                            <Divider
                                                sx={{ height: 28, m: 0.5 }}
                                                orientation="vertical"
                                            />
                                            <Typography color="#2B2E4C" style={{marginTop:"2px"}} gutterBottom>
                                                .pointsville.com
                                            </Typography>
                                            <Tooltip
                                                open={openCopy}
                                                onClose={handleCloseCopy}
                                                title={copyFeedback}
                                                leaveDelay={500}
                                                arrow
                                                placement="top"
                                            >
                                                <IconButton
                                                    style={{
                                                        marginRight:5,
                                                        marginLeft:10,
                                                    }}
                                                    onClick={() => copyToClipBoard(subDomainName + ".pointsville.com")}
                                                    edge="end"
                                                >
                                                    <FileCopy
                                                        color="action"
                                                        style={{ width: "25px" }}
                                                    ></FileCopy>
                                                </IconButton>
                                            </Tooltip>
                                        </Paper>
                                        {subDomainAlert && error ? (
                                            <Typography
                                                color="#FF0000"
                                                gutterBottom
                                                style={{ marginTop: 10, marginBottom: 5, fontSize: 15 }}
                                            >
                                                {error}
                                            </Typography>
                                        ) : (
                                            ""
                                        )}
                                        <Typography
                                            color="#2B2E4C"
                                            gutterBottom
                                            style={{
                                                marginTop: 10,
                                                marginBottom: -10,
                                                fontStyle: "italic",
                                                fontSize: 15,
                                            }}
                                        >
                                            (Note: You can change domain{" "}
                                            {2 - getAllWhiteLabel?.getAllWhiteLabel.length} time)
                                        </Typography>
                                        {getAllWhiteLabel?.getAllWhiteLabel.length < 2 ? (
                                            <Button
                                                className={"btn-submit"}
                                                variant="contained"
                                                color="primary"
                                                onClick={handleClick}
                                            >
                                                {showButtonLoading ? (
                                                    <CircularProgress size={23} color="inherit" />
                                                ) : getAllWhiteLabel?.getAllWhiteLabel.length >= 1 ? (
                                                    "Update"
                                                ) : (
                                                    "Create"
                                                )}
                                            </Button>
                                        ) : (
                                            <Button
                                                className={"btn-submit"}
                                                style={{ width: 350 }}
                                                variant="contained"
                                                color="primary"
                                            >
                                                Contact Supprt for domain change
                                            </Button>
                                        )}
                                    </div>
                                    <br />
                                    <br />
                                    <br />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Snackbar
                        open={openToast}
                        autoHideDuration={3000}
                        onClose={handleClosed}
                    >
                        <Alert onClose={handleClosed} severity={alertType}>
                            {error}
                        </Alert>
                    </Snackbar>
                </React.Fragment>
            )}
        </div>
    );
}
