/* eslint-disable no-use-before-define */
import React from 'react';
import './loader.css';
import LoaderImg from '../../assets/Spinner_admin.gif';
interface Props {
  size ?: string,
}


export default function Loader({size}:Props) {
  
  return (
    <div className="sk-chase loader-container" style={{width:size,height:size}}>
      <img src={LoaderImg}/>
  </div>
  );
}

