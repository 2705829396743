import React ,{useEffect, useState}from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useQuery, gql } from '@apollo/client';
import Loader from '../../components/Loader/loader';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import OrgLogo from '../../assets/toyota-logo.png';
import Pirates from '../../assets/Pirates.png';
import Pnc from '../../assets/PNC.png';
import Highmark from '../../assets/Highmark.png';
import Nasdaq from '../../assets/Nasdaq.png';
import MUIDataTable from "mui-datatables";


interface promotionInventory {
  id: string;
  
}

interface promotionInventoryData {
  promotionInventory: promotionInventory[];
}

interface promotionInventoryVars {
  year: number;
}


export const GET_PROMO_QUERY = gql`
query getAllPromos{
  getPromotions{
    id
    orgName
    orgLogo
    sponsorName
    sponsorLogo
    availablePoints
    validityDay
  }
}
`;

export default function PromotionOverview() {

  const history = useHistory();
  //const promotionOverView = () => history.push({pathname:'/clubdetails', state:"dashboard"});
  const [promoData, setPromoData] = useState([]);
  const {refetch, loading, data } = useQuery<any>(GET_PROMO_QUERY);  
  useEffect(() => {
    refetch();
  },[]);
  useEffect(() => {
    let tempArray:any = [];
    if(data && data.getPromotions && data.getPromotions.length > 0) {
      data.getPromotions.map((promos:any) => {
        if(promos.availablePoints > 0 && new Date(promos.validityDay) > new Date() ){
          tempArray.push(promos);
        }
      })
    }
    setPromoData(tempArray);
  },[data]);

  const columns:any = [
    {
      name:"sponsorLogo",
      options:{
          filter:false,
          sortThirdClickReset: true,
          sort: true,
          customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
            let orderName = (sortOrder.name == "sponsorLogo")?sortOrder.direction:"";
            return(
            <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={0} onClick={() => updateDirection(0)} >
              <a className="sort-by">Sponsor</a>
            </th>
            )
          },
          customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
              return (<div><img className='profile-img' src={value || "https://png.pngtree.com/png-vector/20190321/ourmid/pngtree-vector-users-icon-png-image_856952.jpg"} /><label style={{whiteSpace: 'nowrap'}}>{`${tableMeta.tableData[tableMeta.rowIndex].sponsorName}`}</label></div>)
            }
          
      }
    },
    {
      name:"orgName",
      options:{
          filter:false,
          sortThirdClickReset: true,
           sort: true,
          customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
              let orderName = (sortOrder.name == "orgName")?sortOrder.direction:"";
  
              return(
              <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={0} onClick={() => updateDirection(1)} >
                <a className="sort-by">Organization</a>
              </th>
              )
          }
      }
    },
    {
      name:"availablePoints",
      options:{
          filter:false,
          sortThirdClickReset: true,
          sort: true,
          customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
              let orderName = (sortOrder.name == "availablePoints")?sortOrder.direction:"";
  
              return(
              <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={0} onClick={() => updateDirection(2)} >
                <a className="sort-by">Available</a>
              </th>
              )
          },
          customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
            return (parseInt(value))
          }
      }
    },
    {
      name:"validityDay",
      options:{
          filter:false,
          sortThirdClickReset: true,
           sort: true,
          customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
              let orderName = (sortOrder.name == "validityDay")?sortOrder.direction:"";
  
              return(
              <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={0} onClick={() => updateDirection(3)} >
                <a className="sort-by">Expiring Soon</a>
              </th>
              )
          }
      }
    },
  ];
  const options:any = {
    filter: false,
    count: promoData &&  promoData.length ? promoData.length : 10,
    filterType: "dropdown",
    responsive:"standard",
    print:false,
    download : false,
    pagination : false,
    serverSide: false,
    viewColumns : false,
    selectableRowsHideCheckboxes: true,
    fixedSelectColumn: false,
    selectToolbarPlacement: "none",
    selectableRowsHeader: false,
    selectableRows: "none",
    selectableRowsOnClick: false,
    fixedHeader : true,
    tableBodyHeight:"275px",
    search:false,
  }
  return (
      <div className="table-container muidata-table pad0" style={{ height: 305, width: '100%' }}>
          {loading 
              ? <Loader size="90px"/>
              : <MUIDataTable title={""} data={promoData} columns={columns} options={options} /> 
          }
    </div>
  );
}