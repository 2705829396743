import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Select, MenuItem, Snackbar, Typography } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import { Backdrop, CircularProgress } from '@material-ui/core';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';

import mergeImages from 'merge-images';

import AvatarEditor from 'react-avatar-editor';
import ReCAPTCHA from "react-google-recaptcha";

import moment from 'moment';
import "moment-timezone";

import coinImage from '../../assets/PiratesCoin.png'
import AvatarDefault from '../../assets/ic_avatar-default.svg';
import CamerLogo from '../../assets/ic_camera.svg';
import ArrowLight from '../../assets/ic_arrow_next_light.svg';
import ArrowDark from '../../assets/ic_arrow_next_dark.svg';
import PasswordEye from '../../assets/ic_eye.svg';
import FacebookLogo from '../../assets/ic_facebook.svg';
import TwitterLogo from '../../assets/ic_twitter.svg';
import InstagramLogo from '../../assets/ic_instagram.svg';
import YoutubeLogo from '../../assets/ic_youtube.svg';
import PlayStoreLogo from '../../assets/ic_playstore.svg';
import AppStoreLogo from '../../assets/ic_appstore.svg';

import { config } from '../../config';

import './signup.css';
import { Auth } from 'aws-amplify';
import Slider from '@material-ui/core/Slider';
import NoPasswordEye from '../../assets/eye-slash-solid.webp';
interface Props {
    handleActiveStep: any,
}

interface IFormSingUp {
    fullName: string,
    businessEmail: string,
    password: string,
    confirmPassword: string,
    businessName: string,
    orgCode: string,
    orgBio: string,
    businessType: string,
    businessAddress: string,
    default_reward: string;
    deploy_tokens: string;
    facebook: string,
    twitter: string,
    instagram: string,
    youtube: string,
    playstore: string,
    appstore: string,
    blockChainType: string
}

let defaultValues: any = {
    fullName: '',
    businessEmail: '',
    password: '',
    confirmPassword: '',
    businessName: '',
    orgCode: '',
    orgBio: '',
    businessType: '',
    businessAddress: '',
    default_reward : "points",
    deploy_tokens : "testnet",
    blockChainType: "Avalanche",
    facebook: '',
    twitter: '',
    instagram: '',
    youtube: '',
    playstore: '',
    appstore: ''
}

function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        backButton: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    }),
);

function getSteps() {
    return ['Business Info', 'Other Info', 'Confirmation'];
}

// function getStepContent(stepIndex: number) {
//   switch (stepIndex) {
//     case 0:
//       return 'Select campaign settings...';
//     case 1:
//       return 'What is an ad group anyways?';
//     case 2:
//       return 'This is the bit I really care about!';
//     default:
//       return 'Unknown stepIndex';
//   }
// }

const GET_PRE_SIGNED_URL = `
    query getPreSignedUrl($fileName: String!){
        getPreSignedUrl(fileName: $fileName){
            preSignedUrl
        }
    }
`;

const ONBOARDING = `
    mutation onboarding($data : OnboardingInput!){
        onboarding(data:$data)
    }
`

const ORGEXIST = `
    query checkOrgExist($org_code: String!) {
        checkOrgExist(org_code: $org_code)
    }
`

export const GET_ORGS_TYPES = `
    query GetOrgTypes{
        getOrgTypes {
            id
            title
            value
        }
    }
`;

export default function SignUpForm({ handleActiveStep }: Props) {
    const history = useHistory();
    const reCaptchaKey: any = config.googleCaptchaKey;

    const inputRef: any = useRef();
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState<any>(0);
    const [loading, setLoading] = useState(false);
    const steps = getSteps();
    const [defaultReward, setDefaultReward] = React.useState(defaultValues.default_reward);
    // const handleBack = () => { setActiveStep((prevActiveStep:any) => prevActiveStep - 1); };

    const [avatarImageToCrop, setAvatarImageToCrop] = React.useState<any>([]);

    const setEditorRef: any = useRef();

    const [open, setOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [orgTypes, setOrgTypes] = useState([]);

    const [username, setUsername] = React.useState<any>('');
    const [showPassword, setShowPassword] = React.useState<any>(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState<any>(false);

    const [termsAndPolicy, setTermsAndPolicy] = React.useState<any>(false);
    const [googleCaptcha, setGoogleCaptcha] = React.useState<any>(false);

    const { register, handleSubmit, errors, control, reset, formState, setValue } = useForm<IFormSingUp>({ defaultValues, shouldUnregister: false });

    // Below code to uplaod files to S3 bucket using presigned url.
    const [uploadFileName, setUploadFileName] = useState<any>('');
    const [finalTargetFile, setFinalTargetFile] = useState<any>([]);
    const [zoomValue, setZoomValue] = React.useState(1.2);

    const handleZoomChange = (event: any, newValue: any) => {
        setZoomValue(newValue);
    };

    const getOrgTypes = async () => {
        fetch(await config.baseURL, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                query: GET_ORGS_TYPES,
            }),
        }).then((res: any) => {
            return res.json();
        }).then((result) => {
            setOrgTypes(result.data.getOrgTypes);
        });
    }

    useEffect(() => {
        getOrgTypes();
    }, []);

    // to change input text values here.
    const handleChangeField = (e: React.ChangeEvent<any>) => {
        const targetValue = (e.target as HTMLInputElement).value;
        const targetName: string = (e.target as HTMLInputElement).name;
        setValue(targetName, targetValue);
        if(targetName === 'default_reward') {
            setDefaultReward(targetValue)
        }
    }

    // to change dropdown values here.
    const handleChange = (e: React.ChangeEvent<{ value: string | unknown }>) => {
        const targetType = (e.target as HTMLInputElement).value;
        const targetName: string = (e.target as HTMLInputElement).name;
        setValue(targetName, targetType);
    }

    const handleClickShowPassword = (obj: any) => {
        if (obj === 'P') setShowPassword(!showPassword)
        else if (obj === 'CP') setShowConfirmPassword(!showConfirmPassword)
    };

    const handleTermsAndPolicy = () => {
        setTermsAndPolicy(!termsAndPolicy)
        // setValue('termsAndPolicy', !termsAndPolicy)
    }

    const handleCaptchaChange = (value: any) => {
        if (value.length > 0) setGoogleCaptcha(true)
    }

    // Step 1 - Uploading image to crop.
    const uploadImage = (e: React.ChangeEvent<any>) => {
        const targetFiles: any = e.target.files;

        if (targetFiles.length === 0) return;


        if (targetFiles[0].type === "image/png" || targetFiles[0].type === "image/jpeg") {
            // localStorage.setItem('fileName', targetFiles[0].name.split('.')[0]);
            setAvatarImageToCrop(targetFiles);
        } else {
            // setErrorMsg("Please upload image format as png or jpg.");
            // setOpenToast(true);
        }
    }

    // to Convert selected avatar base64 data to image file to uplaod file to s3 bucket.
    const dataURLtoFile = (dataurl: any, filename: any) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    const getResizedUrl = (base64url:any) => {
        // to get base64 url for selected org logo position.
        return new Promise((resolve) => {
            let img = new Image()
            img.src = base64url;

            img.onload = () => {
            let canvas = document.createElement('canvas');
            canvas.width = 216;
            canvas.height = 216;
            let ctx:any = canvas.getContext('2d');
            ctx.drawImage(img, 39, 39, 138, 138); // here as => 39+39+138 = 216 is coin image actual width and height.
            resolve(canvas.toDataURL());
            }
        })
    }

    // to submit signup form data into the database.
    const onSubmit: SubmitHandler<IFormSingUp> = async (fieldData) => {

        // to get cropped avatar here.
        if (activeStep === 0) {
            if (fieldData.password !== fieldData.confirmPassword) {
                setErrorMessage('Password and confirm password do not match');
                setOpen(true);
                return;
            }

            if (avatarImageToCrop.length > 0) {
                let imageName = fieldData.businessName.trim() + '_' + avatarImageToCrop[0].name.split('.')[0] + '_' + moment().toDate().getTime() + '.png';

                const canvas = setEditorRef.current.getImage().toDataURL('image/png', 1.0);
                let convertedImage:any = await getResizedUrl(canvas);

                const mImage = await mergeImages([{src: convertedImage}, {src: coinImage}], {width: 216, height: 216}).then((b64: any) =>  b64);

                let tempTargetFile = dataURLtoFile(mImage, imageName);

                setFinalTargetFile(tempTargetFile);
                setUploadFileName(imageName);
                setUsername(fieldData.businessEmail)
                setActiveStep((prevActiveStep: any) => prevActiveStep + 1);

            } else {
                setErrorMessage('Please upload business logo');
                setOpen(true);
                return;
            }
        } else if (activeStep === 1 && uploadFileName.length > 0) {
            setLoading(true);
            if (!googleCaptcha) {
                setErrorMessage('Please verify your identity with reCaptcha');
                setOpen(true);
                setLoading(false);
                return;
            }

            if (!termsAndPolicy) {
                setErrorMessage('Please select terms and policy');
                setOpen(true);
                setLoading(false);
                return;
            }

            await fetch(await config.baseURL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    query: ORGEXIST,
                    variables: { org_code: fieldData.orgCode.toUpperCase() },
                }),
            }).then((res) => res.json())
            .then((res: any) => {
                if (res.data.checkOrgExist === true) {
                    setErrorMessage("Org Code provided is already in use.");
                    setLoading(false);
                    setOpen(true);
                    setActiveStep((prevActiveStep: any) => prevActiveStep - 1);
                    return;
                }

                // to get S3 image url after uploading file using presigned url.
                fetch(config.baseURL, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        query: GET_PRE_SIGNED_URL,
                        variables: { fileName: uploadFileName },
                    }),
                })
                .then((res) => res.json())
                .then(async (result) => {
                    fetch(await result.data.getPreSignedUrl.preSignedUrl, {
                        method: 'PUT',
                        headers: { 'Content-Type': 'image/*' },
                        body: finalTargetFile
                    }).then(async (response) => {
                        if (response && response.url) {
                            const { username, email, password, name } = {
                                username: fieldData.businessEmail,
                                email: fieldData.businessEmail,
                                password: fieldData.password,
                                name: fieldData.fullName
                            };
                            await Auth.signUp({
                                username,
                                password,
                                attributes: {
                                    email,
                                    name,
                                    // other custom attributes
                                    'custom:user_role': "1" // 0 - ADMIN, 1 - ORGADMIN, 2 - USER
                                },
                                validationData: [] //optional
                            })
                                .then(async (data: any) => {
                                    let logo = await response.url.split('?')[0];
                                    let formData = {
                                        logoUrl: logo,
                                        fullName: fieldData.fullName,
                                        businessEmail: fieldData.businessEmail,
                                        businessName: fieldData.businessName.toUpperCase(),
                                        orgCode: fieldData.orgCode.toUpperCase(),
                                        orgBio: fieldData.orgBio,
                                        businessType: fieldData.businessType,
                                        businessAddress: fieldData.businessAddress,
                                        facebook: fieldData.facebook,
                                        twitter: fieldData.twitter,
                                        instagram: fieldData.instagram,
                                        youtube: fieldData.youtube,
                                        playstore: fieldData.playstore,
                                        appstore: fieldData.appstore,
                                        cognitoId: data.userSub,
                                        isToken: fieldData.default_reward === 'tokens' ? true : false,
                                        isMainnet: fieldData.deploy_tokens === 'mainnet' ? true : false,
                                        blockChainType: fieldData.blockChainType
                                    }
                                    fetch(await config.baseURL, {
                                        method: 'POST',
                                        headers: { 'Content-Type': 'application/json' },
                                        body: JSON.stringify({
                                            query: ONBOARDING,
                                            variables: {
                                                data: {
                                                    ...formData
                                                }
                                            },
                                        }),
                                    }).then((res) => {
                                        setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
                                        handleActiveStep(activeStep);
                                        setLoading(false);
                                        return res;
                                    }).catch(err => {
                                        setErrorMessage('Business Name Exists.');
                                        setOpen(true);
                                        setLoading(false);
                                        setActiveStep((prevActiveStep: any) => prevActiveStep - 1);
                                    })
                                })
                                .catch((err: any) => {
                                    setErrorMessage(err.message);
                                    setLoading(false);
                                    setOpen(true);
                                    setActiveStep((prevActiveStep: any) => prevActiveStep - 1);
                                });
                        };
                    })
                });
            });
        } else if (activeStep === 2) {
            history.push('/signin');
        }

        // to go to next page after permission granted.
        // if (isNextStepAllowed) {
        //     if (activeStep === 2) history.push('/signin');
        //     else setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
        // }
    }

    // to close the snackbar.
    const handleClose = (event: any, reason: any) => { setOpen(false); };

    const handleEmailExist = (e: React.ChangeEvent<{ value: string | unknown }>) => {
        const targetType = (e.target as HTMLInputElement).value;
        const targetName: string = (e.target as HTMLInputElement).name;
        //setValue(targetName, targetType);
        Auth.signIn(targetType, 'Welcome@ta').then(res => {
            setErrorMessage('Email address provided is already in use.');
            setOpen(true);
            return ;
        }).catch(error => {
            if(error.code != "Email address provided is already in use."){
                setErrorMessage(error.message);
                setOpen(true);
                return;
            }
        })
    }
    const onKeyUpValue = (e: React.ChangeEvent<any>) => {
        let targetValue = (e.target as HTMLInputElement).value;
        let targetName: string = (e.target as HTMLInputElement).name;
        targetValue = targetValue.trim();
        setValue(targetName, targetValue);
    }
    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep === 2 ? activeStep + 1 : activeStep} alternativeLabel>
                {steps.map((label, idx) => (
                    <Step key={label}>
                        <StepLabel>{label} {idx !== (steps.length - 1) && <img className='arrow-next-img' src={activeStep === idx ? ArrowDark : ArrowLight} alt='Arrow Next' />}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div className='divider' />
            <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
                {activeStep === 0
                    ? (
                        <div className='business-info-block'>
                            <div className={avatarImageToCrop.length > 0 ? 'avatar-block' : 'avatar-block border'}>
                                {avatarImageToCrop.length > 0
                                    ? (
                                        <>
                                            <AvatarEditor
                                                ref={setEditorRef}
                                                className='avatar-make-up'
                                                image={avatarImageToCrop[0]}
                                                border={380}
                                                color={[0, 0, 0, 0.36]} // RGBA
                                                scale={zoomValue}
                                                // rotate={0}
                                                borderRadius={100}
                                            />
                                            <div className="zoom-container"><Typography variant="caption" id="range-slider" gutterBottom>
                                                Zoom
                                            </Typography>
                                                <Slider value={zoomValue} onChange={handleZoomChange} step={0.1} min={.5} max={5} aria-labelledby="continuous-slider" />
                                            </div>
                                            <div className='avatar-default-block zero-width'>
                                                <img className='default-avatar-img' src={CamerLogo} alt='Avatar default logo' />
                                                <input type="file" className="default-file-type zero-height" onChange={(e) => uploadImage(e)} />
                                            </div>
                                        </>
                                    ) : (
                                        // Default avatar space
                                        <div className='avatar-default-block'>
                                            <img className='default-avatar-img' src={AvatarDefault} alt='Avatar default logo' />
                                            <div className='title'>Upload Logo</div>
                                            <input type="file" className="default-file-type" onChange={(e) => uploadImage(e)} />
                                        </div>
                                    )
                                }
                            </div>

                            <Grid item className="flx-align-center pt-20" sm={12} xs={12}>
                                {/* Full Name */}
                                <Grid item sm={6} xs={12}>
                                    <div className="input-group pr-10">
                                        <label className="input-label">Full Name *</label>
                                        <Controller as={TextField} rules={{ required: true }} type="text" name="fullName" inputRef={inputRef} placeholder="Enter name" className={`custom-input form-fields  ${errors.fullName && 'error'}`} control={control} defaultValue="" onChange={(e: any) => handleChangeField(e)} />
                                        {errors.fullName && errors.fullName.type === "required" && (<label className="error-message">Field is required</label>)}
                                    </div>
                                </Grid>

                                {/* Business Email */}
                                <Grid item sm={6} xs={12}>
                                    <div className="input-group pl-10">
                                        <label className="input-label">Business Email *</label>
                                        <Controller as={TextField} rules={{ required: true, pattern: /^([\w.\+.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|googlemail|yahoo|gmx|ymail|outlook|bluewin|protonmail|t\-online|web\.|online\.|aol\.|live\.)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,63}|\d{1,3})(\]?)$/ }} type="text" name="businessEmail" inputRef={inputRef} placeholder="example@xyz.com" className={`custom-input form-fields  ${errors.businessEmail && 'error'}`} control={control} defaultValue="" onKeyUp={(e: any) => onKeyUpValue(e)}  onChange={(e: any) => handleChangeField(e)} />
                                        {/* <Controller  name= "businessEmail" control={control} rules={{ required: true, pattern: /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|googlemail|yahoo|gmx|ymail|outlook|bluewin|protonmail|t\-online|web\.|online\.|aol\.|live\.)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,63}|\d{1,3})(\]?)$/ }} render={({ value }) => (<TextField type="text" name="businessEmail" inputRef={inputRef} placeholder="example@xyz.com" className={`custom-input form-fields  ${errors.businessEmail && 'error'}`}  defaultValue=""   onBlur = {(e:any) => handleEmailExist(e)} onChange={(e: any) => handleChangeField(e)} />)} /> */}

                                        {errors.businessEmail && errors.businessEmail.type === "required" && (<label className="error-message">Field is required</label>)}
                                        {errors.businessEmail && errors.businessEmail.type === "pattern" && (<label className="error-message">Please enter a valid business email</label>)}
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid item className="flx-align-center" sm={12} xs={12}>
                                {/* Password */}
                                <Grid item sm={6} xs={12}>
                                    <div className="input-group pr-10">
                                        <label className="input-label">Password *</label>
                                        <Controller as={TextField} rules={{ required: true , pattern:/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/}} type={showPassword ? "text" : "password"} name="password" inputRef={inputRef} placeholder="Password" className={`custom-input pr-40 form-fields  ${errors.password && 'error'}`} control={control} defaultValue="" onChange={(e: any) => handleChangeField(e)} />
                                        <span onClick={() => handleClickShowPassword('P')}><img className='password-eye' src={showPassword ? PasswordEye : NoPasswordEye} alt='Password Eye' /></span>
                                        {errors.password && errors.password.type === "required" && (<label className="error-message">Field is required</label>)}
                                        {errors.password && errors.password.type === "pattern" && (<label className="error-message">Password should be at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character</label>)}
                                    </div>
                                </Grid>

                                {/* Confirm Password */}
                                <Grid item sm={6} xs={12}>
                                    <div className="input-group pl-10">
                                        <label className="input-label">Confirm Password *</label>
                                        <Controller as={TextField} rules={{ required: true }} type={showConfirmPassword ? "text" : "password"} name="confirmPassword" inputRef={inputRef} placeholder="Confirm password" className={`custom-input pr-40 form-fields  ${errors.confirmPassword && 'error'}`} control={control} defaultValue="" onChange={(e: any) => handleChangeField(e)} />
                                        <span onClick={() => handleClickShowPassword('CP')}><img className='confirm-password-eye' src={showConfirmPassword ? PasswordEye : NoPasswordEye} alt='Password Eye' /></span>
                                        {errors.confirmPassword && errors.confirmPassword.type === "required" && (<label className="error-message">Field is required</label>)}
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid item className="flx-align-center" sm={12} xs={12}>
                                {/* Business Name */}
                                <Grid item sm={6} xs={12}>
                                    <div className="input-group pr-10">
                                        <label className="input-label">Business Name *</label>
                                        <Controller as={TextField} rules={{ required: true, validate: (value) => value.length <= 30 }} type="text" name="businessName" inputRef={inputRef} placeholder="Business Name" className={`custom-input form-fields businessname  ${errors.businessName && 'error'}`} control={control} defaultValue="" onChange={(e: any) => handleChangeField(e)} />
                                        {errors.businessName && errors.businessName.type === "required" && (<label className="error-message">Field is required</label>)}
                                        {errors.businessName && errors.businessName.type === "validate" && (<label className="error-message">Name should be maximum of 30 Characters</label>)}

                                    </div>
                                </Grid>

                                {/* Business Type */}
                                <Grid item sm={6} xs={12}>
                                    <div className="input-group pl-10">
                                        <label className="input-label">Business Type *</label>
                                        <Controller name="businessType" control={control} rules={{ required: true }}
                                            render={({ value }) => (
                                                <Select displayEmpty name="businessType" value={value} className={value ? `custom-select form-select ${errors.businessType && 'error'}` : `custom-select light form-select  ${errors.businessType && 'error'}`} onChange={(e) => handleChange(e)}>
                                                    <MenuItem value={value ? value : ''}>{value ? value : 'Select'}</MenuItem>
                                                    {orgTypes.map((orgtype: any) =>
                                                        orgtype.value !== 'all' &&  <MenuItem value={orgtype.value}>{orgtype.title}</MenuItem>
                                                    )}
                                                </Select>
                                            )}
                                        />
                                        {errors.businessType && errors.businessType.type === "required" && (<label className="error-message">Field is required</label>)}
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid item className="flx-align-center" sm={12} xs={12} >
                                {/* Org Code */}
                                <Grid item sm={6} xs={12}>
                                    <div className="input-group pr-10">
                                        <label className="input-label">Business/Organization Code *</label>
                                        <Controller as={TextField} rules={{ required: true, maxLength: 9 }} type="text" name="orgCode" inputRef={inputRef} placeholder="Business/Organization Code" className={`custom-input form-fields orgCode  ${errors.orgCode && 'error'}`} control={control} defaultValue="" onChange={(e: any) => handleChangeField(e)} />
                                        {errors.orgCode && errors.orgCode.type === "required" && (<label className="error-message">Field is required</label>)}
                                        {errors.orgCode && errors.orgCode.type === "maxLength" && (<label className="error-message">Code should be maximum of 9 Characters</label>)}
                                    </div>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <div className="input-group pl-10">
                                        <label className="input-label">Loyalty Type</label>
                                        <div style={{ display: 'flex' }}>
                                            <Controller
                                                name='default_reward'
                                                control={control}
                                                rules={{ required: true }}
                                                // defaultValue={}
                                                render={({ value }) => (
                                                    <Select name="default_reward" value={value} className={`custom-select form-select  ${errors.default_reward && 'error'}`} onChange={(e) => handleChangeField(e)}>
                                                        <MenuItem value={'points'}>{'Points'}</MenuItem>
                                                        <MenuItem value={'tokens'}>{'Tokens'}</MenuItem>
                                                    </Select>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                            {defaultReward === 'tokens' ?
                            <Grid item className="flx-align-center" sm={12} xs={12} >
                                <Grid item sm={6} xs={12}>
                                            <div className="input-group pr-10">
                                                <label className="input-label">Blockchain Type </label>
                                                <Controller
                                                    name="blockChainType"
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ value }) => (
                                                        <Select displayEmpty name="blockChainType"  value={value} className={`custom-select form-select  ${errors.deploy_tokens && 'error'}`} onChange={(e) => handleChangeField(e)}>
                                                            <MenuItem value={'Avalanche'}>{'Avalanche'}</MenuItem>
                                                            <MenuItem value={'Tron'}>{'Tron'}</MenuItem>
                                                            <MenuItem value={'Ethereum'}>{'Ethereum'}</MenuItem>
                                                            <MenuItem value={'Polygon'}>{'Polygon'}</MenuItem>
                                                        </Select>
                                                    )
                                                    }
                                                />
                                                {errors.deploy_tokens && (<label className="error-message">Field is required</label>)}
                                            </div>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                            <div className="input-group pr-10" style={{ paddingLeft: 10 }}>
                                                <label className="input-label">Deploy Tokens </label>
                                                <Controller
                                                    name="deploy_tokens"
                                                    control={control}
                                                    rules={{ required: true }}
                                                    render={({ value }) => (
                                                        <Select displayEmpty name="deploy_tokens" value={value} className={`custom-select form-select  ${errors.deploy_tokens && 'error'}`} onChange={(e) => handleChangeField(e)}>
                                                            <MenuItem value={'testnet'}>{'Testnet'}</MenuItem>
                                                            <MenuItem value={'mainnet'}>{'Mainnet'}</MenuItem>
                                                        </Select>
                                                    )
                                                    }
                                                />
                                                {errors.deploy_tokens && (<label className="error-message">Field is required</label>)}
                                            </div>
                                </Grid>
                            </Grid>
                            :''
                            }
                            <Grid item className="flx-align-center pb-10" sm={12} xs={12}>
                                {/* Business Address */}
                                <Grid item sm={12} xs={12}>
                                    <div className="input-group">
                                        <label className="input-label">Business Address *</label>
                                        <Controller as={TextField} rules={{ required: true }} type="text" name="businessAddress" inputRef={inputRef} placeholder="Enter business location" className={`custom-input form-fields  ${errors.businessAddress && 'error'}`} control={control} defaultValue="" onChange={(e: any) => handleChangeField(e)} />
                                        {errors.businessAddress && errors.businessAddress.type === "required" && (<label className="error-message">Field is required</label>)}
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid item className="flx-align-center pb-10" sm={12} xs={12}>
                                {/* Business Address */}
                                <Grid item sm={12} xs={12}>
                                    <div className="input-group">
                                        <label className="input-label">Business Info {errors.orgBio && errors.orgBio.type === "maxLength" && (<label className="error-message">Info should not be more than 500 characters</label>)}</label>
                                        <Controller as={TextField} multiline rows={4} rules={{ required: false, maxLength: 500, }} type="text" name="orgBio" inputRef={inputRef} placeholder="Enter business info" className={`custom-input form-fields  ${errors.orgBio && 'error'}`} control={control} defaultValue="" onChange={(e: any) => handleChangeField(e)} />
                                        <div></div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    ) : activeStep === 1
                        ? (
                            <div className='other-info-block'>
                                <div className="social-tile">Social Network Handles</div>
                                {/* Facebook */}
                                <Grid item className="flx-align-center" sm={12} xs={12}>
                                    <Grid item sm={12} xs={12}>
                                        <div className="input-group social pr-10">
                                            <img className='social-img' src={FacebookLogo} alt='Facebook logo' />
                                            <Controller as={TextField} rules={{ required: false }} type="text" name="facebook" inputRef={inputRef} placeholder="https://www.facebook.com/homepage" className={`custom-input pad-left form-fields`} control={control} defaultValue="" onChange={(e: any) => handleChangeField(e)} />
                                        </div>
                                    </Grid>
                                </Grid>

                                {/* Twitter */}
                                <Grid item className="flx-align-center" sm={12} xs={12}>
                                    <Grid item sm={12} xs={12}>
                                        <div className="input-group social pr-10">
                                            <img className='social-img' src={TwitterLogo} alt='Twitter logo' />
                                            <Controller as={TextField} rules={{ required: false }} type="text" name="twitter" inputRef={inputRef} placeholder="https://twitter.com/homepage" className={`custom-input pad-left form-fields`} control={control} defaultValue="" onChange={(e: any) => handleChangeField(e)} />
                                        </div>
                                    </Grid>
                                </Grid>

                                {/* Instagram */}
                                <Grid item className="flx-align-center" sm={12} xs={12}>
                                    <Grid item sm={12} xs={12}>
                                        <div className="input-group social pr-10">
                                            <img className='social-img' src={InstagramLogo} alt='Instagram logo' />
                                            <Controller as={TextField} rules={{ required: false }} type="text" name="instagram" inputRef={inputRef} placeholder="https://www.instagram.com/homepage" className={`custom-input pad-left form-fields`} control={control} defaultValue="" onChange={(e: any) => handleChangeField(e)} />
                                        </div>
                                    </Grid>
                                </Grid>

                                {/* Youtube */}
                                <Grid item className="flx-align-center" sm={12} xs={12}>
                                    <Grid item sm={12} xs={12}>
                                        <div className="input-group social pr-10">
                                            <img className='social-img' src={YoutubeLogo} alt='Youtube logo' />
                                            <Controller as={TextField} rules={{ required: false }} type="text" name="youtube" inputRef={inputRef} placeholder="https://www.youtube.com/channel" className={`custom-input pad-left form-fields`} control={control} defaultValue="" onChange={(e: any) => handleChangeField(e)} />
                                        </div>
                                    </Grid>
                                </Grid>

                                {/* Play Store */}
                                <Grid item className="flx-align-center" sm={12} xs={12}>
                                    <Grid item sm={12} xs={12}>
                                        <div className="input-group social pr-10">
                                            <img className='social-img' src={PlayStoreLogo} alt='Play Store logo' />
                                            <Controller as={TextField} rules={{ required: false }} type="text" name="playstore" inputRef={inputRef} placeholder="https://play.google.com/app" className={`custom-input pad-left form-fields`} control={control} defaultValue="" onChange={(e: any) => handleChangeField(e)} />
                                        </div>
                                    </Grid>
                                </Grid>

                                {/* App Store */}
                                <Grid item className="flx-align-center" sm={12} xs={12}>
                                    <Grid item sm={12} xs={12}>
                                        <div className="input-group social pr-10">
                                            <img className='social-img' src={AppStoreLogo} alt='App Store logo' />
                                            <Controller as={TextField} rules={{ required: false }} type="text" name="appstore" inputRef={inputRef} placeholder="https://apple.com/in/app-store/" className={`custom-input pad-left form-fields`} control={control} defaultValue="" onChange={(e: any) => handleChangeField(e)} />
                                        </div>
                                    </Grid>
                                </Grid>

                                <div className='google-recaptcha'>
                                    <ReCAPTCHA size="normal" sitekey={reCaptchaKey} onChange={handleCaptchaChange} />
                                </div>

                                <div className={termsAndPolicy ? 'terms-policy checked' : 'terms-policy'} onClick={handleTermsAndPolicy}><span>By registering you agree with <a className="termsandcondition" href="https://www.pointsville.com/tos.html" target="_blank">Terms &amp; Conditions</a> and <a className="termsandcondition" href="https://www.pointsville.com/privacy.html" target="_blank">Privacy Policy</a></span></div>
                            </div>
                        ) : activeStep === 2
                            ? (
                                <div className='confirmation-block'>
                                    <div className='title'>Account Created!</div>
                                    <div className='message'>Your account has been created with a confirmation link sent to email <span>{username}</span>. Please click on the link to activate your account.</div>
                                </div>
                            ) : ''
                }

                <div className='btn-flex-blox paddigntop-20'>
                    {/* {activeStep !== 2 && <Button disabled={activeStep === 0} onClick={handleBack} className={classes.backButton}>Back</Button>} */}
                    <Button className={activeStep === 1 ? 'btn-submit' : ''} variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>{activeStep === steps.length - 1 ? 'SIGN IN' : activeStep === 0 ? 'NEXT' : 'SUBMIT'}</Button>
                </div>

                {/* {activeStep === steps.length ? (
                    <div>
                        <Typography className={classes.instructions}>All steps completed</Typography>
                        <Button onClick={handleReset}>Reset</Button>
                    </div>
                ) : (
                    <div>
                        <div className='btn-flex-blox'>
                            {activeStep !== 2 && <Button disabled={activeStep === 0} onClick={handleBack} className={classes.backButton}>Back</Button>}
                            <Button className={activeStep === 1 ? 'btn-submit' : ''} variant="contained" color="primary" onClick={handleNext}>{activeStep === steps.length - 1 ? 'SIGN IN' : activeStep === 0 ? 'NEXT' : 'SUBMIT'}</Button>
                        </div>
                    </div>
                )} */}
            </form>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning">
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Backdrop className="loader-signup" open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}
