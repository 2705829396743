import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import { Auth } from 'aws-amplify';

import { Backdrop, CircularProgress, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Logo from '../../assets/logo-big.png';
import PasswordEye from '../../assets/ic_eye.svg';
import NoPasswordEye from '../../assets/eye-slash-solid.webp';

import './signin.css';

interface IFormSingIn {
    userName: string,
    password: string,
}

let defaultValues: any = {
    userName: '',
    password: '',
}

function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CustomSignIn() {

    const [showPassword, setShowPassword] = React.useState<any>(false);
    const [rememberMe, setRememberMe] = React.useState<any>(false);
    const [open, setOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const { register, handleSubmit, errors, control, reset, formState, setValue } = useForm<IFormSingIn>({ defaultValues, shouldUnregister: false });

    const onSubmit: SubmitHandler<IFormSingIn> = async (fieldData) => {
        setLoading(true);
        try {
            const user = await Auth.signIn(fieldData.userName, fieldData.password);
            setLoading(false);
            window.location.reload();
        } catch (error) {
            setErrorMessage(error.message);
            setOpen(true);
            setLoading(false);
        }
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };

    const handleRemember = () => {
        setRememberMe(!rememberMe)
    }

    // to change input text values here.
    const handleChangeField = (e: React.ChangeEvent<any>) => {
        const targetValue = (e.target as HTMLInputElement).value;
        const targetName: string = (e.target as HTMLInputElement).name;
        setValue(targetName, targetValue);
    }

    const redirectToSignUp = () => {
        history.push('/signup');
    }

    const handleClose = (event: any, reason: any) => {
        setOpen(false);
    };


    return (
        <div className='signin-container'>
            <div className='flex-block'>
                {/* pv logo */}
                <div className='pv-logo-block'>
                    <img src={Logo} alt='logo' className='signin-logo-img' />
                </div>

                {/* form */}
                <div className='form-block'>
                    <div className='title-block'>SIGN IN</div>

                    <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
                        {/* Email */}
                        <Grid item className="flx-align-center pt-30" sm={12} xs={12}>
                            <Grid item sm={12} xs={12}>
                                <div className="input-group">
                                    <label className="input-label">Email *</label>
                                    <Controller as={TextField} rules={{ required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ }} type="text" name="userName" placeholder="example@xyz.com" className={`custom-input form-fields  ${errors.userName && 'error'}`} control={control} defaultValue="" onChange={(e: any) => handleChangeField(e)} />
                                    {errors.userName && errors.userName.type === "required" && (<label className="error-message">Field is required</label>)}
                                    {errors.userName && errors.userName.type === "pattern" && (<label className="error-message">Please enter a valid business email</label>)}
                                </div>
                            </Grid>
                        </Grid>

                        {/* Password */}
                        <Grid item className="flx-align-center" sm={12} xs={12}>
                            <Grid item sm={12} xs={12}>
                                <div className="input-group">
                                    <label className="input-label">Password *</label>
                                    <Controller as={TextField} rules={{ required: true }} type={showPassword ? "text" : "password"} name="password" placeholder="password here" className={`custom-input pr-40 form-fields  ${errors.password && 'error'}`} control={control} defaultValue="" onChange={(e: any) => handleChangeField(e)} />
                                    <span onClick={handleClickShowPassword}><img className='password-eye' src={showPassword ? PasswordEye : NoPasswordEye} alt='Password Eye' /></span>
                                    {errors.password && errors.password.type === "required" && (<label className="error-message">Field is required</label>)}
                                </div>
                            </Grid>
                        </Grid>

                        <div className='flex-box'>
                            <div className={rememberMe ? 'remember-me checked' : 'remember-me'} onClick={handleRemember}><span>Remember me</span></div>
                            <div className='forgot-pwd'><a href='/forgotpassword'>Forgot Password</a></div>
                        </div>

                        <div className='btn-flex-box'>
                            <Button type="submit" className='btn-submit' variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>SIGN IN</Button>
                        </div>
                    </form>

                    <div className='footer-block'>New Member? <a onClick={() => redirectToSignUp()}>SIGN UP NOW</a></div>

                </div>
            </div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning">
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Backdrop className="loader-signup" open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}
