import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory ,useParams} from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import DropdownSelect from '../../components/Dropdown/dropdownselect';
import CreatePromotions from '../createpromotions/createpromotions';
import { useQuery, gql } from '@apollo/client';
import '../../constant.css';
import moment from 'moment';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import './sponsorList.css';
import Loader from '../../components/Loader/loader';
import Pirates from '../../assets/Pirates.png';
import Pnc from '../../assets/PNC.png';
import Highmark from '../../assets/Highmark.png';
import Nasdaq from '../../assets/Nasdaq.png';
import Van from '../../assets/van.png';
import Veterans from '../../assets/Veterans.png';
import MUIDataTable from "mui-datatables";
import { TwitterShareButton } from 'react-twitter-embed';
import {twitterMessage, twitterHashTag} from "../../utilities/Helper"
const formatCurrency = require('format-currency');
const opt = { minFraction: 0 };
interface PromotionInventory {
    id: string;
    promoType: string;
}

interface Props {
    onParentRender: () => void,
    code: any
  }
interface ParamTypes {
    orgId: string;
}
interface PromotionInventoryData {
    promoInventory: PromotionInventory[];
}

interface PromoInventoryVars {
    year: number;
}

export const GET_PROMO_QUERY = gql`
    query GetPromotionsData( $orgId : String!) {
        getPromoWithOrgId (orgId : $orgId){
            promoCodePrefix
            promoCodePoints
            promoType
            validity
            promoTotalCount
            sponsorId
            id
            pointsAvailable
            noPointsPerPromo
            sponsorLogo
            createdAt
            expired
        }
    }
`;

export const GET_HUNT_QUERY = gql`
query getAllHuntsByOrg( $orgId : String!) {
    getAllHuntsByOrg (orgId : $orgId){
        id
        huntCodePrefix
        huntCodePoints
        huntCode
        validity
        validityDay
        orgId
        sponsorId
        sponsorLogo
        noPointsPerHunt
        pointsAvailable
        isActive
        createdAt
    }
}
`;

export const GET_POI_QUERY = gql`
query getAllPoiByOrg( $orgId : String!) {
    getAllPoiByOrg (orgId : $orgId){
        id
        poiCodePrefix
        poiCodePoints
        poiCode
        validity
        validityDay
        orgId
        sponsorId
        sponsorLogo
        noPointsPerPoi
        pointsAvailable
        isActive
        createdAt
    }
}
`;

export const GET_VIDEO_PROMO_QUERY = gql`
    query GetVideoPromotionsData( $orgId : String!) {
        getVideoPromoWithOrgId (orgId : $orgId){
            promoCodePrefix
            promoCodePoints
            promoType
            validity
            promoTotalCount
            sponsorId
            id
            pointsAvailable
            noPointsPerPromo
            sponsorLogo
            createdAt
            expired
        }
    }
`;

export const GET_BILLBOARD_QUERY = gql`
query getAllBillboardsByOrg($orgId : String!) {
    getAllBillboardsByOrg(orgId : $orgId){
        id
        billboardCodePrefix
        billboardCodePoints
        billboardCode
        validity
        validityDay
        orgId
        sponsorId
        sponsorLogo
        noPointsPerBillboard
        pointsAvailable
        isActive
        createdAt
    }
}
`;

const GET_ORG_BY_ID = gql`
  query getClub($clubId: String!) {
    getClub(clubId: $clubId) {
        name
    }
  }
`;


interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div className="tabpanel-parent" >
            {children}
          </div>
        )}
      </div>
    );
  }

  function a11yProps(index: any) {
    return {
      id: `game-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  function socialProps(index: any) {
    return {
      id: `social-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
export default function PromotionLists({onParentRender, code}:Props) {

    let { orgId } = useParams<ParamTypes>();
    const [orgName,setOrgName] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [promotionId, setPromotionId] = React.useState<any>();
    const [copyPromoId, setCopyPromoId] = React.useState<any>();
    const [promotionType, setPromotionType] = React.useState<any>("Email");
    const [copyPromo, setCopyPromo] = React.useState<any>(false);
    const hasToken = localStorage.getItem('org_has_token');

    const allPromos:any = [];
    const { loading, data ,refetch} = useQuery<any>(
        GET_PROMO_QUERY , {variables : { orgId : orgId }}
    );

    const { loading: loadingHunt, data: dataHunt ,refetch: refetchHunt} = useQuery<any>(
        GET_HUNT_QUERY , {variables : { orgId : orgId}}
    );

    const { loading: loadingPoi, data: dataPoi ,refetch: refetchPoi} = useQuery<any>(
      GET_POI_QUERY , {variables : { orgId : orgId}}
    );

    const { loading: loadingBillBoard, data: dataBillBoard ,refetch: refetchBillBoard} = useQuery<any>(
        GET_BILLBOARD_QUERY , {variables : { orgId : orgId}}
    );

    const { loading: loadingVideoPromo, data:dataVideoPromo ,refetch: refetchVideoPromo} = useQuery<any>(
        GET_VIDEO_PROMO_QUERY , {variables : { orgId : orgId }}
    );

    const { data: orgData } = useQuery<any>(
      GET_ORG_BY_ID , {variables : { clubId : orgId}}
    );

    // set Organizatino name
    useEffect(() => {
      if(orgData && orgData.getClub) {
        setOrgName(orgData.getClub.name);
      }
    },[orgData]);

    const makeAllPromos = (data:any, promoType:string) => {
        if(data) {
            data.forEach((item:any, index:any) => {
                var obj:any = {};
                if(promoType == 'Email') {
                    obj.promoCode = code + item.promoCodePrefix
                    obj.sponsorId = item.sponsorId
                    obj.sponsorLogo = item.sponsorLogo
                    obj.noPointsPerPromo = item.promoCodePoints
                    obj.pointsAvailable = item.pointsAvailable
                    obj.totalPoints = item.promoTotalCount
                    obj.uniqueUser = ''
                    obj.createdAt = item.createdAt
                    obj.expired = item.expired
                    obj.id = item.id
                    obj.promoType = item.promoType
                    obj.promoTypeCustom = promoType
                    obj.validity = item.validity
                    obj.validityDay = item.validityDay
                    obj.isActive = item.isActive
                    obj.__typename = item.__typename
                }
                else if(promoType == 'Hunt') {
                    obj.promoCode = item.huntCode
                    obj.sponsorId = item.sponsorId
                    obj.sponsorLogo = item.sponsorLogo
                    obj.noPointsPerPromo = item.huntCodePoints
                    obj.pointsAvailable = item.pointsAvailable
                    obj.totalPoints = item.noPointsPerHunt * item.huntCodePoints
                    obj.uniqueUser = item.noPointsPerHunt
                    obj.createdAt = item.createdAt
                    obj.expired = item.expired
                    obj.id = item.id
                    obj.promoType = promoType
                    obj.promoTypeCustom = promoType
                    obj.validity = item.validity
                    obj.validityDay = item.validityDay
                    obj.isActive = item.isActive
                    obj.__typename = item.__typename
                }
                else if(promoType == 'Poi') {
                  obj.promoCode = item.poiCode
                  obj.sponsorId = item.sponsorId
                  obj.sponsorLogo = item.sponsorLogo
                  obj.noPointsPerPromo = item.poiCodePoints
                  obj.pointsAvailable = item.pointsAvailable
                  obj.totalPoints = item.noPointsPerPoi * item.poiCodePoints
                  obj.uniqueUser = item.noPointsPerPoi
                  obj.createdAt = item.createdAt
                  obj.expired = item.expired
                  obj.id = item.id
                  obj.promoType = promoType
                  obj.promoTypeCustom = promoType
                  obj.validity = item.validity
                  obj.validityDay = item.validityDay
                  obj.isActive = item.isActive
                  obj.__typename = item.__typename
                }
                else if(promoType == 'Video') {
                    obj.promoCode = code + item.promoCodePrefix
                    obj.sponsorId = item.sponsorId
                    obj.sponsorLogo = item.sponsorLogo
                    obj.noPointsPerPromo = item.promoCodePoints
                    obj.pointsAvailable = item.pointsAvailable
                    obj.totalPoints = item.promoTotalCount
                    obj.uniqueUser = ''
                    obj.createdAt = item.createdAt
                    obj.expired = item.expired
                    obj.id = item.id
                    obj.promoType = item.promoType
                    obj.promoTypeCustom = promoType
                    obj.validity = item.validity
                    obj.validityDay = item.validityDay
                    obj.isActive = item.isActive
                    obj.__typename = item.__typename
                }
                else if(promoType == 'BillBoard') {
                    obj.promoCode = code + item.billboardCodePrefix
                    obj.sponsorId = item.sponsorId
                    obj.sponsorLogo = item.sponsorLogo
                    obj.noPointsPerPromo = item.billboardCodePoints
                    obj.pointsAvailable = item.pointsAvailable
                    obj.totalPoints = item.noPointsPerBillboard * item.billboardCodePoints
                    obj.uniqueUser = item.noPointsPerBillboard
                    obj.createdAt = item.createdAt
                    obj.expired = item.expired
                    obj.id = item.id
                    obj.promoType = promoType
                    obj.promoTypeCustom = promoType
                    obj.validity = item.validity
                    obj.validityDay = item.validityDay
                    obj.isActive = item.isActive
                    obj.__typename = item.__typename
                }
                allPromos.push(obj);
            })
        }
    }
    if(data?.getPromoWithOrgId) {
        makeAllPromos(data?.getPromoWithOrgId, 'Email');
    }
    if(dataHunt?.getAllHuntsByOrg) {
        makeAllPromos(dataHunt?.getAllHuntsByOrg, 'Hunt');
    }
    if(dataVideoPromo?.getVideoPromoWithOrgId) {
        makeAllPromos(dataVideoPromo?.getVideoPromoWithOrgId, 'Video');
    }
    if(dataBillBoard?.getAllBillboardsByOrg) {
        makeAllPromos(dataBillBoard?.getAllBillboardsByOrg, 'BillBoard');
    }


    // const [allData, setAllData] = React.useState<any>([]);

    const handleClickOpen = () => {
        setOpen(true);
        setPromotionId(false);
        setCopyPromoId(false);
        setCopyPromo(false);
        setPromotionType("");
    };

    useEffect(() => {
        refetch();
        refetchHunt();
        refetchBillBoard();
        onParentRender();
        refetchVideoPromo();
        refetchPoi();
    },[open]);

    const handleClose = (tabIndex: any) => {
        setOpen(false);
        setPromotionType("");
        setSocialTab(tabIndex);
        //setTimeout (() => {refetch()},1000);
    };
    const handleEditClickOpen = (id:string,promotionType:string) =>{
        setOpen(true);
        setPromotionId(id);
        setCopyPromoId(false);
        setCopyPromo(false);
        setPromotionType(promotionType)
    };
    const [socialTab, setSocialTab] = React.useState(0);

    const handleChangeSocial = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSocialTab(newValue);
    };

    const handleCopyPromo = (id:string, promoType:any) => {
        setOpen(true);
        setPromotionId(false);

        setCopyPromoId(id);
        setCopyPromo(true);
        setPromotionType(promoType);
    }

    const columnsEmail:any = [
        {
          name:"promoCodePrefix",
          options:{
              filter:false,
              sortThirdClickReset: true,
              sort: true,
              customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                let orderName = (sortOrder.name == "promoCodePrefix")?sortOrder.direction:"";
                return(
                <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={0} onClick={() => updateDirection(0)} >
                  <a className="sort-by">Promo Code</a>
                </th>
                )
              },
              customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                  let promo = data && data?.getPromoWithOrgId.find((x:any) => x.promoCodePrefix === value);
                  return (<label className="promocode pointer" onClick={() => handleEditClickOpen(promo.id,"Email")} style={{ textTransform: 'uppercase'}}>{code}{promo.promoCodePrefix}</label>)
                }

          }
        },
        {
          name:"promoType",
          options:{
              filter:false,
              sortThirdClickReset: true,
              sort: true,
              customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "promoType")?sortOrder.direction:"";

                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(1)} >
                    <a className="sort-by">Type</a>
                  </th>
                  )
              },
              customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                let promo = data && data?.getPromoWithOrgId.find((x:any) => x.promoType === value);
                return (<label  onClick={() => handleEditClickOpen(promo.id,"Email")}>{value}</label>)
              }
          }
        },
        {
            name:"sponsorLogo",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "sponsorLogo")?sortOrder.direction:"";

                    return(
                    <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(2)} >
                      <a className="sort-by">Sponsors</a>
                    </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let promo = data && data?.getPromoWithOrgId.find((x:any) => x.sponsorLogo === value);
                    return (<label  onClick={() => handleEditClickOpen(promo.id,"Email")}><img className="profile-img promo-list" src={value || "https://us.v-cdn.net/6022045/uploads/defaultavatar.png"} alt="sponsor logo" /></label>)
                }
            }
        },
        {
        name:"promoCodePoints",
        options:{
            filter:false,
            sortThirdClickReset: true,
            sort: true,
            customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                let orderName = (sortOrder.name == "promoCodePoints")?sortOrder.direction:"";

                return(
                <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={3} onClick={() => updateDirection(3)} >
                    <a className="sort-by">{hasToken === "true" ? 'Tokens' : 'Points'} per promo</a>
                </th>
                )
            },
            customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                let promo = data && data?.getPromoWithOrgId.find((x:any) => x.promoCodePoints === value);
                return (<label onClick={() => handleEditClickOpen(promo.id,"Email")}>{formatCurrency(value,opt)}</label>)
            }
        }
        },
        {
        name:"pointsAvailable",
        options:{
            filter:false,
            sortThirdClickReset: true,
            sort: true,
            customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                let orderName = (sortOrder.name == "pointsAvailable")?sortOrder.direction:"";

                return(
                <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={4} onClick={() => updateDirection(4)} >
                    <a className="sort-by">Availability/Total</a>
                </th>
                )
            },
            customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                let promo = data && data?.getPromoWithOrgId.find((x:any) => x.pointsAvailable === value);
                return (<label onClick={() => handleEditClickOpen(promo.id,"Email")}>{formatCurrency(promo.pointsAvailable,opt) +"/"+ formatCurrency(promo.promoTotalCount,opt)}</label>)
            }
        }
        },
        {
        name:"createdAt",
        options:{
            filter:false,
            sortThirdClickReset: true,
            sort: true,
            customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                let orderName = (sortOrder.name == "createdAt")?sortOrder.direction:"";

                return(
                <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={5} onClick={() => updateDirection(5)} >
                    <a className="sort-by">Creation Date</a>
                </th>
                )
            },
            customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                let promo = data && data?.getPromoWithOrgId.find((x:any) => x.createdAt === value);
                return (<label onClick={() => handleEditClickOpen(promo.id,"Email")}>{moment(parseInt(value)).format('MM/DD/YYYY')}</label>)
            }
        }
        },
        {
        name:"validity",
        options:{
            filter:false,
            sortThirdClickReset: true,
            sort: true,
            customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                let orderName = (sortOrder.name == "validity")?sortOrder.direction:"";

                return(
                <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={6} onClick={() => updateDirection(6)} >
                    <a className="sort-by">Validity Date</a>
                </th>
                )
            },
            customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                let promo = data && data?.getPromoWithOrgId.find((x:any) => x.validity === value);
                return (<label onClick={() => handleEditClickOpen(promo.id,"Email")}>{moment(value).format('D MMM YYYY')}</label>)
            }
        }
        },
        {
            name:"id",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "id")?sortOrder.direction:"";

                    return(
                    <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={7} onClick={() => updateDirection(7)} >
                        <a className="sort-by">Status</a>
                    </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let promo = data && data?.getPromoWithOrgId.find((x:any) => x.id === value);
                    return (<div onClick={() => handleEditClickOpen(promo.id,"Email")}>{promo.expired || promo.pointsAvailable == 0 ? (<label className="red">Expired</label>):(<label className="green">Active</label>)} </div>)
                }
            }
        },
        {
            name:"id",
            options:{
                filter:false,
                sortThirdClickReset: false,
                sort: false,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "id")?sortOrder.direction:"";

                    return(
                    <th className={"table-header-sorting " + orderName} key={8}>
                    </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let promo = data && data?.getPromoWithOrgId.find((x:any) => x.id === value);
                    return (<label onClick={() => handleCopyPromo(promo.id, promo.promoType)} className="btn-copy">Copy</label>)
                }
            }
        },
    ];

    const columnsHunt:any = [
        {
          name:"huntCode",
          options:{
              filter:false,
              sortThirdClickReset: true,
              sort: true,
              customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                let orderName = (sortOrder.name == "huntCode")?sortOrder.direction:"";
                return(
                <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={0} onClick={() => updateDirection(0)} >
                  <a className="sort-by">Hunt Code</a>
                </th>
                )
              },
              customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                  let hunt = dataHunt && dataHunt?.getAllHuntsByOrg.find((x:any) => x.huntCode === value);
                  return (<label className="promocode pointer" onClick={() => handleEditClickOpen(hunt.id,"Hunt")} style={{ textTransform: 'uppercase'}}>{hunt.huntCode ? hunt.huntCode : (code + hunt.huntCodePrefix)}</label>)
                }

          }
        },
        {
          name:"sponsorLogo",
          options:{
              filter:false,
              sortThirdClickReset: true,
              sort: true,
              customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "sponsorLogo")?sortOrder.direction:"";

                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(1)} >
                    <a className="sort-by">Sponsors</a>
                  </th>
                  )
              },
              customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                let hunt = dataHunt && dataHunt?.getAllHuntsByOrg.find((x:any) => x.huntCode === value);
                return (<label  onClick={() => handleEditClickOpen(hunt.id,"Hunt")}><img className="profile-img promo-list" src={value || "https://us.v-cdn.net/6022045/uploads/defaultavatar.png"} alt="sponsor logo" /></label>)
              }
          }
        },
        {
            name:"noPointsPerHunt",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "noPointsPerHunt")?sortOrder.direction:"";

                    return(
                    <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(2)} >
                      <a className="sort-by">Unique Users</a>
                    </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let hunt = dataHunt && dataHunt?.getAllHuntsByOrg.find((x:any) => x.noPointsPerHunt === value);
                    return (<label  onClick={() => handleEditClickOpen(hunt.id,"Hunt")}>{value}</label>)
                }
            }
        },
        {
            name:"pointsAvailable",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "pointsAvailable")?sortOrder.direction:"";

                    return(
                    <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={3} onClick={() => updateDirection(3)} >
                      <a className="sort-by">Availability/Total</a>
                    </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let hunt = dataHunt && dataHunt?.getAllHuntsByOrg.find((x:any) => x.pointsAvailable === value);
                    return (<label onClick={() => handleEditClickOpen(hunt.id,"Hunt")}>{ formatCurrency(hunt.pointsAvailable,opt) +"/"+formatCurrency(hunt.noPointsPerHunt*hunt.huntCodePoints,opt)}</label>)
                }
            }
        },
        {
            name:"createdAt",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "createdAt")?sortOrder.direction:"";

                    return(
                    <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={4} onClick={() => updateDirection(4)} >
                      <a className="sort-by">Creation Date</a>
                    </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let hunt = dataHunt && dataHunt?.getAllHuntsByOrg.find((x:any) => x.createdAt === value);
                    return (<label onClick={() => handleEditClickOpen(hunt.id,"Hunt")}>{moment(parseInt(hunt.createdAt)).format('MM/DD/YYYY')}</label>)
                }
            }
        },
        {
            name:"validity",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "validity")?sortOrder.direction:"";

                    return(
                    <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={5} onClick={() => updateDirection(5)} >
                      <a className="sort-by">Validity Date</a>
                    </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let hunt = dataHunt && dataHunt?.getAllHuntsByOrg.find((x:any) => x.validity === value);
                    return (<label onClick={() => handleEditClickOpen(hunt.id,"Hunt")}>{hunt.validity}</label>)
                }
            }
        },
        {
            name:"id",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "id")?sortOrder.direction:"";

                    return(
                    <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={6} onClick={() => updateDirection(6)} >
                      <a className="sort-by">Status</a>
                    </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let hunt = dataHunt && dataHunt?.getAllHuntsByOrg.find((x:any) => x.id === value);
                    let expired = (new Date().setHours(0,0,0,0)<=new Date(hunt.validity).setHours(0,0,0,0))?true:false;
                    return (<div onClick={() => handleEditClickOpen(hunt.id,"Hunt")}>{(expired && hunt.pointsAvailable !== 0)?(<label className="green">Active</label>):(<label className="red">Expired</label>)} </div>)
                }
            }
        },
        {
            name:"id",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "id")?sortOrder.direction:"";

                    return(
                    <th className={"table-header-sorting " + orderName}>
                    </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let hunt = dataHunt && dataHunt?.getAllHuntsByOrg.find((x:any) => x.id === value);
                    return (<label className="btn-copy"  onClick={() => handleCopyPromo(hunt.id, "Hunt")}>Copy</label>)
                }
            }
        },
        {
          name:"id",
          options:{
              filter:false,
              sortThirdClickReset: false,
              sort: false,
              customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                let orderName = (sortOrder.name == "id")?sortOrder.direction:"";
                return(
                <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={8} >
                </th>
                )
              },
              customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                let hunt = dataHunt && dataHunt?.getAllHuntsByOrg.find((x:any) => x.id === value);
                let expired = (new Date().setHours(0,0,0,0)<=new Date(hunt.validity).setHours(0,0,0,0))?true:false;
                return (
                  expired && hunt.pointsAvailable !== 0?
                    <TwitterShareButton
                      url={'#'}
                      options={{ text: twitterMessage("Hunt AR", orgName), hashtags:twitterHashTag("Hunt") }}
                    />
                  :""
                )
                }

          }
        },
    ];

    const columnsPoi:any = [
      {
        name:"poiCode",
        options:{
            filter:false,
            sortThirdClickReset: true,
            sort: true,
            customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
              let orderName = (sortOrder.name == "poiCode")?sortOrder.direction:"";
              return(
              <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={0} onClick={() => updateDirection(0)} >
                <a className="sort-by">Poi Code</a>
              </th>
              )
            },
            customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                let poi:any = dataPoi && dataPoi?.getAllPoiByOrg.find((x:any) => x.poiCode === value);
                return (<label className="promocode pointer" onClick={() => handleEditClickOpen(poi.id,"Poi")} style={{ textTransform: 'uppercase'}}>{poi.poiCode ? poi.poiCode : (code + poi.poiCodePrefix)}</label>)
              }

        }
      },
      {
        name:"sponsorLogo",
        options:{
            filter:false,
            sortThirdClickReset: true,
            sort: true,
            customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                let orderName = (sortOrder.name == "sponsorLogo")?sortOrder.direction:"";

                return(
                <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(1)} >
                  <a className="sort-by">Sponsors</a>
                </th>
                )
            },
            customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
              let poi:any = dataPoi && dataPoi?.getAllPoiByOrg.find((x:any) => x.poiCode === value);
              return (<label  onClick={() => handleEditClickOpen(poi.id,"Poi")}><img className="profile-img promo-list" src={value || "https://us.v-cdn.net/6022045/uploads/defaultavatar.png"} alt="sponsor logo" /></label>)
            }
        }
      },
      {
          name:"noPointsPerPoi",
          options:{
              filter:false,
              sortThirdClickReset: true,
              sort: true,
              customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "noPointsPerPoi")?sortOrder.direction:"";

                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(2)} >
                    <a className="sort-by">Unique Users</a>
                  </th>
                  )
              },
              customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                  let poi:any = dataPoi && dataPoi?.getAllPoiByOrg.find((x:any) => x.noPointsPerPoi === value);
                  return (<label  onClick={() => handleEditClickOpen(poi.id,"Poi")}>{value}</label>)
              }
          }
      },
      {
          name:"pointsAvailable",
          options:{
              filter:false,
              sortThirdClickReset: true,
              sort: true,
              customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "pointsAvailable")?sortOrder.direction:"";

                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={3} onClick={() => updateDirection(3)} >
                    <a className="sort-by">Availability/Total</a>
                  </th>
                  )
              },
              customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                  let poi:any = dataPoi && dataPoi?.getAllPoiByOrg.find((x:any) => x.pointsAvailable === value);
                  return (<label onClick={() => handleEditClickOpen(poi.id,"Poi")}>{ formatCurrency(poi.pointsAvailable,opt) +"/"+formatCurrency(poi.noPointsPerHunt*poi.huntCodePoints,opt)}</label>)
              }
          }
      },
      {
          name:"createdAt",
          options:{
              filter:false,
              sortThirdClickReset: true,
              sort: true,
              customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "createdAt")?sortOrder.direction:"";

                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={4} onClick={() => updateDirection(4)} >
                    <a className="sort-by">Creation Date</a>
                  </th>
                  )
              },
              customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                  let poi:any = dataPoi && dataPoi?.getAllPoiByOrg.find((x:any) => x.createdAt === value);
                  return (<label onClick={() => handleEditClickOpen(poi.id,"Poi")}>{moment(parseInt(poi.createdAt)).format('MM/DD/YYYY')}</label>)
              }
          }
      },
      {
          name:"validity",
          options:{
              filter:false,
              sortThirdClickReset: true,
              sort: true,
              customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "validity")?sortOrder.direction:"";

                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={5} onClick={() => updateDirection(5)} >
                    <a className="sort-by">Validity Date</a>
                  </th>
                  )
              },
              customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                  let poi:any = dataPoi && dataPoi?.getAllPoiByOrg.find((x:any) => x.validity === value);
                  return (<label onClick={() => handleEditClickOpen(poi.id,"Poi")}>{poi.validity}</label>)
              }
          }
      },
      {
          name:"id",
          options:{
              filter:false,
              sortThirdClickReset: true,
              sort: true,
              customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "id")?sortOrder.direction:"";

                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={6} onClick={() => updateDirection(6)} >
                    <a className="sort-by">Status</a>
                  </th>
                  )
              },
              customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                  let poi:any = dataPoi && dataPoi?.getAllPoiByOrg.find((x:any) => x.id === value);
                  let expired = (new Date().setHours(0,0,0,0)<=new Date(poi.validity).setHours(0,0,0,0))?true:false;
                  return (<div onClick={() => handleEditClickOpen(poi.id,"Poi")}>{(expired && poi.pointsAvailable !== 0)?(<label className="green">Active</label>):(<label className="red">Expired</label>)} </div>)
              }
          }
      },
      {
          name:"id",
          options:{
              filter:false,
              sortThirdClickReset: true,
              sort: true,
              customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "id")?sortOrder.direction:"";

                  return(
                  <th className={"table-header-sorting " + orderName}>
                  </th>
                  )
              },
              customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                  let poi:any = dataPoi && dataPoi?.getAllPoiByOrg.find((x:any) => x.id === value);
                  return (<label className="btn-copy"  onClick={() => handleCopyPromo(poi.id, "Poi")}>Copy</label>)
              }
          }
      },
      {
        name:"id",
        options:{
            filter:false,
            sortThirdClickReset: false,
            sort: false,
            customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
              let orderName = (sortOrder.name == "id")?sortOrder.direction:"";
              return(
              <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={8} >
              </th>
              )
            },
            customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
              let poi:any = dataPoi && dataPoi?.getAllPoiByOrg.find((x:any) => x.id === value);
              let expired = (new Date().setHours(0,0,0,0)<=new Date(poi.validity).setHours(0,0,0,0))?true:false;
              return (
                expired && poi.pointsAvailable !== 0?
                  <TwitterShareButton
                    url={'#'}
                    options={{ text: twitterMessage("POI", orgName), hashtags:twitterHashTag("Poi") }}
                  />
                :""
              )
              }

        }
      },
  ];

    const columnsVideo:any = [
        {
          name:"promoCodePrefix",
          options:{
              filter:false,
              sortThirdClickReset: true,
              sort: true,
              customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                let orderName = (sortOrder.name == "promoCodePrefix")?sortOrder.direction:"";
                return(
                <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={0} onClick={() => updateDirection(0)} >
                  <a className="sort-by">Promo Code</a>
                </th>
                )
              },
              customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                  let promo = dataVideoPromo && dataVideoPromo?.getVideoPromoWithOrgId.find((x:any) => x.promoCodePrefix === value);
                  return (<label className="promocode pointer" style={{ textTransform: 'uppercase'}} onClick={() => handleEditClickOpen(promo.id,"Video")}>{code}{promo.promoCodePrefix}</label>)
                }

          }
        },
        {
            name:"promoType",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "promoType")?sortOrder.direction:"";
                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(1)} >
                    <a className="sort-by">Type</a>
                  </th>
                  )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let promo = dataVideoPromo && dataVideoPromo?.getVideoPromoWithOrgId.find((x:any) => x.promoType === value);
                    return (<label  onClick={() => handleEditClickOpen(promo.id,"Video")}>{value}</label>)
                  }

            }
          },
          {
            name:"sponsorLogo",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "sponsorLogo")?sortOrder.direction:"";
                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(2)} >
                    <a className="sort-by">Sponsors</a>
                  </th>
                  )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let promo = dataVideoPromo && dataVideoPromo?.getVideoPromoWithOrgId.find((x:any) => x.sponsorLogo === value);
                    return (<label  onClick={() => handleEditClickOpen(promo.id,"Video")}><img className="profile-img promo-list" src={value || "https://us.v-cdn.net/6022045/uploads/defaultavatar.png"} alt="sponsor logo" /></label>)
                  }

            }
          },
          {
            name:"promoCodePoints",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "promoCodePoints")?sortOrder.direction:"";
                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={3} onClick={() => updateDirection(3)} >
                    <a className="sort-by">{hasToken === "true" ? 'Tokens' : 'Points'} per promo</a>
                  </th>
                  )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let promo = dataVideoPromo && dataVideoPromo?.getVideoPromoWithOrgId.find((x:any) => x.promoCodePoints === value);
                    return (<label  onClick={() => handleEditClickOpen(promo.id,"Video")}>{formatCurrency(value,opt)}</label>)
                  }

            }
          },
          {
            name:"pointsAvailable",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "pointsAvailable")?sortOrder.direction:"";
                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={4} onClick={() => updateDirection(4)} >
                    <a className="sort-by">Availability/Total</a>
                  </th>
                  )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let promo = dataVideoPromo && dataVideoPromo?.getVideoPromoWithOrgId.find((x:any) => x.pointsAvailable === value);
                    return (<label  onClick={() => handleEditClickOpen(promo.id,"Video")}>{formatCurrency(promo.pointsAvailable,opt) +"/"+ formatCurrency(promo.promoTotalCount,opt)}</label>)
                  }

            }
          },
          {
            name:"createdAt",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "createdAt")?sortOrder.direction:"";
                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={5} onClick={() => updateDirection(5)} >
                    <a className="sort-by">Creation Date</a>
                  </th>
                  )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let promo = dataVideoPromo && dataVideoPromo?.getVideoPromoWithOrgId.find((x:any) => x.createdAt === value);
                    return (<label  onClick={() => handleEditClickOpen(promo.id,"Video")}>{moment(parseInt(value)).format('MM/DD/YYYY')}</label>)
                  }

            }
          },
          {
            name:"validity",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "validity")?sortOrder.direction:"";
                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={6} onClick={() => updateDirection(6)} >
                    <a className="sort-by">Validity Date</a>
                  </th>
                  )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let promo = dataVideoPromo && dataVideoPromo?.getVideoPromoWithOrgId.find((x:any) => x.validity === value);
                    return (<label  onClick={() => handleEditClickOpen(promo.id,"Video")}>{moment(promo.validity).format('D MMM YYYY')}</label>)
                  }

            }
          },
          {
            name:"id",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "id")?sortOrder.direction:"";
                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={7} onClick={() => updateDirection(7)} >
                    <a className="sort-by">Status</a>
                  </th>
                  )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let promo = dataVideoPromo && dataVideoPromo?.getVideoPromoWithOrgId.find((x:any) => x.id === value);
                    return (<div  onClick={() => handleEditClickOpen(promo.id,"Video")}>{promo.expired || promo.pointsAvailable == 0 ? (<label className="red">Expired</label>):(<label className="green">Active</label>)}</div>)
                  }

            }
          },
          {
            name:"id",
            options:{
                filter:false,
                sortThirdClickReset: false,
                sort: false,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "id")?sortOrder.direction:"";
                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={8} >
                  </th>
                  )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let promo = dataVideoPromo && dataVideoPromo?.getVideoPromoWithOrgId.find((x:any) => x.id === value);
                    return (<label className="btn-copy" onClick={() => handleCopyPromo(promo.id, promo.promoType)}>Copy</label>)
                  }

            }
          },
          {
            name:"id",
            options:{
                filter:false,
                sortThirdClickReset: false,
                sort: false,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "id")?sortOrder.direction:"";
                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={8} >
                  </th>
                  )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let promo = dataVideoPromo && dataVideoPromo?.getVideoPromoWithOrgId.find((x:any) => x.id === value);
                    return (
                      promo.expired || promo.pointsAvailable == 0 ? "" :
                          <TwitterShareButton
                            url={'#'}
                            options={{ text: twitterMessage("Video Quiz Promotion", orgName), hashtags:twitterHashTag("Video") }}
                        />

                  )
                  }

            }
          },
    ];

    const columnsBillboard:any = [
        {
          name:"billboardCode",
          options:{
              filter:false,
              sortThirdClickReset: true,
              sort: true,
              customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                let orderName = (sortOrder.name == "billboardCode")?sortOrder.direction:"";
                return(
                <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={0} onClick={() => updateDirection(0)} >
                  <a className="sort-by">Bill Board Code</a>
                </th>
                )
              },
              customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                  let billboard = dataBillBoard && dataBillBoard?.getAllBillboardsByOrg.find((x:any) => x.billboardCode === value);
                  return (<label onClick={() => handleEditClickOpen(billboard.id,"BillBoard")} className="promocode pointer">{value ? value : (code + billboard.billboardCodePrefix)}</label>)
                }
          }
        },
        {
            name:"sponsorLogo",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "sponsorLogo")?sortOrder.direction:"";
                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(1)} >
                    <a className="sort-by">Sponsors</a>
                  </th>
                  )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let billboard = dataBillBoard && dataBillBoard?.getAllBillboardsByOrg.find((x:any) => x.sponsorLogo === value);
                    return (<label onClick={() => handleEditClickOpen(billboard.id,"BillBoard")} ><img className="profile-img promo-list" src={value || "https://us.v-cdn.net/6022045/uploads/defaultavatar.png"} alt="sponsor logo" /></label>)
                  }
            }
          },
          {
            name:"pointsAvailable",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "pointsAvailable")?sortOrder.direction:"";
                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(2)} >
                    <a className="sort-by">Availability/Total</a>
                  </th>
                  )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let billboard = dataBillBoard && dataBillBoard?.getAllBillboardsByOrg.find((x:any) => x.pointsAvailable === value);
                    return (<label onClick={() => handleEditClickOpen(billboard.id,"BillBoard")} >{ formatCurrency(billboard.pointsAvailable,opt) +"/"+formatCurrency(billboard.noPointsPerBillboard*billboard.billboardCodePoints,opt)}</label>)
                  }
            }
          },
          {
            name:"createdAt",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "createdAt")?sortOrder.direction:"";
                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={3} onClick={() => updateDirection(3)} >
                    <a className="sort-by">Creation Date</a>
                  </th>
                  )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let billboard = dataBillBoard && dataBillBoard?.getAllBillboardsByOrg.find((x:any) => x.createdAt === value);
                    return (<label onClick={() => handleEditClickOpen(billboard.id,"BillBoard")} >{moment(parseInt(billboard.createdAt)).format('MM/DD/YYYY')}</label>)
                  }
            }
          },
          {
            name:"validity",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "validity")?sortOrder.direction:"";
                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={4} onClick={() => updateDirection(4)} >
                    <a className="sort-by">Validity Date</a>
                  </th>
                  )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let billboard = dataBillBoard && dataBillBoard?.getAllBillboardsByOrg.find((x:any) => x.pointsAvailable === value);
                    return (<label onClick={() => handleEditClickOpen(billboard.id,"BillBoard")}>{value}</label>)
                  }
            }
          },
          {
            name:"id",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "id")?sortOrder.direction:"";
                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={5} onClick={() => updateDirection(5)} >
                    <a className="sort-by">Status</a>
                  </th>
                  )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let billboard = dataBillBoard && dataBillBoard?.getAllBillboardsByOrg.find((x:any) => x.id === value);
                    let expired = (new Date().setHours(0,0,0,0)<=new Date(billboard.validity).setHours(0,0,0,0))?true:false;
                    return (<div onClick={() => handleEditClickOpen(billboard.id,"BillBoard")}>{(expired && billboard.pointsAvailable !== 0)?(<label className="green">Active</label>):(<label className="red">Expired</label>)}</div> )
                  }
            }
          },
          {
            name:"id",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "id")?sortOrder.direction:"";
                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={6}  >
                  </th>
                  )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    return (<label onClick={() => handleCopyPromo(value, "BillBoard")} className="btn-copy">Copy</label>)
                  }
            }
          },
          {
            name:"id",
            options:{
                filter:false,
                sortThirdClickReset: false,
                sort: false,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "id")?sortOrder.direction:"";
                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={8} >
                  </th>
                  )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                  let billboard = dataBillBoard && dataBillBoard?.getAllBillboardsByOrg.find((x:any) => x.id === value);
                  let expired = (new Date().setHours(0,0,0,0)<=new Date(billboard.validity).setHours(0,0,0,0))?true:false;
                    return (
                      expired && billboard.pointsAvailable !== 0 ?
                        <TwitterShareButton
                          url={'#'}
                          options={{ text: twitterMessage("Bill board Mode", orgName), hashtags:twitterHashTag("BillBoard") }}
                        />
                      : ""
                    )
                  }

            }
          },
    ];

    const columnsAll:any = [
        {
          name:"promoCode",
          options:{
              filter:false,
              sortThirdClickReset: true,
              sort: true,
              customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                let orderName = (sortOrder.name == "promoCode")?sortOrder.direction:"";
                return(
                <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={0} onClick={() => updateDirection(0)} >
                  <a className="sort-by">Promo Code</a>
                </th>
                )
              },
              customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                  let promo = allPromos.find((x:any) => x.promoCode === value);
                  return (<label onClick={() => handleEditClickOpen(promo.id,promo.promoTypeCustom)} className="promocode pointer">{value ? value : (code + promo.promoCode)}</label>)
                }
          }
        },
        {
            name:"promoType",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "promoType")?sortOrder.direction:"";
                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(1)} >
                    <a className="sort-by">Promo Type</a>
                  </th>
                  )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let promo = allPromos.find((x:any) => x.promoType === value);
                    return (<label onClick={() => handleEditClickOpen(promo.id,promo.promoTypeCustom)} >{value}</label>)
                  }
            }
          },
          {
            name:"sponsorLogo",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "sponsorLogo")?sortOrder.direction:"";
                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(2)} >
                    <a className="sort-by">Sponsors</a>
                  </th>
                  )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let promo = allPromos.find((x:any) => x.sponsorLogo === value);
                    return (<label onClick={() => handleEditClickOpen(promo.id,promo.promoTypeCustom)} ><img className="profile-img promo-list" src={value || "https://us.v-cdn.net/6022045/uploads/defaultavatar.png"} alt="sponsor logo" /></label>)
                  }
            }
          },
          {
            name:"noPointsPerPromo",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "noPointsPerPromo")?sortOrder.direction:"";
                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={3} onClick={() => updateDirection(3)} >
                    <a className="sort-by">{hasToken === "true" ? 'Tokens' : 'Points'} per promo</a>
                  </th>
                  )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                     let promo = allPromos.find((x:any) => x.noPointsPerPromo === value);
                    return (<label onClick={() => handleEditClickOpen(promo.id,promo.promoTypeCustom)} >{`${tableMeta.tableData[tableMeta.rowIndex].noPointsPerPromo}`}</label>)
                  }
            }
          },
          {
            name:"pointsAvailable",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "pointsAvailable")?sortOrder.direction:"";
                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={4} onClick={() => updateDirection(4)} >
                    <a className="sort-by">Availability/Total</a>
                  </th>
                  )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let promo = allPromos.find((x:any) => x.pointsAvailable === value);
                    return (<label onClick={() => handleEditClickOpen(promo.id,promo.promoTypeCustom)} >{ formatCurrency(promo.pointsAvailable,opt) +"/"+formatCurrency(promo.totalPoints,opt)}</label>)
                  }
            }
          },
          {
            name:"createdAt",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "createdAt")?sortOrder.direction:"";
                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={5} onClick={() => updateDirection(5)} >
                    <a className="sort-by">Creation Date</a>
                  </th>
                  )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let promo = allPromos.find((x:any) => x.createdAt === value);
                    return (<label onClick={() => handleEditClickOpen(promo.id,promo.promoTypeCustom)} >{moment(parseInt(promo.createdAt)).format('MM/DD/YYYY')}</label>)
                  }
            }
          },
          {
            name:"validity",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "validity")?sortOrder.direction:"";
                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={6} onClick={() => updateDirection(6)} >
                    <a className="sort-by">Validity Date</a>
                  </th>
                  )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let promo = allPromos.find((x:any) => x.validity === value);
                    return (<label onClick={() => handleEditClickOpen(promo.id,promo.promoTypeCustom)} >{value}</label>)
                  }
            }
          },
          {
            name:"id",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "id")?sortOrder.direction:"";
                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={7} onClick={() => updateDirection(7)} >
                    <a className="sort-by">Status</a>
                  </th>
                  )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let promo = allPromos.find((x:any) => x.id === value);
                    let expired = (new Date().setHours(0,0,0,0)<new Date(promo.validity).setHours(0,0,0,0))?true:false;
                    return (<div onClick={() => handleEditClickOpen(promo.id,promo.promoTypeCustom)} >{(expired && promo.pointsAvailable !== 0)?(<label className="green">Active</label>):(<label className="red">Expired</label>)} </div>)
                  }
            }
          },
          {
            name:"id",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "id")?sortOrder.direction:"";
                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={8}>
                  </th>
                  )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let promo = allPromos.find((x:any) => x.id === value);
                    return (<label className="btn-copy" onClick={() => handleCopyPromo(promo.id,promo.promoTypeCustom)} > Copy</label>)
                  }
            }
          },
    ];

    const options:any = {
    filter: false,
    filterType: "dropdown",
    responsive:"standard",
    print:false,
    download : false,
    pagination : false,
    serverSide: false,
    viewColumns : false,
    selectableRowsHideCheckboxes: true,
    fixedSelectColumn: false,
    selectToolbarPlacement: "none",
    selectableRowsHeader: false,
    selectableRows: "none",
    selectableRowsOnClick: false,
    fixedHeader : true,
    tableBodyHeight:"450px",
    search:false,
    }
    return (
        <div className="list-page transaction-list-wrapper">
            <Grid container xs={12} sm={12} spacing={2}>
                <Grid  item sm={12} xs={12}>
                    <Paper className="table-wrapper">
                        {/* Table Header Block */}
                        <Grid container className="table-head-section p-relative" sm={12}>
                            <AppBar className="pad0 header-bar" position="static">
                                <Tabs value={socialTab} onChange={handleChangeSocial} aria-label="simple tabs example">
                                    <Tab label="ALL" {...socialProps(0)} />
                                    <Tab label="EMAIL" {...socialProps(1)} />
                                    <Tab label="AR HUNTS" {...socialProps(2)} />
                                    <Tab label="VIDEO QUIZ" {...socialProps(3)} />
                                    <Tab label="VIRTUAL BILLBOARD" {...socialProps(4)} />
                                    <Tab label="POI" {...socialProps(5)} />
                                </Tabs>
                            </AppBar>
                            <Grid  item sm={4} className="select-with-label align-right flx-align-center flx-end list-sidebar" >
                                {/* <label className="select-label">Sort By</label>
                                <DropdownSelect/> */}
                                <button onClick={handleClickOpen} data-view="Create" className="btn btn-primary add-btn"><span data-view="Create" className="btn-icon"></span><label className="btn-caption">Add Promotion</label></button>
                            </Grid>
                        </Grid>

                         {/* All Block */}
                         <TabPanel value={socialTab} index={0}>

                            <div className="table-container muidata-table pad0">
                                {loading
                                    ? <Loader size="90px"/>
                                    : <MUIDataTable title={""} data={allPromos} columns={columnsAll} options={options} />
                                }
                            </div>
                        </TabPanel>

                        {/* Email/InApp Block */}
                        <TabPanel value={socialTab} index={1}>
                            <div className="table-container muidata-table pad0">
                                {loading
                                    ? <Loader size="90px"/>
                                    : <MUIDataTable title={""} data={data?.getPromoWithOrgId} columns={columnsEmail} options={options} />
                                }
                            </div>
                        </TabPanel>

                        {/* Hunt Block */}
                        <TabPanel value={socialTab} index={2}>
                            <div className="table-container muidata-table pad0">
                                {loadingHunt
                                    ? <Loader size="90px"/>
                                    : <MUIDataTable title={""} data={dataHunt?.getAllHuntsByOrg} columns={columnsHunt} options={options} />
                                }
                            </div>
                        </TabPanel>

                        {/* Video Block */}
                        <TabPanel value={socialTab} index={3}>
                            <div className="table-container muidata-table pad0">
                                {loadingVideoPromo
                                    ? <Loader size="90px"/>
                                    : <MUIDataTable title={""} data={dataVideoPromo?.getVideoPromoWithOrgId} columns={columnsVideo} options={options} />
                                }
                            </div>
                        </TabPanel>

                        {/* Bill Board Block */}
                        <TabPanel value={socialTab} index={4}>
                                <div className="table-container muidata-table pad0">
                                {loadingBillBoard
                                    ? <Loader size="90px"/>
                                    : <MUIDataTable title={""} data={dataBillBoard?.getAllBillboardsByOrg} columns={columnsBillboard} options={options} />
                                }
                            </div>
                        </TabPanel>

                        {/* POI Block */}
                        <TabPanel value={socialTab} index={5}>
                                <div className="table-container muidata-table pad0">
                                {loadingPoi
                                    ? <Loader size="90px"/>
                                    : <MUIDataTable title={""} data={dataPoi?.getAllPoiByOrg} columns={columnsPoi} options={options} />
                                }
                            </div>
                        </TabPanel>
                    </Paper>
                </Grid>
            </Grid>

            <CreatePromotions onOpen ={open} promoId= {promotionId} promotionHuntType={promotionType} code={code} handleCloseClick={(index:any) => handleClose(index)} copyPromoId={copyPromoId} copyPromo={copyPromo} currentTab={socialTab} />
        </div>
    )
}
