import React ,{useState,useEffect}from 'react'
import { useHistory } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import ReactDatePicker from "react-datepicker";
import '../../constant.css';
import '../manageclubs/manageclubs.css';
import { useQuery, gql, useMutation } from '@apollo/client';
import AWS from 'aws-sdk';
import { Backdrop, CircularProgress, MenuItem, Select, Snackbar, Typography } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

//import OrgImage from '../../../assets/Bitmap@2x.png';
interface Props {
    onOpen : boolean,
    handleCloseClick: () => void,
    memberId:string
}

interface IFormAddClub {
    
    // firstName: string;
    // lastName: string;
    fullName: string
    phoneNumber: string;
    address: string;
    // dob : string;
    email : string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
}

let defaultValues:any = {
    // firstName: "",
    // lastName: "",
    fullName: "",
    phoneNumber: "",
    address:"",
    // dob:"10/10/2200",
    email:"",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: ""
    
  };

  const GET_USER_BY_ID = gql`
  query getUsers($username: String!) {
    getUserWithIdAdmin(username: $username) {
        id
        username
        givenName
        nickName
        email
        dob
        address
        phoneNumber
        isActive

    }
  }
`;

const UPDATE_MEMBER = gql`
mutation updateUserDetails($username : String!,$data: UpdatedUserInput! ){
    updateUserDetails(username: $username, data: $data ){
        username
    }
   }`;

const DEACTIVATE_MEMBER = gql`
   mutation activateDeactiveUser($username : String!,$data: UpdatedUserInput! ){
    activateDeactiveUser(username: $username, data: $data ){
           username
       }
      }`;

export default function EditMemberProfile({handleCloseClick,onOpen,memberId}:Props) {
    const userId = memberId?memberId:"";
    const openState = onOpen;
    const { loading, data,refetch } = useQuery<any>(GET_USER_BY_ID, { variables: { username: userId } });
    const history = useHistory();
    const backClick = () => history.push('/managemembers');
    const [open, setOpen] = React.useState(false);
    // const [isValid, setIsValid] = React.useState(true);

    // const [dobValidate,setDobValidate] = React.useState<any>(null);
    const [isActiveStatus, setIsActiveStatus] = React.useState(true);
    const [errorMsg, setErrorMsg] = useState<any>("");
    const [openToast, setOpenToast] = React.useState<any>(false);
    const { handleSubmit, control,setValue, reset,register ,errors,clearErrors} = useForm<IFormAddClub>({defaultValues,shouldUnregister:false});
    const Alert = (props: AlertProps) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    const handleClosed = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenToast(false);
    };
    useEffect(() => {
       //setTimeout(() => { 
        if(!loading){
            clearErrors();
            setIsActiveStatus(data.getUserWithIdAdmin.isActive);
            //setMemberDeails(data.getMemberWithId);
            const { givenName,email,phoneNumber,dob,address } = data.getUserWithIdAdmin;
            defaultValues = {givenName,email,phoneNumber,dob,address};
            // let dateConversion = moment(defaultValues.dob).format("YYYY-MM-DD");
            let userName:string = defaultValues.givenName; 
            // setValue("firstName",userName.split("&")[0]);
            // setValue("lastName",userName.split("&")[1]);
            setValue("fullName", userName.replace("&", " "));
            setValue("phoneNumber", defaultValues.phoneNumber ? defaultValues.phoneNumber : '');
            setValue("email",defaultValues.email);
            // setValue("dob", dateConversion);
            // setValue("address",defaultValues.address);

            if (defaultValues.address) {
                let userAddress = JSON.parse(defaultValues.address);
                // userAddress = userAddress.address1 + ', ' + userAddress.address2 + ', ' + userAddress.address3 + ', ' + userAddress.address4 + '-' + userAddress.address5
                setValue("address1", userAddress.address1);
                setValue("address2", userAddress.address2);
                setValue("city", userAddress.address3);
                setValue("state", userAddress.address4);
                setValue("zipCode", userAddress.address5);
            }
        }
    //})
    },[data,openState,setValue])

    const [updateUserDetails] = useMutation(UPDATE_MEMBER,{ onCompleted : () => { 
        history.push({ pathname: '/memberprofile', state: userId }); 
        handleCloseClick();
    }});

    const [activeDeactive] = useMutation(DEACTIVATE_MEMBER,{ onCompleted : () => { 
        history.push({ pathname: '/memberprofile', state: userId }); 
        handleCloseClick();
    }});

    const onSubmit: SubmitHandler<IFormAddClub> = data => {
        // if (isValid) {
            if (userId) {
                if(data.phoneNumber) {
                    // let pattern = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
                    let pattern = /^(\+\d{1,2}\s?)(\d{10})$/
                    let result = pattern.test(data.phoneNumber);
                    if(!result) {
                        setErrorMsg('Please enter valid phone number');
                        setOpenToast(true);
                        return;
                    }
                }
                let userAddress:any = {
                    address1: data.address1, 
                    address2: data.address2,
                    address3: data.city,
                    address4: data.state, 
                    address5: data.zipCode
                };

                updateUserDetails({
                    variables : { 
                        username : userId,
                        data : { 
                            givenName : data.fullName,
                            address : JSON.stringify(userAddress),
                            username:userId,
                            email : data.email,
                            // dob :new Date(data.dob),
                            phoneNumber : data.phoneNumber
                        }
                    }
                })
            }
        // } else {
        //     setDobValidate("Dob should not greater than");
        // }
    }

    const handledeactiveClick = () => {    
        if(userId){
            activeDeactive({
                variables : 
                { username : userId,
                    data : 
                    { 
                        isActive : !isActiveStatus
                    }
                }
            });
        }
    }
    
    // const handleChangeField = (e: React.ChangeEvent<any>) => {
    //     const targetValue = (e.target as HTMLInputElement).value;
    //     const targetName = (e.target as HTMLInputElement).name;
    //     setValue(targetName, targetValue);
    //     const currentDate = new Date().setHours(0,0,0);
    //     const selectedDate = new Date(targetValue).setHours(0,0,0);
    //     if(selectedDate >= currentDate){
    //         setDobValidate("Dob should not greater than");
    //         setIsValid(false);
    //     }else{
    //         setIsValid(true);
    //         setDobValidate(null);
    //     }
        
    // }

    // const handleChangeFieldPhone = (e: React.ChangeEvent<any>) => {
    //     const targetValue:string = (e.target as HTMLInputElement).value;
    //     const targetName = (e.target as HTMLInputElement).name;
    //     const re =  /^[0-9-+]/;
    //     if (targetValue === '' || targetValue.match(re)) {
    //         setValue(targetName, targetValue);
    //     }
    // }

    return (
        <Dialog open={onOpen} onClose={handleCloseClick} aria-labelledby="form-dialog-title" className="common-dialog-container member-container">
            <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <h5 id="form-dialog-title" className="section-heading">Edit Member</h5>
                    <Grid className="form-grid" container sm={12} spacing={2}>
                        <Grid item sm={6} xs={12}>
                            <div className="input-group">
                                <label className="input-label">Full Name</label>
                                <Controller as={TextField} type="text" name="fullName" defaultValue="" placeholder="Full Name" className="form-fields" control={control}/>                            
                            </div>
                            
                            {/* <div className="input-group">
                                <label className="input-label" >Contact Phone</label>
                                <Controller as={TextField} type="text" defaultValue="" name="phoneNumber" placeholder="Phone number" className={`form-fields  ${errors.phoneNumber && 'error'}`} control={control}  />    
                                <Controller as={TextField} type="text" rules={{ pattern:/[0-9]/ }} defaultValue="" name="phoneNumber" placeholder="Phone number" className={`form-fields  ${errors.phoneNumber && 'error'}`} control={control}  />                            
                                {errors.phoneNumber && errors.phoneNumber.type === "pattern" && (<label className="error-message">Please enter valid contact number</label>)}
                                {errors.phoneNumber && errors.phoneNumber.type === "validate" && (<label className="error-message">Number should be Min 5 and Max 13 Characters</label>)}
                                {errors.phoneNumber && errors.phoneNumber.type === "required" && (<label className="error-message">Field is required</label>)}
                            </div> */}

                            <div className="input-group">
                                <label className="input-label">Contact Phone</label>
                                <Controller as={TextField} type="text" name="phoneNumber" placeholder="Phone Number" className={`form-fields ${errors.phoneNumber && 'error'}`} control={control} defaultValue="" />
                            </div>

                            <div className="input-group textarea-group">
                                <label className="input-label" >Address</label>
                                <Controller as={TextField} type="text" name="address1" defaultValue="" placeholder="Address1" className="form-fields ptb-15" control={control}/>                            
                                <Controller as={TextField} type="text" name="address2" defaultValue="" placeholder="Address2" className="form-fields ptb-15" control={control}/>                            
                                <Controller as={TextField} type="text" name="city" defaultValue="" placeholder="City" className="form-fields ptb-15" control={control}/>                            
                                <Controller as={TextField} type="text" name="state" defaultValue="" placeholder="State" className="form-fields ptb-15" control={control}/>                            
                                <Controller as={TextField} type="text" name="zipCode" defaultValue="" placeholder="Zip Code" className="form-fields ptb-15" control={control}/>                            

                                {/* <Controller as={TextareaAutosize} aria-label="minimum height" defaultValue="" rowsMin={3} placeholder="Address" name="address"  className="form-fields textarea-field MuiInputBase-input MuiInput-input" control={control}   />                             */}
                            </div>

                        </Grid>

                        <Grid item className="padr0" sm={6} xs={12}>  
                            {/* <div className="input-group">
                                <label className="input-label" >DOB</label>
                                <Controller  type="date"  name="dob"  control={control} placeholder="yyyy-mm-dd" render = { ({value}) => ( <TextField type="date" name="dob" value={value}  className={`form-fields  ${errors.dob && 'error'}`}   onChange={(e) => handleChangeField(e)}   />)}  />                            
                                {dobValidate && (<label className="error-message">{dobValidate}</label>)}
                            </div> */}
                            <div className="input-group">
                                <label className="input-label"> Contact Email ID</label>
                                <Controller as={TextField}  type="text" disabled name="email" defaultValue="" placeholder="example@gmail.com" className="form-fields" control={control}  />                            
                            </div>
                            {/* <div className="input-group">
                                <label className="input-label" >Last Name</label>
                                <Controller as={TextField}  type="text" name="lastName" defaultValue="" placeholder="Full Name" className="form-fields" control={control}/>                            
                            </div>                  */}
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions className="edit-member-profile-actions">
                    <Button className="btn btn-secondary btn-delete display-none" color="primary">DELETE</Button>
                    <Button className="btn btn-secondary btn-delete" color="primary" onClick={handledeactiveClick}>{isActiveStatus ? "DEACTIVATE":"ACTIVATE"}</Button>
                    <Button className="btn btn-secondary" color="primary" onClick={handleCloseClick}>CANCEL</Button>
                    <Button className="btn btn-primary button" color="primary" onClick={handleSubmit(onSubmit)}>UPDATE</Button>
                </DialogActions>
            </form>
            <Snackbar open={openToast} autoHideDuration={5000} onClose={handleClosed}>
                    <Alert onClose={handleClosed} severity="error">{errorMsg}</Alert>
            </Snackbar>

        </Dialog>
    )
}
