import React,{useEffect} from 'react';
import { useHistory ,useParams} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { useQuery, gql } from '@apollo/client';
import Loader from '../../components/Loader/loader';
import moment from 'moment';
import MUIDataTable from "mui-datatables";


import DropdownSelect from '../../components/Dropdown/dropdownselect';
import Button from "@material-ui/core/Button";
import '../../constant.css';
import '../transaction/transactions.css';


  interface pointHoldersInventory {
    id: string;

}

interface pointHoldersInventoryData {
    pointHoldersInventory: pointHoldersInventory[];
}

interface pointHoldersInventoryVars {
    year: number;
}
interface ParamTypes {
    orgId: string;
}

export const GET_POINTHOLDERS_QUERY = gql`
 query getPointHolderLists($clubId : String!,$offset:Int,$limit:Int,$sort:FieldSort){
  getPointsHolders(clubId: $clubId,offset:$offset,limit:$limit,sort:$sort){
    givenName
    email
    walletId
    profilePicture
    walletBalance
    totalPoints
    walletId
    createdAt
    __typename
    userCount
  }
}
`;

// export const GET_POINTHOLDERS_QUERY = gql`
// query getPointHolderLists($clubId : String!){
//     getPointsHolders(clubId: $clubId){
//       givenName
//       email
//       walletId
//       profilePicture
//       walletBalance
//       totalPoints
//       walletId
//       createdAt
//       tdc_patronid
//       tdc_walletbalance
//     }
//    }
// `;


export default function PointsHolderList() {

        const history = useHistory();
        const memberprofileClick = (userId:string,walletPoints:number) => {
            history.push({pathname:'/memberprofile',state:{userId: userId, walletbalance: walletPoints}});
        }
        const hasToken = localStorage.getItem('org_has_token');
       const columns:any = [
        {
            name:"givenName",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "givenName")?sortOrder.direction:"";
                    return(
                    <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={0} onClick={() => updateDirection(0)} >
                      <a className="sort-by">{ hasToken === 'true' ? 'Token Holders' :'Point Holders'}</a>
                    </th>
                    )
                },
                // customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                //     let fieldValues = JSON.parse(value);
                //     let userName:string = fieldValues.givenName;
                //     return (<div className="pointer" onClick={() => memberprofileClick(fieldValues.username,fieldValues.walletPoints)}><img className='profile-img pointer' src={fieldValues.profileImage ||  "https://png.pngtree.com/png-vector/20190321/ourmid/pngtree-vector-users-icon-png-image_856952.jpg"} /><label className='membername pointer'>{userName.replace("&"," ")}</label></div>)
                // }
            }
        },
        {
            name:"email",
            options:{
                filter:false,
                sortThirdClickReset: true,

                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "email")?sortOrder.direction:"";
                    return(
                    <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(1)} >
                      <a className="sort-by">Email Id</a>
                    </th>
                    )
                }
            }
        },
        // {
        //      name:"phoneNumber",
        //      options:{
        //         filter:false,
        //         sortThirdClickReset: true,

        //         customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
        //             let orderName = (sortOrder.name == "phoneNumber")?sortOrder.direction:"";

        //             return(
        //             <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(2)} >
        //               <a className="sort-by">Phone Number</a>
        //             </th>
        //             )
        //         }
        //      }
        // },
        // {
        //     name:"tdc_patronid",
        //     options:{
        //         filter:false,
        //         sortThirdClickReset: true,

        //         customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
        //             let orderName = (sortOrder.name == "tdc_patronid")?sortOrder.direction:"";

        //             return(
        //             <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={3} onClick={() => updateDirection(3)} >
        //               <a className="sort-by">TDC Patron Id</a>
        //             </th>
        //             )
        //         }
        //     }
        // },
        // {
        //     name:"tdc_walletbalance",
        //     options:{
        //         filter:false,
        //         sortThirdClickReset: true,

        //         customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
        //             let orderName = (sortOrder.name == "tdc_walletbalance")?sortOrder.direction:"";

        //             return(
        //             <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={3} onClick={() => updateDirection(3)} >
        //               <a className="sort-by">TDC Wallet Balance</a>
        //             </th>
        //             )
        //         }
        //     }
        // },
        {
            name:"walletId",
            options:{
                filter:false,
                sortThirdClickReset: true,

                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "walletId")?sortOrder.direction:"";

                    return(
                    <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(2)} >
                      <a className="sort-by">Wallet ID</a>
                    </th>
                    )
                }
            }
         },
         {
            name:"totalPoints",
            label:"Points",
            options:{
                filter:false,
                sortThirdClickReset: true,
                // sort: false,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "totalPoints")?sortOrder.direction:"";

                    return(
                    <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={3} onClick={() => updateDirection(3)} >
                      <a className="sort-by">{hasToken === 'true' ? 'Tokens' :'Points'}</a>
                    </th>
                    )
                }
            }
        },
        // {
        //     name:"balance",
        //     options:{
        //         filter:false,

        //         sortThirdClickReset: true,

        //         customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
        //             let orderName = (sortOrder.name == "balance")?sortOrder.direction:"";

        //             return(
        //             <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={5} onClick={() => updateDirection(5)} >
        //               <a className="sort-by">$ Value</a>
        //             </th>
        //             )
        //         },
        //         customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
        //             return (formatter.format(value))
        //         }
        //     }

        // },
        {
            name: 'createdAt',
            label:"Member Since",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "createdAt")?sortOrder.direction:"";

                    return(
                    <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={4} onClick={() => updateDirection(4)} >
                      <a className="sort-by">Member Since</a>
                    </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    return (<div><label className="membername">{moment(parseInt(value)).format('DD MMM YYYY HH:mm')}</label></div>)
                }
            }
        },
        // {
        //     name: 'isActive',
        //     options:{
        //         filter:false,
        //         sortThirdClickReset: true,
        //         customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
        //             let orderName = (sortOrder.name == "isActive")?sortOrder.direction:"";
        //             return(
        //             <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={7} onClick={() => updateDirection(7)} >
        //               <a className="sort-by">Status</a>
        //             </th>
        //             )
        //         },
        //         customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
        //         return (<div><label className="membername">{value ? "Active":"InActive"}</label></div>)
        //         }
        //     }
        // }
    ];

  // function changePage = (tableState:any) => {
  //   // this.setState({
  //   //   isLoading: true,
  //   // });
  //   const {refetch, loading, data } = useQuery<any>(
  //      GET_POINTHOLDERS_QUERY,{variables : {clubId : orgId, offset: tableState.page * tableState.rowsPerPage, limit: tableState.rowsPerPage}}
  //   );
  //   // this.xhrRequest(`/myApiServer?page=${page}`, page, sortOrder).then(res => {
  //   //   this.setState({
  //   //     isLoading: false,
  //   //     page: res.page,
  //   //     sortOrder,
  //   //     data: res.data,
  //   //     count: res.total,
  //   //   });
  //   // });
  // };




    let { orgId } = useParams<ParamTypes>();

    let offset = 0;
    let limit = 10;

    const {refetch, loading, data } = useQuery<any>(
        GET_POINTHOLDERS_QUERY,{variables : {clubId : orgId, offset: offset, limit: limit}}
    );


// let count = 20

    const options:any = {
        filter: false,
        count: data && data.getPointsHolders && data.getPointsHolders[0] && data.getPointsHolders[0].userCount ? data.getPointsHolders[0].userCount : 10,
        filterType: "dropdown",
        responsive:"standard",
        print:false,
        download : false,
        pagination : true,
        serverSide: true,
        onTableChange: (action:any, tableState:any) => {

        switch (action) {
          case 'changePage':
            refetch({offset: tableState.page * tableState.rowsPerPage, limit: tableState.rowsPerPage})
            // count = data && data.getPointsHolders && data.getPointsHolders[0] && data.getPointsHolders[0].userCount ? data.getPointsHolders[0].userCount : 10;
            // changePage(tableState);
            break;
          case 'changeRowsPerPage':
            refetch({offset: 0, limit: tableState.rowsPerPage})
            break;
          case 'sort':
            if (tableState.sortOrder.direction !== 'none')
            {
                refetch({sort: tableState.sortOrder})
            }
            else
            {
                refetch({sort: null})
            }
            // this.sort(tableState.page, tableState.sortOrder);
            // count = data && data.getPointsHolders && data.getPointsHolders[0] && data.getPointsHolders[0].userCount ? data.getPointsHolders[0].userCount : 10;

            break;
          default:
            console.log('action not handled.');
        }

            //     let {refetch, loading, data } = useQuery<any>(
            //     GET_POINTHOLDERS_QUERY,{variables : {clubId : orgId, offset: offset, limit: limit}}
            // );

        // this.xhrRequest('my.api.com/tableData', result => {
        //   this.setState({ data: result });
        },
        viewColumns : false,
        selectableRowsHideCheckboxes: true,
        fixedSelectColumn: false,
        selectToolbarPlacement: "none",
        selectableRowsHeader: false,
        selectableRows: "none",
        selectableRowsOnClick: false,
        fixedHeader : true,
        tableBodyHeight:'450px',
        search:false,
    }


    // useEffect(() => {
    //     refetch();
    // },[data]);
    return (
    <div className="list-page transaction-list-wrapper">
        <Grid container xs={12} sm={12}>
                <Grid  item sm={12} xs={12}>
                    <Paper className="table-wrapper">
                    <Grid container className="table-head-section" sm={12}>
                        <Grid item sm={8}>
                            <label className="table-heading">{ hasToken === 'true' ? 'TOKEN HOLDER LIST' : 'POINT HOLDER LIST' }</label>
                        </Grid>
                        <Grid  item sm={4} className="select-with-label align-right flx-align-center flx-end">
                            {/* <label className="select-label">Sort By</label> */}
                            {/* <DropdownSelect/> */}
                            <button className="btn btn-primary add-btn display-none"><span className="btn-icon"></span><label className="btn-caption"> Add Org</label></button>
                        </Grid>
                    </Grid>
                <Grid  item sm={12} xs={12}>
                    {/* <Paper className="table-wrapper"> */}
                        <div className="table-container muidata-table">
                            {loading
                                ? <Loader size="90px"/>
                                : <MUIDataTable title={""} data={data.getPointsHolders} columns={columns} options={options} />
                            }
                        </div>
                    {/* </Paper>     */}
                </Grid>
                </Paper>
            </Grid>
        </Grid>
    </div>
    )
}
