import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";
import { useQuery, gql } from '@apollo/client';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./checkoutform";

const stripePromise = loadStripe('pk_test_51IuWeqSHIIXoToShqI8ES4LkMtLwhuUo0jyQFMLd8Slwh3UYFJn6S9NFgKA8Q3NjtMDjgBsSLdL1oGTVzVNxVtZm00VzeGTdxz');
// const GET_CLUB_INVENTORY = gql`
//   query GetClubInventory {
//     getPromo{
//       id
//       promocode
//     }
//   }
// `;

export default function Checkout() {
    // const { loading, data } = useQuery<any>(
    //     GET_CLUB_INVENTORY
    // );
    // const classes = useStyles();
    // const { loading, data } = useQuery<ClubInventoryData[]>(
    //     GET_CLUB_INVENTORY
    //   );



    return (
        <div className="details-page dashboard-details-page">
            <h2 className="page-title padd-left8">DashBoard</h2>
            <div className="dashboard-container">
                <Grid container>

                    <Elements stripe={stripePromise}>
                        <CheckoutForm />
                    </Elements>

                </Grid>
            </div>
        </div>
    )
}