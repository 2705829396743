

import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import "./settingorg.css";
import WhiteLabel from "./whiteLabel";
import WidgetSetting from "./widgetsetting";
import ExternalApi from './externalapi/externalApi';
import { Label } from "@material-ui/icons";

const useStyles = makeStyles({
  tabs: {
    "& .MuiTab-root.Mui-selected": {
      color: "red",
      backgroundColor: "red",
    },
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function SettingsOrg() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const classes = useStyles();

  return (
    <div className="list-page transaction-list-wrapper">
      <Grid container xs={12} sm={12}>
        <Grid item sm={12} xs={12}>
          <Paper className="table-wrapper">
            <Grid container className="table-head-section" sm={12}>
              <Grid item sm={8}>
                <label className="table-heading">SETTINGS</label>
              </Grid>
            </Grid>
            <Grid item sm={12} xs={12}>
              <Box
                sx={{
                  flexGrow: 1,
                  bgcolor: "background.paper",
                  display: "flex",
                }}
              >
                <Tabs
                  orientation="vertical"
                  classes={classes}
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{
                    borderRight: 1,
                    borderColor: "divider",
                    minWidth: 190,

                    "& .MuiTabs-indicator": { display: "none" },
                    "& .MuiTab-root.Mui-selected": {
                      backgroundColor: "#2b2e4c",
                      borderRadius: "10px",
                      alignSelf: "flex-start",
                      // width: "96%",
                    },
                  }}
                >
                    {/* <Tab
                    label="Manage Members"
                    {...a11yProps(0)}
                    sx={{ alignSelf: "flex-start" }}
                    /> */}
                    
                    <Tab
                    label="Widget Installation"
                    {...a11yProps(0)}
                    sx={{ alignSelf: "flex-start" }}
                    />
                  <Tab
                    label="White Label Config"
                    {...a11yProps(1)}
                    sx={{ alignSelf: "flex-start" }}
                  />
                  {/* <Tab
                    label="Api Configuration"
                    {...a11yProps(2)}
                    sx={{ alignSelf: "flex-start" }} */}
                  />
                </Tabs>
                {/* <TabPanel value={value} index={0}>
                  Manage Members
                </TabPanel> */}
                <TabPanel value={value} index={0}>
                  <WidgetSetting />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <WhiteLabel />
                </TabPanel>
                {/* <TabPanel value={value} index={2}>
                <ExternalApi />                </TabPanel> */}
              </Box>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
