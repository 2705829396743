import React from 'react';
import { RouteProps,useLocation ,RouteComponentProps} from "react-router";

// import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
// import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
// import Grid from '@material-ui/core/Grid';
import DailyPOI from './dailyPOI'
import BillBoard from './billBoard'
import DailyHunt from './dailyHunt'
import RewardEngine from './rewardsEngine'
import DailyBillBoard from './dailyBillBoard';
import { useQuery, gql } from '@apollo/client';
import { useHistory } from "react-router-dom";

interface Props {
  onParentRender: () => void,
  refetch : () => void
  rate: any,
  code: any
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
interface LocationState extends RouteComponentProps {
  state ?: string;
}


function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="tabpanel-parent" >
          {children}
        </div>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const GET_ME = gql`
  query me {
    me {
      givenName
      userRole
    }
  }
`;

export default function RewardTabDetails() {
  const {state} = useLocation<LocationState>();
  // const hasToken = localStorage.getItem('org_has_token');
  const tabValue = (state)?1:0;
  const [value, setValue] = React.useState(tabValue);
  const history = useHistory();
  const { loading, data } = useQuery<any>(GET_ME, {fetchPolicy: "network-only"});
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  React.useEffect(()=> {
    if(data && data?.me && data?.me?.userRole && data?.me?.userRole !== "ADMIN") {
      history.goBack(); 
    }
  },[data])
  return (
    <div className="custom-tab org-info-main">
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Daily Hunt" {...a11yProps(0)} />
          <Tab label="Daily POI" {...a11yProps(1)} />
          <Tab label="Daily Billboard" {...a11yProps(2)} />
          <Tab label="Reward Engine" {...a11yProps(3)} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <DailyHunt/>
      </TabPanel>

      <TabPanel value={value} index={1}>
      <DailyPOI/>
      </TabPanel>

      <TabPanel value={value} index={2}>
      <DailyBillBoard/>
      </TabPanel>

      <TabPanel value={value} index={3}>
      <RewardEngine/>
      </TabPanel>
      
    </div>
  );
}
