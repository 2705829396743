import React, {useEffect,useState} from 'react';
import { useHistory } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import PointOverview from './pointsoverview';
import PromotionOverview from './promotionoverview'
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { useQuery, gql } from '@apollo/client';
import { CircularProgressbar,buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import './dashboard.css';
import NetworkManager from '../../utilities/NetworkManager';

interface ClubInventory {
    id: number;
    name: string;
  }
  
  interface ClubInventoryData {
    clubInventory: ClubInventory[];
  }
  
  interface ClubInventoryVars {
    year: number;
  }
  
  
  export const GET_ORGS_QUERY = gql`
  query GetClubInventory($orgStatus: String!, $orgTypes: String!) {
    orgsAllSponserShip(orgStatus: $orgStatus, orgTypes: $orgTypes){
    id
    id
    name
    rate
    orgCode
    logoUrl
    availablePoints
    totalPoints
    distributionPoints
    isActive
    isToken
    isMainnet
    blockChainType
  }
  }
  `;
export default function OrgOverview() {
    const history = useHistory();
    const mangeOrgOverView = () => history.push('/manageclubs');
    const [activeCount,setActiveCount] = useState<any>("0");
    const [inActiveCount,setInActiveCount] = useState<any>("0");
    const [chartValue,setChartValue] = useState<any>(100);
    const { refetch,loading, data } = useQuery<any>(
        GET_ORGS_QUERY,
        { variables: { orgStatus: "All", orgTypes: "All" } }
      );
    useEffect(() => {
        if(!loading){
            // const tamil = 100;
            var activeClub =  data.orgsAllSponserShip.filter(function(org :any) { return org.isActive == true;});
            setActiveCount(activeClub.length);
            var inActiveClub =  data.orgsAllSponserShip.filter(function(org :any) { return org.isActive == false;});
            setInActiveCount(inActiveClub.length);
            const active = activeClub.length * 100 / data.orgsAllSponserShip.length;
            const inactive = inActiveClub.length * 100 / data.orgsAllSponserShip.length;
            setChartValue(active);
            if(data.orgsAllSponserShip.length === 1 && data.orgsAllSponserShip[0].isToken == true) {
                const orgData = data.orgsAllSponserShip[0];
                getTokenID(orgData.isMainnet, orgData.id, orgData.blockChainType)      
            }
        }
    },[data]);

    const getTokenID = async (isMainnet:boolean, orgId:string, blockChainType:string) => {
        let blockchain_enviroment = isMainnet === true ? 'mainnet' : 'testnet';
        let dataToSend = {
            organization_id: orgId,
            blockchain_env: blockchain_enviroment
        }
        await NetworkManager.getTokenID('Ethereum', dataToSend).then((response: any) => {
            if (response) {
                if(!localStorage.getItem('isShowNotification')) {
                    let event:any = new Event('storage');
                    event.newValue = response?.has_token == false ? 'true' : 'false';
                    window.dispatchEvent(event);
                    localStorage.setItem('isShowNotification',response?.has_token == false ? 'true' : 'false');
                }
            }
        })
    }
    // var marvelHeroes =  heroes.filter(function(hero) {
    //     return hero.franchise == "test";
    // });
    return (
        <div  className="org-member-overview-report" >
            <Paper className=""> 
           <Grid container sm={12} spacing={2}>
               <Grid className="mobile-view" item md={4} sm={12} >
               <CircularProgressbar
                    value={chartValue}
                    styles={buildStyles({
                        textColor: "red",
                        pathColor: "#6DD400",
                        trailColor: "#FF6A6A"
                      })}
                    
                />
                    {/* { chartValue && (
                        <svg viewBox="0 0 36 36" className="circular-chart orange">
                            <path className="circle-bg"
                                d="M18 2.0845
                                a 15.9155 15.9155 0 0 1 0 31.831
                                a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <path className="circle"
                                stroke-dasharray="60,40"
                                d="M18 2.0845
                                a 15.9155 15.9155 0 0 1 0 31.831
                                a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                        </svg>
                    )
                    }    */}
               </Grid>
               <Grid container sm={8} xs={12} className="overview-chart-panel">
                   <label className="overview-title">ORGANIZATIONS</label>
                   <Grid item md={6} sm={12} xs={12}>
    <span className="data-bold green">{activeCount}</span><label className="sub-caption">Active</label>
                   </Grid>
                   <Grid item md={6} sm={12}>
    <span className="data-bold red">{inActiveCount}</span><label className="sub-caption">Inactive</label>
                   </Grid>
               </Grid>
           </Grid>
           <Grid container sm={12}>
               <Grid className="btn-section" item  xs={12}>
                        <Button onClick={mangeOrgOverView} className="btn" variant="contained">Manage</Button>
               </Grid>
           </Grid>
           </Paper>
        </div>
    )
}
