import React ,{useEffect, useState}from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  NavLink,
  useHistory
} from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import './App.css';
import './components/Dropdown/dropdownselect.css';
import PTVRouter from './router';
import ProfileImage from './assets/Combined Shape@2x.svg';
import { Auth }from 'aws-amplify';
import { AmplifyTheme } from 'aws-amplify-react';

// import { AmplifySignOut,AmplifyAuthenticator, AmplifySignUp, AmplifySignIn  } from '@aws-amplify/ui-react';
import { useQuery, gql } from '@apollo/client';
import AuthContainer from './AuthContainer';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '@material-ui/core';
import { config } from "./config";
import Notification from "./containers/Notification/Notification"
import { getTokens,onMessageListener } from "./firebase";
interface ClubInventory {
  id: number;
  name: string;
}

interface ClubInventoryData {
  clubInventory: ClubInventory[];
}

interface ClubInventoryVars {
  year: number;
}

const GET_CLUB_INVENTORY = gql`
  query GetClubInventory {
    orgsSponserShip{
      id
      name
    }
  }
`;

const GET_ME = gql`
  query me {
    me {
      givenName
      userRole
    }
  }
`;

const GET_USER_BY_ID = gql`
  query getUsers($username: String!) {
    getUserWithAccess(username: $username) {
        id
        username
        nickName
        givenName
        email
        profileImage
        dob
        address
        phoneNumber

    }
  }
`;
const themeStyle = {
  ...AmplifyTheme,
  signInButton: {
    color: "red",
    backgroundColor: "green"
  },
  container:{
    paddingLeft:"100px"
  }
}

function App() {

  const { loading, data } = useQuery<any>(GET_ME, {fetchPolicy: "network-only"});
  
  const history = useHistory();
  const [userData, setUserData] = React.useState<any>({});
  const [adminData, setadminData] = React.useState<any>({});
  const [errors, setErrors] = React.useState<any>();
  const [userState, setUserState] = useState(false);
  const [collapsedStatus, setCollapsedStatus] = useState(true);
  const [backIcon, setBackIcon] = useState("");

  const [collapsedClass, setcollapsedClass] = useState("");
  const [render, setRender] =  React.useState(false);
  const [open, setOpen] = React.useState(true);

  const [isTokenFound, setTokenFound] = useState(false);

  const { loading: userLoading, error, data: adminUserData, refetch: userRefetch } = useQuery<any>(GET_USER_BY_ID, { skip: userData.sub ? false: true, variables: { username: userData.sub } });

  const editProfileClick = (userId:any) => history.push({pathname:'/profiledetails', state: userId});

  const valuesUpdate = () => {
    setRender(true);
  }


  const handleClose = () => {
    setOpen(false);
    try {
      localStorage.removeItem("userId")
      Auth.signOut();
      setUserState(false);
      setErrors(undefined);
      history.push('/');
    } catch (error) {
    }
  };

  const signOutClick = () =>{
    try {
        localStorage.clear()
        Auth.signOut();
        setUserState(false);
        history.push('/');
    } catch (error) {
    }
  }

  const getBearerToken = () => {
    fetch(config.baseBlockChainUrl + "/auth/GetBearerToken", {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify("anything")
  })
      .then((res) => res.json())
      .then((response: any) => {
          localStorage.setItem("blockchainToken", response?.access_token);
      })
  }
  // to get curent user details from cognito.
  useEffect(() => {  
    Auth.currentAuthenticatedUser({
        bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then((user) => {
      localStorage.setItem("userId", user.attributes.sub)
      setUserData(user.attributes)
      setUserState(true);
      getBearerToken();
    }).catch(err => {
      setUserState(false);
    });
  }, [userState]);

  // to get user info from database.
  useEffect (() => {
    if (!userLoading) {
      if(error) setErrors(error);
      if (adminUserData) setadminData(adminUserData.getUserWithAccess);
  }
  }, [adminUserData, userLoading, error])

  useEffect(() => {
    getTokens(setTokenFound);

  onMessageListener().then((payload:any) => {
  //  setShow(true);
    //setNotification({title: payload.notification.title, body: payload.notification.body})
    console.log(payload);
  }).catch(err => console.log('failed: ', err));
  },[])
  if(errors) {

    return (<Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Your business account has been deactivated!"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This means your organization account been deactivated by PointsVille Admin.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Go back to SignIn
        </Button>
      </DialogActions>
    </Dialog>);

  }

  if(!userState) {
    return (<AuthContainer onParentRender={valuesUpdate}/>)
  }
  const menuCollapse = () => {
    if(collapsedStatus){
      setcollapsedClass("");
      setBackIcon("");
    }else{
      setcollapsedClass("expand");
      setBackIcon("show")
    }
    setCollapsedStatus(!collapsedStatus)
  }
  const handleLogoClick = () => {
    history.push("/");
  }
  return (    
    <div className={"ptv-app "+collapsedClass}>
      <nav className="ptv-nav-left">
        <div className="back-arrow-container">
          <span onClick={handleLogoClick} className={"back-parent "+backIcon}><span className="back-arrow"></span></span>
        <div className="logo" onClick={handleLogoClick} />
        </div>
        <ul className="left-nav">
          <li className="menu dashboard-menu-item" title="Dashboard"><NavLink exact to="/" activeClassName="active"><div></div><span>Dashboard</span></NavLink></li>
          <li className="menu manage-club-menu-item" title="Manage Orgs"><NavLink exact to="/manageclubs" activeClassName="active"><div></div><span>Organizations</span></NavLink></li>
          <li className="menu manage-member-menu-item" title="Manage Members"><NavLink exact to="/managemembers" activeClassName="active"><div></div><span>Members</span></NavLink></li>
          <li className="menu refresh-menu-item" title="Transactions"><NavLink exact to="/transactions"><div></div><span>Transactions</span></NavLink></li>
          {data && data?.me && data?.me?.userRole && data?.me?.userRole === "ADMIN" && <li className="menu reward-menu-item" title="Transactions"><NavLink exact to="/settings"><div></div><span>Settings</span></NavLink></li>}
        </ul>
      </nav>
      <header className="ptv-header">
        <div className="header-section">
          <Grid container className="header-container" sm={12} spacing={3}>
            <Grid item sm={7}>
                <div className="pointsville-title-container">
                    <h4 className="pointsville-title">PointsVille Partners - Asset Launchpad</h4>
                    <label className="sub-caption">Create, connect and manage your organizations, sponsors, promotions, and members</label>
                </div>
            </Grid>
            <Grid item className="align-right" sm={5}>
                <div className="profile-section">
                    <img onClick={editProfileClick} src={adminData.profileImage || ProfileImage} className="profile-img" alt="profile image"/>
                    <label onClick={() => editProfileClick(userData.sub)} className="profile-name">{adminData.givenName || userData.name}</label>
                    <span onClick ={signOutClick}  className="logout-icon mrgl-30"></span>
                </div>
            </Grid>
          </Grid>
        </div>
          {/* <div><AmplifySignOut /></div> */}
      </header>
      <main className="ptv-main">
        <PTVRouter onParentRender = {valuesUpdate} />
      </main>
    
    
      <footer className="ptv-footer">
        <div className="footer-section">
          <Grid container className="footer-container" sm={12} spacing={3}>
            <Grid item sm={7}>
                <div className="pointsville-title-container">
                    <label className="sub-caption">© 2023 PointsVille. All Rights Reserved.</label>
                </div>
            </Grid>
            <Grid item className="align-right" sm={5}>
              <div className="sub-caption-footer-link">
                <a href="https://pointsville.com/Pricing.pdf" target="_blank">Pricing</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="https://pointsville.com/privacy.html" target="_blank">Privacy Policy</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="https://pointsville.com/tos.html" target="_blank">Terms and Conditions</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="https://pointsville.com/disclaimer.html" target="_blank">Disclaimer</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a href="https://pointsville.com/cookies.html" target="_blank">Cookies Policy</a>
              </div>
            </Grid>
          </Grid>
        </div>
        <Notification />
      </footer>
      
    
    
    </div>
  );
}

export default App;
