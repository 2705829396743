import React ,{useEffect}from 'react';
import { useHistory, useLocation, useParams } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import OrgImage from '../../assets/Bitmap@2x.png';
import EmptyOrg from '../../assets/org-empty@2x.svg';

import ClubDetailsTabs from './clubdetailtabs';
import CreateClub from './../manageclubs/createclub/createclub';
import { useQuery, gql } from '@apollo/client';
import moment from 'moment';
import './clubdetails.css';
import NetworkManager from '../../utilities/NetworkManager';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { tooltipClasses } from '@mui/material';
import { styled } from '@material-ui/core/styles';
import UserGuide from './userGuide'
import TokenWarning from './tokenWarning';
const formatCurrency = require('format-currency');
const opt = { minFraction: 0 };

interface OrgInventory {
    id: string;
    name: string;
    orgCode: string;
}

interface OrgInventoryData {
    orgInventory: OrgInventory[];
}

interface OrgInventoryVars {
    clubId: string;
}

const GET_ORG_BY_ID = gql`
  query getClub($clubId: String!) {
    getClub(clubId: $clubId) {
        id
        name
        orgCode
        rate
        logoUrl
        availablePoints
        totalPoints
        fanCount
        createdAt
        isToken
        isApproved
        isMainnet
        totalTokens
        availableTokens
        blockChainType
    }
  }
`;

export const GET_POINTHOLDERS_QUERY = gql`
query getPointHolderLists($clubId : String!){
    getPointsHolders(clubId: $clubId){
      givenName
      email
      walletId
      profilePicture
      walletBalance
      totalPoints
      walletId
      createdAt
    }
   }
`;
interface ParamTypes {
    orgId: string;
}

export default function ClubDetails() {
    const [open, setOpen] = React.useState(false);
    const [UserGuidePopup, setUserGuidePopup] = React.useState(false);
    const [orgIds,setOrgId] = React.useState("");
    const [updatedDate,setUpdatedDate] = React.useState("");
    const [fanCount,setFancount] = React.useState();
    const [tokenData, setTokenData] = React.useState<any>("");
    let { orgId } = useParams<ParamTypes>();
    const { loading, data,refetch } = useQuery<any>(
        GET_ORG_BY_ID,
        { variables: { clubId: orgId } }
    );
    // const {refetch:pointholdersRefetch, loading:pointholdersLoading, data:pointholdersData } = useQuery<any>(
    //     GET_POINTHOLDERS_QUERY,{variables : {clubId : orgId}}
    // );
    const handleClickOpen = () => {
        setOpen(true);

    };
    const valuesUpdate = () => {
        refetch();
        getTokenID();
        // pointholdersRefetch();
    }
    useEffect(() => {
        if(!loading){
            setUpdatedDate(data.getClub.createdAt);
        }
        if (data) {
            localStorage.setItem('org_has_token', data.getClub.isToken)
        }
        // if(!pointholdersLoading){
        //     let selectedSponsor = pointholdersData.getPointsHolders.filter((i:any) => i.totalPoints > 0 );
        //     setFancount(selectedSponsor.length);
        // }
        refetch();
        getTokenID();
    },[open,data]);
    const handleEditClickOpen = () => {
        setOpen(true);
        setOrgId(orgId);
    };
    const handleClose = () => {
        setOpen(false);
        setUserGuidePopup(false);
    };
    const handleClosed = () => {
        setUserGuidePopup(false);
        handleEditClickOpen();
    };
    const handleDeleteClose = () => {
        setOpen(false);
        history.push('/manageclubs');
    };
    const handleCloseWarning = () => {
        refetch();
        getTokenID();
    }
    const history = useHistory();
    const backClick = () => history.push('/manageclubs');
    const getTokenID = async () => {
        let blockchain_enviroment = data?.getClub.isMainnet === true ? 'mainnet' : 'testnet';
        let dataToSend = {
            organization_id: orgId,
            blockchain_env: blockchain_enviroment
        }
        await NetworkManager.getTokenID(data?.getClub.blockChainType, dataToSend).then((response: any) => {
            if (response) {
                setTokenData(response)
            }
        })
    }
    useEffect(() => {
        getTokenID();
    },[]);
    useEffect(() => {
        if(data && tokenData) {
            if(data.getClub.isToken === true && tokenData.has_token === false){
                setUserGuidePopup(true)
            }
        }
    },[open,data,tokenData]);
    function popupWindow(url:any, windowName:any, win:any, w:any, h:any) {
        const y = win.top.outerHeight / 2 + win.top.screenY - ( h / 2);
        const x = win.top.outerWidth / 2 + win.top.screenX - ( w / 2);
        return win.open(url, windowName, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
    }
     //ToolTip
     const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
        },
    }));
    return (
        <div className="detail-page org-detail-page">
            <div className="page-head org-details-page-container p-relative padl-40">
                <Grid className="flx-align-center" container sm={12}>
                    <Grid container sm={9} >
                        <span className="back-icon" onClick={backClick}></span>
                        {loading ? (
                            <p>Loading ...</p>
                        ) : (
                                <Grid className="flx-align-center" container sm={12} spacing={3}>
                                    <Grid className="pad0" item sm={1}>
                                        <img src={data.getClub.logoUrl || EmptyOrg} className="org-detail-img" alt="organization image" />
                                    </Grid>
                                    <Grid className="border-right mrg" item sm={6}>
                                            <h5 className="page-title page-main-title mrg0">{data.getClub.name}</h5>
                                            <h5 className="page-title mrg0">({data.getClub.orgCode})</h5>
                                        <Chip className="status green" variant="outlined" label="Active" />
                                        &nbsp;
                                        {data.getClub.isApproved && <Chip size="small" label="Approved" color="primary" />}&nbsp;
                                        {data?.getClub?.isToken == false ? <Chip className="status success" variant="outlined" label="Points" /> : <Chip className="status error" variant="outlined" label="Tokens" />}
                                    </Grid>
                                    <Grid item sm={5}>
                                            {
                                                data?.getClub?.isToken == false ?
                                                    <div className="org-info-section">
                                                        <span className="data-bold-with-icon"><span className="org-info-icon green"></span><span className="data-bold">{formatCurrency(data.getClub.availablePoints,opt)} / {formatCurrency(data.getClub.totalPoints,opt)}</span></span>
                                                        <label className="sub-caption">Available / Total</label>
                                                    </div>
                                                : <div className="org-info-section">
                                                    <span className="data-bold-with-icon"><span className="org-info-icon green"></span><span className="data-bold">{formatCurrency(data.getClub.availableTokens,opt)} / {formatCurrency(data.getClub.totalTokens,opt)}</span></span>
                                                    <label className="sub-caption">Available / Total</label><br /><br />
                                                    {
                                                        tokenData && tokenData.has_token ?
                                                            <React.Fragment>
                                                                <BootstrapTooltip placement='bottom' title="Click to show contract data"><a onClick={() => {popupWindow(tokenData?.contract_link, 'Contract', window, 1000, 700)}} ><Chip className="status sky-blue" variant="outlined" label="Contract" style={{cursor:"pointer"}} /></a></BootstrapTooltip>&nbsp;
                                                                <BootstrapTooltip placement='bottom' title="Click to show token data"><a onClick={() => {popupWindow(tokenData?.token_link, 'Token', window, 1000, 700)}} ><Chip className="status blue" variant="outlined" label="Token" style={{cursor:"pointer"}}  /></a></BootstrapTooltip>&nbsp;
                                                                <BootstrapTooltip placement='bottom' title="Click to show wallet data"><a onClick={() => {popupWindow(tokenData?.wallet_link, 'Wallet', window, 1000, 700)}} ><Chip className="status yellow" variant="outlined" label="Wallet" style={{cursor:"pointer"}}  /></a></BootstrapTooltip>&nbsp;
                                                            </React.Fragment>
                                                        : ''
                                                    }
                                                </div>
                                            }
                                    </Grid>
                                    {/* <Grid item sm={2}>
                                        <div className="org-info-section">
                                            <span className="data-bold-with-icon  doller-txt"><span className="org-info-icon d-txt">$</span><span className="data-bold">{data.getClub.rate}</span></span>
                                            <label className="sub-caption">Current Rate</label>
                                        </div>
                                    </Grid> */}
                                    {/* <Grid item sm={3}>
                                        <div className="org-info-section">
                                            <span className="data-bold-with-icon avatar-img"><span className="org-info-icon avatar"></span><span className="data-bold">{data.getClub.fanCount}</span></span>
                                            <label className="sub-caption">Point Holders</label>
                                        </div>
                                    </Grid> */}
                                </Grid>
                            )}
                    </Grid>
                    <Grid className="org-edit-section align-right flx-align-center" item sm={3}>
                        <label className="update-date-txt">Last updated:{moment(updatedDate).format('MM/DD/YYYY')}</label>
                        <button onClick={handleEditClickOpen} className="btn btn-secondary edit-btn"><span className="btn-icon"></span> <label className="btn-caption">Edit Org</label></button>
                    </Grid>
                </Grid>
            </div>
            <div className="page-body">
                {!loading && data?.getClub?.isToken && tokenData && tokenData?.has_token == false ? <TokenWarning orgId={data.getClub.id} refetch={handleCloseWarning} /> : ''}
                <Grid container sm={12} spacing={3}>
                    <Grid item sm={12}>
                    {!loading && <ClubDetailsTabs refetch={refetch} onParentRender = {valuesUpdate} rate={data.getClub.rate} code={data.getClub.orgCode}/>}
                    </Grid>
                </Grid>
            </div>
            <CreateClub onOpen={open} editId = {orgIds} editData={"Edit"} handleCloseClick={handleClose} handleDeleteCloseClick={handleDeleteClose} />
            {/* <UserGuide onOpen={UserGuidePopup}  handleCloseClick={handleClosed} /> */}
        </div>
    );
}
