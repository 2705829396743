import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useQuery, gql } from "@apollo/client";
import Loader from "../../components/Loader/loader";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import "./transactions.css"
interface ITransList {
  // refresh:boolean;
  // handleRefresh: () => void;
  userId: string
}

const GET_TRANSACTION_QUERY = gql`

  query getTransactionsByClub($userId: String!) {
    getTransactionsByUserId(userId: $userId)  {
      data {
        id
        transactionRef
        user
        organization
        walletId
        tdc_patronid
        price
        sponsor
        transactionDate
        transactionType
        points
        transactionStatus
        refund_amount
        username
      }
    }
  }`


export default function TransactionOverview(props : ITransList) {

  const EmptyOrg = "https://png.pngtree.com/png-vector/20190321/ourmid/pngtree-vector-users-icon-png-image_856952.jpg";


  const columns:any = [
    {
      name:"organization",
      label: " ",
      width: 1,
      options:{
        filter:false,
        sortThirdClickReset: true,
        sort: false,
        customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
          return (
            <td className={"table-header-sorting " } key={1}>
              <a >Organization</a>
            </td>
          )
        },
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          let fieldValues = JSON.parse(value);
          return (<div><img className='profile-img' src={fieldValues.orgLogo || EmptyOrg} /><label className='membername'>{fieldValues.orgName}</label></div>)
        }
      }
    },
    {
      name:"points",
      label: " ",
      width: 1,
      options:{
        filter:false,
        sortThirdClickReset: false,
        sort: false,
        customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
          return (
            <td className={"table-header-sorting " } key={2}>
              <a >Amount</a>
            </td>
          )
        },
        customBodyRender:(value:any, tableMeta:any, updateValue:any) => {

          return (<div><label className='membername'>{value}</label></div>)
        }
      }
    },
    {
      name:"id",
      label: "",
      width: 1,
      options:{
        filter:false,
        sortThirdClickReset: false,
        sort: false,
        customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
          return (
            <td className={"table-header-sorting " } key={3}>
              <a >Transactions ID</a>
            </td>
          )
        },
        customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
          return (<div><label>{value}</label></div>)
        }
      }
    },

    {
      name: "transactionDate",
      label:"Date & Time",
      options:{
        filter:false,
        sortThirdClickReset: true,
        customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
          const orderName = (sortOrder.name == "transactionDate")?sortOrder.direction:"";

          return(
            <th className={"table-header-sorting " + orderName} key={4}  >
              <a >Date & Time</a>
            </th>
          )
        },
        customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
          return (<div><label className="membername">{moment(parseInt(value)).format("DD MMM YYYY HH:mm")}</label></div>)
        }
      }
    },
    // {
    //   name:"transactionStatus",
    //   label: "",
    //   width: 1,
    //   options:{
    //       filter:false,
    //       sortThirdClickReset: false,
    //       sort: false,
    //       customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
    //         return (
    //             <td className={"table-header-sorting " }>
    //                 <a >Status</a>
    //             </td>
    //         )
    //     },
    //       customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
    //         return (<div><label>{value}</label></div>)
    //       }
    //   }
    // },


  ];

  const offset = 0;
  const limit = 10;
  const {refetch, loading, data, fetchMore } = useQuery<any>( GET_TRANSACTION_QUERY,{variables : {userId: props.userId, offset: offset, limit: limit }});
  const [ageFilterChecked, setAgeFilterChecked] = React.useState(false);
  const history = useHistory();
  // set data with api resopoce
  // useEffect(() => {
  //   refetch();
  // }, [props.refresh]);
  const options:any = {
    filter: false,
    //count: data && data.getTransactionsByUserId && data.getTransactionsByUserId?.data && data.getTransactionsByUserId.data.count ? data.getTransactionsByUserId.data.count : 10,
    filterType: "dropdown",
    responsive:"standard",
    print:false,
    download : false,
    pagination : true,
    serverSide: false,
    viewColumns : false,
    selectableRowsHideCheckboxes: true,
    fixedSelectColumn: false,
    selectToolbarPlacement: "none",
    selectableRowsHeader: false,
    selectableRows: "none",
    selectableRowsOnClick: false,
    fixedHeader : true,
    tableBodyHeight:"400px",
    search:false,
    onTableChange: (action:any, tableState:any) => {
      switch (action) {
        case 'changeRowsPerPage':
          refetch({userId: props.userId, offset: tableState.page * tableState.rowsPerPage, limit: tableState.rowsPerPage})
          // count = data && data.getPointsHolders && data.getPointsHolders[0] && data.getPointsHolders[0].userCount ? data.getPointsHolders[0].userCount : 10;
          // changePage(tableState);
          break;
        case 'sort':
          if (tableState.sortOrder.direction !== 'none')
          {
              refetch({sort: tableState.sortOrder})
          }
          else
          {
              refetch({sort: null})
          }
          // this.sort(tableState.page, tableState.sortOrder);
          // count = data && data.getPointsHolders && data.getPointsHolders[0] && data.getPointsHolders[0].userCount ? data.getPointsHolders[0].userCount : 10;
          break;
        default:
      console.log('action not handled.');
       }
    }
  }
  return (
      <div className="table-container muidata-table pad0">
        {loading
          ? <Loader size="90px"/>
          : <MUIDataTable title={""} data={data?.getTransactionsByUserId?.data} columns={columns} options={options} />
        }
      </div>
  )

}