import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import PointOverview from './pointsoverview';
import PromotionOverview from './promotionoverview'
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { useQuery, gql } from '@apollo/client';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import './dashboard.css';


interface MemberInventory {
    id: string;
    promoType: string;
}

interface MemberInventoryData {
    memberInventory: MemberInventory[];
}

interface MemberInventoryVars {
    year: number;
}

export const GET_MEMBER_QUERY = gql`
    query GetUserData {
        getUser{
            id
            givenName
            username
            email
            phoneNumber
            isActive
        }
    }
`;

export const GET_MEMBERS_COUNT = gql`
    query {
        getUsersCount {
            totalMembers
            activeMembers
            inActiveMembers
        }
    }
`;

export default function MemberOverview() {
    const history = useHistory();
    const mangeMemberView = () => history.push('/managemembers');
    const [memberCount, setMemberCount] = useState<any>("0");
    const [chartValue, setChartValue] = useState<any>(100);

    // const {data, loading, refetch} = useQuery<any>(GET_MEMBER_QUERY);
    const { data, loading, refetch } = useQuery<any>(GET_MEMBERS_COUNT);


    useEffect(() => {
        if (!loading) {
            setMemberCount(data.getUsersCount[0].totalMembers);
        }
    }, [data]);


    return (
        <div className="org-member-overview-report" >
            <Paper>
                <Grid container sm={12} spacing={2}>
                    <Grid item md={4} sm={12}>
                        <CircularProgressbar
                            value={chartValue}
                            styles={buildStyles({
                                textColor: "red",
                                pathColor: "#6DD400",
                                trailColor: "#FF6A6A"
                            })}

                        />
                        {/* <svg viewBox="0 0 36 36" className="circular-chart orange">
                        <path className="circle-bg"
                            d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        <path className="circle"
                            stroke-dasharray="100, 0"
                            d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                    </svg> */}
                    </Grid>
                    <Grid container sm={8} className="overview-chart-panel">
                        <label className="overview-title">MEMBERS&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                        <Grid item className="" md={6} sm={6}>
                            <span className="data-bold green">{memberCount}</span><label className="sub-caption nowrap">Total</label>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid container sm={12}>
                    <Grid className="btn-section" item xs={12}>
                        <Button onClick={mangeMemberView} className="btn" variant="contained">Manage</Button>

                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}
