import React,{useState,useEffect} from 'react';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useHistory ,useParams} from "react-router-dom";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import DropdownSelect from '../../components/Dropdown/dropdownselect';
import Button from "@material-ui/core/Button";
import '../../constant.css';
import '../transaction/transactions.css';
import './sponsorList.css';
import SponsorEmty from '../../assets/sponsor-empty@2x.svg';
import SponsorLogo from '../../assets/sponsor-logo.png';
import Emirates from '../../assets/emirates.png';
import Spark from '../../assets/spark.png';
import Omega from '../../assets/omega.png';
import CreateSponsor from './createsponsor/createsponsor';
import { useQuery, gql } from '@apollo/client';
import Loader from '../../components/Loader/loader';
import SponsorDetails from './sponsorDetails';
import MUIDataTable from "mui-datatables";
import TokenDetails from "./TokenDetails"
import UserGuide from './userGuide'
import NetworkManager from '../../utilities/NetworkManager';
const formatCurrency = require('format-currency');
const opt = { minFraction: 0 };
interface Props {
    onParentRender: () => void,
    rate: any
  }
interface SponsorInventory {
    id: string;
    sponsor_name: string;
    contact_name : string;
    contact_email : string;
}

interface SponsorInventoryData {
    sponsorInventory: SponsorInventory[];
}

interface SponsorInventoryVars {
    year: number;
}
interface ParamTypes {
    orgId: string;
}


interface IFormAddClub {
    sponsorLogo: string;
    companyName: string;
    pointRequest: string;
    contactName: string;
    contactMail : string;
    contactPhone : string;
    contactAddress : string;
}



export const GET_SPONSOR_QUERY = gql`
query GetSponsorInventory ($clubId: String!) {
    getSponsorWithOrgId (clubId: $clubId){
    id
    sponsorName
    sponsorLogo
    pointRequest
    contactName
    contactEmail
    contactPhone
    contactAddress
    promotions
    availablePoints
    since
    tokenRequest
    availableTokens
    tokenPromotions
  }
}
`;
const GET_ORG_BY_ID = gql`
  query getClub($clubId: String!) {
    getClub(clubId: $clubId) {
        id
        name
        orgCode
        isToken
        isMainnet
        totalTokens
        availableTokens
        blockChainType
    }
  }
`;

export default function SponsorList({onParentRender, rate}:Props) {
    let { orgId } = useParams<ParamTypes>();

    const {refetch, loading, data } = useQuery<any>(
        GET_SPONSOR_QUERY,{variables : {clubId : orgId},fetchPolicy: "network-only"}
    );
    const { data:clubData } = useQuery<any>(
        GET_ORG_BY_ID,
        { variables: { clubId: orgId },fetchPolicy: "network-only" }
    );
    const [open, setOpen] = React.useState(false);
    const [openDetails, setOpenDetails] = React.useState(false);
    const [openTokenDetails, setTokenOpenDetails] = React.useState(false);
    const hasToken = localStorage.getItem('org_has_token');
    const [sponsorInfo ,setSponsorInfo] = useState<any>({});
    const [viewType , setViewType] = React.useState<string | null>("Create");
    const [sponsorId , setSponsorId] = React.useState<any>("");
    const [UserGuidePopup, setUserGuidePopup] = React.useState(false);
    const [tokenData, setTokenData] = React.useState<any>("");
    //const [sponsorData, setSponsorData] = React.useState<any>({});

    const { register, handleSubmit, errors,control, reset, formState, setValue} = useForm();

    const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOpen(true);
        setSponsorId(false);
        const targetElement = (event.target as HTMLButtonElement);
        setViewType(targetElement.getAttribute("data-view"));
    };
    const handleEditClickOpen = (id:string) => {
        setOpen(true);
        setSponsorId(id);
    };

    const handleDetailsClickOpen = (id:string) => {
        setOpenDetails(true);
        setSponsorId(id);
    };

    const handleTokenDetailsClickOpen = (id:string) => {
        setSponsorId(id);
        if(tokenData && tokenData.has_token) {
            setTokenOpenDetails(true);
        }
        else {
            setUserGuidePopup(true);
        }
    }
    const getTokenID = async () => {
        if(!clubData?.getClub?.blockChainType) {
            return false;
        }
        let blockchain_enviroment = clubData?.getClub.isMainnet === true ? 'mainnet' : 'testnet';
        let dataToSend = {
            organization_id: orgId,
            blockchain_env: blockchain_enviroment
        }
        await NetworkManager.getTokenID(clubData?.getClub.blockChainType, dataToSend).then((response: any) => {
            if (response) {
                setTokenData(response)
            }
        })
    }
    useEffect(() => {
        if(clubData || rate || openTokenDetails) {
            getTokenID();
        }
    },[clubData,rate,openTokenDetails,onParentRender]);
    const columns:any = [
        {
          name:"sponsorName",
          options:{
              filter:false,
              sortThirdClickReset: true,
              sort: true,
              customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                let orderName = (sortOrder.name == "sponsorName")?sortOrder.direction:"";
                return(
                <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={0} onClick={() => updateDirection(0)} >
                  <a className="sort-by">Company Name</a>
                </th>
                )
              },
              customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                  let sponsor = data && data.getSponsorWithOrgId.find((x:any) => x.sponsorName === value);
                  return (
                  <div>
                      <img className='profile-img' src={sponsor.sponsorLogo || SponsorEmty} />
                      <label style={{whiteSpace: 'nowrap'}}>{value}</label>
                      <label className="edit-icon-sponsor"><span onClick={() => handleEditClickOpen(sponsor.id)} data-id={sponsor.id} data-view="Edit" className="table-edit-icon"></span></label>
                   </div>)
                }

          }
        },
        {
          name:"contactAddress",
          options:{
              filter:false,
              sortThirdClickReset: true,
               sort: true,
              customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "contactAddress")?sortOrder.direction:"";

                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(1)} >
                    <a className="sort-by">Address</a>
                  </th>
                  )
              }
          }
        },
        {
          name:"contactEmail",
          options:{
              filter:false,
              sortThirdClickReset: true,
              sort: true,
              customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                  let orderName = (sortOrder.name == "contactEmail")?sortOrder.direction:"";

                  return(
                  <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(2)} >
                    <a className="sort-by">Contact Email</a>
                  </th>
                  )
              }
          }
        },
        {
            name:"since",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "since")?sortOrder.direction:"";

                    return(
                    <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={3} onClick={() => updateDirection(3)} >
                      <a className="sort-by">Sponsors Since</a>
                    </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    return (<label className="">{moment(parseInt(value)).format('MM/DD/YYYY')}</label>)
                }
            }
          },
          {
            name:"id",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "id")?sortOrder.direction:"";

                    return(
                    <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={4} onClick={() => updateDirection(4)} >
                      <a className="sort-by">{hasToken === "true" ? 'Tokens' : 'Points'} Available / Total</a>
                    </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let sponsor = data && data.getSponsorWithOrgId.find((x:any) => x.id === value);
                    let availablePoints = hasToken === "true" ?  sponsor.availableTokens : sponsor.availablePoints;
                    let pointRequest = hasToken === "true" ? sponsor.tokenRequest : sponsor.pointRequest;
                    return (
                        <label className="org-info-section">
                            <span className="data-bold-with-icon">
                                <span className="org-info-icon green"></span>
                                <span className="data-bold">{formatCurrency(availablePoints,opt) +"/"+formatCurrency(pointRequest,opt)}</span>
                            </span>
                        </label>
                    )
                }
            },

          },
          {
            name:"id",
            options:{
                filter:false,
                sortThirdClickReset: true,
                sort: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "id")?sortOrder.direction:"";

                    return(
                    <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={5} onClick={() => updateDirection(5)} >
                      <a className="sort-by">Promotions</a>
                    </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let sponsor = data && data.getSponsorWithOrgId.find((x:any) => x.id === value);
                    const promotions =  hasToken === "true" ?  sponsor.tokenPromotions : sponsor.promotions
                    return (<label className="">{promotions}</label>)
                }
            },

          },
          {
            name:"id",
            options:{
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "id")?sortOrder.direction:"";

                    return(
                    <th className={"table-header-sorting " + orderName} key={6} ></th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    return  hasToken === "true" ? <button onClick={() => handleTokenDetailsClickOpen(value)} data-view="Create" className="add-points btn add-btn " ><span data-view="Create" className="btn-icon"></span><label className="btn-caption add-points-text"> Add Tokens</label></button>
                        : <button onClick={() => handleDetailsClickOpen(value)} data-view="Create" className="add-points btn add-btn " ><span data-view="Create" className="btn-icon"></span><label className="btn-caption add-points-text"> Add Points</label></button>

                }
            },

          }
      ];
      const options:any = {
        filter: false,
        count: data && data.getUser && data.getUser.length ? data.getUser.length : 10,
        filterType: "dropdown",
        responsive:"standard",
        print:false,
        download : false,
        pagination : false,
        serverSide: false,
        viewColumns : false,
        selectableRowsHideCheckboxes: true,
        fixedSelectColumn: false,
        selectToolbarPlacement: "none",
        selectableRowsHeader: false,
        selectableRows: "none",
        selectableRowsOnClick: false,
        fixedHeader : true,
        tableBodyHeight:"275px",
        search:false,
      }
    useEffect(() => {
        refetch();
        onParentRender();
    },[open,data]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseSponsorDetails = () => {
        setOpenDetails(false);
    };

    const handleCloseTokenDetails = () => {
        setTokenOpenDetails(false);
        onParentRender();
        refetch();
    };
    const handleCloseGuide = () => {
        setUserGuidePopup(false);
        getTokenID();
        onParentRender();
        refetch();
        setUserGuidePopup(false);
        setTokenOpenDetails(true);
    };

    const handleCancleGuide = () => {
        setUserGuidePopup(false);
    }
    const methods = useForm<IFormAddClub>();
    // const { handleSubmit, control, reset,errors } = methods;
    const onSubmit: SubmitHandler<IFormAddClub> = data =>{}
    return (
    <div className="list-page sponsor-list-wrapper">
        <Grid container item xs={12} sm={12}>
            <Grid  item sm={12} xs={12}>
                <Paper className="table-wrapper">
                    <Grid container className="table-head-section" sm={12}>
                        <Grid item sm={8}>
                            <label className="table-heading">SPONSORS LIST</label>
                        </Grid>
                        <Grid  item sm={4} className="select-with-label align-right flx-align-center flx-end">
                            {/* <label className="select-label">Sort By</label>
                            <DropdownSelect/> */}
                            <button onClick={handleClickOpen} data-view="Create" className="btn btn-primary add-btn"><span data-view="Create" className="btn-icon"></span><label className="btn-caption"> Add SPONSOR</label></button>
                        </Grid>
                    </Grid>
                    <div className="table-container muidata-table pad0">
                        {loading
                            ? <Loader size="90px"/>
                            : <MUIDataTable title={""} data={data.getSponsorWithOrgId} columns={columns} options={options} />
                        }
                    </div>
                </Paper>
            </Grid>
        </Grid>

        <CreateSponsor  onOpen ={open} editId = {sponsorId} viewTypeTxt={viewType} handleCloseClick={handleClose} rate={rate}/>
        <SponsorDetails onOpen ={openDetails} id={sponsorId} handleCloseClick={handleCloseSponsorDetails} rate={rate}/>
        <TokenDetails onOpen ={openTokenDetails} id={sponsorId} handleCloseClick={handleCloseTokenDetails} rate={rate}/>
        <UserGuide onOpen={UserGuidePopup} handleCancleGuide={handleCancleGuide}  handleCloseClick={handleCloseGuide} orgDataToken={clubData?.getClub} />
    </div>
    )
}
