import React, { useEffect, useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, TableContainer, Paper, Table, TableRow, TableCell, DialogActions, TableHead, TableBody, Grid, Select, MenuItem, Backdrop, CircularProgress, Chip, IconButton, TextField } from '@material-ui/core'
import { Button } from 'aws-amplify-react'
import { gql, useMutation, useQuery } from '@apollo/client';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import './sponsordetails.css';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@mui/material/Typography';
import NetworkManager from '../../utilities/NetworkManager';
import { useParams } from "react-router-dom";
import ConnectWallet from "../connectwallet/connectwallet";
import ConnectTronWallet from "../connectwallet/connecttronwallet";
import { useEthers } from "@usedapp/core";
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { tooltipClasses } from '@mui/material';
import { styled } from '@material-ui/core/styles'
const formatCurrency = require('format-currency');
const opt = { minFraction: 0 };
interface ParamTypes {
    orgId: string;
}
interface Props {
    onOpen: boolean,
    handleCloseClick: () => void,
    id?: any,
    rate: any
}

const GET_ORG_BY_ID = gql`
    query getClub($clubId: String!) {
        getClub(clubId: $clubId) {
            id
            name
            orgCode
            orgType
            blockChainType
            isMainnet
        }
    }
`;
const SPONSOR_TOKENS_LEDGER_BY_ID = gql`
    query getSponsorTokensLedgerById($id: String!) {
        getSponsorTokensLedgerById(id: $id) {
            id
            sponsorId
            tokens
            transactionHash
            transactionLink
            type
            createdAt
        }
    }
`;

const REQUEST_SPONSOR_TOKENS = gql`
    mutation newTokenTransaction($data: NewTokenTransactionsInput!) {
        newTokenTransaction(data: $data) {
            id
        }
    }
`;

interface IFormRequestSponsorPoints {
    points: string;
    mode: string;
    paymentCycle: string;
}

let defaultValues: any = {
    points: "",
    mode: "",
    paymentCycle: ""
}

const useStyles = makeStyles({
    paper: {
        padding: "0",
        fontFamily: "Graphik Regular !important",
        fontSize: "14px !important",
        color: "#8A8D9D !important"
    }
});

export default function TokenDetails({ handleCloseClick, onOpen, id, rate }: Props) {

    const open = onOpen;
    const [openToast, setOpenToast] = React.useState<any>(false);
    const { loading, data, refetch:refresh } = useQuery<any>(SPONSOR_TOKENS_LEDGER_BY_ID, { variables: { id: id }, fetchPolicy: "network-only" });
    const [message, setMessage] = useState<any>("");
    const [points, setPoints] = useState<any>('');
    const [publicKey, setPublicKey] = React.useState("");
    const hasToken = localStorage.getItem('org_has_token');
    const [resLoading, setResLoading] = useState(false);
    const [pointsRequestOptions, setPointsRequestOptions] = useState([{ title: "100", _id: "100" }, { title: "1000", _id: "1000" }, { title: "10000", _id: "10000" }, { title: "100000", _id: "100000" }, { title: "1000000", _id: "1000000" }, { title: "10000000", _id: "10000000" }])
    let { orgId } = useParams<ParamTypes>();
    const { data: orgData } = useQuery<any>(GET_ORG_BY_ID, { variables: { clubId: orgId }, fetchPolicy: "network-only" });
    const { account } = useEthers();
    const [isErrorMessage, setIsErrorMessage] = React.useState(false);
    const [error, setError] = React.useState<any>({});
    const [transactionLink, setTransactionLink] = React.useState("");
    const [inputError, setInputError] = React.useState(false);
    const { register, handleSubmit, errors, control, reset, formState, setValue, clearErrors } = useForm<IFormRequestSponsorPoints>({ defaultValues, shouldUnregister: false });
    const [newPointsRequest] = useMutation(REQUEST_SPONSOR_TOKENS, {});
    const [TransOpen, setTranOpen] = React.useState(false);
    const [tokenData, setTokenData] = React.useState<any>("");
    const Alert = (props: AlertProps) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    function popupWindow(url:any, windowName:any, win:any, w:any, h:any) {
        const y = win.top.outerHeight / 2 + win.top.screenY - ( h / 2);
        const x = win.top.outerWidth / 2 + win.top.screenX - ( w / 2);
        return win.open(url, windowName, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
    }
     //ToolTip
     const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
        },
    }));

    const getTokenID = async () => {
        let dataToSend = {
            organization_id: orgId,
            blockchain_env: orgData?.getClub.isMainnet === true ? 'mainnet' : 'testnet',
        }
        await NetworkManager.getTokenID(orgData?.getClub.blockChainType, dataToSend).then((response: any) => {
            if (response) {
                setTokenData(response)
            }
        })
    }
    useEffect(() => {

    },[]);
    useEffect(() => {
        getTokenID();
        setError({});
        setPoints('');
    },[open])
    const handleChangePubKey = (pubkey: any) => {
        setPublicKey(pubkey);
    }

    const saveTokensToDB = async(tx_hash: any, type: string,transaction_link:string) => {
        await newPointsRequest({
            variables:
            {
                data:
                {
                    sponsorId: id,
                    tokens: parseInt(points),
                    transactionHash : tx_hash,
                    type : type === 'mint_token' ? 'MINT' : 'TRANSFER',
                    transactionLink : transaction_link
                }
            }});
            refresh();
            reset();
            setPoints('');
            setValue("points", '');
    }
    const getTransactionDetail = async (tx_hash: any, type: string) => {
        let dataToSend = {
            transaction_hash: tx_hash,
            blockchain_env: orgData?.getClub.isMainnet === true ? 'mainnet' : 'testnet',
        }
        await NetworkManager.getTransactionDetail(orgData?.getClub.blockChainType, dataToSend).then((response: any) => {
            if (response && response.status === 1) {
                setResLoading(false);
                setTransactionLink(response?.transaction_link);
                saveTokensToDB(tx_hash,type,response?.transaction_link);
                if(type === 'mint_token') {
                    setOpenToast(true);
                    setIsErrorMessage(false);
                    setMessage('Token Successfully Supply');
                }
                else {
                    setOpenToast(true);
                    setIsErrorMessage(false);
                    setMessage('Token Successfully Transfer');
                }
            }
            else {
                setResLoading(false);
                setIsErrorMessage(true);
                setOpenToast(true);
                setMessage(response?.resMessage ? response?.resMessage : 'Transaction was failed');
            }
        })
            .catch((error: any) => {
                setResLoading(false);
                setIsErrorMessage(true);
                setOpenToast(true);
                setMessage(error.message);
                console.log('error', error);
            });
    };

    const openMetamask = async (transaction: any,type: string) => {
        try {

            const param: any = [{
                chainId: transaction.chainId.toString(),
                gasPrice: transaction.gasPrice.toString(),
                gasLimit: transaction.gas.toString(),
                from: transaction.from.toString(),
                data: transaction.data.toString(),
                to: transaction.to.toString(),
            }];

            const value = await (window as any).ethereum.request({
                method: "eth_sendTransaction", params: param,
            }).then(async (tx_hash: any) => {
                if(tx_hash) {
                    getTransactionDetail(tx_hash,type);
                }
                else {
                    setIsErrorMessage(true);
                    setOpenToast(true);
                    setMessage('Invalid transaction hash.');
                }

            })
                .catch((error: any) => {
                    setResLoading(false);
                    setIsErrorMessage(true);
                    if (error && error.code == '4001') {
                        console.log('error', error)
                        setOpenToast(true);
                        setMessage("You have denied the transaction.");
                    }
                    else {
                        setOpenToast(true);
                        setMessage(error.message);
                        console.log('error', error)
                    }
                    // If the request fails, the Promise will reject with an error.
                });


            return true;
        }
        catch (E) {
            console.log(E, "errors")
            return false;
        }
    }

    const openTron = async (transaction: any, type: any) => {
        // refresh();
        // reset();
        // setPoints('');
        // setValue("points", '');
        // setOpenToast(true);
        // setMessage('Tron wallet');


        const tronweb = (window as any).tronWeb;

        var senderAddress = tronweb.defaultAddress.base58;

        var receiverAddress = tronweb.address.toHex(transaction.to.toString());

        var amount = parseInt(transaction.amount);

        var parameter = [{type:'address',value:receiverAddress},{type:'uint256',value:amount}]

        var options = {
          feeLimit:parseInt(transaction.feeLimit)
        }

        const trxns = await tronweb.transactionBuilder.triggerSmartContract(
            tronweb.address.toHex(transaction.contractAddress.toString()),
            type+"(address,uint256)",
            options,
            parameter,
            tronweb.address.toHex(senderAddress)
        );

        await tronweb.trx.sign(trxns.transaction)
            .then(async (signedTx:any)  => {
                await tronweb.trx.sendRawTransaction(signedTx)
                .then((trnx:any)  => {
                    if (trnx && trnx.txid) {
                        setTimeout(() => {
                            getTransactionDetail(trnx.txid, type);
                        }, 10000)

                    }
                    else {
                        setOpenToast(true);
                        setMessage('Invalid txid.');
                        setResLoading(false);
                    }
                })
                .catch((error: any) => {
                    console.log('send row transaction error',error);
                    setOpenToast(true);
                    setIsErrorMessage(true);
                    setMessage(error);
                    console.log("error",error)
                    setResLoading(false);
                });
            })
            .catch((error: any) => {
                setIsErrorMessage(true);
                setOpenToast(true);
                if (error == 'Confirmation declined by user') {
                    setMessage("You have denied the transaction.");
                }
                else {
                    console.log('trx sign error',error);
                    setMessage(error);
                }
                setResLoading(false);
            });
    }
    const isValid = (isTransfer:any) => {
        let error: any = {};
        let isError = false;
        if(!points) {
            error.points = "This field is required";
            isError = true;
        }
        if(points) {
            let regex_allowed2 = /^[0-9/]+$/
            if (points.length && regex_allowed2.test(points) === false) {
                error.points = "Only number is allow";
                isError = true;
            }

            if (parseInt(points) <= 0) {
                error.points = "Value should be more than 0";
                isError = true;
            }
            // if(isTransfer && parseInt(points) > tokenData.token_balance) {
            //     error.points = "Value should be less than "+tokenData.token_balance;
            //     isError = true;
            // }
        }
        setError(error);
        return isError;
    }
    const onSubmit = async (e:any) => {
        e.preventDefault();
        if(!isValid(false)) {

        let dataToSend = {
            amount: parseInt(points),
            organization_id: orgId,
            blockchain_env: orgData?.getClub.isMainnet === true ? 'mainnet' : 'testnet',
        }
        await NetworkManager.supplyToken('Avalanche', dataToSend).then((response: any) => {
            if (response && response?.resp?.transaction) {
                openMetamask(response?.resp?.transaction,'mint_token')
            }
        })
        }

    }

    const handleBurnToken = (e: any) => {
        e.preventDefault();
    }

    const handleClick = (e: any) => {
        e.preventDefault();
        if(tokenData && tokenData?.token_type === "IMPORTED") {
            handleTransferToken();
        }
        else {
            handleMintAndTransferToken();
        }
    }
    const handleTransferToken = async () => {
        // e.preventDefault();
        // if type == "transferwithmins"
        // else if == "transfer"
        if(!isValid(true)) {
        let dataToSend = {
            amount: parseInt(points),
            organization_id: orgId,
            blockchain_env: orgData?.getClub.isMainnet === true ? 'mainnet' : 'testnet',
        }
        setResLoading(true);
        await NetworkManager.transferTokenTransaction(orgData?.getClub.blockChainType, dataToSend).then((response: any) => {
            if (response && response?.transaction) {
                if(orgData?.getClub.blockChainType  === 'Avalanche' || orgData?.getClub.blockChainType  === 'Ethereum' || orgData?.getClub.blockChainType  === 'Polygon' ) {
                    openMetamask(response?.transaction,'transfer_token');
                }
                else {
                    openTron(response?.transaction,'transfer');
                }
            }
            else if(response && response.has_token === false ) {
                setIsErrorMessage(true);
                setOpenToast(true);
                setMessage('Please create the token.');
            }
            else {
                setIsErrorMessage(true);
                setOpenToast(true);
                setMessage('Token transfered was failed.');
            }
        })
        .catch((error: any) => {
            console.log('import wallet error',error);
            setIsErrorMessage(true);
            setOpenToast(true);
            setMessage(error && error.message ? error.message : 'Token transfered was failed.');
        });
        }
    }

    const handleMintAndTransferToken = async () => {
        // e.preventDefault();
        if(!isValid(true)) {
        let dataToSend = {
            amount: parseInt(points),
            organization_id: orgId,
            blockchain_env: orgData?.getClub.isMainnet === true ? 'mainnet' : 'testnet',
        }
        setResLoading(true);
        await NetworkManager.mintAndTransferTokenTransaction(orgData?.getClub.blockChainType, dataToSend).then((response: any) => {
            if (response && response?.transaction) {
                if(orgData?.getClub.blockChainType  === 'Avalanche' || orgData?.getClub.blockChainType  === 'Ethereum' || orgData?.getClub.blockChainType  === 'Polygon') {
                    openMetamask(response?.transaction,'transfer_token');
                }
                else {
                    openTron(response?.transaction,'transferWithMint');
                }
            }
            else if(response && response.has_token === false ) {
                setIsErrorMessage(true);
                setOpenToast(true);
                setMessage('Please create the token.');
            }
            else {
                setIsErrorMessage(true);
                setOpenToast(true);
                setMessage('Token transfered was failed.');
            }
        })
        .catch((error: any) => {
            console.log('import wallet error',error);
            setIsErrorMessage(true);
            setOpenToast(true);
            setMessage(error && error.message ? error.message : 'Token transfered was failed.');
        });
        }
    }

    const handleChange = (e: any, source = "elm") => {
        if (source === "elm") {
            const targetValue = (e.target as HTMLInputElement).value;
            // const targetName:string = (e.target as HTMLInputElement).name;
            // setPointsRequest(targetValue);
            setValue("points", targetValue);
            setPoints(targetValue)
        } else {
            setValue("points", e);
            setPoints(e)
        }
        clearErrors("points");
        setInputError(false);
        setError({});
    }


    const dateFormater = (dateString: any) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]
    }
    const handleClosed = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenToast(false);
        setPoints('');
        setValue("points", '');
    };

    const classes = useStyles();

    const handleCopy = (transactionHash:string)=> {

        setTranOpen(true)
        navigator.clipboard.writeText(transactionHash);

    }
    return (
        <Dialog
            open={open}
            onClose={handleCloseClick}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{"Transaction Summary"}
                <IconButton aria-label="close" className="closeButton" onClick={handleCloseClick}>
                    <CloseIcon />
                </IconButton></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">

                    <div className="sponsor-request-form">
                        <form className="form-container">
                            <Grid container alignItems="center">
                                <Grid item xs={4}>
                                    <div className="input-group">
                                        <label className="input-label" >Tokens Request *</label>

                                                <Autocomplete
                                                    freeSolo
                                                    classes={{ paper: classes.paper }}
                                                    options={pointsRequestOptions.map((option) => option.title)}
                                                    // getOptionLabel={option => option.title}
                                                    // getOptionSelected={(option, value) => option.title === value.title}
                                                    value={points}
                                                    onChange={(e, value: any) => handleChange(value, "auto")}
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    renderOption={(option, { selected }) => (
                                                        <React.Fragment>{formatCurrency(option, opt)}</React.Fragment>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField {...params} name="points"  required onChange={(e) => handleChange(e, "elm")} />
                                                    )}
                                                />

                                        {error && error.points && (<label className="error-message">{error.points}</label>)}
                                        {/* {points ? `Tokens ${points * rate}` : ''} */}
                                        <br />
                                        {/* <label className="input-label" >* 5% Service Fee will be applied</label> */}
                                        {
                                            // transactionLink ?
                                            //     <Typography variant="caption"  display="block">
                                            //         <a href={transactionLink} target={'_blank'}  style={{textDecoration:'none'}} >Contract Link</a>
                                            //     </Typography>
                                            // : ''
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={8} style={{ textAlign: "end", display: "inline-flex" }}>
                                    &nbsp;{orgData?.getClub.blockChainType  === 'Avalanche' || orgData?.getClub.blockChainType  === 'Ethereum' || orgData?.getClub.blockChainType  === 'Polygon' ?
                                       !publicKey ? <ConnectWallet handleChangePubKey={handleChangePubKey} /> : ''
                                        : !publicKey? <ConnectTronWallet handleChangePubKey={handleChangePubKey} /> : ''}
                                    {/* &nbsp;{tokenData && tokenData?.token_type !== "IMPORTED" ? <Button color="primary" className="btn btn-primary button" onClick={onSubmit}>Mint Token</Button> : ''} */}
                                    &nbsp; <Button color="primary" className="btn btn-primary button" onClick={handleClick} style={{width:"200px"}}>{tokenData?.token_type === "IMPORTED" ? "Transfer Token" : "Mint Tokens"}</Button>
                                </Grid>

                            </Grid>
                        </form>
                    </div>

                    <div className="pv-email-template" >
                        <div className="email-body-block" >
                            <div className="zig-zag-top-bdr" >
                                <div className="summary-block" >

                                    <TableContainer component={Paper}>
                                        <Table className="order-table" aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Date</TableCell>
                                                    <TableCell>Transaction ID</TableCell>
                                                    <TableCell >Tokens Requested</TableCell>
                                                    <TableCell >Type</TableCell>
                                                    <TableCell>Transaction Link</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {loading && <p>Loading ...</p>}
                                                {!loading && data?.getSponsorTokensLedgerById.length > 0 && data?.getSponsorTokensLedgerById.map((row: any) => (
                                                    <TableRow key={row.id}>
                                                        <TableCell component="th" scope="row">
                                                            {dateFormater(row.createdAt)}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <BootstrapTooltip placement='bottom' title={row.transactionHash}><span>{row.transactionHash.substr(0, 10)}...</span></BootstrapTooltip>&nbsp;
                                                            <BootstrapTooltip placement='top' title="Copy"><img src="https://furtaev.ru/preview/copy_small.png" onClick={()=>handleCopy(row.transactionHash)} style={{ width: "20px",paddingLeft:'5px' }} /></BootstrapTooltip>
                                                            <Snackbar
                                                                        open={TransOpen}
                                                                        onClose={() => setTranOpen(false)}
                                                                        autoHideDuration={2000}
                                                                        message="Copied to TransacationHash"
                                                                    />
                                                            {/* {row.transactionHash} */}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.tokens}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">{row.type} </TableCell>
                                                        <TableCell component="th" scope="row">
                                                           {row.transactionLink ? <BootstrapTooltip placement='bottom' title="Click to show transaction detail"><a onClick={() => {popupWindow(row.transactionLink, 'Transaction', window, 1000, 700)}} ><Chip className="status sky-blue" variant="outlined" label="Transaction" style={{cursor:"pointer"}} /></a></BootstrapTooltip>: ''} &nbsp;
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContentText>
                <Snackbar open={openToast} autoHideDuration={3000} onClose={handleClosed}>
                    <Alert onClose={handleClosed} severity={isErrorMessage ? "error" : "success"}>
                        {message}
                    </Alert>
                </Snackbar>
            </DialogContent>
            <Backdrop className="loader-signup" open={resLoading || loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Dialog>
    )
}
