export function twitterMessage(promoType:string, orgName:string) {
    return "Go grab your reward points for "+  orgName +" Organization using " + promoType + " in @pointsvilleApp \n\n Download on Play Store: https://bit.ly/3LOlgVI \n\n Download on App Store: https://apple.co/3HU4YYN  \n\n"
}

export function twitterHashTag(promoType:string) {
    if(promoType === "BillBoard") {
        return "PointsVille,Rewards,RewardsPoints";
    }
    else if(promoType === "Video") {
        return "PointsVille,Rewards,RewardsPoints,Video,Quiz";
    }
    else if(promoType === "Hunt") {
        return "PointsVille,Rewards,RewardsPoints";
    }
    else if(promoType === "Poi") {
        return "PointsVille,Rewards,RewardsPoints,POI";
    }
}