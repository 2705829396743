import React, { useEffect, useState } from "react";
import NotificationComponent from "../../components/NotificationComponent"

export default function Notification() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    window.addEventListener('storage', (e:any) => setShow(e.newValue == 'true' ? true : false));
    return () => {
      window.removeEventListener('storage', (e:any) => setShow(e.newValue == 'true' ? true : false));
    };
  }, []);
  const closeNotification = () => {
    setShow(false);
    localStorage.setItem('isShowNotification','false');
  }
  return (
    <React.Fragment>
      <NotificationComponent 
        title={'Notification'} 
        message='You have not created any token. Please create it.'
        show={show}
        closeNotification={closeNotification} />
    </React.Fragment>
  );
}
