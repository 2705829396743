import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useQuery, gql } from "@apollo/client";
import Loader from "../../components/Loader/loader";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import "./transactions.css"
import Chip from '@material-ui/core/Chip';
interface ITransList {
  // refresh:boolean;
  // handleRefresh: () => void;
  userId: string
}

const GET_TRANSACTION_QUERY = gql`

query getTransactionsGroupByOrgByUserId($userId: String!)
{
  getTransactionsGroupByOrgByUserId(userId: $userId)
    {
      orgName
        orgLogo
        orgType
        orgRate
        price
        points
        orgId
    }
}`


export default function BrandOverview(props: ITransList) {

  const EmptyOrg = "https://png.pngtree.com/png-vector/20190321/ourmid/pngtree-vector-users-icon-png-image_856952.jpg";


  const columns: any = [
    {
      name: "orgName",
      label: " ",
      width: 1,
      options: {
        filter: false,
        sortThirdClickReset: true,
        sort: false,
        customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
          return (
            <td className={"table-header-sorting "} key={1}>
              <a >Organization</a>
            </td>
          )
        },
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          let orgLogo = `${tableMeta.tableData[tableMeta.rowIndex].orgLogo}`;
          return (<div><img className='profile-img' src={orgLogo || EmptyOrg} /><label className='membername'>{value}</label></div>)
        }
      }
    },
    {
      name: "points",
      label: " ",
      width: 1,
      options: {
        filter: false,
        sortThirdClickReset: false,
        sort: false,
        customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
          return (
            <td className={"table-header-sorting "} key={2}>
              <a >Amount</a>
            </td>
          )
        },
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {

          return (<div><label className='membername'>{value}</label></div>)
        }
      }
    },
    {
      name: "points",
      label: " ",
      width: 1,
      options: {
        filter: false,
        sortThirdClickReset: false,
        sort: false,
        customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
          return (
            <td className={"table-header-sorting "} key={2}>
              <a >Active</a>
            </td>
          )
        },
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {

          return (<Chip className={"status green"} variant="outlined" label={"Active"} />)
        }
      }
    },

  ];

  const offset = 0;
  const limit = 10;
  const { refetch, loading, data, fetchMore } = useQuery<any>(GET_TRANSACTION_QUERY, { variables: { userId: props.userId, offset: offset, limit: limit } });
  const [ageFilterChecked, setAgeFilterChecked] = React.useState(false);
  const history = useHistory();
  const options: any = {
    filter: false,
    //count: data && data.getTransactionsByUserId && data.getTransactionsByUserId?.data && data.getTransactionsByUserId.data.count ? data.getTransactionsByUserId.data.count : 10,
    filterType: "dropdown",
    responsive: "standard",
    print: false,
    download: false,
    pagination: false,
    serverSide: false,
    viewColumns: false,
    selectableRowsHideCheckboxes: true,
    fixedSelectColumn: false,
    selectToolbarPlacement: "none",
    selectableRowsHeader: false,
    selectableRows: "none",
    selectableRowsOnClick: false,
    fixedHeader: true,
    tableBodyHeight:"400px",
    search: false,
    onTableChange: (action: any, tableState: any) => {
      switch (action) {
        case 'changeRowsPerPage':
          refetch({ userId: props.userId, offset: tableState.page * tableState.rowsPerPage, limit: tableState.rowsPerPage })
          break;
        case 'sort':
          if (tableState.sortOrder.direction !== 'none') {
            refetch({ sort: tableState.sortOrder })
          }
          else {
            refetch({ sort: null })
          }
          break;
        default:
          console.log('action not handled.');
      }
    }
  }
  return (
    <div className="table-container muidata-table pad0">
      {loading
        ? <Loader size="90px" />
        : <MUIDataTable title={""} data={data?.getTransactionsGroupByOrgByUserId} columns={columns} options={options} />
      }
    </div>
  )

}