import React from 'react';
import Button from '@mui/material/Button';
import Tron from "../../assets/tron.svg";
import { Backdrop, CircularProgress, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

interface Props {
    handleChangePubKey: (pubkey:any) => void,
}

function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ConnectTronWallet(props: Props) {
    const [open, setOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [isErrorMessage, setIsErrorMessage] = React.useState(false);
    const handleClose = (event: any, reason: any) => {
      setOpen(false);
    };

    const handleConnectWallet = async () => {
        try {
        if ((window as any).tronWeb) {
            //checking if wallet injected
                // const rd = await (window as any).tronWeb.ready;
                await (window as any).tronLink.request({method: 'tron_requestAccounts'})
                .then((response:any) => {
                    if(response) {
                        if(response.code !== 200) {
                            setOpen(true);
                            setIsErrorMessage(true);
                            setErrorMessage(response.message);
                        }
                        else {
                            localStorage.setItem("tron_wallet_key", (window as any).tronWeb.defaultAddress.base58);
                            props.handleChangePubKey((window as any).tronWeb.defaultAddress.base58);
                        }
                    }
                    else {
                        setOpen(true);
                        setIsErrorMessage(false);
                        setErrorMessage('Wallet detected please login ');
                    }
                })
                .catch((error:any) => {
                    console.log('error',error);
                    setOpen(true);
                    setIsErrorMessage(true);
                    setErrorMessage(error);
                })

          } else {
            //wallet is not detected at all
            setOpen(true);
            setErrorMessage('Please install tron chrome extensions ');
          }
        }
        catch (e) {
            console.log('e',e)
          }
    }
    return (
        <React.Fragment>
          <Button variant="outlined" style={{ width: "95%" }} onClick={handleConnectWallet} ><img src={Tron} style={{width:"5%", marginRight:"10px"}} /> Connect to TronLink</Button>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity={isErrorMessage ? "error" : "success"}>
                  {errorMessage}
              </Alert>
          </Snackbar>
        </React.Fragment>
      );
}