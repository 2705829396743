import React from 'react'
import { Route, Switch } from 'react-router-dom'
import CustomSignIn from './containers/authentication/signin'
import SignUp from './containers/authentication/signup'
import ForgotPassword from './containers/authentication/forgotpassword'

interface Props {
    onParentRender: () => void,
}

export default function AuthContainer({ onParentRender }: Props) {
    return (
        <Switch>
            <Route path="/signup">
                <SignUp />
            </Route>
            <Route path="/forgotpassword">
                <ForgotPassword />
            </Route>
            <Route path="/">
                <CustomSignIn />
            </Route>
        </Switch>
    )
}
