import React, { useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Loader from '../../components/Loader/loader';
import EmptyOrg from '../../assets/org-empty@2x.svg';
import { useQuery, gql, useMutation } from '@apollo/client';
import AddDailyPoiConfig from './addDailyPoiConfig';
import moment from 'moment';
import Button from "@material-ui/core/Button";
import ConformAlert from "./conformAlert";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Backdrop, CircularProgress, Snackbar } from '@material-ui/core';
export const GET_DAILY_POI_CONFIG = gql`
    query getDailyPoiConfig {
        getDailyPoiConfig {
            id
            orgId
            orgName
            orgLogo
            sponsorId
            sponsorName
            sponsorLogo
            validity
            keywords
            pointsPerCoin
            noCoinsPerHunt
            isActive
            createDate
        }
    }
`;

const APPROVE_HUNT_CONFIG = gql`
    mutation approveDailyPoiConfig($id: String!, $data: UpdatePoiConfigInput!) {
        approveDailyPoiConfig(id: $id, data: $data ) {
            id
            orgId
        }
   }
`;

export default function DailyPOI() {

    const { loading, data,refetch } = useQuery<any>(GET_DAILY_POI_CONFIG);
    const [open, setOpen] = React.useState(false);
    
    const [openConfirm, setConfirm] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [openToast, setOpenToast] = React.useState<any>(false);
    const [tostType, setToastType] = React.useState<any>("error");
    const [errorMsg, setErrorMsg] = React.useState<any>("");
    const [currentConfig,setCurrentConfig] = React.useState<any>("");
    const [approveHuntConfig] = useMutation(APPROVE_HUNT_CONFIG, {
        // after updating the post, we go to the home page
        onCompleted: () => {
            setConfirm(false);
            refetch();
        },
        onError: (data) => {
            setErrorMsg(data.message);
            setOpenToast(true);
        }
    });
    
    const Alert = (props: AlertProps) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClose = () => {
        setOpen(false);
        setConfirm(false);
    };

    const handleConformClick = () => {        
        approveHuntConfig({
            variables:
            {
                id: currentConfig.id,
                data:
                {
                    isActive: !currentConfig.isActive
                }
            }
        });
        
    }
    const handelStatusChange = (configData:any) => {
        setConfirm(true);
        setMessage(configData.isActive ? "Are you sure to deactivate daily POI?" : "Are you sure to activate daily POI?");
        console.log(configData);
        setCurrentConfig(configData);
    }
    
    const columns: any = [
        {
            name: "orgId",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "orgId") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={0} onClick={() => updateDirection(0)}>
                            <a className="sort-by">Organization</a>
                        </th>
                    )
                },
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    let orgdata = data && data?.getDailyPoiConfig.find((x:any) => x.orgId === value);
                    return (<div style={{display:'flex'}}><img className='profile-img' alt="" src={orgdata.orgLogo || EmptyOrg} /><label className='membername'>{orgdata.orgName}</label></div>)
                }
            }
        },
        {
            name: "sponsorId",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "sponsorId") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(1)} >
                            <a className="sort-by">Sponsor</a>
                        </th>
                    )
                },
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    let sponsordata = data && data?.getDailyPoiConfig.find((x:any) => x.sponsorId === value);
                    return (<div style={{display:'flex'}}><img className='profile-img' alt="" src={sponsordata.sponsorLogo || EmptyOrg} /><label className='membername'>{sponsordata.sponsorName}</label></div>)
                }
            }
        },
        {
            name: "validity",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "validity") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(2)} >
                            <a className="sort-by">Validity</a>
                        </th>
                    )
                },
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (<label className='membername'>{value}</label>)
                }
            }
        },
        {
            name: "keywords",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "decimals") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={3} onClick={() => updateDirection(3)} >
                            <a className="sort-by">Keyword</a>
                        </th>
                    )
                },
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (<label className='membername'>{value}</label>)
                }
            }
        },
        {
            name: "pointsPerCoin",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "pointsPerCoin") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={4} onClick={() => updateDirection(4)} >
                            <a className="sort-by">Points Per Coin</a>
                        </th>
                    )
                },
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (<label className='membername'>{value}</label>)
                }
            }
        },
        {
            name: "noCoinsPerHunt",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "noCoinsPerHunt") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={5} onClick={() => updateDirection(5)} >
                            <a className="sort-by">No. of Coins</a>
                        </th>
                    )
                },
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (<label className='membername'>{value}</label>)
                }
            }
        },
        {
            name: "isActive",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "isActive") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={6} onClick={() => updateDirection(6)} >
                            <a className="sort-by">Active</a>
                        </th>
                    )
                },
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (<label className='membername'>{value ? "Yes" : "No"}</label>)
                }
            }
        },
        {
            name: "createDate",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "createDate") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={7} onClick={() => updateDirection(7)} >
                            <a className="sort-by">Created Date</a>
                        </th>
                    )
                },
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (<label className='membername'>{moment(value).format('DD MMM YYYY HH:mm')}</label>)
                }
            }
        },
        {
            name: "isActive",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta: any, updateDirection: any, sortOrder: any) => {
                    let orderName = (sortOrder.name == "isActive") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting"} >
                            <a>Action</a>
                        </th>
                    )
                },
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                    return (<Button className="btn btn-primary add-btn" onClick={() => { handelStatusChange(tableMeta.tableData[tableMeta.rowIndex]) }}>{value ? "Deactive" : "Active"}</Button>
                 )
                    
                }
            }
        }
    ];
    
    const options: any = {
        filter: false,
       // count: 10,
        filterType: "dropdown",
        responsive: "standard",
        print: false,
        download: false,
        viewColumns: false,
        selectableRowsHideCheckboxes: true,
        fixedSelectColumn: false,
        selectToolbarPlacement: "none",
        selectableRowsHeader: false,
        selectableRows: "none",
        selectableRowsOnClick: false,
        fixedHeader: true,
        tableBodyHeight: '450px',
        search: false,
        pagination: true,
        onTableChange: (action:any, state:any) => {
            console.dir(state);
        }
    }
    return (
        <React.Fragment>
            <div className="list-page transaction-list-wrapper" style={{marginRight:"1%"}}>
                <Grid item sm={12} xs={12}>
                    <Paper className="table-wrapper padd-0">
                    <Grid container className="table-head-section" sm={12}>
                        <Grid item sm={8}>
                            <label className="table-heading">DAILY POI CONFIG LIST</label>
                        </Grid>
                        <Grid  item sm={4} className="select-with-label align-right flx-align-center flx-end">
                            {/* <label className="select-label">Sort By</label>
                            <DropdownSelect/> */}
                            <button onClick={() => setOpen(true)} data-view="Create" className="btn btn-primary add-btn"><span data-view="Create" className="btn-icon"></span><label className="btn-caption">Create Daily POI</label></button>
                        </Grid>
                    </Grid>
                        <div className="table-container muidata-table trans-cust-body">
                            {loading ?
                                (
                                    <Loader size="90px" />
                                ) : (
                                    <MUIDataTable
                                        title={""}
                                        data={data && data.getDailyPoiConfig && data.getDailyPoiConfig.length ? data.getDailyPoiConfig : []}
                                        columns={columns}
                                        options={options}
                                    />)
                            }
                        </div>
                    </Paper>
                </Grid>
            </div>
            <AddDailyPoiConfig onOpen ={open} handleCloseClick={handleClose} refetch={refetch} />
            <ConformAlert onOpen ={openConfirm} handleCloseClick={handleClose} message={message} handleConformClick={handleConformClick} />
            <Snackbar open={openToast} autoHideDuration={5000} onClose={() => { setOpenToast(false) }}>
                <Alert onClose={() => { setOpenToast(false) }} severity={tostType === "success" ? "success" : "error"}>{errorMsg}</Alert>
            </Snackbar>
        </React.Fragment>
    );
}