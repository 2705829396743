import React, { useState,useEffect ,useCallback, useRef, } from 'react';
import { RouteProps,useLocation ,RouteComponentProps,useHistory} from "react-router";
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import DoneIcon from '@material-ui/icons/Done';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ReactCrop from 'react-image-crop';
import { Auth } from 'aws-amplify';
import { useQuery, gql, useMutation } from '@apollo/client';
import Loader from '../../components/Loader/loader';
import '../../constant.css';
import '../memberprofile/memberprofile.css';
import '../adminprofiledetails/adminprofiledetails.css';
import ProfileImg from '../../assets/Combined Shape@2x.svg';
import "react-image-crop/dist/ReactCrop.css";


interface IFormUser {
    givenName: string;
    email: string;
    phoneNumber: string;
    
}
interface LocationState extends RouteComponentProps {
    state ?: string;
  }

interface TestState {
    crop?: ReactCrop.Crop;
}

const initialState = {
    crop: {
        x: 100,
        y: 200
    }
};
interface HTMLInputEvent extends Event {
    target: HTMLInputElement & EventTarget;
}

let defaultValues:any = {
    givenName:"",
    email:"",
    phoneNumber:""
}
const UPDATE_MEMBER = gql`
mutation updateUserDetails($username : String!,$data: UpdatedUserInput! ){
    updateUserDetails(username: $username, data: $data ){
        givenName
    }
   }`;
const GET_USER_BY_ID = gql`
  query getUsers($username: String!) {
    getUserWithIdAdmin(username: $username) {
        id
        username
        givenName
        nickName
        email
        profileImage
        dob
        address
        phoneNumber
        isActive

    }
  }
`;

const GET_PRE_SIGNED_URL = gql`
    query getPreSignedUrl($fileName: String!){
        getPreSignedUrl(fileName: $fileName){
            preSignedUrl
        }
    }
`;

// Increase pixel density for crop preview quality on retina screens.
const pixelRatio = window.devicePixelRatio || 1;

// We resize the canvas down when saving on retina devices otherwise the image
// will be double or triple the preview size.
function getResizedCanvas(canvas:any, newWidth:any, newHeight:any) {
  const tmpCanvas = document.createElement("canvas");
  tmpCanvas.width = newWidth;
  tmpCanvas.height = newHeight;

  const ctx:any = tmpCanvas.getContext("2d");
  ctx.drawImage(
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    0,
    0,
    newWidth,
    newHeight
  );

  return tmpCanvas;
}

function generateDownload(previewCanvas:any, crop:any) {
  if (!crop || !previewCanvas) {
    return;
  }

  const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height);

  canvas.toBlob(
    (blob) => {
      const previewUrl = window.URL.createObjectURL(blob);

      const anchor = document.createElement("a");
      anchor.download = "cropPreview.png";
      anchor.href = URL.createObjectURL(blob);
      anchor.click();

      window.URL.revokeObjectURL(previewUrl);
    },
    "image/png",
    1
  );
}

export default function AdminEditProfile(Test:TestState) {
    const history = useHistory();
    const {state} = useLocation<LocationState>();
    
    const [upImg, setUpImg] = useState();
    const imgRef = useRef(null);
    const [openToast,setOpenToast] = React.useState<any>(false);

    const previewCanvasRef = useRef(null);
    const [crop, setCrop] = useState<any>({ unit: "%", width: 30, aspect: 16 / 9 });
    const [completedCrop, setCompletedCrop] = useState(null);
    const [logoUrl, setlogoUrl] = useState<any>();
    const [loader,setLoader] = useState<any>(false);
    const [errorMsg,setErrorMsg] = useState<any>("");

    const cancelClick = () => history.push({pathname:'/profiledetails',state:state});
    
    const { handleSubmit, control, reset, errors,setValue,setError } =  useForm<IFormUser>({defaultValues,shouldUnregister:false});
   
    const [updateUserDetails] = useMutation(UPDATE_MEMBER,{ onCompleted : () =>  history.push({pathname:'/profiledetails',state:state}) });
    const onSubmit: SubmitHandler<IFormUser> = data => {
        //updateUser(data); 
        if(data.phoneNumber) {
            // let pattern = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
            let pattern = /^(\+\d{1,2}\s?)(\d{10})$/
            let result = pattern.test(data.phoneNumber);
            if(!result) {
                // setErrorMsg('Please enter valid phone number');
                // setOpenToast(true);
                setError('phoneNumber', { type: 'pattern', message: 'custom message' });


                return;
            }
        }
        updateUserDetails({
            variables : 
            { username : state,
                data : 
                { 
                    givenName : data.givenName,
                    email : data.email,
                    username:state,
                    phoneNumber : data.phoneNumber,
                    profilePicture:logoUrl
                }
            }
        })
        //updateUser(data);
    }

    // async function updateUser(data:any) {
    //     const user = await Auth.currentAuthenticatedUser();
    //     await Auth.updateUserAttributes(user, {
    //       'name': '105 Main St. New York, NY 10001',
    //       'email':''
    //     });
    //   }

    const { loading, data, refetch } = useQuery<any>(GET_USER_BY_ID, { fetchPolicy: "network-only", variables: { username: state } });

    // Below code to uplaod files to S3 bucket using presigned url.
    const [uploadFileName, setUploadFileName] = useState<any>('')
    const [targetFile, setTargetFile] = useState<any>([])
    const { loading: urlLoading, data: urlData, refetch: urlRefetch } = useQuery<any>(GET_PRE_SIGNED_URL, { skip: uploadFileName.length > 0 ? false : true , variables: { fileName: uploadFileName }});
   
    useEffect(() => {
        if(!loading){
            //setMemberDeails(data.getMemberWithId);
            const { givenName,email,phoneNumber,profileImage} = data.getUserWithIdAdmin;
            defaultValues = {givenName,email,phoneNumber,profileImage};
            setValue("givenName", getUserName(defaultValues.givenName));
            setValue("phoneNumber",defaultValues.phoneNumber);
            setValue("email",defaultValues.email);
            setlogoUrl(defaultValues.profileImage);
        }
        
        if(!urlLoading && uploadFileName.length > 0) {
            urlRefetch()
            if (urlData) {
                setUploadFileName('')
                fileUploadToS3(urlData.getPreSignedUrl.preSignedUrl)
            }
        }
    },[uploadFileName, urlData, data, setValue]);

  
    //state = initialState;

    // const onChange = (crop: ReactCrop.Crop) => {
    //     setCrop(crop);
    // }

    const onLoad = useCallback((img:any) => {
        imgRef.current = img;
      }, []);
    
      useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
          return;
        }
    
        const image:any = imgRef.current;
        const canvas:any = previewCanvasRef.current;
        const crop:any = completedCrop;
    
        const scaleX = image?.naturalWidth / image?.width;
        const scaleY = image?.naturalHeight / image?.height;
        const ctx = canvas.getContext("2d");
    
        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;
    
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
      }, [completedCrop]);

    const onSelectFile = (e?: React.ChangeEvent<any> ) => {
        let files:any = (e?.target).files;
        if (files && files.length > 0) {
          const reader:any = new FileReader();
          reader.addEventListener("load", () => setUpImg(reader.result));
          reader.readAsDataURL(files[0]);
        }
      };
    // handleChangeFile(selectorFiles: FileList)
    // {
    // }

    // const S3Client = new S3(config.s3Config);

    // to upload a image to S3 using presigned url.
    const fileUploadToS3 = (objUrl:any) => {
        if (objUrl) {
            fetch(objUrl, {
                method: 'PUT',
                headers: { 'Content-Type': 'image/*' },
                body: targetFile[0]
            }).then((response) => {
                if (response && response.url) {
                    setlogoUrl(response.url.split('?')[0]);
                    setLoader(false);
                    updateUserDetails({
                        variables : 
                        { username : state,
                            data : 
                            { 
                                givenName : data.givenName,
                                email : data.email,
                                username:state,
                                phoneNumber : data.phoneNumber,
                                profilePicture:response.url.split("?")[0]
                            }
                        }
                    })
                }
            })
        }
    }
    

    const uploadImage = (e:React.ChangeEvent<any>) =>{
        const targetFiles:any = e.target.files;

        if (targetFiles.length === 0) return;

        if(targetFiles[0].type === "image/png" || targetFiles[0].type === "image/jpeg"){
            setLoader(true);
            if (targetFiles.length > 0) {
                // After passing targetFiles and file name then, upload logic to S3 using presigned url written in useeffect.
                setUploadFileName(targetFiles[0].name)
                setTargetFile(targetFiles)
            }
        }else{
            setErrorMsg("Image format should png,jpg");
            setOpenToast(true);
        }
    }

    const getUserName = (name:any) => {
        if (name && name.search('&') > 0) {
            return name.replace('/&/gi', ' ')
        } else return name;
    }

    return (
    <div className="detail-page admin-profile-details">
        <div className="page-head p-relative padl-40">
            
        </div>
        <div className="page-body padl-30" >
            <Grid container spacing={3} sm={12}>
                <Grid item className="mrgCenter" sm={6} spacing={1}>
                        <div className="input-group input-file-group edit admin-profile-edit">
                            <img className="profile-img-editview" src={ logoUrl || ProfileImg} alt="org-logo"/>
                                </div>
                     {/* <div className="profile-img-section p-relative profile-details edit-profiledetails align-center mrgb-20">
                        <img className="profile-img" src={ProfileImg}/>
                        <span className="profile-photo-icon"></span>
                    </div> */}
                    {/* <div className="profile-img-section edit-profile-details align-center" style={{backgroundImage:`url(${ProfileImg})`}}>
                        <span className="profile-photo-icon"></span>
                        {/* <div>
                            <input type="file" accept="image/*" onChange={(e) => onSelectFile(e)} />
                        </div> 
                         <ReactCrop
                            src={upImg}
                            onImageLoaded={onLoad}
                            crop={Test.crop}
                            onChange={(c:any) => setCrop(c)}
                            onComplete={(c:any) => setCompletedCrop(c)}
                        /> 
                        <div>
                    <canvas
                    ref={previewCanvasRef}
                    // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                    style={{
                        width: 100,
                        height: 100
                    }}
                    /> 
                </div>*/}
                        {/* <div>
        <input type="file" accept="image/*" onChange={ (e) => handleChangeFile(e.target.files) } />
      </div> 
                    </div> */}
                    <Paper className="">
                        <Grid container className="profile-details flx-align-center" sm={12} spacing={0}>
                            <Grid item sm={12} xs={12}>
                                <h5 className="profile-name">Personal Info</h5>
                            </Grid>
                        </Grid>    
                        <div className="admin-personal-info-section edit-container">
                            <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
                            <Grid item sm={12}>
                                <div className="input-group">
                                    <label className="input-label" >Full Name*</label>
                                    <Controller as={TextField}  rules={{ required: true}} defaultValue = ""  className={`form-fields  ${errors.givenName && 'error'}`} type="text" name="givenName" placeholder="Full Name" control={control} />                            
                                    {errors.givenName && <label className="error-message">Field is required</label>}
                                </div>
                            </Grid>
                            <Grid item sm={12}>
                                 <div className="input-group">
                                    <label className="input-label" >Email*</label>
                                    <Controller as={TextField}  type="email" name="email" disabled placeholder="Email" defaultValue ="" rules={{ required: true,pattern :/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/}} className={`form-fields  ${errors.email && 'error'}`} control={control} />                            
                                    {errors.email && errors.email.type === "required" && (<label className="error-message">Field is required</label>)}
                                    {errors.email && errors.email.type === "pattern" && (<label className="error-message">Field should be in mail format</label>)}
                                </div>
                            </Grid>
                            <Grid item sm={12}>
                                 {/* <div className="input-group">
                                    <label className="input-label" >Phone Number</label>
                                    <Controller as={TextField}  type="text" rules={{ pattern:/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/ }} defaultValue ="" name="phoneNumber" placeholder="Contact Phone Number"  className={`form-fields  ${errors.phoneNumber && 'error'}`} control={control}  />                            
                                    {errors.phoneNumber && errors.phoneNumber.type === "pattern" && (<label className="error-message">Please enter valid phone number</label>)}
                                    {errors.phoneNumber && errors.phoneNumber.type === "required" && (<label className="error-message">Field is required</label>)}
                                </div> */}
                                <div className="input-group">
                                    <label className="input-label">Contact Phone</label>
                                    <Controller as={TextField} type="text" name="phoneNumber" placeholder="Phone Number" className={`form-fields ${errors.phoneNumber && 'error'}`} control={control} defaultValue="" />
                                    {errors.phoneNumber && errors.phoneNumber.type === "pattern" && (<label className="error-message">Please enter valid phone number</label>)}
                                </div>
                            </Grid>
                            <Grid item className="align-right" sm={12}>
                                <Button onClick={cancelClick} className="btn btn-secondary mrgl-30 btn-padding" color="primary">
                                    CANCEL
                                </Button>
                                <Button color="primary" className="btn btn-primary button mrgl-30 btn-padding" onClick={handleSubmit(onSubmit)}>
                                    SAVE   
                                </Button>
                            </Grid>
                            
                            </form>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
            
        </div>
    </div>
    )
}
