

import React, { useEffect, useState, useRef } from 'react'
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import InputLabel from '@mui/material/InputLabel';
import { SelectChangeEvent } from '@mui/material/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Select, MenuItem, Typography, } from "@material-ui/core";
import { Backdrop, CircularProgress, Snackbar } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { styled } from "@mui/material/styles";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/Chip";

import moment from 'moment';
import "moment-timezone";

import { config, countryList } from '../../../config';

import AvatarEditor from 'react-avatar-editor';
import { gql, useMutation, useQuery } from '@apollo/client';

import AvatarDefault from '../../../assets/ic_avatar-default.svg';
import CamerLogo from '../../../assets/ic_camera.svg';
import coinImage from '../../../assets/PiratesCoin.png'

import '../../../constant.css';
import '../manageclubs.css';

import mergeImages from 'merge-images';
// import { truncate } from 'fs';

import { EditorState, ContentState, convertToRaw, convertFromRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
// import draftToHtml from 'draftjs-to-html'
// import htmlToDraft from 'html-to-draftjs'
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { AnyAaaaRecord } from 'dns';
import ChipInput from 'material-ui-chip-input';
import { useEthers } from "@usedapp/core";
import NetworkManager from '../../../utilities/NetworkManager';
import MinitingToken from  '../../clubdetails/minitingtoken';
interface Props {
    onOpen: boolean,
    handleCloseClick: () => void,
    handleDeleteCloseClick: () => void,
    editData?: string,
    editId?: string
}

interface IFormAddClub {
    orgLogo: string;
    name: string;
    orgCode: string;
    orgType: string;
    // orgBio: string;
    rate: number;
    rosterApi: string;
    scheduleApi: string;
    videoApi: string;
    eventsApi: string;
    newsApi: string;
    facebook: string;
    instagram: string;
    twitter: string;
    youtube: string;
    playstore: string;
    appstore: string;
    default_reward: string;
    deploy_tokens: string;
    blockChainType: string;
}

let defaultValues: any = {
    orgLogo: "",
    name: "",
    orgCode: "",
    // orgBio: "",
    orgType: "others",
    rate: 0.01,
    rosterApi: "https://www.mlb.com/",
    scheduleApi: "https://www.mlb.com/",
    videoApi: "https://www.mlb.com/",
    eventsApi: "https://www.mlb.com/",
    newsApi: "https://www.mlb.com/",
    facebook: "",
    instagram: "",
    twitter: "",
    youtube: "",
    playstore: "",
    appstore: "",
    default_reward : "points",
    deploy_tokens : "testnet",
    blockChainType: "Avalanche"
}

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const useStyles = makeStyles((theme) => ({
    formControl: {
        //   margin: theme.spacing(1),
        //   minWidth: 120,
        //   maxWidth: 300
    },
    chips: {
        display: "flex",
        flexWrap: "wrap"
    },
    chip: {
        margin: 2
    },
    noLabel: {
        marginTop: theme.spacing(3)
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

function getStyles(countryName: any, countryListArr: any, theme: any) {
    return {
        fontWeight:
            countryListArr.indexOf(countryName) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium
    };
}

const UPDATE_ORG = gql`
    mutation updateOrgs($id: String!, $data: UpdateClubInput!) {
        updateOrg(id: $id, data: $data) {
            id
            name
            orgCode
            rate
            isMainnet
            blockChainType
        }
    }
`;

const ACTIVE_DEACTIVE_ORG = gql`
    mutation deActiveOrg($id : String!,$data: UpdateClubInput!) {
        deActiveOrg(id: $id, data: $data) {
            name
            orgCode
        }
    }
`;

const APPROVE_ORG = gql`
    mutation approveOrg($id: String!, $data: UpdateClubInput!) {
        approveOrg(id: $id, data: $data ) {
            name
            orgCode
        }
   }
`;

const CREATE_ORG = gql`
    mutation createOrgs($data: NewClubInput!) {
        createOrg(data: $data) {
            id
            name
            orgCode
            rate
            isMainnet
            blockChainType
        }
    }
`;

const GET_ORG_BY_ID = gql`
    query getClub($clubId: String!) {
        getClub(clubId: $clubId) {
            id
            name
            orgCode
            orgType
            orgBio
            countryBlackList
            logoUrl
            rate
            rosterApi
            scheduleApi
            videoApi
            eventsApi
            newsApi
            facebook
            instagram
            youtube
            twitter
            playstore
            appstore
            isActive
            isApproved
            isPointsOnly
            emailsForNotification
            isToken
            isMainnet
            blockChainType
        }
    }
`;

const CHECK_ORG_CODE = gql`
    query checkOrgCode($org_code: String!) {
        checkOrgExist(org_code: $org_code)
    }
`;

const GET_PRE_SIGNED_URL = `
    query getPreSignedUrl($fileName: String!) {
        getPreSignedUrl(fileName: $fileName) {
            preSignedUrl
        }
    }
`;

export const GET_ORGS_TYPES = gql`
    query GetOrgTypes {
        getOrgTypes {
            id
            title
            value
        }
    }
`;

const GET_ME = gql`
    query me {
        me {
            givenName
            userRole
        }
    }
`;

export default function CreateClub({ handleCloseClick, handleDeleteCloseClick, onOpen, editData, editId }: Props) {
    const open = onOpen;
    const orgId = editId ? editId : "";

    const setEditorRef: any = useRef();

    // Org Info..
    // const { contentBlocks, entityMap } = htmlToDraft('')
    // const contentStateFromDB = ContentState.createFromBlockArray(contentBlocks, entityMap)
    // const editorStateFromDB = EditorState.createWithContent(contentStateFromDB)

    const markdownString = '';
    const rawData = markdownToDraft(markdownString);
    const contentStateFromDB = convertFromRaw(rawData);
    const editorStateFromDB = EditorState.createWithContent(contentStateFromDB);

    const [editorState, setEditorState] = useState<any>(editorStateFromDB);
    const [contentState, setContentState] = useState<any>(contentStateFromDB);

    const [avatarImageToCrop, setAvatarImageToCrop] = React.useState<any>([]);
    const [load, setLoad] = useState(false);

    const [logoUrl, setlogoUrl] = useState<any>();
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [errorMsg, setErrorMsg] = useState<any>("");
    const [isActiveStatus, setIsActiveStatus] = useState<any>(true);
    const [isApproved, setIsApproved] = useState<any>(false);
    const [openToast, setOpenToast] = React.useState<any>(false);
    const [tostType, setToastType] = React.useState<any>("error");
    const [typesOrg, setTypesOrg] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [zoomValue, setZoomValue] = React.useState(0.8);

    const [mergedImage, setMergedImage] = useState('');

    const [pointsOnly, setPointsOnly] = React.useState<any>(false);
    const [emailNotifications, setEmailNotifications] = React.useState<any>([]);
    const [emailNotificationValidation, setEmailNotificationValidation] = React.useState<any>(false);
    const [emailNotificationsList, setEmailNotificationList] = React.useState<any>(false);
    const [visible, setVisible] = React.useState(false)
    const [dropdown, setDropdown] = React.useState('');
    const [contractAddress, setContractAddress] = React.useState("");
    const { activateBrowserWallet, account, deactivate } = useEthers();
    const [defaultReward, setDefaultReward] = React.useState(defaultValues.default_reward);
    const [page, setPage] = React.useState(0);
    const [orgDataToken, setOrgDataToken] = React.useState('');
    const Visibility = () => {
        setVisible(!visible)
    }

    const dropdownSeter = (event: SelectChangeEvent) => {
        setDropdown(event.target.value as string);
    };

    const handleZoomChange = async (event: any, newValue: any) => {
        setZoomValue(newValue);

        handleMergeImages();
    };

    const Alert = (props: AlertProps) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const classes = useStyles();
    const theme = useTheme();
    const [selCountryList, setSelCountryList] = React.useState([]);

    const handleChangeCountry = (event: any) => {
        setSelCountryList(event.target.value);
        setWhiteCountryList([]);
        let countryArr: any = [];
        countryArr = countryList.filter(({ name: id1 }) => !event.target.value.some((id2: any) => id2 === id1));

        setWhiteCountryList(countryNameArray(countryArr));
    };

    const [whiteCountryList, setWhiteCountryList] = React.useState([]);

    const handleChangeWhiteCountry = (event: any) => {
        let isAllSelected: boolean = event.target.value.includes('All');
        let countryArr: any = [];
        setSelCountryList([]);
        if (isAllSelected) {
            setWhiteCountryList([]);
            let allCountryList: any = [];
            countryList.forEach((countryList: any) => {
                allCountryList.push(countryList.name);
            });
            setWhiteCountryList(allCountryList);
        }
        else {
            setWhiteCountryList(event.target.value);
            countryArr = countryList.filter(({ name: id1 }) => !event.target.value.some((id2: any) => id2 === id1));
            setSelCountryList(countryNameArray(countryArr));
        }
    };

    const countryNameArray = (list: any) => {
        let arr: any = [];
        list.forEach((element: any) => {
            arr.push(element.name);
        });

        return arr;
    }
    const { loading, data, refetch } = useQuery<any>(GET_ORG_BY_ID, { variables: { clubId: orgId }, fetchPolicy: "network-only" });
    const { refetch: orgTypesRefetch, loading: orgTypesLoading, data: orgTypes } = useQuery<any>(GET_ORGS_TYPES);
    const { loading: orgCodeLoad, data: orgCodeRes, fetchMore, error } = useQuery<any>(CHECK_ORG_CODE, { variables: { org_code: orgId } });
    const { loading: meLoading, data: meData } = useQuery<any>(GET_ME, { fetchPolicy: "network-only" });

    const { register, handleSubmit, errors, control, reset, formState, setValue, clearErrors } = useForm<IFormAddClub>({ defaultValues, shouldUnregister: false });
    const [tokenData, setTokenData] = React.useState<any>("");
    const fieldSetValueFunction = (defaultValue: any) => {
        setlogoUrl(defaultValues.logoUrl);
        setValue("name", defaultValues.name);
        setValue("orgCode", defaultValues.orgCode);
        setValue("orgType", defaultValues.orgType);
        // setValue("orgBio", defaultValues.orgBio);
        // setValue("orgLogo",defaultValues.logoUrl);
        setValue("rate", defaultValues.rate);
        setValue("rosterApi", defaultValues.rosterApi);
        setValue("scheduleApi", defaultValues.scheduleApi);
        setValue("videoApi", defaultValues.videoApi);
        setValue("eventsApi", defaultValues.eventsApi);
        setValue("newsApi", defaultValues.newsApi);
        setValue("facebook", defaultValues.facebook);
        setValue("instagram", defaultValues.instagram);
        setValue("twitter", defaultValues.twitter);
        setValue("youtube", defaultValues.youtube);
        setValue("playstore", defaultValues.playstore);
        setValue("appstore", defaultValues.appstore);
        setDefaultReward(defaultValues.default_reward);
        setValue("default_reward", defaultValues.default_reward);
        setValue("deploy_tokens", defaultValues.deploy_tokens);
        setValue("blockChainType", defaultValues.blockChainType);

        if (orgId) setPointsOnly(defaultValues.isPointsOnly);
        else setPointsOnly(false);
    }

    useEffect(() => {
        refetch();
        clearErrors();
        if (!loading && !orgTypesLoading && editId && data) {
            defaultValues = { ...data.getClub };
            setIsActiveStatus(!data.getClub.isActive);
            setIsApproved(data.getClub.isApproved);
            fieldSetValueFunction(defaultValues);
            setTypesOrg(orgTypes.getOrgTypes);

            // Org Info..
            let orgBio: any = data.getClub.orgBio;

            // Country blacklist.
            if (data.getClub.countryBlackList) {
                let tempSelCountryList: any = JSON.parse(data.getClub.countryBlackList);
                let selCountryArr: any = [];
                let countryArr: any = [];
                setWhiteCountryList([]);
                tempSelCountryList.forEach((selCountryCode: any) => {
                    countryList.forEach((countryList: any) => {
                        if (selCountryCode === countryList.code) {
                            selCountryArr.push(countryList.name);
                        }
                    });
                });
                countryArr = countryList.filter(({ name: id1 }) => !selCountryArr.some((id2: any) => id2 === id1));
                setSelCountryList(selCountryArr);
                if(selCountryArr.length > 0) {
                    setWhiteCountryList(countryNameArray(countryArr));
                }
            }

            if (data.getClub.emailsForNotification) {
                let tempEmailNotificationList: any = JSON.parse(data.getClub.emailsForNotification);
                let emailNotificationsList: any = [];
                tempEmailNotificationList.forEach((value: any) => {
                    emailNotificationsList.push(value);
                });
                setEmailNotifications(emailNotificationsList);
            }



            // let FinalOrgBio:any = '';
            // if (orgBio && orgBio.includes("</p>")) FinalOrgBio = orgBio;
            // else FinalOrgBio = '<p>' + (orgBio ? orgBio : '')  + '</p>'; // to support existing orgbio with null values.

            // const { contentBlocks, entityMap } = htmlToDraft(FinalOrgBio);
            // const contentStateFromDB = ContentState.createFromBlockArray(contentBlocks, entityMap);
            // const editorStateFromDB = EditorState.createWithContent(contentStateFromDB);
            setDefaultReward(data && data?.getClub?.isToken === true ? 'tokens' : 'points');
            setValue("default_reward", data && data?.getClub?.isToken === true ? 'tokens' : 'points');
            setValue("deploy_tokens", data && data?.getClub?.isMainnet === true ? 'mainnet' : 'testnet');
            const rawData = markdownToDraft(orgBio);
            const contentStateFromDB = convertFromRaw(rawData);
            const editorStateFromDB = EditorState.createWithContent(contentStateFromDB);

            setEditorState(editorStateFromDB);
            setContentState(contentStateFromDB);
            getTokenID();
        } else {
            defaultValues = {
                orgLogo: "",
                name: "",
                orgCode: "",
                // orgBio: "",
                orgType: "",
                rate: 0.01,
                rosterApi: "https://www.mlb.com/",
                scheduleApi: "https://www.mlb.com/",
                videoApi: "https://www.mlb.com/",
                eventsApi: "https://www.mlb.com/",
                newsApi: "https://www.mlb.com/",
                facebook: "",
                instagram: "",
                youtube: "",
                twitter: "",
                playstore: "",
                appstore: "",
                default_reward : "points",
                deploy_tokens : "testnet",
                blockChainType : "Avalanche"
            }
            fieldSetValueFunction(defaultValues);
            setEditorState('');
            setContentState('');
            setSelCountryList([]);
            setWhiteCountryList([]);
            setEmailNotifications([]);
            setEmailNotificationValidation(false);
        }
    }, [data, open, setValue, orgTypes]);

    useEffect(() => {
        if (!orgTypesLoading) {
            setTypesOrg(orgTypes?.getOrgTypes);
        }
    }, [orgTypes]);

    useEffect(() => {
        if (!meLoading && meData.me.userRole === 'ADMIN') {
            setIsAdmin(true);
        }
    }, [meLoading, meData]);

    const handleClosed = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenToast(false);
        setToastType("error");
    };

    const [createOrgs] = useMutation(CREATE_ORG, {
        // after updating the post, we go to the home page
        onCompleted: (data:any) => {
            setOrgDataToken(data.createOrg);
            defaultReward === 'tokens' ? setPage(1) : clickCanceled();
        },
        onError: (data) => {
            setErrorMsg(data.message);
            setOpenToast(true);
        }
    });

    const [deActiveOrg] = useMutation(ACTIVE_DEACTIVE_ORG, {
        // after updating the post, we go to the home page
        onCompleted: () => handleDeleteCloseClick(),
        onError: (data) => {
            setErrorMsg(data.message);
            setOpenToast(true);
        }
    });

    const [approveOrg] = useMutation(APPROVE_ORG, {
        // after updating the post, we go to the home page
        onCompleted: () => handleCloseClick(),
        onError: (data) => {
            setErrorMsg(data.message);
            setOpenToast(true);
        }
    });

    const [updateOrgs] = useMutation(UPDATE_ORG, {
        // after updating the post, we go to the home page
        onCompleted: (data:any) => {
            setOrgDataToken(data.updateOrg);
            tokenData?.has_token === false && defaultReward === 'tokens' ? setPage(1) : clickCanceled();
        },
        onError: (data) => {
            setErrorMsg(data.message);
            setOpenToast(true);
        }
    });

    // to update org info.
    const updateOrg = (data: any, imageUrl: string) => {
        let orgRate: number = parseFloat(data.rate);

        let selCountryArr: any = [];
        selCountryList.forEach((selCountry: any) => {
            countryList.forEach((countryList: any) => {
                if (selCountry === countryList.name) {
                    selCountryArr.push(countryList.code);
                }
            });
        });

        updateOrgs({
            variables:
            {
                id: orgId,
                data:
                {
                    name: data.name.toUpperCase(),
                    rate: parseFloat(orgRate.toFixed(2)),
                    orgCode: data.orgCode.toUpperCase(),
                    orgType: data.orgType,
                    countryBlackList: JSON.stringify(selCountryArr),
                    orgBio: editorState ? draftToMarkdown(convertToRaw(editorState.getCurrentContent())) : '', // draftToHtml(contentState), // data.orgBio,
                    logoUrl: imageUrl,
                    rosterApi: data.rosterApi,
                    scheduleApi: data.scheduleApi,
                    videoApi: data.videoApi,
                    eventsApi: data.eventsApi,
                    newsApi: data.newsApi,
                    facebook: data.facebook,
                    instagram: data.instagram,
                    youtube: data.youtube,
                    playstore: data.playstore,
                    appstore: data.appstore,
                    twitter: data.twitter,
                    isPointsOnly: pointsOnly,
                    emailsForNotification: JSON.stringify(emailNotifications),
                    isToken : data.default_reward === 'tokens' ? true : false,
                    isMainnet : data.deploy_tokens === 'mainnet' ? true : false,
                    blockChainType : data.blockChainType
                }
            }
        });
    }

    // to create new org info.
    const createOrg = (data: any, imageUrl: string) => {
        let orgRate: number = parseFloat(data.rate);

        let selCountryArr: any = [];
        selCountryList.forEach((selCountry: any) => {
            countryList.forEach((countryList: any) => {
                if (selCountry === countryList.name) {
                    selCountryArr.push(countryList.code);
                }
            });
        });
        createOrgs({
            variables:
            {
                data:
                {
                    name: data.name.toUpperCase(),
                    rate: parseFloat(orgRate.toFixed(2)),
                    orgCode: data.orgCode.toUpperCase(),
                    orgType: data.orgType,
                    countryBlackList: JSON.stringify(selCountryArr),
                    orgBio: editorState ? draftToMarkdown(convertToRaw(editorState.getCurrentContent())) : '', // draftToHtml(contentState), // data.orgBio,
                    logoUrl: imageUrl,
                    rosterApi: data.rosterApi,
                    scheduleApi: data.scheduleApi,
                    videoApi: data.videoApi,
                    eventsApi: data.eventsApi,
                    newsApi: data.newsApi,
                    facebook: data.facebook,
                    instagram: data.instagram,
                    youtube: data.youtube,
                    playstore: data.playstore,
                    appstore: data.appstore,
                    twitter: data.twitter,
                    isPointsOnly: pointsOnly,
                    emailsForNotification: JSON.stringify(emailNotifications),
                    isToken : data.default_reward === 'tokens' ? true : false,
                    isMainnet : data.deploy_tokens === 'mainnet' ? true : false,
                    blockChainType : data.blockChainType
                }
            }
        });
    }

    const getResizedUrl = (base64url: any) => {
        // to get base64 url for selected org logo position.
        return new Promise((resolve) => {
            let img = new Image()
            img.src = base64url;

            img.onload = () => {
                let canvas = document.createElement('canvas');
                canvas.width = 216;
                canvas.height = 216;
                let ctx: any = canvas.getContext('2d');
                ctx.drawImage(img, 39, 39, 138, 138); // here as => 39+39+138 = 216 is coin image actual width and height.
                resolve(canvas.toDataURL());
            }
        })
    }

    const onSubmit: SubmitHandler<any> = async (data) => {
        setLoad(true);
        if (avatarImageToCrop.length > 0) {
            let uploadFileName = data.orgCode.trim() + '_' + avatarImageToCrop[0].name.split('.')[0] + '_' + moment().toDate().getTime() + '.png';

            let canvas = setEditorRef.current.getImage().toDataURL('image/png', 1.0);
            let convertedImage: any = await getResizedUrl(canvas);

            const mImage = await mergeImages([{ src: convertedImage }, { src: coinImage }], { width: 216, height: 216 }).then((b64: any) => b64);

            // setMergeImage(mImage);

            let tempTargetFile: any = dataURLtoFile(mImage, uploadFileName);

            // setEmailNotificationList(emailNotifications.length == 0);
            // to get S3 image url after uploading file using presigned url.
            fetch(config.baseURL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    query: GET_PRE_SIGNED_URL,
                    variables: { fileName: uploadFileName },
                }),
            })
                .then((res) => res.json())
                .then(async (result) => {
                    fetch(await result.data.getPreSignedUrl.preSignedUrl, {
                        method: 'PUT',
                        headers: { 'Content-Type': 'image/*' },
                        body: tempTargetFile
                    }).then(async (response) => {
                        if (response && response.url) {
                            if (orgId) {
                                // to update org info with new logo url.
                                updateOrg(data, await response.url.split('?')[0]);
                                setLoad(false);
                            } else {
                                // to create org info with new logo url.
                                createOrg(data, await response.url.split('?')[0]);
                                setLoad(false);
                            }
                        }
                    });
                });
        } else {
            if (orgId) {
                // to update org info with existing logo url.
                // setEmailNotificationList(emailNotifications.length == 0);
                updateOrg(data, logoUrl);
                setLoad(false);
            } else {
                setErrorMsg('Please upload organization logo');
                setOpenToast(true);
                setLoad(false);
                return;
            }
        }
    }

    // to Convert selected avatar base64 data to image file to uplaod file to s3 bucket.
    const dataURLtoFile = (dataurl: any, filename: any) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }

    const uploadImage = async (e: React.ChangeEvent<any>) => {
        const targetFiles = e.target.files;

        if (targetFiles.length === 0) return;

        if (targetFiles[0].type === "image/png" || targetFiles[0].type === "image/jpeg") {
            // setLoader(true);
            if (targetFiles.length > 0) {
                setMergedImage('');
                setAvatarImageToCrop(targetFiles);
            }
        } else {
            setErrorMsg("Image format should be in png,jpg,svg");
            setOpenToast(true);
        }
    }

    const clickCanceled = () => {
        setAvatarImageToCrop([]);
        setMergedImage('');
        // setConfirmed(false);
        setOpenConfirm(false);
        handleCloseClick();
        setSelCountryList([]);
        setWhiteCountryList([]);
        setPage(0);
    }

    const clickConfirmed: SubmitHandler<any> = (fieldData) => {
        if (orgId) {
            deActiveOrg({
                variables:
                {
                    id: orgId,
                    data:
                    {
                        isActive: isActiveStatus
                    }
                }
            });
        }
        // setConfirmed(true);
        setOpenConfirm(false);
    }

    const handleChangeField = (e: React.ChangeEvent<any>) => {
        const targetValue = (e.target as HTMLInputElement).value;
        const targetName:any = (e.target as HTMLInputElement).name;
        setValue(targetName, targetValue);
        if (targetName === 'orgCode') {
            targetValue.toLowerCase()
            setValue("rosterApi", "https://www.mlb.com/" + targetValue.toLowerCase() + "/roster");
            setValue("scheduleApi", "https://www.mlb.com/" + targetValue.toLowerCase() + "/schedule");
            setValue("videoApi", "https://www.mlb.com/" + targetValue.toLowerCase() + "/video");
            setValue("eventsApi", "https://www.mlb.com/" + targetValue.toLowerCase());
            setValue("newsApi", "https://www.mlb.com/" + targetValue.toLowerCase() + "/news");
            // setValue("facebook", "https://www.facebook.com/" + targetValue.toLowerCase());
            // setValue("instagram", targetValue.toLowerCase());
            // setValue("twitter", "https://twitter.com/" + targetValue.toLowerCase());
        }
        if(targetName === 'default_reward') {
            setDefaultReward(targetValue)
        }
    }

    const handleInActiveClick = (orgId: any) => {
        setOpenConfirm(true);
    }

    const handleInApproveClick = (orgId: any) => {
        if (orgId) {
            approveOrg({
                variables:
                {
                    id: orgId,
                    data:
                    {
                        isApproved: !isApproved
                    }
                }
            });
            refetch();
        }
    }

    const handleMergeImages = async () => {
        let canvas = setEditorRef.current.getImage().toDataURL('image/png', 1.0);
        let convertedImage: any = await getResizedUrl(canvas);

        const mImage = await mergeImages([{ src: convertedImage }, { src: coinImage }], { width: 216, height: 216 }).then((b64: any) => b64);

        setMergedImage(mImage);
    }

    const handlePointsOnlyCheckBox = () => {
        setPointsOnly(!pointsOnly)
    }

    const onEditorStateChange = (editorState: any) => {
        // setEditorState(editorState);
        const MAX_LENGTH = 500;
        const length = editorState.getCurrentContent().getPlainText('').length;
        if (length <= MAX_LENGTH) setEditorState(editorState);
    }

    const onContentStateChange = (contentState: any) => {
        // setContentState(contentState);
        const MAX_LENGTH = 500;
        const length = editorState.getCurrentContent().getPlainText('').length;
        if (length <= MAX_LENGTH) setContentState(contentState);
    };


    const handleAddChip = (chip: any) => {
        let reg = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/
        //  reg.test(email.trim()) === true
        if (reg.test(chip.trim()) === true) {
            const addChips = emailNotifications.slice();
            addChips.push(chip);
            setEmailNotifications(addChips);
        } else {
            setEmailNotificationValidation(true);
        }


    }

    const handleDeleteChip = (chip: any, index: number) => {

        const removeChips = emailNotifications.slice();
        removeChips.splice(index, 1);
        setEmailNotifications(removeChips);
    }

    const handleResetCounryList = () => {
        setSelCountryList([]);
        setWhiteCountryList([]);
    }

    const handleUpdateWallet = async () => {
        setLoad(true);
        await activateBrowserWallet();
        if ((window as any).ethereum) {
            if ((window as any).ethereum.selectedAddress) {
                let dataToSend: any = {
                    organization_id: orgId,
                    wallet_type: "CONNECTED",
                    wallet_name: data ? data?.getClub?.name + " Metamask wallet" : "",
                    private_key: "",
                    public_key: (window as any).ethereum.selectedAddress,
                    wallet_description: data ? data?.getClub?.name + " Metamask wallet" : "",
                    blockchain_type: "Avalanche",
                    blockchain_env: "Fuji_TestNet",
                    overwrite: "true"
                }
                await NetworkManager.importWallet('Avalanche', dataToSend).then((response: any) => {
                    if (response) {
                        setLoad(false);
                        setOpenToast(true);
                        setToastType("success");
                        setErrorMsg(response?.result);
                    }
                })
            }

        } else {
            setLoad(false);
            setOpenToast(true);
            setErrorMsg('Please install metamask chrome extensions');
            return false;
        }
    }
    const transactionDetail = async (tx_hash: any, token_data_json: any) => {
        let txn_detail_data = {
            transaction_hash: tx_hash,//transaction_hash
            blockchain_env: data?.getClub.isMainnet === true ? 'mainnet' : 'testnet',
        }
        const details = await NetworkManager.getTransactionDetail('Avalanche', txn_detail_data).then((response: any) => {
            if (response && response?.status) {
                let dataToSend = {
                    organization_id: orgId,
                    token_data_json: token_data_json,
                    contractAddress: response.contractAddress.toString(),
                    token_type: "CREATED",
                    blockchain_env: data?.getClub.isMainnet === true ? 'mainnet' : 'testnet',
                    tx_hash: tx_hash.toString()
                }
                NetworkManager.saveToken('Avalanche', dataToSend).then((response: any) => {
                    if (response && response.status) {

                        setToastType("success");
                        setOpenToast(true);
                        setErrorMsg("Token created successfully");

                    }

                })

            }
        });
    }
    const openMetamask = async (token_data_json: any, transaction: any) => {
        try {
            const param: any = [{
                chainId: transaction.chainId.toString(),
                gasPrice: transaction.gasPrice.toString(),
                gasLimit: transaction.gas.toString(),
                from: transaction.from.toString(),
                data: transaction.data.toString()
            }];

            const value = await (window as any).ethereum.request({
                method: "eth_sendTransaction", params: param,
            }).then(async (tx_hash: any) => {
                if (tx_hash) {
                    setTimeout(() => {
                        transactionDetail(tx_hash, token_data_json);
                    }, 5000)
                }

            })
                .catch((error: any) => {
                    setToastType("error");
                    setOpenToast(true);
                    if (error && error.code == '4001') {
                        setErrorMsg("You have denied the transaction.");
                    }
                    else {
                        setErrorMsg(error.message);
                    }
                });


            return true;
        }
        catch (E) {
            console.log(E, "errors")
            return false;
        }
    }
    const handleCreateNewToken = async () => {
        await activateBrowserWallet();
        if ((window as any).ethereum) {
            if ((window as any).ethereum.selectedAddress) {
                const tokenName = data ? data?.getClub?.orgCode : '';
                let dataToSend = {
                    token_name: tokenName,
                    decimals: 0,
                    symbol: tokenName,
                    initial_supply: 0,
                    organization_id: orgId,
                    blockchain_env: data?.getClub.isMainnet === true ? 'mainnet' : 'testnet',
                    //create_user: userId,
                }
                await NetworkManager.createToken('Avalanche', dataToSend).then((response: any) => {

                    if (response && response?.transaction) {
                        openMetamask(response?.token_data_json, response?.transaction);

                    }
                })
            }
        }
    }
    const handleChangeContractAddress = (e: any) => {
        setContractAddress(e.target.value);
    }
    const handleUpdateToken = () => {
        if (!contractAddress) {
            setOpenToast(true);
            setErrorMsg("Contract address is required");
        }
        else {
            setLoad(true);
            let dataToSend = {
                organization_id: orgId,
                token_data_json: {
                    name: "Test_Token_Name"
                },
                contractAddress: contractAddress,
                token_type: "CREATED",
                tx_hash: "",
                overwrite: "true"
            }
            NetworkManager.saveToken('Avalanche', dataToSend).then((response: any) => {
                if (response) {
                    setLoad(false);
                    setOpenToast(true);
                    setToastType(response?.status === "success" ? "success" : "error");
                    setErrorMsg(response?.result);
                    setContractAddress("");
                }
            })
        }
    }

    const getTokenID = async () => {
        let blockchain_enviroment = data?.getClub?.isMainnet === true ? 'mainnet' : 'testnet';
        let dataToSend = {
            organization_id: orgId,
            blockchain_env: blockchain_enviroment
        }
        await NetworkManager.getTokenID(data?.getClub?.blockChainType, dataToSend).then((response: any) => {
            if (response) {
                setTokenData(response)
            }
        })
    }
    useEffect(() => {
        if(orgId) {
            getTokenID();
        }
    },[orgId,onOpen]);
    return (
        <Dialog open={onOpen} onClose={handleCloseClick} aria-labelledby="form-dialog-title" className="common-dialog-container org-container" disableBackdropClick disableEscapeKeyDown>
            <form className="form-container pos-rel" onSubmit={handleSubmit(onSubmit)}>
                {page === 0 ?
                <DialogTitle id="form-dialog-title">{editId ? "Edit" : "Add"} organization</DialogTitle>
                :
                <DialogTitle id="form-dialog-title"> Token Creation</DialogTitle>
                }
                <DialogContent>
                    {
                        page === 0 ?

                    <Grid className="form-grid" container sm={12} spacing={6}>
                        <Grid className=" pl-0" item sm={5} xs={12}>
                            <div className='avatar-crop-block'>
                                <div className={avatarImageToCrop.length > 0 ? 'avatar-block crop' : orgId ? 'avatar-block border-none' : 'avatar-block border'}>
                                    {avatarImageToCrop.length > 0
                                        ? (
                                            <>
                                                <AvatarEditor
                                                    ref={setEditorRef}
                                                    className='avatar-make-up'
                                                    image={avatarImageToCrop[0]}
                                                    border={90}
                                                    style={{display:'none'}}
                                                    color={[0, 0, 0, 0.36]} // RGBA
                                                    scale={zoomValue}
                                                    // rotate={0}
                                                    borderRadius={100}
                                                    onPositionChange={handleMergeImages}
                                                    onImageReady={handleMergeImages}
                                                />
                                                {avatarImageToCrop.length > 0
                                                    ? (
                                                        <div className="input-group social-url-container">
                                                            <h5 className="section-heading form-heading mt-20">AR Mode Preview</h5>
                                                            {/* AR Mode coin preview */}
                                                            <div className='ar-mode-prev-block'>
                                                                <img src={mergedImage} width='216' height='216' alt='AR Mode coin' />
                                                            </div>
                                                        </div>
                                                    ) : ''
                                                }
                                                {/* <div className="coinImage"></div> */}

                                                <div className="zoom-container">
                                                    <Typography variant="caption" id="range-slider" gutterBottom>Zoom</Typography>
                                                    <Slider value={zoomValue} onChange={handleZoomChange} step={0.1} min={.5} max={5} aria-labelledby="continuous-slider" />
                                                </div>
                                                <div className='avatar-default-block zero-width'>
                                                    <img className='camera-img' src={CamerLogo} alt='Avatar default logo' />
                                                    <input type="file" className="default-file-type zero-height" onChange={(e) => uploadImage(e)} />
                                                </div>
                                            </>
                                        ) : (
                                            <div className='avatar-default-block'>
                                                {orgId ? (
                                                    // update org avatar space
                                                    <>
                                                        <img className='avatar-img' src={logoUrl} alt='Avatar logo' />
                                                        <div className='title flex-box'><img className='avatar-img-sm' src={AvatarDefault} alt='Avatar default logo' />&nbsp; Reupload logo</div>
                                                        <input type="file" className="default-file-type replace" onChange={(e) => uploadImage(e)} />
                                                    </>
                                                ) : (
                                                    // create org avatar space
                                                    <>
                                                        <img className='default-avatar-img' src={AvatarDefault} alt='Avatar default logo' />
                                                        <div className='title'>Upload Logo</div>
                                                        <input type="file" className="default-file-type" onChange={(e) => uploadImage(e)} />
                                                    </>
                                                )}

                                            </div>
                                        )
                                    }
                                </div>
                            </div>

                            <div className="input-group case-sensitive">
                                <label className="input-label" style={{marginTop: 40}}>Organization Name*</label>
                                <Controller as={TextField} rules={{ required: true, pattern: /[A-Za-z]/, validate: (value) => value.length <= 30 }} type="text" name="name" placeholder="Organization Name" className={`form-fields  ${errors.name && 'error'}`} control={control} />
                                {errors.name && errors.name.type === "required" && (<label className="error-message">Field is required</label>)}
                                {errors.name && errors.name.type === "validate" && (<label className="error-message">Name should be maximum of 30 Characters</label>)}
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div className="input-group case-sensitive" style={{ paddingRight: 10 }}>
                                    <label className="input-label">Organization Code*</label>
                                    <Controller disabled={(editId) ? true : false} name="orgCode" control={control} rules={{ required: true, maxLength: 9 }} render={({ value }) => (<TextField type="text" name="orgCode" value={value} placeholder="Organization Code" className={`form-fields  ${errors.orgCode && 'error'}`} onChange={(e) => handleChangeField(e)} disabled={(editId) ? true : false} />)} />
                                    {errors.orgCode && errors.orgCode.type === "required" && (<label className="error-message">Field is required</label>)}
                                    {errors.orgCode && errors.orgCode.type === "maxLength" && (<label className="error-message">Code should be maximum of 9 Characters</label>)}
                                </div>

                                <div className="input-group" style={{ paddingLeft: 10 }}>
                                    <label className="input-label" >Organization Type*</label>
                                    <Controller
                                        name="orgType"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ value }) => (
                                            <Select displayEmpty name="orgType" value={value} className={`custom-select form-select  ${errors.orgType && 'error'}`} onChange={(e) => handleChangeField(e)}>
                                                <MenuItem value="">Select</MenuItem>
                                                {typesOrg.map((type: any) =>
                                                    type.value !== 'all' && <MenuItem value={type.value}>{type.title}</MenuItem>
                                                )}
                                            </Select>
                                        )
                                        }
                                    />
                                    {errors.orgType && errors.orgType.type === "required" && (<label className="error-message">Field is required</label>)}
                                </div>
                            </div>

                            {/* <div className="input-group">
                                <label className="input-label">Rate per Point(USD)*</label>
                                <Controller as={TextField} type="number" rules={{ required: true, pattern: /[0-9]/, validate: (value) => value > 0, maxLength: 5 }} disabled={true} name="rate" placeholder="Organization Rate" className={`form-fields  ${errors.rate && 'error'}`} control={control} />
                                {errors.rate && errors.rate.type === "required" && (<label className="error-message">Field is required</label>)}
                                {errors.rate && errors.rate.type === "pattern" && (<label className="error-message">Field should be in number</label>)}
                                {errors.rate && errors.rate.type === "maxLength" && (<label className="error-message">Rate should not be more than 5 digits</label>)}
                                {errors.rate && errors.rate.type === "validate" && (<label className="error-message">Rate at should not be 0</label>)}
                            </div> */}



                                <div className="input-group" style={{ paddingRight: 10 }}>
                                    <label className="input-label">Loyalty Type</label>
                                    <Controller
                                        name="default_reward"
                                        control={control}
                                        rules={{ required: true }}
                                        disabled={(editId) ? true : false}
                                        // defaultValue={}
                                        render={({ value }) => (
                                            <Select name="default_reward" value={value} disabled={(editId) ? true : false} className={`custom-select form-select  ${errors.default_reward && 'error'}`} onChange={(e) => handleChangeField(e)}>
                                                <MenuItem value={'points'}>{'Points'}</MenuItem>
                                                <MenuItem value={'tokens'}>{'Tokens'}</MenuItem>
                                            </Select>
                                        )
                                        }
                                    />
                                    {/* {errors && errors.default_reward === "required" && (<label className="error-message">Field is required</label>)} */}
                                </div>

                            <div style={{ display: 'flex' }}>
                                {
                                    defaultReward === 'tokens' ?
                                        <React.Fragment>
                                            <div className="input-group" style={{ paddingRight: 10,width:"45%" }}>
                                                <label className="input-label">Blockchain Type </label>
                                                <Controller
                                                    name="blockChainType"
                                                    control={control}
                                                    rules={{ required: true }}
                                                    disabled={(editId) ? true : false}
                                                    render={({ value }) => (
                                                        <Select displayEmpty name="blockChainType" disabled={(editId) ? true : false} value={value} className={`custom-select form-select  ${errors.deploy_tokens && 'error'}`} onChange={(e) => handleChangeField(e)}>
                                                            <MenuItem value={'Avalanche'}>{'Avalanche'}</MenuItem>
                                                            <MenuItem value={'Tron'}>{'Tron'}</MenuItem>
                                                            <MenuItem value={'Ethereum'}>{'Ethereum'}</MenuItem>
                                                            <MenuItem value={'Polygon'}>{'Polygon'}</MenuItem>
                                                            <MenuItem value={'Solana'}>{'Solana'}</MenuItem>
                                                        </Select>
                                                    )
                                                    }
                                                />
                                                {errors.deploy_tokens && (<label className="error-message">Field is required</label>)}
                                            </div>
                                            <div className="input-group" style={{ paddingLeft: 10 }}>
                                                <label className="input-label">Deploy Tokens </label>
                                                <Controller
                                                    name="deploy_tokens"
                                                    control={control}
                                                    rules={{ required: true }}
                                                    disabled={(editId) ? true : false}
                                                    render={({ value }) => (
                                                        <Select displayEmpty name="deploy_tokens" disabled={(editId) ? true : false} value={value} className={`custom-select form-select  ${errors.deploy_tokens && 'error'}`} onChange={(e) => handleChangeField(e)}>
                                                            <MenuItem value={'testnet'}>{'Testnet'}</MenuItem>
                                                            <MenuItem value={'mainnet'}>{'Mainnet'}</MenuItem>
                                                        </Select>
                                                    )
                                                    }
                                                />
                                                {errors.deploy_tokens && (<label className="error-message">Field is required</label>)}
                                            </div>
                                        </React.Fragment>
                                        : ''
                                }
                            </div>
                            <div className={pointsOnly ? 'points-only checked' : 'points-only'} onClick={handlePointsOnlyCheckBox}><span> {defaultReward === 'tokens' ? 'Allow loyalty users to purchase with tokens only.' : 'Allow loyalty users to purchase with points only.'}</span></div>
                            {
                                editId &&  defaultReward === 'tokens' && tokenData?.has_token === true ?
                                    <React.Fragment>
                                        <div className="input-group case-sensitive">
                                            <label className="input-label">Wallet Address</label>
                                            <TextField id="wallet_address"  variant="standard" disabled value={tokenData?.authority_address} className={'form-fields'} />
                                            {/* <Controller as={TextField} type="text" name="wallet_address" value={tokenData?.authority_address} placeholder="Wallet Address" className={'form-fields'}  control={control} /> */}
                                        </div>
                                        <div className="input-group case-sensitive">
                                            <label className="input-label">Contract Address</label>
                                            <TextField id="contract_address"  variant="standard" disabled value={tokenData?.contract_address} className={'form-fields'} />
                                        </div>
                                    </React.Fragment>
                                : ''
                            }

                        </Grid>

                        <Grid item className="borderL padr0" sm={7} xs={12}>
                            <h5 className="section-heading form-heading mrg0 cust-org-info">Organization Info <span>(max. 500 characters)</span></h5>
                            {/* <div className="input-group">
                                <label className="input-label" ></label>
                                <Controller as={TextField} type="text" multiline rows={4} rules={{ required: false, maxLength: 500 }} name="orgBio" placeholder="Org Info" className={`form-fields  ${errors.rate && 'error'} bioTextarea`} control={control} defaultValue="" />
                                {errors.orgBio && errors.orgBio.type === "maxLength" && (<label className="error-message">Info should not be more than 500 characters</label>)}
                            </div> */}

                            <div className="input-group">
                                <Editor
                                    toolbarOnFocus
                                    editorState={editorState}
                                    onEditorStateChange={onEditorStateChange}
                                    onContentStateChange={onContentStateChange}
                                    placeholder='Describe the org info in brief...'
                                    toolbar={{
                                        // options: ['blockType', 'fontFamily', 'fontSize', 'inline', 'colorPicker', 'list', 'textAlign', 'link', 'history'],
                                        options: ['inline', 'list', 'textAlign', 'link', 'history'],
                                        inline: { inDropdown: true },
                                        list: { inDropdown: true, options: ['unordered', 'ordered'] }
                                    }}
                                />
                            </div>


                            <div className="input-group social-url-container">
                                <label className="input-label" >Social Network Handles</label>
                                <Controller as={TextField} type="text" name="facebook" placeholder="https://www.facebook.com/homepage" className="form-fields social-field fb" control={control} defaultValue="" />
                                <Controller as={TextField} type="text" name="instagram" placeholder="https://instagram.com/homepage" className="form-fields social-field insta" control={control} defaultValue="" />
                                <Controller as={TextField} type="text" name="twitter" placeholder="https://twitter.com/homepage" className="form-fields social-field twitter" control={control} defaultValue="" />
                                <Controller as={TextField} type="text" name="youtube" placeholder="https://youtube.com/channel" className="form-fields social-field youtube" control={control} defaultValue="" />
                                <Controller as={TextField} type="text" name="playstore" placeholder="https://play.google.com/app" className="form-fields social-field playstore" control={control} defaultValue="" />
                                <Controller as={TextField} type="text" name="appstore" placeholder="https://apple.com/in/app-store/" className="form-fields social-field appstore" control={control} defaultValue="" />
                            </div>

                            <div className='input-group mul-sel-country'>
                                <label className="input-label">Country Whitelist</label>
                                {whiteCountryList.length === 0 && <span className='cls-placeholder'>All</span>}
                                <FormControl className={classes.formControl + ' makeStyles-formControl-1'}>
                                    <Select
                                        multiple
                                        value={whiteCountryList}
                                        onChange={handleChangeWhiteCountry}
                                        renderValue={(selected: any) => (
                                            <div className={classes.chips}>
                                                {selected.map((value: any) => (
                                                    <Chip key={value} label={value} className={classes.chip} />
                                                ))}
                                            </div>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem key={'All'} value={'All'} style={getStyles('All', countryList, theme)}>All</MenuItem>
                                        {countryList.map((country: any) => (
                                            <MenuItem key={country.name} value={country.name} style={getStyles(country.name, countryList, theme)}>{country.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {whiteCountryList.length > 0 ? <label className="input-label reset-country-lable" onClick={handleResetCounryList}>Reset</label> : ''}
                            </div>

                            <div className='input-group mul-sel-country'>
                                <label className="input-label">Country Blacklist</label>
                                {selCountryList.length === 0 && <span className='cls-placeholder'>None</span>}
                                <FormControl className={classes.formControl + ' makeStyles-formControl-1'}>
                                    <Select
                                        multiple
                                        value={selCountryList}
                                        onChange={handleChangeCountry}
                                        renderValue={(selected: any) => (
                                            <div className={classes.chips}>
                                                {selected.map((value: any) => (
                                                    <Chip key={value} label={value} className={classes.chip} />
                                                ))}
                                            </div>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        {countryList.map((country: any) => (
                                            <MenuItem key={country.name} value={country.name} style={getStyles(country.name, countryList, theme)}>{country.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>

                            <div className='input-group mul-sel-country'>
                                <label className="input-label">Email Notification</label>
                                <ChipInput
                                    value={emailNotifications}
                                    onAdd={(chip) => handleAddChip(chip)}
                                    onDelete={(chip, index) => handleDeleteChip(chip, index)}
                                />
                                {emailNotificationValidation ? <label className="error-message">Please Enter a valid email id</label> : null}
                                {emailNotificationsList ? <label className="error-message">Please Enter at least one email Id</label> : null}
                            </div>
                            {/* <div className='input-group wallet-section'>
                                {data && data?.getClub?.isToken ? <React.Fragment>
                                    <Button className="custom-outline-button" variant="outlined" onClick={() =>{ handleUpdateWallet() }}>Update Wallet</Button> <br />
                                    <Button className="custom-outline-button" variant="outlined" onClick={() =>{ handleCreateNewToken() }}>Create new Token</Button><br />
                                    <p>OR</p>
                                    <label className="input-label" >Contract Address</label>
                                    <TextField type="text" name="contract_address" className="form-fields" value={contractAddress} variant="standard" onChange={handleChangeContractAddress}/>
                                    <Button className="custom-outline-button" variant="outlined" onClick={() =>{ handleUpdateToken() }}>Update token</Button><br />
                                </React.Fragment>
                                 : ''}
                            </div> */}
                            {/* <div>
                                <FormGroup>
                                    <label className="input-label" >Deploy BlockChain*</label>
                                    <FormControlLabel
                                        onClick={() => Visibility()}
                                        control={<IOSSwitch sx={{ m: 1 }} />}
                                        label=""
                                    />

                                </FormGroup>
                                {
                                    visible ? (
                                        <div className="input-group">
                                            <label className="input-label" >Choose Blockchain Network</label>
                                            <Controller
                                                name="orgType"
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ value }) => (
                                                    <Select displayEmpty name="orgType" value={dropdown} className={`custom-select form-select  ${errors.orgType && 'error'}`} onChange={(e) => dropdownSeter(e)}>
                                                        <MenuItem value="">Select</MenuItem>
                                                        <MenuItem value={10}>Ethereum</MenuItem>
                                                        <MenuItem value={20}>Solana</MenuItem>
                                                        <MenuItem value={30}>Avalanche</MenuItem>
                                                    </Select>
                                                )
                                                }
                                            />
                                            {errors.orgType && errors.orgType.type === "required" && (<label className="error-message">Field is required</label>)}
                                        </div>
                                    ) : null
                                }
                            </div> */}

                            {/* AR Mode Coin Preview */}

                        </Grid>
                    </Grid>
                     : <MinitingToken refetch={clickCanceled} handleClosePopup={handleCloseClick} orgDataToken={orgDataToken} />
                    }
                </DialogContent>

                <DialogActions>
                    {page === 0 ?
                    <React.Fragment>
                    {editId &&
                        <Button onClick={() => handleInActiveClick(data.getClub.id)} className="btn btn-secondary btn-delete" color="primary">
                            {!isActiveStatus ? "DELETE" : "ACTIVATE"}
                        </Button>
                    }
                    {isAdmin && editId && <Button onClick={() => handleInApproveClick(data.getClub.id)} className="btn btn-secondary" color="primary">
                        {isApproved ? "DISAPPROVE" : "APPROVE"}
                    </Button>}

                    <Button onClick={clickCanceled} className="btn btn-secondary" color="primary">
                        CANCEL
                    </Button>
                    {
                        editId ?
                            <Button color="primary" className="btn btn-primary button" onClick={handleSubmit(onSubmit)}>
                                {((tokenData?.has_token === true && defaultReward === 'tokens') || defaultReward === 'points') ? "UPDATE ORG" : "NEXT"}
                            </Button>
                        :
                            <Button color="primary" className="btn btn-primary button" onClick={handleSubmit(onSubmit)}>
                                {defaultReward === 'tokens' ?  "Next" : "ADD ORG"}
                            </Button>
                    }
                    </React.Fragment>
                    :''
                    }
                </DialogActions>
            </form>

            {openConfirm &&
                <div className="confim-model-container with-scroll">
                    <Paper className="confirmation-wrapper padd-20">
                        <Grid container sm={12}>
                            <div className="confirmation-sec">
                                <p>Are you sure you want to {!isActiveStatus ? "delete" : "activate"} this Organization. By choosing Confirm you will loose all the information for {data.getClub.name}</p>
                                <DialogActions>
                                    <Button className="btn btn-secondary" onClick={clickCanceled} color="primary">CANCEL</Button>
                                    <Button color="primary" onClick={handleSubmit(clickConfirmed)} className="btn btn-primary button">CONFIRM</Button>
                                </DialogActions>
                            </div>
                        </Grid>
                    </Paper>
                </div>
            }

            <Snackbar open={openToast} autoHideDuration={5000} onClose={handleClosed}>
                <Alert onClose={handleClosed} severity={tostType === "success" ? "success" : "error"}>{errorMsg}</Alert>
            </Snackbar>

            <Backdrop className="loader-signup" open={load}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Dialog>
    )
}