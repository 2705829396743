import React, { useEffect, useRef, useState } from 'react'
import coin from '../../assets/coin.png';
import billboardIcon from '../../assets/billboard-icon.png';
import LocationIcon from '../../assets/poi_marker.png';
import Rating from '@mui/material/Rating';
import  './promotionmap.css';
interface Props {
  coords?: any;
  coinsNvalue?:any;
  markerImage ?: any;
  pointsArray?: any;
  viewType?:any;
  placedCoins?:any;
  autoDistributeSelected?:any;
  locationSelected ?: any;
  distArray?:any;
  mapProperties?:any;
  promotionType?:any;
  coinUrl?:any;
  poiMarkers?:any
  mapRadiusConst:(e: any) => void;
  mapRadiusChange:(e: any) => void;
  updatePoints: (e: any) => void;
  resetGMap: (obj: any) => void;
}

export default function Promotionmap({coords, markerImage, autoDistributeSelected, locationSelected, placedCoins, coinsNvalue, viewType, pointsArray, distArray, mapProperties, promotionType, coinUrl, poiMarkers, updatePoints, resetGMap, mapRadiusConst, mapRadiusChange}: Props) {
  
  const refMap: any = useRef();
  const [placedCoin,setPlacedCoins] = useState(placedCoins);

  let isDistributeCoinsClicked:any = autoDistributeSelected;

  let points: any = [];
  let map: any;

  let zoomPoint: any = mapProperties.zoomPoint;
  let mapRadius: any = mapProperties.mapRadius;
  let coinDistributionRadius: any = mapProperties.coinDistributionRadius;
 
  let mapCircle: google.maps.Circle;

  const onClick = (point:any) => {
    points.push(point);
    updatePoints(point);
    setPlacedCoins(placedCoin + points)
  }

  // to draw a circle on the map.
  const drawCircleOnMap = (map:any, mylatlag: any) => {
    // to clear a circle before draw a new circle.
    if (mapCircle) mapCircle.setMap(null);

    mapCircle = new google.maps.Circle({
      strokeColor: "#2B2E4C",
      strokeOpacity: 0.6,
      strokeWeight: 1,
      fillColor: "#2B2E4C",
      fillOpacity: 0.35,
      map,
      center: mylatlag,
      radius: parseInt(mapRadius),
    });
  }

  const showPoisDetail = (mylatlag:any) => {
    if(poiMarkers && poiMarkers.length > 0){
      poiMarkers.map((poi:any)=>{
        var infowindow = new google.maps.InfoWindow({
          content: "<div class='map_info_wrapper'>"+
                      "<div class='pv_content_wrap'>"+
                          "<div class='map_title'>"+
                              "<span>"+poi.name+"</span>"+
                          "</div>"+
                          "<div class='map_address'>"+
                              "<span>"+poi.address+"</span>"+
                          "</div><br/>"+  
                          "<div class='map_points_value'>"+
                          "<span>Points </span>"+
                          "<span>"+poi.points+" Pts</span>"+
                          "</div>"+      
                        "</div></div>"
        });
        const latlng = {lat :poi.lat, lng:poi.lng};
        const marker = new window.google.maps.Marker({
          map,
          position: latlng,
          label: "",
          title: poi.name,
          icon: LocationIcon,
        })  
        let pos:any = {...latlng, "pointsValue": coinsNvalue, "locationName":poi.name };
        points.push(pos);
        updatePoints(pos);
        google.maps.event.addListener(marker, 'click', function() {
          infowindow.open(map,marker);
        });
      });
      drawCircleOnMap(map, mylatlag);
    }
  }

  const onLoad = (mylatlag: any) => {
    points = [];
    map = new window.google.maps.Map(refMap.current, {center: mylatlag, zoom: zoomPoint})
    console.log("viewType",viewType)
    // below code for edit hunt.
    if(viewType === "Edit"){
      if (pointsArray) {
        var resizedIcon = {
          url: billboardIcon, // url
          scaledSize: new google.maps.Size(150, 150), // size
        };

        pointsArray.forEach( (element:any) => {
          const marker = new window.google.maps.Marker({
            map,
            position: element,
            label: promotionType === 'BillBoard' ? '' : `${element.pointsValue}`,
            title: "Map",
            icon: promotionType === 'BillBoard' ? resizedIcon : coin,
          })
        });

        if(promotionType === "Poi"){
          showPoisDetail(mylatlag)
        }
      }
    }

    // below code for create hunt.
    if (viewType === "Create") {
      // for Hunt creation, randomly distributing coins on selected radius location.
      if (isDistributeCoinsClicked) {
        if (pointsArray) {
          let resizedIcon = {
            url: billboardIcon, // url
            scaledSize: new google.maps.Size(150, 150), // size
          };
          pointsArray.forEach((coinValue:any) => {
            var r = coinDistributionRadius/111300
            , y0 = mylatlag.lat
            , x0 = mylatlag.lng
            , u = Math.random()
            , v = Math.random()
            , w = r * Math.sqrt(u)
            , t = 2 * Math.PI * v
            , x = w * Math.cos(t)
            , y1 = w * Math.sin(t)
            , x1 = x / Math.cos(y0)

            var newLat:any = y0 + y1
            var newLng:any = x0 + x1

            let pos:any = {"lat": newLat, "lng": newLng, "pointsValue": coinValue};

            points.push(pos);
            updatePoints(pos);
            setPlacedCoins(placedCoin + points);

            const marker = new window.google.maps.Marker({
              map,
              position: pos,
              label: promotionType === 'BillBoard' ? '' : `${coinValue}`,
              title: "Map",
              icon: promotionType === 'BillBoard' ? resizedIcon : coin,
            })
          });
        }

        // to draw a circle on the map.
        drawCircleOnMap(map, mylatlag);

      } else {
        map.addListener("click", (mapsMouseEvent: any) => {
          // Close the current InfoWindow.
          // infoWindow.close();
          if(points.length < pointsArray.length && locationSelected) {
            onClick({...mapsMouseEvent.latLng.toJSON(), pointsValue: pointsArray[points.length]});

            let resizedIcon = {
              url: billboardIcon, // url
              scaledSize: new google.maps.Size(150, 150), // size
            };

            const marker = new window.google.maps.Marker({
              map,
              position: mapsMouseEvent.latLng,
              label: promotionType === 'BillBoard' ? '' : `${pointsArray[points.length - 1]}`,
              title: "Test",
              icon: promotionType === 'BillBoard' ? resizedIcon : coin,
            })
          }

          // Create a new InfoWindow.
          // infoWindow = new google.maps.InfoWindow({
          //   position: mapsMouseEvent.latLng,
          // });
          // infoWindow.setContent(
          //   JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2)
          // );
          // infoWindow.open(map);
        });
      
        if(promotionType === "Poi"){
          showPoisDetail(mylatlag)
        }
      }
    }

    // below code for copy hunt feature
    if (viewType === "Copy") {
      if (pointsArray.length > 0) {
        if(promotionType === "Poi"){
          showPoisDetail(mylatlag)
        }
        else {
          let resizedIcon = {
            url: billboardIcon, // url
            scaledSize: new google.maps.Size(150, 150), // size
          };
  
          pointsArray.forEach( (element:any) => {
            const marker = new window.google.maps.Marker({
              map,
              position: element,
              label: promotionType === 'BillBoard' ? '' : `${element.pointsValue}`,
              title: "Map",
              icon: promotionType === 'BillBoard' ? resizedIcon : coin,
            })
          });
        }
      } else {
        if (isDistributeCoinsClicked) {
          if (distArray) {
            let resizedIcon = {
              url: billboardIcon, // url
              scaledSize: new google.maps.Size(150, 150), // size
            };

            distArray.forEach((coinValue:any) => {
              var r = coinDistributionRadius/111300
              , y0 = mylatlag.lat
              , x0 = mylatlag.lng
              , u = Math.random()
              , v = Math.random()
              , w = r * Math.sqrt(u)
              , t = 2 * Math.PI * v
              , x = w * Math.cos(t)
              , y1 = w * Math.sin(t)
              , x1 = x / Math.cos(y0)

              var newLat:any = y0 + y1
              var newLng:any = x0 + x1

              let pos:any = {"lat": newLat, "lng": newLng, "pointsValue": coinValue};

              points.push(pos);
              updatePoints(pos);
              setPlacedCoins(placedCoin + points);

              const marker = new window.google.maps.Marker({
                map,
                position: pos,
                label: promotionType === 'BillBoard' ? '' : `${coinValue}`,
                title: "Map",
                icon: promotionType === 'BillBoard' ? resizedIcon : coin,
              })
            });
          }

          // to draw a circle on the map.
          drawCircleOnMap(map, mylatlag);

        } else {
          map.addListener("click", (mapsMouseEvent: any) => {
            if(points.length < distArray.length && locationSelected) {
              onClick({...mapsMouseEvent.latLng.toJSON(), pointsValue: distArray[points.length]});

              var resizedIcon = {
                url: billboardIcon, // url
                scaledSize: new google.maps.Size(150, 150), // size
              };

              const marker = new window.google.maps.Marker({
                map,
                position: mapsMouseEvent.latLng,
                label: promotionType === 'BillBoard' ? '' : `${distArray[points.length - 1]}`,
                title: "Test",
                icon: promotionType === 'BillBoard' ? resizedIcon : coin,
              })
            }
          });
          if(promotionType === "Poi"){
            showPoisDetail(mylatlag)
          }
        }
      }
    }

    if (viewType !== "Edit") {
      // to change cirle radius dynamically based on zoom-in-out.
      map.addListener("zoom_changed", () => {
        let mapCenterLanLng = {lat: map.getCenter().lat(), lng: map.getCenter().lng()}

        if (map.getZoom() > zoomPoint) {
          mapRadius = mapRadius / 2;
          coinDistributionRadius = coinDistributionRadius / 2;
          console.log('here')
        } else {
          mapRadius = mapRadius * 2;
          coinDistributionRadius = coinDistributionRadius * 2;
        }
        mapRadiusConst(mapRadius);
        mapRadiusChange(true);
        zoomPoint = map.getZoom();

        resetGMap({
          mapLatLng: mapCenterLanLng,
          location: '',
          zoomPoint: zoomPoint,
          mapRadius: mapRadius,
          coinDistributionRadius: coinDistributionRadius
        });
      });

      // to re-draw a cirlce based on drag/navigate the map.
      map.addListener("dragend", async () => {
        let mapDragendLanLng:any = {lat: map.getCenter().lat(), lng: map.getCenter().lng()};
        let tempLatLng:any = {latLng: mapDragendLanLng};
        // to get location name
        let geocoder = new google.maps.Geocoder();
        geocoder.geocode(tempLatLng, function(responses) {
          if (responses && responses.length > 0) {

            resetGMap({
              mapLatLng: mapDragendLanLng,
              location: responses[0].formatted_address,
              zoomPoint: zoomPoint,
              mapRadius: mapRadius,
              coinDistributionRadius: coinDistributionRadius
            });
          } else {
          }
        });
      });
    }
  }

  useEffect(() => {
    onLoad(coords);
  }, [autoDistributeSelected, coords, coinsNvalue, pointsArray, coinUrl,poiMarkers])

  return (
    <div ref={refMap} className="map">map</div>
  )
}
