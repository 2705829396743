import React ,{useEffect}from 'react';
import { useHistory } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import { useQuery, gql } from '@apollo/client';
import Loader from '../../components/Loader/loader';
import moment from 'moment';
import Button from "@material-ui/core/Button";
import MUIDataTable from "mui-datatables";
const formatCurrency = require('format-currency');
const opt = { minFraction: 0 };
interface ClubInventory {
  id: number;
  name: string;
}

interface ClubInventoryData {
  clubInventory: ClubInventory[];
}

interface ClubInventoryVars {
  year: number;
}


export const GET_ORGS_QUERY = gql`
query GetClubInventory {
orgsSponserShip{
  id
  name
  rate
  orgCode
  logoUrl
  availablePoints
  totalPoints
  distributionPoints
  isApproved
  isToken
  totalTokens
  availableTokens
  createdAt
}
}
`;

export default function PointOverview() {
  const mangeOrgOverView = () => history.push('/manageclubs');
  const [count, setCount] = React.useState(0);
  const { refetch,loading, data } = useQuery<any>(
    GET_ORGS_QUERY
  );
  useEffect(() => {
    refetch();
    if(data && data.orgsSponserShip) {
      setCount(data.orgsSponserShip.length)
    }
  },[data]);
    const history = useHistory();
    const clubdetailsClick = (id: string) => history.push(`/clubdetails/${id}`);

    const columns:any = [
      {
        name:"logoUrl",
        width: 2,
        options:{
            filter:false,
            sortThirdClickReset: true,
            sort: true,
            customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
              let orderName = (sortOrder.name == "name")?sortOrder.direction:"";
              return(
              <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={0} onClick={() => updateDirection(0)} >
                <a className="sort-by">Organizations</a>
              </th>
              )
            },
            customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                return (<div onClick={() => clubdetailsClick(tableMeta.tableData[tableMeta.rowIndex].id)} style={{cursor:'pointer'}}><img className='profile-img' src={value || "https://png.pngtree.com/png-vector/20190321/ourmid/pngtree-vector-users-icon-png-image_856952.jpg"} /><label style={{whiteSpace: 'nowrap'}}>{`${tableMeta.tableData[tableMeta.rowIndex].name}`}</label></div>)
              }
            
        }
      },
      {
        name:"distributionPoints",
        options:{
            filter:false,
            sortThirdClickReset: true,
            // sort: false,
            customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                let orderName = (sortOrder.name == "distributionPoints")?sortOrder.direction:"";
    
                return(
                <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={0} onClick={() => updateDirection(1)} >
                  <a className="sort-by">Distribution</a>
                </th>
                )
            }
        }
      },
      {
        name:"availablePoints",
        options:{
            filter:false,
            sortThirdClickReset: true,
            // sort: false,
            customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                let orderName = (sortOrder.name == "availablePoints")?sortOrder.direction:"";
    
                return(
                <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={0} onClick={() => updateDirection(2)} >
                  <a className="sort-by">Available</a>
                </th>
                )
            },
            customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
              const points = tableMeta.tableData[tableMeta.rowIndex].isToken ? tableMeta.tableData[tableMeta.rowIndex].availableTokens : value
              return (<label >{formatCurrency(points,opt)}</label>)
            }
        }
      },
      {
        name:"totalPoints",
        options:{
            filter:false,
            sortThirdClickReset: true,
            // sort: false,
            customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                let orderName = (sortOrder.name == "totalPoints")?sortOrder.direction:"";
    
                return(
                <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={0} onClick={() => updateDirection(3)} >
                  <a className="sort-by">Total</a>
                </th>
                )
            },
            customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
              const total_Token = tableMeta.tableData[tableMeta.rowIndex].isToken ? tableMeta.tableData[tableMeta.rowIndex].totalTokens : value
              return (<label >{formatCurrency(total_Token,opt)}</label>)
            }
        }
      },
      {
        name:"createdAt",
        options:{
            filter:false,
            sortThirdClickReset: true,
            // sort: false,
            customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                let orderName = (sortOrder.name == "createdAt")?sortOrder.direction:"";
    
                return(
                <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={0} onClick={() => updateDirection(3)} >
                  <a className="sort-by">Date Added</a>
                </th>
                )
            },
            customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
              return (<label >{moment(parseInt(value)).format('MM/DD/YYYY')}</label>)
            }
        }
      },

  ];
  const options:any = {
    filter: false,
    count: data && data.orgsSponserShip ? count : 10,
    filterType: "dropdown",
    responsive:"standard",
    print:false,
    download : false,
    pagination : false,
    serverSide: false,
    viewColumns : false,
    selectableRowsHideCheckboxes: true,
    fixedSelectColumn: false,
    selectToolbarPlacement: "none",
    selectableRowsHeader: false,
    selectableRows: "none",
    selectableRowsOnClick: false,
    fixedHeader : true,
    tableBodyHeight:"275px",
    search:false,
  }
  return (
    <div className="table-container muidata-table pad0" style={{ height: 305, width: '100%' }}>
          {loading 
              ? <Loader size="90px"/>
              : <MUIDataTable title={""} data={data.orgsSponserShip} columns={columns} options={options} /> 
          }
      <Grid container className="top-section" sm={12} >
        <Grid className="btn-section view-all-btn" item  xs={12}>
            <Button  onClick={mangeOrgOverView} className="btn" variant="contained">View All</Button>
        </Grid>
      </Grid>
    </div>
  );
}