/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";

import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Backdrop, CircularProgress, MenuItem, Select, Snackbar, Typography } from '@material-ui/core';

import moment from 'moment';
import "moment-timezone";

import { config } from '../../../config';

import AvatarEditor from 'react-avatar-editor';
import { gql, useMutation, useQuery } from '@apollo/client';
import AvatarEdit from '../../../assets/sponsor-empty@2x.svg';
import AvatarDefault from '../../../assets/ic_avatar-default.svg';
import CamerLogo from '../../../assets/ic_camera.svg';
import Slider from '@material-ui/core/Slider';
import '../../../constant.css';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from "@material-ui/core/styles";

const formatCurrency = require('format-currency');
const opt = { minFraction: 0 };
// Below commented code to be removed while code clean up *************************************************

// import { gql, useMutation,useQuery } from '@apollo/client';
// import Loader from '../../../components/Loader/loader';
// import SponsorEmty from '../../../assets/sponsor-empty@2x.svg';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import Chip from '@material-ui/core/Chip';
// import { Select, MenuItem, Switch } from "@material-ui/core";
// import SponsorLogo from '../../../assets/sponsor-logo.png';
// import { stringify } from 'querystring';

interface Props {
    onOpen: boolean,
    handleCloseClick: () => void,
    viewTypeTxt?: any,
    editId?: any,
    rate: any
}

interface ParamTypes {
    orgId: string;
}

interface IFormAddSponsor {
    sponsorLogo: string;
    sponsorName: string;
    contactName: string;
    contactEmail: string;
    contactPhone: string;
    contactAddress: string;
}

let defaultValues: any = {
    sponsorLogo: "",
    sponsorName: "",
    contactName: "",
    contactEmail: "",
    contactPhone: "",
    contactAddress: ""
}

const CREATE_SPONSOR = gql`
    mutation createSponsor($data: NewSponsorInput!) {
        createSponsor(data: $data) {
            sponsorName
            contactName
        }
    }
`;

const UPDATE_SPONSOR = gql`
    mutation updateSponsors($id: String!, $data: UpdateSponsorInput!) {
        updateSponsor(id: $id, data: $data) {
            sponsorName
        }
    }
`;

const SPONSOR_BY_ID = gql`
    query getSponsor($id: String!) {
        getSponsorWithId(id: $id) {
            id
            sponsorName
            contactName
            sponsorLogo
            contactEmail
            contactPhone
            contactAddress
        }
    }
`;

const DELETE_SPONSOR_BY_ID = gql`
    mutation deleteSponsors($id: String!) {
        deleteSponsor(id: $id)
    }
`;

const GET_PRE_SIGNED_URL = `
    query getPreSignedUrl($fileName: String!) {
        getPreSignedUrl(fileName: $fileName) {
            preSignedUrl
        }
    }
`;

const useStyles = makeStyles({
    paper: {
        padding: "0",
        fontFamily: "Graphik Regular !important",
        fontSize: "14px !important",
        color: "#8A8D9D !important"
    }
});

export default function CreateSponsor({ handleCloseClick, onOpen, viewTypeTxt, editId, rate }: Props) {
    const sponsorId = editId ? editId : "";
    const open = onOpen;
    const [confirmed, setConfirmed] = React.useState(false);
    const inputRef: any = useRef();
    const hasToken = localStorage.getItem('org_has_token');

    let { orgId } = useParams<ParamTypes>();
    const [errorMsg, setErrorMsg] = useState<any>("");
    const [openToast, setOpenToast] = React.useState<any>(false);

    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [deleteClicked, setDeleteClicked] = React.useState(false);
    const [logoUrl, setlogoUrl] = useState<any>();
    const [confirmMessage, setConfirmMessage] = useState<any>("Are you sure with the Point Requested value?");

    const setEditorRef: any = useRef();
    const [avatarImageToCrop, setAvatarImageToCrop] = React.useState<any>([]);
    const [load, setLoad] = useState(false);
    const [zoomValue, setZoomValue] = React.useState(1.2);
    const [pointsRequest, setPointsRequest] = useState<any>();
    const [pointsRequestOptions, setPointsRequestOptions] = useState([{title: "100", _id: "100"}, {title: "1000", _id: "1000"}, {title: "10000", _id: "10000"}, {title: "100000", _id: "100000"}, {title: "1000000", _id: "1000000"}, {title: "10000000", _id: "10000000"} ])

    const handleZoomChange = (event: any, newValue: any) => {
        setZoomValue(newValue);
    };



    const { loading, data, refetch } = useQuery<any>(SPONSOR_BY_ID, { variables: { id: sponsorId } });
    const { register, handleSubmit, errors, control, reset, formState, setValue ,clearErrors} = useForm<IFormAddSponsor>({ defaultValues, shouldUnregister: false });


    const Alert = (props: AlertProps) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const fieldSetValueFunction = (defaultValue: any) => {
        let sponsorLogo = defaultValues.sponsorLogo != "" ? defaultValues.sponsorLogo : AvatarEdit; 
        setlogoUrl(sponsorLogo);
        setValue("sponsorName", defaultValues.sponsorName);
        setValue("contactName", defaultValues.contactName);
        setValue("contactEmail", defaultValues.contactEmail);
        setValue("contactPhone", defaultValues.contactPhone);
        setValue("contactAddress", defaultValues.contactAddress);
    }

    useEffect(() => {
        setPointsRequest('');
        refetch();
        clearErrors();
        if (!loading && editId) {
            defaultValues = { ...data.getSponsorWithId };
            fieldSetValueFunction(defaultValues);
            // setConfirmed(true);
        } else {
            setOpenConfirm(false);
            // setConfirmed(false);
            defaultValues = {
                sponsorLogo: "",
                sponsorName: "",
                contactName: "",
                contactEmail: "",
                contactPhone: "",
                contactAddress: ""
            };
            fieldSetValueFunction(defaultValues);
        }
    }, [data, open, setValue]);
     

    const [deleteSponsors] = useMutation(DELETE_SPONSOR_BY_ID, {
        // after updating the post, we go to the home page
        onCompleted: () => handleCloseClick(),
        onError: (data) => {
            setErrorMsg(data.message);
            setOpenToast(true);
        }
    });

    const handleDeleteSponsor = () => {
        setConfirmMessage("Are you sure you want to delete this Sponsor.By choosing Delete you will loose all the information for " + data.getSponsorWithId.sponsorName);
        setDeleteClicked(true);
        setOpenConfirm(true);
    }

    const handleClosed = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenToast(false);
    };

    const [createSponsor, { error, loading: resLoading }] = useMutation(CREATE_SPONSOR, {
        // after updating the post, we go to the home page
        onCompleted: () => clickCanceled(),
    });

    const [updateSponsors] = useMutation(UPDATE_SPONSOR, {
        // after updating the post, we go to the home page
        onCompleted: () => clickCanceled(),
    });

    const clickCanceled = () => {
        setAvatarImageToCrop([]);
        setConfirmed(false);
        setOpenConfirm(false);
        handleCloseClick();
        // inputRef.current.focus();
    }

    const handleConfirmCanceled = () => {
        setConfirmed(false);
        setOpenConfirm(false);
        //alert("checking");
    }

    // to create sponsor info.
    const createSponsors = (fieldData: any, imageUrl: string) => {
        createSponsor({
            variables:
            {
                data:
                {
                    sponsorName: fieldData.sponsorName,
                    sponsorLogo: imageUrl,
                    contactName: fieldData.contactName,
                    contactEmail: fieldData.contactEmail,
                    contactPhone: fieldData.contactPhone,
                    contactAddress: fieldData.contactAddress,
                    clubId: orgId
                }
            }
        });
    }

    // to update sponsor info.
    const updateSponsor = (fieldData: any, imageUrl: string) => {
        updateSponsors({
            variables:
            {
                id: sponsorId,
                data:
                {
                    sponsorName: fieldData.sponsorName,
                    sponsorLogo: imageUrl,
                    contactName: fieldData.contactName,
                    contactEmail: fieldData.contactEmail,
                    contactPhone: fieldData.contactPhone,
                    contactAddress: fieldData.contactAddress,
                    clubId: orgId
                }
            }
        });
    }
    const deletedConfirm = () => {
        setLoad(true);
        deleteSponsors({ variables: { id: sponsorId } });
        setLoad(false);   
        setConfirmed(true);
        setOpenConfirm(false);
    }
    const clickConfirmed: SubmitHandler<IFormAddSponsor> = (fieldData) => {
        setLoad(true);
            if (avatarImageToCrop.length > 0) {
                let uploadFileName = fieldData.sponsorName.trim() + '_' + avatarImageToCrop[0].name.split('.')[0] + '_' + moment().toDate().getTime() + '.png';

                const canvas = setEditorRef.current.getImage().toDataURL('image/png', 1.0);
                let tempTargetFile = dataURLtoFile(canvas, uploadFileName);

                // to get S3 image url after uploading file using presigned url.          
                fetch(config.baseURL, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        query: GET_PRE_SIGNED_URL,
                        variables: { fileName: uploadFileName },
                    }),
                })
                    .then((res) => res.json())
                    .then(async (result) => {
                        fetch(await result.data.getPreSignedUrl.preSignedUrl, {
                            method: 'PUT',
                            headers: { 'Content-Type': 'image/*' },
                            body: tempTargetFile
                        }).then(async (response) => {
                            if (response && response.url) {
                                if (editId) {
                                    updateSponsor(fieldData, await response.url.split('?')[0]);
                                    setLoad(false);
                                } else {
                                    // to create sponsor info with new logo url.
                                    createSponsors(fieldData, await response.url.split('?')[0]);
                                    setLoad(false);
                                }
                            }
                        });
                    });
            } else {
                if (editId) {
                    // to update sponsor info with existing logo url.
                    updateSponsor(fieldData, logoUrl);
                    setLoad(false);
                } else {
                    setErrorMsg('Please upload organization logo');
                    setOpenToast(true);
                    setLoad(false);
                    return;
                }
            }


        setConfirmed(true);
        setOpenConfirm(false);
    }

    const onSubmit: SubmitHandler<IFormAddSponsor> = fieldData => {
        // to check crop avatar is empty or not.
        if (avatarImageToCrop.length === 0) {
            if (!sponsorId) {
                setErrorMsg('Please upload a sponsor logo');
                setOpenToast(true);
                return;
            }
        }
        if(fieldData.contactPhone) {
        // let pattern = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        let pattern = /^(\+\d{1,2}\s?)(\d{10})$/
        let result = pattern.test(fieldData.contactPhone);
            if(!result) {
                setErrorMsg('Please enter valid phone number');
                setOpenToast(true);
                return;
            }
        }
        
        // setConfirmMessage("Are you sure with the Point Requested value?");
        {
            editId ?
                setConfirmMessage("Are you sure you want to make this change?")
            :
                setConfirmMessage("Are you sure you want to create sponsor?")
        }
        if (!confirmed) setOpenConfirm(true);

        
    }

    // to Convert selected avatar base64 data to image file to uplaod file to s3 bucket.
    const dataURLtoFile = (dataurl: any, filename: any) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }


    const uploadImage = (e: React.ChangeEvent<any>) => {
        const targetFiles: any = e.target.files;

        if (targetFiles.length === 0) return;

        if (targetFiles[0].type === "image/png" || targetFiles[0].type === "image/jpeg") {
            // setLoader(true);
            if (targetFiles.length > 0) {
                setAvatarImageToCrop(targetFiles);
            }
        } else {
            setErrorMsg("Image format should png,jpg");
            setOpenToast(true);
        }
    }

    const classes = useStyles();

    return (
        <div>
            <Dialog open={onOpen} onClose={handleCloseClick} aria-labelledby="form-dialog-title" className="common-dialog-container org-container confirmation-popup" disableBackdropClick disableEscapeKeyDown>
                <form className="form-container" onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle id="form-dialog-title">{sponsorId ? "Edit" : "Create"} Sponsor</DialogTitle>
                    <DialogContent>
                        <Grid className="form-grid" container sm={12} spacing={6}>
                            <Grid className="pl-zero" item sm={4} xs={12}>

                                <div className='avatar-crop-block'>
                                    <div className={avatarImageToCrop.length > 0 ? 'avatar-block crop' : editId ? 'avatar-block border-none' : 'avatar-block border'}>
                                        {avatarImageToCrop.length > 0
                                            ? (
                                                <>
                                                    <AvatarEditor
                                                        ref={setEditorRef}
                                                        className='avatar-make-up'
                                                        image={avatarImageToCrop[0]}
                                                        border={90}
                                                        color={[0, 0, 0, 0.36]} // RGBA
                                                        scale={zoomValue}
                                                        // rotate={0}
                                                        borderRadius={100}
                                                    />
                                                    <div className="zoom-container"><Typography variant="caption" id="range-slider" gutterBottom>
                                                        Zoom
                                                    </Typography>
                                                        <Slider value={zoomValue} onChange={handleZoomChange} step={0.1} min={.5} max={5} aria-labelledby="continuous-slider" />
                                                    </div>
                                                    <div className='avatar-default-block zero-width'>
                                                        <img className='camera-img' src={CamerLogo} alt='Avatar default logo' />
                                                        <input type="file" className="default-file-type zero-height" onChange={(e) => uploadImage(e)} />
                                                    </div>
                                                </>
                                            ) : (
                                                <div className='avatar-default-block'>
                                                    {editId ? (
                                                        // update org avatar space
                                                        <>
                                                            <img className='avatar-img' src={logoUrl} alt='Avatar logo' />
                                                            <div className='title flex-box'><img className='avatar-img-sm' src={AvatarDefault} alt='Avatar default logo' />&nbsp; Reupload logo</div>
                                                            <input type="file" className="default-file-type replace" onChange={(e) => uploadImage(e)} />
                                                        </>
                                                    ) : (
                                                        // create org avatar space
                                                        <>
                                                            <img className='default-avatar-img' src={AvatarDefault} alt='Avatar default logo' />
                                                            <div className='title'>Upload Logo</div>
                                                            <input type="file" className="default-file-type" onChange={(e) => uploadImage(e)} />
                                                        </>
                                                    )}
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>

                                <div className="input-group">
                                    <label className="input-label" >Company Name*</label>
                                    <Controller as={TextField} type="text" rules={{ required: true }} name="sponsorName" placeholder="Company Name" className={`form-fields  ${errors.sponsorName && 'error'}`} control={control} />
                                    {errors.sponsorName && errors.sponsorName.type === "required" && (<label className="error-message">Field is required</label>)}
                                </div>
                            </Grid>

                            <Grid item className="borderL padr0" sm={8} xs={12}>
                                <h5 className="section-heading form-heading mrg0">Sponsor Info</h5>
                                <div className="input-group">
                                    <label className="input-label">Contact Name*</label>
                                    <Controller as={TextField} type="text" rules={{ required: true }} name="contactName" placeholder="Contact Name" className={`form-fields  ${errors.contactName && 'error'}`} control={control} defaultValue="" />
                                    {errors.contactName && errors.contactName.type === "required" && (<label className="error-message">Field is required</label>)}
                                </div>
                                <div className="input-group">
                                    <label className="input-label">Contact Email*</label>
                                    <Controller as={TextField} type="email" rules={{ required: true, pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,validate: (value) => value.length >= 5 && value.length <= 30 }} name="contactEmail" placeholder="example@gmail.com" className={`form-fields  ${errors.contactEmail && 'error'}`} control={control} defaultValue="" />
                                    {errors.contactEmail && errors.contactEmail.type === "required" && (<label className="error-message">Field is required</label>)}
                                    {errors.contactEmail && errors.contactEmail.type === "pattern"  && (<label className="error-message">Field should be in mail format</label>)}
                                    {errors.contactEmail && errors.contactEmail.type === "validate" && (<label className="error-message">Field should allow Min 5 and Max 30 Characters</label>)}
                                </div>
                                {/* <div className="input-group">
                                    <label className="input-label">Contact Phone*</label>
                                    <Controller as={TextField} type="number" rules={{ required: true, pattern: /[0-9]/, validate: (value) => value.length <= 13 && value.length >= 5 }} name="contactPhone" placeholder="Contact Phone Number" className={`form-fields  ${errors.contactPhone && 'error'}`} control={control} defaultValue="" />
                                    {errors.contactPhone && errors.contactPhone.type === "required" && (<label className="error-message">Field is required</label>)}
                                    {errors.contactPhone && errors.contactPhone.type === "validate" && (<label className="error-message">Number should be Min 5 and Max 13 Characters</label>)}
                                </div> */}
                                <div className="input-group">
                                    <label className="input-label">Contact Phone</label>
                                    <Controller as={TextField} type="text" name="contactPhone" placeholder="Contact Phone Number" className={`form-fields ${errors.contactPhone && 'error'}`} control={control} defaultValue="" />
                                </div>
                                <div className="input-group">
                                    <label className="input-label">Address</label>
                                    <Controller as={TextField} type="text" name="contactAddress" placeholder="Address" className={`form-fields ${errors.contactAddress && 'error'}`} control={control} defaultValue="" />
                                </div>
                            </Grid>
                        </Grid>

                        {openConfirm &&
                            <div className="confim-model-container">
                                <Paper className="confirmation-wrapper padd-20">
                                    <Grid container sm={12}>
                                        <div className="confirmation-sec">
                                            <p>{confirmMessage}</p>
                                            <DialogActions>
                                                <Button className="btn btn-secondary" onClick={handleConfirmCanceled} color="primary">CANCEL</Button>
                                                <Button color="primary" onClick={deleteClicked ? deletedConfirm : handleSubmit(clickConfirmed)} className="btn btn-primary button">CONFIRM</Button>
                                            </DialogActions>
                                        </div>
                                    </Grid>
                                </Paper>
                            </div>
                        }
                    </DialogContent>

                    <DialogActions>
                        {sponsorId && <Button onClick={handleDeleteSponsor} className="btn btn-secondary btn-delete" color="primary">DELETE</Button>}
                        <Button onClick={clickCanceled} className="btn btn-secondary" color="primary">CANCEL</Button>
                        <Button color="primary" className="btn btn-primary button" onClick={handleSubmit(onSubmit)}>{sponsorId ? "UPDATE SPONSOR" : "ADD SPONSOR"}</Button>
                    </DialogActions>
                </form>

                <Snackbar open={openToast} autoHideDuration={5000} onClose={handleClosed}>
                    <Alert onClose={handleClosed} severity="error">{errorMsg}</Alert>
                </Snackbar>

                <Backdrop className="loader-signup" open={load}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Dialog>
        </div>
    )
}
