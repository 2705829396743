import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Grid, TextField } from '@material-ui/core';
import Card from '@mui/material/Card';
import './mintingtoken.css'
import NetworkManager from '../../utilities/NetworkManager';
import { useParams } from "react-router-dom";
import { gql, useMutation, useQuery } from '@apollo/client';
import { Backdrop, CircularProgress, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { config } from "../../config";
import logo from "../../assets/ic_logo.png";
import ConnectWallet from "../connectwallet/connectwallet";
import Connectwallet1 from "../connectwallet/connectwallet1";
import PointsvilleWallet from "./pointsvillewallet";
import Web3 from "web3";
import { useEthers, useEtherBalance } from "@usedapp/core";
import ConnectTronWallet from "../connectwallet/connecttronwallet";

// const Web3Eth = new Web3(new Web3.providers.HttpProvider(`https://rinkeby.infura.io/v3/0x7c5d4eCAD420aFBaC8040be91FE36A1eC9614Cb2`));

interface ParamTypes {
    orgId: string;
}
interface Props {
    refetch: () => void
    handleClosePopup: (active: boolean) => void,
    orgDataToken : any
}
const GET_ORG_BY_ID = gql`
    query getClub($clubId: String!) {
        getClub(clubId: $clubId) {
            id
            name
            orgCode
            orgType
            blockChainType
            isMainnet
        }
    }
`;
const UPDATE_ORG_TOKEN = gql`
mutation orgIsToken($Id: String!,$IsToken: Boolean!) {
        orgIsToken(id: $Id, isToken: $IsToken) {
            id,
            isToken
        }
    }
`;
function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function MinitingToken(props: Props) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [amount, setAmount] = React.useState("");
    const [publicKey, setPublicKey] = React.useState("");
    const [privateKey, setPrivateKey] = React.useState("");
    const [transactionLink, setTransactionLink] = React.useState("");
    const [contractLink, setContractLink] = React.useState("");
    const [supplyTokenLink, setSupplyTokenLink] = React.useState("");
    const [walletId, setWalletId] = React.useState("");
    const [error, setError] = React.useState<any>({});
    const [isWalletExist, setIsWalletExist] = React.useState(false);
    // let { orgId } = useParams<ParamTypes>();
    let orgId:any = props.orgDataToken.id;
    const { data: orgData } = useQuery<any>(GET_ORG_BY_ID, { variables: { clubId: orgId }, fetchPolicy: "network-only" });
    const [loading, setLoading] = React.useState(false);
    const userId = localStorage.getItem("userId");
    const [open, setOpen] = React.useState(false);
    const [isErrorMessage, setIsErrorMessage] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [completeAllSteps, setCompleteAllSteps] = React.useState(false);
    const [token, setToken] = React.useState("");
    const [hasToken, setHasToken] = React.useState(false);
    const [tokenName, setTokenName] = React.useState("");
    const [walletBalance, setWalletBalance] = React.useState("");
    const [totalSupply, setTotalSupply] = React.useState("");
    const [tokenBalance, setTokenBalance] = React.useState("");
    const [contractAddress, setContractAddress] = React.useState("");
    const [initialSupply, setInitialSupply] = React.useState(0);
    const [updateOrgToken] = useMutation(UPDATE_ORG_TOKEN,{ onCompleted: () => { props.refetch() } });
    const { account, deactivate } = useEthers();
    const handleChangePubKey = (pubkey: any) => {
        setPublicKey(pubkey);
        importWallet(pubkey);
    }
    const handleClose = (event: any, reason: any) => {
        setIsErrorMessage(false);
        setErrorMessage("");
        setOpen(false);
    };
    const deactivWallet = async () => {
        if (account) {
            await deactivate();
        }
    }
    const importWallet = async (pubkey: any) => {
        let dataToSend: any = {
            organization_id: orgId,
            wallet_type: "CONNECTED",
            wallet_name: orgData ? orgData?.getClub?.name + " wallet" : "",
            private_key: "",
            public_key: pubkey,
            wallet_description: orgData ? orgData?.getClub?.name + " wallet" : "",
            blockchain_type: orgData?.getClub?.blockChainType,
            blockchain_env: orgData?.getClub.isMainnet === true ? 'mainnet' : 'testnet',
        }
        setLoading(true);
        await NetworkManager.importWallet(orgData?.getClub?.blockChainType, dataToSend).then((response: any) => {
            if (response) {
                setLoading(false);
                setOpen(true);
                setIsErrorMessage(false);
                setErrorMessage(response?.result);
                setWalletId(response?.wallet_id);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
            else {
                setLoading(false);
                setIsErrorMessage(true);
                setOpen(true);
                setErrorMessage('Wallet was not imported.');

            }
        })
        .catch((error: any) => {
            console.log('import wallet error',error);
            setLoading(false);
            setIsErrorMessage(true);
            setOpen(true);
            setErrorMessage(error && error.message ? error.message : 'Wallet was not imported.');
        });
    }

    const transactionDetail = async (tx_hash: any, token_data_json: any) => {
        let txn_detail_data = {
            transaction_hash: tx_hash,
            blockchain_env: orgData.getClub.isMainnet === true ? 'mainnet' : 'testnet',
        }
        const details = await NetworkManager.getTransactionDetail(orgData?.getClub?.blockChainType, txn_detail_data).then((response: any) => {
            if (response && response?.status === 1) {
                let dataToSend = {
                    organization_id: orgId,
                    token_data_json: token_data_json,
                    contractAddress: response.contractAddress.toString(),
                    token_type: "CREATED",
                    blockchain_env: orgData.getClub.isMainnet === true ? 'mainnet' : 'testnet',
                    blockchain_type: orgData?.getClub?.blockChainType,
                    token_name:token_data_json.name,
                    tx_hash: tx_hash.toString()
                }
                NetworkManager.saveToken(orgData?.getClub?.blockChainType, dataToSend).then((response: any) => {
                    setLoading(false);
                    if (response && response?.resp && response?.status === "success") {
                        updateOrgToken({
                            variables:
                            {
                                Id: orgId,
                                IsToken: true
                            }
                        })
                        setOpen(true);
                        setIsErrorMessage(false);
                        setErrorMessage("Token ceated successfully");
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                        deactivWallet();
                        props.handleClosePopup(false);
                    }
                    else {
                        setLoading(false);
                        setIsErrorMessage(true);
                        setOpen(true);
                        setErrorMessage(response?.error ? response?.error : 'Token was not created.');
                    }

                })
                .catch((error: any) => {
                    console.log('save token db error',error);
                    setLoading(false);
                    setIsErrorMessage(true);
                    setOpen(true);
                    setErrorMessage(error && error.message ? error.message : 'Token was not save to db.');
                });
            }
            else {
                setLoading(false);
                setIsErrorMessage(true);
                setOpen(true);
                setErrorMessage( response && response?.resMessage ? response?.resMessage : 'Transaction detail was not fetch successfully.');
            }
        })
        .catch((error: any) => {
            console.log('transaction detail error',error);
            setLoading(false);
            setIsErrorMessage(true);
            setOpen(true);
            setErrorMessage(error && error.message ? error.message : 'Transaction detail was not fetch successfully.');
        });
    }
    const openMetamask = async (token_data_json: any, transaction: any) => {

            const val: any = Web3.utils.toWei('1', 'ether');

            const param: any = [{
                chainId: transaction.chainId.toString(),
                gasPrice: transaction.gasPrice.toString(),
                gasLimit: transaction.gas.toString(),
                from: transaction.from.toString(),
                data: transaction.data.toString()
            }];


            const value = await (window as any).ethereum.request({
                method: "eth_sendTransaction", params: param,
            }).then(async (tx_hash: any) => {
                if (tx_hash) {
                    setTimeout(() => {
                        transactionDetail(tx_hash, token_data_json);
                    }, 5000)
                }
                else {
                    setLoading(false);
                    setIsErrorMessage(true);
                    setOpen(true);
                    setErrorMessage('Invalid transaction hash.');
                }

            })
                .catch((error: any) => {

                    setLoading(false);
                    setIsErrorMessage(true);
                    if (error && error.code == '4001') {
                        setOpen(true);
                        setErrorMessage("You have denied the transaction.");
                    }
                    else {
                        setOpen(true);
                        setErrorMessage(error.message);
                    }
                    // If the request fails, the Promise will reject with an error.
                });
    }

    const openTron = async (token_data_json: any, transaction: any) => {

        const tronweb = (window as any).tronWeb;
        const senderAddress  = tronweb.defaultAddress.base58;
        const l_args = {
            feeLimit: 10e9,
            // callValue: 0,
            // tokenId:"",
            // tokenValue:0,
            // userFeePercentage: 30,
            originEnergyLimit: 1e7,
            abi: token_data_json.abi,
            // abi: tronweb.toHex(token_data_json.abi),
            bytecode: token_data_json.bytecode.toString(),
            // parameters: [],
            name: token_data_json.name.toString()//,
            // permissionId:1
        };
        await tronweb.transactionBuilder.createSmartContract(l_args, senderAddress)
            .then(async (trnx:any)  => {
                await tronweb.trx.sign(trnx)
                .then(async (signedTx:any)  => {
                    await tronweb.trx.sendRawTransaction(signedTx)
                    .then((trnx:any)  => {
                        if (trnx && trnx.txid) {
                            setTimeout(() => {
                                transactionDetail(trnx.txid, token_data_json);
                            }, 10000)
                        }
                        else {
                            setLoading(false);
                            setIsErrorMessage(true);
                            setOpen(true);
                            setErrorMessage('Invalid txid.');
                        }
                    })
                    .catch((error: any) => {
                        console.log('send row transaction error',error);
                        setIsErrorMessage(true);
                        setOpen(true);
                        setErrorMessage(error);
                        console.log("error",error)
                        setLoading(false);
                    });
                })
                .catch((error: any) => {
                    setIsErrorMessage(true);
                    setOpen(true);
                    if (error == 'Confirmation declined by user') {
                        setOpen(true);
                        setErrorMessage("You have denied the transaction.");
                    }
                    else {
                        console.log('trx sign error',error);
                        setOpen(true);
                        setErrorMessage(error);
                    }
                    setLoading(false);
                });
            })
            .catch((error: any) => {
                console.log('creare smartcontract error',error);
                setIsErrorMessage(true);
                setOpen(true);
                setErrorMessage(error);
                setLoading(false);
            });
    }
    const createToken = async () => {
        const tokenName = orgData ? orgData?.getClub?.orgCode : '';
        let dataToSend = {
            token_name: tokenName,
            decimals: 0,
            symbol: tokenName,
            initial_supply: initialSupply,
            organization_id: orgId,
            blockchain_env: orgData?.getClub.isMainnet === true ? 'mainnet' : 'testnet',
            //create_user: userId,
        }
        setLoading(true);
        await NetworkManager.createToken(orgData?.getClub?.blockChainType, dataToSend).then((response: any) => {

            if (response && response?.transaction) {
                if(props.orgDataToken.blockChainType === 'Avalanche' || props.orgDataToken.blockChainType === 'Ethereum' || props.orgDataToken.blockChainType === 'Polygon') {
                    openMetamask(response?.token_data_json, response?.transaction);
                }
                else {
                    openTron(response?.token_data_json, response?.transaction);
                }

            }
            else {
                setLoading(false);
                setIsErrorMessage(true);
                setOpen(true);
                setErrorMessage('Token was not created.');
            }
        })
        .catch((error: any) => {
            console.log('create token error',error);
            setLoading(false);
            setIsErrorMessage(true);
            setOpen(true);
            setErrorMessage(error && error.message ? error.message : 'Token was not created.');
        });
    }

    const handleOnchange = (event: any) => {
        const { name, value } = event.target
        let error: any = {};
        if (name === "publicKey") {
            setPublicKey(value);
            error.publicKey = "";
        }
        if (name === "privateKey") {
            setPrivateKey(value);
            error.privateKey = "";
        }
        if (name === "amount") {
            let regex_allowed2 = /^[0-9/]+$/
            if (value.length && regex_allowed2.test(value) === false) {
                return false;
            }
            setAmount(value);
            error.amount = "";
        }
        if (name === "initialSupply") {
            let regex_allowed2 = /^[0-9/]+$/
            if (value.length && regex_allowed2.test(value) === false) {
                return false;
            }
            setInitialSupply(value);
            error.initialSupply = "";
        }

        if (name === "contractAddress") {
            setContractAddress(value);
        }


        // setTokenDecimal(value)
        setError(error)
    };

    const getTokenID = async () => {
        let dataToSend = {
            organization_id: orgId,
            blockchain_env: orgData.getClub.isMainnet === true ? 'mainnet' : 'testnet',
        }
        setLoading(true);
        await NetworkManager.getTokenID('Avalanche', dataToSend).then((response: any) => {
            if (response) {
                setLoading(false);
                setHasToken(response.has_token)
                if (response.has_token) {
                    setTokenName(response?.token_name);
                    setWalletBalance(response?.wallet_balance);
                    setTotalSupply(response?.total_supply);
                    setContractLink(response?.contract_link);
                    setTokenBalance(response?.token_balance);
                    setTransactionLink(response?.token_link);
                }
            }
        })
    };


    React.useEffect(() => {
        if (token) {

        }
        // getTokenID();
    }, [token]);

    const registerToken = async () => {
        if(!contractAddress) {
            let error: any = {};
            error.contractAddress = "This field is required";
            setError(error);
            return false;
        }
        const tokenName = orgData ? orgData?.getClub?.orgCode : '';
        let dataToSend = {
            organization_id: orgId,
            token_name: tokenName,
            token_data_json: {"name": tokenName},
            contractAddress: contractAddress,
            token_type: "IMPORTED",
            tx_hash: "",
            blockchain_env: orgData?.getClub.isMainnet === true ? 'mainnet' : 'testnet',
            blockchain_type : orgData?.getClub?.blockChainType
        }
        setLoading(true);
        await NetworkManager.registerToken(orgData?.getClub?.blockChainType, dataToSend).then((response: any) => {
            setLoading(false);
            if(response && response?.resp && response?.resp?.status === "failed") {
                setIsErrorMessage(true);
                setOpen(true);
                setErrorMessage(response?.resp?.error);
            }
            else if(response && response?.status === 'failed') {
                setIsErrorMessage(true);
                setOpen(true);
                setErrorMessage(response?.error);
            }
            else {
                setIsErrorMessage(false);
                setOpen(true);
                props.handleClosePopup(false);
                setErrorMessage(response?.resp?.result);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                updateOrgToken({
                    variables:
                    {
                        Id: orgId,
                        IsToken: true
                    }
                });
            }
        })
        .catch((error: any) => {
            console.log('register token error',error);
            setLoading(false);
            setIsErrorMessage(true);
            setOpen(true);
            setErrorMessage(error && error.message ? error.message : 'Wallet was not imported.');
        });
    }

    return (
        <div className="list-page minting-token-conatainer">
            {/* <Card sx={{ boxShadow: 'none !important' }}> */}
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Box sx={{ maxWidth: "500px" }}>
                            <Stepper activeStep={activeStep} orientation="vertical">
                                <Step key={"0"}>
                                    <StepLabel>Import Wallet</StepLabel>
                                    <StepContent>
                                        {
                                            props.orgDataToken.blockChainType === 'Avalanche' || props.orgDataToken.blockChainType === 'Ethereum' || props.orgDataToken.blockChainType === 'Polygon' ?
                                                <ConnectWallet handleChangePubKey={handleChangePubKey} />
                                            :
                                                <ConnectTronWallet handleChangePubKey={handleChangePubKey} />
                                        }
                                    </StepContent>
                                </Step>
                                <Step key={"1"}>
                                    <StepLabel>Create or Register Token </StepLabel>
                                    <StepContent>
                                        <Box sx={{ mb: 2 }}>
                                            <Button variant="contained" onClick={createToken} sx={{ mt: 1, mr: 1, width: "100%" }}>Create Token</Button>
                                            <Typography variant="subtitle2" gutterBottom component="div" className='or-section'>
                                                OR
                                            </Typography>
                                            <React.Fragment>
                                                <TextField
                                                    id="standard-basic"
                                                    label="Contract Address"
                                                    onChange={handleOnchange}
                                                    name="contractAddress"
                                                    variant="standard"
                                                    value={contractAddress}
                                                    style={{ width: "95%", borderBottom: error && error.contractAddress ? "1px solid red" : "1px solid #2B2E4C" }} />
                                                    <Typography className='sub-caption' sx={{ color: "red" }}>
                                                    {error && error.contractAddress ? error.contractAddress : ""}
                                                </Typography>
                                                <Button variant="contained" onClick={registerToken} sx={{ mt: 1, mr: 1, width: "100%" }}>Register Token</Button>
                                            </React.Fragment>
                                        </Box>
                                    </StepContent>
                                </Step>
                                <Step key={"2"} completed={completeAllSteps}>
                                    <StepLabel>Finish</StepLabel>
                                    <StepContent>
                                        <Box sx={{ mb: 2 }}>
                                            <Typography variant="caption" display="block" gutterBottom sx={{ fontSize: "14px" }}>
                                                You have successfully completed all the steps
                                            </Typography>
                                        </Box>
                                    </StepContent>
                                </Step>

                            </Stepper>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>

                    </Grid>
                </Grid>
            {/* </Card> */}
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={isErrorMessage ? "error" : "success"}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Backdrop className="loader-signup" open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

