import React,{useState,useEffect} from 'react';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useHistory ,useParams} from "react-router-dom";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import MUIDataTable from "mui-datatables";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import DropdownSelect from '../../components/Dropdown/dropdownselect';
import Button from "@material-ui/core/Button";
import '../../constant.css';
import '../transaction/transactions.css';
import './merchandiselist.css';

import SponsorEmty from '../../assets/sponsor-empty@2x.svg';
import SponsorLogo from '../../assets/sponsor-logo.png';
import Emirates from '../../assets/emirates.png';
import Spark from '../../assets/spark.png';
import Omega from '../../assets/omega.png';
import AddMerchandise from './merchandise/addmerchandise';
import { useQuery, gql } from '@apollo/client';
import Loader from '../../components/Loader/loader';
import { AnyAaaaRecord } from 'dns';

import { EditorState, ContentState, convertFromRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
// import draftToHtml from 'draftjs-to-html'
// import htmlToDraft from 'html-to-draftjs'
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

// import S3 from 'aws-s3';
// import { config } from '../../config';

interface Props {
    onParentRender: () => void,
  }

// interface SponsorInventory {
//     id: string;
//     sponsor_name: string;
//     contact_name : string;
//     contact_email : string;
// }

// interface SponsorInventoryData {
//     sponsorInventory: SponsorInventory[];
// }

// interface SponsorInventoryVars {
//     year: number;
// }

interface ParamTypes {
    orgId: string;
}


// interface IFormAddClub {
//     sponsorLogo: string;
//     companyName: string;
//     pointRequest: string;
//     contactName: string;
//     contactMail : string;
//     contactPhone : string;
//     contactAddress : string;
// }

// export const GET_MEMBER_QUERY = gql`
//     query GetUserData {
//         getUser{
//             id
//             user
//             username
//             email
//             phoneNumber
//             isActive
//             walletId
//             walletPoints
//             balance
//             createdAt
//         }
//     }
// `;

// export const GET_MERCHANDISE_QUERY = gql`
//     query merchandiseAllByOrg($orgId: String!) {
//         merchandiseAllByOrg(orgId: $orgId){
//             merchandise_id
//             merchandise_name
//             merchandise_category
//             merchandise_pointcost
//             merchandise_numberoflisting
//             merchandise_creationdate
//             merchandise_updatedBy
//             merchandise_updatedAt
//             merchandise_status
//             merchandise_imageurl
//             merchandise_description
//             merchandise_isemailvoucher
//             merchandise_fulfillment
//             merchandise_listings {
//                 ml_id
//                 ml_starttime
//                 ml_endtime
//                 ml_selectioncapacity
//                 ml_availablecapacity
//                 ml_location
//                 ml_city
//                 ml_country
//             }
//         }
//     }
// `;


export const GET_MERCHANDISE_QUERY = gql`
    query merchandiseAllByOrg($orgId: String!) {
        merchandiseAllByOrg(orgId: $orgId){
            merchandise_id
            merchandise_imageurl
            merchandise_name
            merchandise_category
            merchandise_pointcost
            merchandise_numberoflisting
            merchandise_description
            merchandise_isemailvoucher
            merchandise_fulfillment
            merchandise_creationdate
            merchandise_updatedBy
            merchandise_updatedAt
            merchandise_status
        }
    }
`;

export default function MerchandiseList({onParentRender}:Props) {

    let { orgId } = useParams<ParamTypes>();

    // const [expanded, setExpanded] = useState(false);

    // const {refetch, loading, data} = useQuery<any>(GET_MEMBER_QUERY);
    const { refetch, loading, data } = useQuery<any>(GET_MERCHANDISE_QUERY, { variables: { orgId: orgId }});
    const [merchandiseData, setMerchandiseData] = React.useState([]);
    const [merchandiseId , setMerchandiseId] = React.useState<any>(0);

    const [open, setOpen] = React.useState(false);
    const [errorMsg,setErrorMsg] = useState<any>("");
    const [openToast,setOpenToast] = React.useState<any>(false);

    // const [viewType , setViewType] = React.useState<string | null>("Create");
    const [viewType , setViewType] = React.useState<any>("Create");
    const hasToken = localStorage.getItem('org_has_token');

    // const [sponsorId , setSponsorId] = React.useState<any>("");

    // const S3Client = new S3(config.s3Config);

    // to open error messages.
    const Alert = (props: AlertProps)  => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClose = () => {
        refetch();
        setOpen(false);
        setMerchandiseId(0);
        setViewType('Create');
        refetch();  // reload
    };

    const handleClosed = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenToast(false);
    };

    const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOpen(true);
        setMerchandiseId(0);
        // const targetElement = (event.target as HTMLButtonElement);
        setViewType('Create');
    };

    const handleEditClickOpen = (id:string) => {
        setOpen(true);
        setMerchandiseId(id);
        setViewType('Edit');
    };

    const handleCopyClickOpen = (id:string) => {
        setOpen(true);
        setMerchandiseId(id);
        setViewType('Copy');
    };

    // useEffect(() => {
    //     refetch();
    //     onParentRender();
    // },[open,data]);

    const getMerchandiseDetails = (objData:any) => {
        let selectedImageUrl = ''

        let newMerchandiseData:any = []
        for (let i=0; i<objData.length; i++) {
            let imageUrlList = objData[i].merchandise_imageurl ? JSON.parse(objData[i].merchandise_imageurl) : []
            if (imageUrlList && imageUrlList.length > 0) {
                for (let j=0; j<imageUrlList.length; j++) {
                    if (imageUrlList[j].isActive) {
                        selectedImageUrl = imageUrlList[j].imageUrl
                    }
                }
            } else {
                // selectedImageUrl = 'https://pointsvilleimagebucket.s3.amazonaws.com/photos_58cce953-1431-4260-b725-077b3c276d66'
            }


            // merchandise description..
            let merchandise_description:any = objData[i].merchandise_description;

            // let Final_Merchandise_description:any = '';
            // if (merchandise_description && merchandise_description.includes("</p>")) Final_Merchandise_description = merchandise_description;
            // else Final_Merchandise_description = '<p>' + (merchandise_description ? merchandise_description : '')  + '</p>'; // to support existing merchandise description.. with null values.

            // const { contentBlocks, entityMap } = htmlToDraft(Final_Merchandise_description);
            // const contentStateFromDB = ContentState.createFromBlockArray(contentBlocks, entityMap);

            const rawData = markdownToDraft(merchandise_description);
            const contentStateFromDB = convertFromRaw(rawData);
            // const editorStateFromDB = EditorState.createWithContent(contentStateFromDB);

            newMerchandiseData.push ({
                merchandiseId: objData[i].merchandise_id,
                merchandiseName: JSON.stringify([{
                    givenName: objData[i].merchandise_name,
                    profileImage: selectedImageUrl,
                }]),
                merchandiseCategory: objData[i].merchandise_category === 'card' ? 'Gift Card' : objData[i].merchandise_category,
                merchandisePointsCost: objData[i].merchandise_pointcost,
                merchandiseListingCount: objData[i].merchandise_numberoflisting,
                merchandiseCrDt: objData[i].merchandise_creationdate,
                merchandiseUpdateAt: objData[i].merchandise_updatedAt,
                merchandiseStatus: objData[i].merchandise_status,
                merchandiseDescription:  objData[i].merchandise_description, //contentStateFromDB
                merchandiseEmailVoucher: objData[i].merchandise_isemailvoucher,
                merchandiseFulfillment: objData[i].merchandise_fulfillment,
                merchandiseEditDet: JSON.stringify([{
                    merchandiseId: objData[i].merchandise_id,
                    merchandiseStatus: objData[i].merchandise_status,
                }])
            })
        }
        setMerchandiseData(newMerchandiseData)
    }

    useEffect(() => {
        if(localStorage.getItem('isMerchandiseUpdated') === 'YES') {
            localStorage.removeItem('isMerchandiseUpdated');
            setErrorMsg("Merchandise updated successfully.");
            setOpenToast(true);
        }

        if(localStorage.getItem('isMerchandiseDeactivated') === 'YES') {
            localStorage.removeItem('isMerchandiseDeactivated');
            setErrorMsg("Merchandise deleted successfully.");
            setOpenToast(true);
        }

        if(localStorage.getItem('isMerchandiseCreated') === 'YES') {
            localStorage.removeItem('isMerchandiseCreated');
            setErrorMsg("Merchandise created successfully.");
            setOpenToast(true);
        }


        // refetch();
        if (!loading) getMerchandiseDetails(data.merchandiseAllByOrg)
        // onParentRender();
    },[data]);

    // const handleReadMoreLess = () => {
    //     setExpanded(expanded ? false : true)
    // }

    // const methods = useForm<IFormAddClub>();
    // // const { handleSubmit, control, reset,errors } = methods;
    // const onSubmit: SubmitHandler<IFormAddClub> = data =>

    // to pass table columns
    const columns:any = [
        {
            name:"merchandiseName",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "merchandiseName") ? sortOrder.direction : "";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={0} onClick={() => updateDirection(0)} >
                            <a className="sort-by">Merchandise</a>
                        </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let fieldValues = JSON.parse(value);
                    let userName:string = fieldValues[0].givenName;
                    return (
                        <div className="pointer">
                            <img className={fieldValues[0].profileImage ? 'profile-img merchandise' : 'profile-img'} src={fieldValues[0].profileImage ||  "https://png.pngtree.com/png-vector/20190321/ourmid/pngtree-vector-users-icon-png-image_856952.jpg"} /><label className='membername pointer'>{userName.replace("&"," ")}</label>
                        </div>
                    )
                }
            }
        },
        {
            name:"merchandiseCategory",
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "merchandiseCategory")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={1} onClick={() => updateDirection(1)} >
                            <a className="sort-by">Category</a>
                        </th>
                    )
                }
            }
        },
        {
             name:"merchandisePointsCost",
             options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "merchandisePointsCost")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(2)} >
                            <a className="sort-by">{hasToken === 'true' ? 'Tokens Cost' : 'Points Cost'}</a>
                        </th>
                    )
                }
             }
        },
        {
            name:"merchandiseListingCount",
            options:{
               filter:false,
               sortThirdClickReset: true,
               customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                   let orderName = (sortOrder.name == "merchandiseListingCount")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={3} onClick={() => updateDirection(3)} >
                            <a className="sort-by"># of listing</a>
                        </th>
                    )
               }
            }
        },
        {
            name: 'merchandiseCrDt',
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "merchandiseCrDt")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={4} onClick={() => updateDirection(4)} >
                            <a className="sort-by">Creation Date</a>
                        </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    return (
                        // <div><label className="">{moment(parseInt(value)).format('DD MMM YYYY HH:mm')}</label></div>
                        <div><label className="">{moment(parseInt(value)).format('MM/DD/YYYY')}</label></div>
                    )
                }
            }
        },
        {
            name: 'merchandiseUpdateAt',
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "merchandiseUpdateAt")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={5} onClick={() => updateDirection(5)} >
                            <a className="sort-by">Last Modified</a>
                        </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    return (
                        // <div><label className="">{moment(parseInt(value)).format('DD MMM YYYY HH:mm')}</label></div>
                        <div><label className="">{moment(parseInt(value)).format('MM/DD/YYYY')}</label></div>
                    )
                }
            }
        },
        {
            name: 'merchandiseStatus',
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "merchandiseStatus")?sortOrder.direction:"";
                    return(
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={6} onClick={() => updateDirection(6)} >
                            <a className="sort-by">Status</a>
                        </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    return (
                        <div><label className={value === 'INACTIVE' ? 'status-inactive' : 'status-active'}>{value === 'ACTIVE' ? "Active" : "InActive"}</label></div>
                    )
                }
            }
        },
        {
            name: 'merchandiseEditDet',
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "merchandiseEditDet")?sortOrder.direction:"";
                    return(
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={7} onClick={() => updateDirection(7)} >
                            {/* <a className="sort-by">Edit</a> */}
                        </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let fieldValues = JSON.parse(value);
                    let merchandise_Id = fieldValues[0].merchandiseId;
                    let merchandise_Status = fieldValues[0].merchandiseStatus;
                    return (
                        // <div><label className="">{value ? "Active":"InActive"}</label></div>
                        merchandise_Status === 'ACTIVE' && <label className=""><span onClick={() => handleEditClickOpen(merchandise_Id)} data-id={merchandise_Id} data-view="Edit" className="table-edit-icon"></span></label>
                    )
                }
            }
        },
        {
            name: 'merchandiseEditDet',
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "merchandiseEditDet")?sortOrder.direction:"";
                    return(
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={8} onClick={() => updateDirection(7)} >
                            {/* <a className="sort-by">Edit</a> */}
                        </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let fieldValues = JSON.parse(value);
                    let merchandise_Id = fieldValues[0].merchandiseId;
                    let merchandise_Status = fieldValues[0].merchandiseStatus;
                    return (
                        // <div><label className="">{value ? "Active":"InActive"}</label></div>
                        merchandise_Status === 'ACTIVE' && <label className=""><span onClick={() => handleCopyClickOpen(merchandise_Id)} data-id={merchandise_Id} data-view="Edit" className="btn-copy">Copy</span></label>
                    )
                }
            }
        },
        {
            name: 'merchandiseId',
            options:{
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "isEdit")?sortOrder.direction:"";
                    return(
                        <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={9} onClick={() => updateDirection(8)} >
                            {/* <a className="sort-by">Edit</a> */}
                        </th>
                    )
                },
                customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
                    let merchandise_Id = value;
                    return (
                        // <div><label className="">{value ? "Active":"InActive"}</label></div>
                        <label className=""><span data-id={merchandise_Id} data-view="Edit" className=""></span></label>
                    )
                }
            }
        },
        {
            name:'merchandiseDescription',
            options:{
                display: false,
                viewColumns: false,
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "merchandiseDescription")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting "} style={{ cursor: 'pointer' }} key={10} onClick={() => updateDirection(9)} >
                            <a className="sort-by">Description</a>
                        </th>
                    )
                }
            }
        },
        {
            name:'merchandiseEmailVoucher',
            options:{
                display: false,
                viewColumns: false,
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "merchandiseEmailVoucher")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting "} style={{ cursor: 'pointer' }} key={11} onClick={() => updateDirection(10)} >
                            <a className="sort-by">Email Voucher</a>
                        </th>
                    )
                }
            }
        },
        {
            name:'merchandiseFulfillment',
            options:{
                display: false,
                viewColumns: false,
                filter:false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
                    let orderName = (sortOrder.name == "merchandiseFulfillment")?sortOrder.direction:"";
                    return (
                        <th className={"table-header-sorting "} style={{ cursor: 'pointer' }} key={12} onClick={() => updateDirection(11)} >
                            <a className="sort-by">Fulfillment</a>
                        </th>
                    )
                }
            }
        }
    ];

    // // to se various options for table columns and rows.
    const options:any = {
        filter: false,
        filterType: "dropdown",
        responsive:"standard",
        print:false,
        download : false,
        pagination : true,
        viewColumns : false,
        selectableRowsHideCheckboxes: true,
        fixedSelectColumn: false,
        selectToolbarPlacement: "none",
        selectableRowsHeader: false,
        selectableRows: "none",
        selectableRowsOnClick: false,
        fixedHeader : true,
        tableBodyHeight:'450px',
        search:false,
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        isRowExpandable: (dataIndex:any, expandedRows:any) => {
            // if (dataIndex === 1 || dataIndex === 2) return false;

            // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
            if (expandedRows.data.length > 1 && expandedRows.data.filter((d:any) => d.dataIndex === dataIndex).length === 0) return false;
            return true;
        },
        rowsExpanded: [], //[0, 1],
        renderExpandableRow: (rowData:any, rowMeta:any) => {
            const colSpan = rowData.length + 1;
            return (

                <TableRow>
                    <TableCell colSpan={colSpan} className='expand-block-highlight'>
                        <Grid container spacing={4}>
                            <Grid item xs={8}>
                                <div className='expand-desc-title'>Description</div>
                                <div>{merchandiseData[rowMeta?.rowIndex]['merchandiseDescription']}</div>

                            </Grid>
                            <Grid item xs={2}>
                                <div className='expand-desc-title'>Email Voucher</div>
                                <div>{merchandiseData[rowMeta?.rowIndex]['merchandiseEmailVoucher'] ? 'YES' : 'NO'}</div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className='expand-desc-title'>Merchandise Fulfillment</div>
                                <div>{merchandiseData[rowMeta?.rowIndex]['merchandiseFulfillment'] ? 'YES' : 'NO'}</div>
                            </Grid>
                        </Grid>
                    </TableCell>
                </TableRow>
            );
        },
        onRowExpansionChange: (curExpanded:any, allExpanded:any, rowsExpanded:any) => {}
    }

    // to upload csv file to S3 to add merchandise as draft status.
    const uploadImage = (e:React.ChangeEvent<any>) => {
        const targetFiles:any = e.target.files;


        if (targetFiles[0].type == "text/csv") {
            // setLoader(true);
            if (targetFiles.length > 0) {
                // S3Client
                // .uploadFile(targetFiles[0], `import_merchandise_${Math.random()}`)
                // .then((res: any) => {

                //     // setLoader(false);
                // })
                // .catch((err: any) => {});
            }
        } else {
            setErrorMsg("Please upload file type as CSV.");
            setOpenToast(true);
        }
    }

    return (
        <div className="list-page manage-member-wrapper">
            <Grid container xs={12} sm={12}>
                <Grid  item sm={12} xs={12}>
                    <Paper className="table-wrapper">
                        <Grid container className="table-head-section" sm={12}>
                            <Grid item sm={8}>
                                <label className="table-heading">FAN SHOP LIST</label>
                            </Grid>
                            <Grid  item sm={4} className="select-with-label align-right flx-align-center flx-end">
                                <div className='import-box flx-align-center'>
                                    <div className='import-flx-box flx-align-center'>
                                        <div className='ic-import-merch' /> <span className='import-btn-tile'>import</span>
                                        <input type="file" className="form-fields form-field-file import-csv-file" onChange={(e) => uploadImage(e)} />
                                    </div>
                                </div>
                                {/* + Add Merchandise */}
                                <button onClick={handleClickOpen} data-view="Create" className="btn-highlight btn btn-primary add-btn"><span data-view="Create" className="btn-icon"></span><label className="btn-caption"> ADD MERCHANDISE</label></button>
                            </Grid>
                            <Grid container className='cust-tble-hdr' xs={12} sm={12}>
                                <Grid item sm={12} xs={12}>
                                    <div className="table-container muidata-table cust-tbl-block">
                                        {loading ? (
                                            <Loader size="90px"/>
                                        ):(
                                            <MUIDataTable
                                                title={""}
                                                data={merchandiseData}
                                                columns={columns}
                                                options={options}
                                            />
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            {/* to open Add Merchandise Dialog */}
            {/* {open && <AddMerchandise onOpen={open} editId={merchandiseId} viewTypeTxt={viewType} handleCloseClick={handleClose} />} */}
            <AddMerchandise onOpen={open} editId={merchandiseId} viewTypeTxt={viewType} handleCloseClick={handleClose} />

            {/* To display error message */}
            <Snackbar open={openToast} autoHideDuration={5000}  onClose={handleClosed}>
                <Alert onClose={handleClosed} severity="success">{errorMsg}</Alert>
            </Snackbar>
        </div>
    )
}
