import React ,{useEffect}from 'react';
import { RouteProps,useLocation ,RouteComponentProps,useHistory} from "react-router";
import Paper from '@material-ui/core/Paper';
import { Auth } from 'aws-amplify';
import Grid from '@material-ui/core/Grid';
// import Chip from '@material-ui/core/Chip';
// import DoneIcon from '@material-ui/icons/Done';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import '../../constant.css';
import '../memberprofile/memberprofile.css';
import './adminprofiledetails.css';
import ProfileImg from '../../assets/Combined Shape@2x.svg';
import DataTable from './datatable';
import { useQuery, gql,useMutation } from '@apollo/client';
import Loader from '../../components/Loader/loader';
interface LocationState extends RouteComponentProps {
    state ?: string;
  }
  interface Props {
    onParentRender: () => void,
  }
const GET_USER_BY_ID = gql`
  query getUsers($username: String!) {
    getUserWithIdAdmin(username: $username) {
        id
        username
        givenName
        nickName
        email
        profileImage
        dob
        address
        phoneNumber
        isActive

    }
  }
`;
const GET_PRE_SIGNED_URL = gql`
    query getPreSignedUrl($fileName: String!){
        getPreSignedUrl(fileName: $fileName){
            preSignedUrl
        }
    }
`;

const UPDATE_MEMBER = gql`
    mutation updateUserDetails($username : String!,$data: UpdatedUserInput! ){
        updateUserDetails(username: $username, data: $data ){
            givenName
    }
}`;
// Increase pixel density for crop preview quality on retina screens.
const pixelRatio = window.devicePixelRatio || 1;

// We resize the canvas down when saving on retina devices otherwise the image
// will be double or triple the preview size.
function getResizedCanvas(canvas:any, newWidth:any, newHeight:any) {
  const tmpCanvas = document.createElement("canvas");
  tmpCanvas.width = newWidth;
  tmpCanvas.height = newHeight;

  const ctx:any = tmpCanvas.getContext("2d");
  ctx.drawImage(
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    0,
    0,
    newWidth,
    newHeight
  );

  return tmpCanvas;
}
export default function AdminProfileDetails({onParentRender} : Props) {
    const history = useHistory();
    const {state} = useLocation<LocationState>();

    const editClick = (userId:any) => history.push({pathname:'/editprofile', state:userId});
    const [userData,setUserData] = React.useState<any>({});
    const [userId,setUserId] = React.useState<any>({});
    const [loader,setLoader] = React.useState<any>(false);
    const [targetFile, setTargetFile] = React.useState<any>([])
    const [uploadFileName, setUploadFileName] = React.useState<any>('')
    const [logoUrl, setlogoUrl] = React.useState<any>();
    const imgRef = React.useRef(null);
    const { loading: urlLoading, data: urlData, refetch: urlRefetch } = useQuery<any>(GET_PRE_SIGNED_URL, { skip: uploadFileName.length > 0 ? false : true , variables: { fileName: uploadFileName }});
    const { loading, data, refetch } = useQuery<any>(GET_USER_BY_ID, { fetchPolicy: "network-only", variables: {username: localStorage.userId } });
    const [updateUserDetails] = useMutation(UPDATE_MEMBER,{ onCompleted : () =>  history.go(0) });
    useEffect(() => {
        refetch();
       if(!loading){
        setUserData(data.getUserWithIdAdmin);
        setlogoUrl(data.getUserWithIdAdmin.profileImage)
        onParentRender();
       }
    },[data]);

    useEffect(() => {
        if(!urlLoading && uploadFileName.length > 0) {
            urlRefetch()
            if (urlData) {
                setUploadFileName('')
                fileUploadToS3(urlData.getPreSignedUrl.preSignedUrl)
                refetch();
            }
        }
    },[uploadFileName, urlData]);

    const signOutClick = () => {
        try {
            localStorage.removeItem("userId")
            Auth.signOut();
            window.open('/');
        } catch (error) {
        }
    }

    const getUserName = (name:any) => {
        if (name && name.search('&') > 0) {
            return name.replace('/&/gi', ' ')
        } else return name;
    }

    // to upload a image to S3 using presigned url.
    const fileUploadToS3 = (objUrl:any) => {
        if (objUrl) {
            fetch(objUrl, {
                method: 'PUT',
                headers: { 'Content-Type': 'image/*' },
                body: targetFile[0]
            }).then((response) => {
                if (response && response.url) {
                    setlogoUrl(response.url.split('?')[0]);
                    setLoader(false);
                    updateUserDetails({
                        variables :
                        { username : state,
                            data :
                            {
                                givenName : data.givenName,
                                email : data.email,
                                username:state,
                                phoneNumber : data.phoneNumber,
                                profilePicture:response.url.split("?")[0]
                            }
                        }
                    })
                }
            })
        }
    }

    const uploadImage = (e:React.ChangeEvent<any>) =>{
        const targetFiles:any = e.target.files;

        if (targetFiles.length === 0) return;
        if(targetFiles[0].type === "image/png" || targetFiles[0].type === "image/jpeg"){
            setLoader(true);

            if (targetFiles.length > 0) {
                // After passing targetFiles and file name then, upload logic to S3 using presigned url written in useeffect.
                setUploadFileName(targetFiles[0].name)
                setTargetFile(targetFiles)
            }
        }else{
            // setErrorMsg("Image format should png,jpg");
            // setOpenToast(true);
        }
    }

    return (
    <div className="detail-page admin-profile-details">
        <div className="page-head p-relative padl-40">

        </div>
        <div className="page-body padl-30" >
            <Grid container spacing={3} sm={12}>
                <Grid item className="mrgCenter" sm={6} spacing={1}>
                    <div className="input-group input-file-group edit admin-profile-edit">
                        {loader && <Loader size="30px"/>}
                        <img className="profile-img-editview" src={logoUrl || ProfileImg} alt="org-logo"/>
                        <input  type="file" name="adminLogo" onChange={(e) => uploadImage(e)}   className="form-fields form-field-file" style={{width:"15%"}} title="Select image" />
                        <span  className="profile-edit-icon">&#128397;</span>
                    </div>
                    <Paper className="">
                        <Grid container className="profile-details flx-align-center" sm={12} spacing={0}>
                            <Grid item sm={6} xs={6}>
                                <h5 className="profile-name">Personal Info</h5>
                            </Grid>
                            <Grid item className="align-right" sm={6} xs={6}>
                                <span onClick={() => editClick(userData.username)} className="general-icon edit-icon"></span>
                            </Grid>
                        </Grid>
                        <div className="admin-personal-info-section">
                            <Grid item sm={12}>
                                <label className="label-txt">Full Name</label>
                                <label className="field-txt">{getUserName(userData.givenName)}</label>
                            </Grid>
                            <Grid item sm={12} className="display-none">
                                <label className="label-txt">Last Name</label>
                                <label className="field-txt"></label>
                            </Grid>
                            <Grid item className="" sm={12}>
                                <label className="label-txt">Email ID</label>
                                <label className="field-txt">{userData.email}</label>
                            </Grid>
                            <Grid item sm={12} className={userData.phoneNumber ? "" : "display-none"}>
                                <label className="label-txt">Phone Number</label>
                                <label className="field-txt">{userData.phoneNumber}</label>
                            </Grid>

                        </div>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container sm={12} spacing={3}>
                <Grid item className="mrgCenter" sm={6} spacing={1}>
                    <Paper>
                        <span className="logout-icon"></span>
                        <label onClick ={signOutClick} className="logout-txt">Logout</label>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container sm={12} spacing={3}>
                <Grid item className="mrgCenter align-right" sm={6} spacing={1}>
                   <div className="company-conditions-section">
                       <a href="https://www.pointsville.com/privacy.html" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                       <a href="https://www.pointsville.com/tos.html" target="_blank" rel="noopener noreferrer">Terms and Condition</a>
                       <a href="https://www.pointsville.com/disclaimer.html" target="_blank" rel="noopener noreferrer">Disclaimer</a>
                       <a href="https://www.pointsville.com/cookies.html" target="_blank" rel="noopener noreferrer">Cookie Policy</a>
                   </div>
                </Grid>
            </Grid>
        </div>
    </div>
    )
}
