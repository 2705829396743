import React from 'react';
import { RouteProps,useLocation ,RouteComponentProps} from "react-router";

// import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
// import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
// import Grid from '@material-ui/core/Grid';
import PointHolderList from './pointholders';
import PromotionLists from './promotionlist';
import SponsorList from './sponsorList';
import MerchandiseList from './merchandiselist';
// import EventsList from './eventslist';
import OrgInfo from './orginfo';
import './clubdetailtabs.css';
import { CodeRounded } from '@material-ui/icons';
import WidgetSetting from "./widgetsetting";
import MinitingToken from "./minitingtoken";
import WhiteLabel from "./whiteLabel";
import ApiGuide from './apiguide';
import ExternalApi from './externalapi/externalApi';
import Setting from "./settingsOrg";

interface Props {
  onParentRender: () => void,
  refetch : () => void
  rate: any,
  code: any
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
interface LocationState extends RouteComponentProps {
  state ?: string;
}


function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="tabpanel-parent" >
          {children}
        </div>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function ClubDetailsTabs({onParentRender, rate, code,refetch} : Props) {
  const {state} = useLocation<LocationState>();
  const hasToken = localStorage.getItem('org_has_token');
  const tabValue = (state)?1:0;
  const [value, setValue] = React.useState(tabValue);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className="custom-tab org-info-main">
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Sponsor" {...a11yProps(0)} />
          <Tab label="Promotions" {...a11yProps(1)} />
          <Tab label="Shop" {...a11yProps(2)} />
          {/* <Tab label="Events" {...a11yProps(3)} /> */}
          <Tab label={hasToken === "true" ? 'Tokens Holders' : 'Points Holders'} {...a11yProps(3)} />
          {/* <Tab label="Org Info" {...a11yProps(4)} /> */}
          {/* <Tab label="Widget Installation" {...a11yProps(4)} /> */}
          {/* <Tab label="Minting Token" {...a11yProps(6)} /> */}
          {/* <Tab label="White Label" {...a11yProps(5)} /> */}
          {/* <Tab label="Api Access" {...a11yProps(6)} />   */}
          <Tab label="Settings" {...a11yProps(4)} />

        </Tabs>
      </AppBar>

      {/* Sponsors */}
      <TabPanel value={value} index={0}>
        <SponsorList onParentRender = {onParentRender} rate={rate}/>
      </TabPanel>

      {/* Promotions */}
      <TabPanel value={value} index={1}>
        <PromotionLists onParentRender = {onParentRender} code={code}/>
      </TabPanel>

      {/* Merchandise */}
      <TabPanel value={value} index={2}>
        <MerchandiseList onParentRender = {onParentRender} />
      </TabPanel>

      {/* Events List from Tickets.com(TDC) */}
      {/* <TabPanel value={value} index={3}>
        <EventsList onParentRender = {onParentRender} />
      </TabPanel> */}

      {/* Point Holders */}
      <TabPanel value={value} index={3}>
        <PointHolderList/>
      </TabPanel>

      {/* Org Info */}
      {/* <TabPanel value={value} index={4}>
        <OrgInfo/>
      </TabPanel> */}
      {/* Widget Setting */}
      {/* <TabPanel value={value} index={4}>
        <WidgetSetting />
      </TabPanel> */}
      {/* <TabPanel value={value} index={6}>
          <MinitingToken refetch={refetch} />
      </TabPanel> */}
      {/* <TabPanel value={value} index={5}>
        <WhiteLabel />
      </TabPanel> */}
      {/* <TabPanel value={value} index={6}>
        <ApiGuide />
      </TabPanel> */}
      {/* <TabPanel value={value} index={6}>
        <ExternalApi />
      </TabPanel> */}
      
      <TabPanel value={value} index={4}>
        <Setting />
      </TabPanel>
      
    </div>
  );
}
