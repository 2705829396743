import React, { useState } from 'react'
import Alert from '@mui/material/Alert';
import MinitingToken from  './minitingtoken';
import { Dialog,DialogContentText } from "@material-ui/core";
import { useQuery, gql } from '@apollo/client';
import { VpcFlowLogId } from 'aws-sdk/clients/ec2';

const GET_ORG_BY_ID = gql`
  query getClub($clubId: String!) {
    getClub(clubId: $clubId) {
        id
        name
        orgCode
        isToken
        isMainnet
        totalTokens
        availableTokens
        blockChainType
    }
  }
`;
interface Props {
    orgId: string;
    refetch: () => void
}
export default function TokenWarning(props : Props) {
    const [open,setOpen] = useState(false);
    const { data ,loading} = useQuery<any>(GET_ORG_BY_ID,{ variables: { clubId: props.orgId } });
    const handleCick = ()=> {
        setOpen(!open)
    }
    return (
        <React.Fragment>
        {
            !data ? ''
            :
            <React.Fragment>
                <Alert severity="warning" sx={{marginTop:"10px", marginBottom:"-20px"}}>You haven't created any token. Please <i  style={{cursor:'pointer', fontWeight:'bold'}} onClick={() => {setOpen(true)}}>click here </i> to create a token.</Alert>
                <Dialog
                open={open}
                onClose={() => {}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={"md"}
                fullWidth={true}
                >
                    <DialogContentText>
                        <MinitingToken refetch={props.refetch} handleClosePopup={handleCick} orgDataToken={data?.getClub} />
                    </DialogContentText>
                </Dialog>
            </React.Fragment>

        }
        </React.Fragment>
    )
}