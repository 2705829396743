import React, { useState, useRef, useEffect } from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
interface Props {
  onOpen: boolean;
  message: string;
  handleCloseClick: () => void;
  handleConformClick: () => void;
}

export default function ConformAlert(props: Props) {
  return (
    <Dialog
      open={props.onOpen}
      onClose={props.handleCloseClick}
      aria-labelledby="form-dialog-title"
      className="common-dialog-container org-container confirmation-popup"
      // disableBackdropClick
      disableEscapeKeyDown
      maxWidth={"xl"}
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className="btn btn-secondary"
          onClick={props.handleCloseClick}
          color="primary"
        >
          CANCEL
        </Button>
        <Button color="primary" className="btn btn-primary button" onClick={props.handleConformClick}>
          CONFIRM
        </Button>
      </DialogActions>
    </Dialog>
  );
}
