import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAl6yC1G62j_anTrp3ZwEADrKX1QFq6NF8",
  authDomain: "pointsville---non-production.firebaseapp.com",
  projectId: "pointsville---non-production",
  storageBucket: "pointsville---non-production.appspot.com",
  messagingSenderId: "1097261777891",
  appId: "1:1097261777891:web:d224110a690bfa9682d6d6",
  measurementId: "G-HXW63JZP8Z"
};


const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);


export const getTokens = (setTokenFound:any) => {
  return getToken(messaging, { vapidKey: 'BDlskcqZQyKsKvXJVZJTVxTY5hoJkDXfFQcEHAvw_3U44RxSVj_vjFVHmtXqtTa0o0XgTT7pd3vf3RmvLiqwLV0' })
    .then((currentToken:any) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err:any) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve:any) => {
    onMessage(messaging, (payload:any) => {
      resolve(payload);
    });
});