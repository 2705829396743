import React, { useState, useRef, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import {Backdrop, CircularProgress, Snackbar} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Select, MenuItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AvatarEditor from "react-avatar-editor";
import Loader from "../../components/Loader/loader";
import mergeImages from "merge-images";
import borderImage from "../../assets/border-frame-billboard.png";
import AvatarDefault from "../../assets/ic_avatar-default.svg";
import BillBoardLogo from "../../assets/ic-default-logo.svg";
import CamerLogo from "../../assets/ic_camera.svg";
import Slider from "@material-ui/core/Slider";
import "../../constant.css";
import { useQuery, gql, useLazyQuery ,useMutation} from "@apollo/client";
// import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
const formatCurrency = require("format-currency");
 const opt = { minFraction: 0 };
interface Props {
  onOpen: boolean;
  refetch: () => void;
  handleCloseClick: () => void;
}

export const GET_ORGS_QUERY = gql`
  query GetClubInventory($orgStatus: String!, $orgTypes: String!) {
    orgsAllSponserShip(orgStatus: $orgStatus, orgTypes: $orgTypes) {
      id
      name
      rate
      orgCode
      logoUrl
    }
  }
`;
export const GET_SPONSOR_QUERY = gql`
  query GetSponsorInventory($clubId: String!) {
    getSponsorWithOrgId(clubId: $clubId) {
      id
      sponsorName
      sponsorLogo
    }
  }
`;
const CREATE_DAILY_BILLBOARD = gql`
    mutation createDailyBillBoardConfig($data: BillBoardConfigInput!) {
        createDailyBillBoardConfig(data: $data) {
            id
        }
    }
`;
const GET_PRE_SIGNED_URL = gql`
  query getPreSignedUrl($fileName: String!) {
    getPreSignedUrl(fileName: $fileName) {
      preSignedUrl
    }
  }
`;
interface IFormAddSponsor {
  orgId: string;
  sponsorId: string;
  sponsorLogo: string;
  validityDay: string;
  pointsPerCoin: string;
  noCoinsPerBillboard: number;
  billboardUrl: string;
  billboardUrl_ios: string;
}

let defaultValues: any = {
  orgId: "",
  sponsorId: "",
  sponsorLogo: "",
  validityDay: "",
  pointsPerCoin: "",
  noCoinsPerBillboard: 0,
  billboardUrl: "",
  billboardUrl_ios: ""
};
const useStyles = makeStyles({
  paper: {
    padding: "0",
    fontFamily: "Graphik Regular !important",
    fontSize: "14px !important",
    color: "#8A8D9D !important",
  },
});
export default function AddDailyBillboard(props: Props) {
  const setEditorRef: any = useRef();
  const [billBoardImgUrl, setBillBoardImgUrl] = React.useState<any>("");
  const [loader, setLoader] = useState<any>(false);
  const [avatarImageToCrop, setAvatarImageToCrop] = React.useState<any>([]);
  const [zoomValue, setZoomValue] = React.useState(0.8);
  const [mergedImage, setMergedImage] = useState("");
  const [uploadFileName, setUploadFileName] = useState<any>("");
  const [targetFile, setTargetFile] = useState<any>([]);
  const [sponsorList,setSponsorList] = useState<any>([]);
  const [sponsorKey,setSponsorKey] = useState(Math.random());
  const [load, setLoad] = useState(false);

  const [createDailyBillboard, { error, loading: resLoading }] = useMutation(CREATE_DAILY_BILLBOARD, {
    // after updating the post, we go to the home page
    onCompleted: () => {props.handleCloseClick(); props.refetch(); resetForm(); setLoad(false)},
    onError: (data) => { console.log('data erorr',data); setErrorMessage(data.message);  setOpenToast(true); }
  });
  const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const {register,handleSubmit,errors,control,reset,formState,setValue,clearErrors,} = useForm<IFormAddSponsor>({ defaultValues, shouldUnregister: false });
  const classes = useStyles();
  const [pointsOptions, setPointsOptions] = useState([
    { title: "1", _id: "1" },
    { title: "10", _id: "10" },
    { title: "20", _id: "20" },
    { title: "100", _id: "100" },
    { title: "500", _id: "500" },
    { title: "1000", _id: "1000" },
  ]);
  const [validityOptions, setValidityOptions] = useState([
    { title: "1 Day", _id: "1" },
    { title: "7 Day", _id: "7" },
    { title: "30 Day", _id: "30" },
    { title: "90 Day", _id: "90" },
    { title: "180 Day", _id: "180" },
    { title: "360 Day", _id: "360" },
  ]);
  const [openToast, setOpenToast] = React.useState<any>(false);
  const [errorMessage, setErrorMessage] = useState<any>("");
  const { refetch, loading, data } = useQuery<any>(GET_ORGS_QUERY, {
    variables: { orgStatus: "Approved", orgTypes: "Points" },
  });
  const [getSponsor, { loading: orderLoading, data: sponsorData }] =useLazyQuery(GET_SPONSOR_QUERY,{fetchPolicy: "network-only"});
  const { loading: urlLoading,data: urlData,refetch: urlRefetch,} = useQuery<any>(GET_PRE_SIGNED_URL, { skip: uploadFileName.length > 0 ? false : true, variables: { fileName: uploadFileName },});
  useEffect(()=> {
    if(sponsorData && sponsorData.getSponsorWithOrgId) {
      setSponsorList(sponsorData.getSponsorWithOrgId)
    }
  },[sponsorData])
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if(name === "noCoinsPerBillboard") {
      let regex_allowed2 = /^[0-9/]+$/
      if (value.length && regex_allowed2.test(value) === false) {
        return false;
      }
    }
    setValue(name, value);
    clearErrors(name);
  };
  const handleClosed = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
        return;
    }
    setOpenToast(false);
  };
  
  const resetForm = () => {
    // reset form
    reset();

    // set default values
    setValue("orgId",defaultValues.orgId)
    setValue("sponsorId",defaultValues.sponsorId)
    setValue("sponsorLogo",defaultValues.sponsorLogo)
    setValue("validityDay",defaultValues.validityDay)
    setValue("pointsPerCoin",defaultValues.pointsPerCoin)
    setValue("noCoinsPerBillboard",defaultValues.noCoinsPerBillboard)

    // clear errors
    clearErrors("orgId");
    clearErrors("sponsorId");
    clearErrors("sponsorLogo");
    clearErrors("validityDay");
    clearErrors("pointsPerCoin");
    clearErrors("noCoinsPerBillboard");

    // reset image
    setBillBoardImgUrl("");
    setMergedImage("");

    // reset sponsor list
    setSponsorList([]);
  }

  const handleGetSponsor = (clubId: string) => {
    getSponsor({ variables: { clubId: clubId } });
  };
  useEffect(() => {
    clearErrors();

    if (!urlLoading && uploadFileName.length > 0) {
      urlRefetch();
      if (urlData) {
        setUploadFileName("");
        fileUploadToS3(urlData.getPreSignedUrl.preSignedUrl);
      }
    }
  }, [uploadFileName, urlData]);

  // to upload a image to S3 using presigned url.
  const fileUploadToS3 = (objUrl: any) => {
    if (objUrl) {
      fetch(objUrl, {
        method: "PUT",
        headers: { "Content-Type": "image/*" },
        body: targetFile[0],
      }).then((response) => {
        if (response && response.url) {
          setBillBoardImgUrl(response.url.split("?")[0]);
          setLoader(false);
        }
      });
    }
  };
  const handleMergeImages = async () => {
    let canvas = setEditorRef.current.getImage().toDataURL("image/png", 1.0);
    let convertedImage: any = await getResizedUrl(canvas);

    const mImage = await mergeImages(
      [{ src: convertedImage }, { src: borderImage }],
      { width: 430, height: 185 }
    ).then((b64: any) => b64);

    setMergedImage(mImage);
  };

  const handleZoomChange = async (event: any, newValue: any) => {
    setZoomValue(newValue);

    handleMergeImages();
  };

  const getResizedUrl = (base64url: any) => {
    // to get base64 url for selected org logo position.
    return new Promise((resolve) => {
      let img = new Image();
      img.src = base64url;

      img.onload = () => {
        let canvas = document.createElement("canvas");
        canvas.width = 430;
        canvas.height = 185;
        let ctx: any = canvas.getContext("2d");
        ctx.drawImage(img, 19, 10, 380, 165); // here as => 39+39+138 = 216 is coin image actual width and height.
        resolve(canvas.toDataURL());
      };
    });
  };
  // to upload selected images to S3.
  const uploadImage = (e: React.ChangeEvent<any>) => {
    const targetFiles: any = e.target.files;
    if (targetFiles.length === 0) return;

    if (
      targetFiles[0].type === "image/png" ||
      targetFiles[0].type === "image/jpeg"
    ) {
      setLoader(true);
      if (targetFiles.length > 0) {
        // After passing targetFiles and file name then, upload logic to S3 using presigned url written in useeffect.
        setUploadFileName(targetFiles[0].name);
        setTargetFile(targetFiles);
        setMergedImage("");
        setAvatarImageToCrop(targetFiles);
      }
    } else {
      setErrorMessage("Please upload image format as png or jpg.");
      setOpenToast(true);
    }
  };
  const onSubmit: SubmitHandler<IFormAddSponsor> = fieldData => {
    setLoad(true);
   if(!billBoardImgUrl) {
      setErrorMessage("Please upload image format as png or jpg.");
      setOpenToast(true);
      setLoad(false);
      return ;
   }
   createDailyBillboard({
    variables:
    {
        data:
        {
            orgId: fieldData.orgId,
            sponsorId: fieldData.sponsorId,
            sponsorLogo: fieldData.sponsorLogo,
            validity: fieldData.validityDay,
            billboardUrl: fieldData.billboardUrl,
            billboardUrl_ios: fieldData.billboardUrl_ios,
            noCoinsPerBillboard: parseInt(fieldData.noCoinsPerBillboard.toString()),
            pointsPerCoin: parseInt(fieldData.pointsPerCoin.toString()),
            billboardImageUrl: billBoardImgUrl,
            isActive: true

        }
    }
});
  }
  const handleChangeOrganization = (event: React.SyntheticEvent<Element, Event>, value: any) => {
    setValue("orgId", value ? value.id : "");
    clearErrors("orgId");
    setSponsorList([]);
    setValue("sponsorId",defaultValues.sponsorId);
    setSponsorKey(Math.random());
   
    if(value) {
      handleGetSponsor(value.id);
    }
  }
  const handleChangeSponsor = (event: React.SyntheticEvent<Element, Event>, value: any) => {
    setValue("sponsorId", value ? value.id : "");
    clearErrors("sponsorId");
  }
  const handleChangePointsPerCoin = (event: React.SyntheticEvent<Element, Event>, value: any) => {
    setValue("pointsPerCoin", value ? value._id : "");
    clearErrors("pointsPerCoin");
  }
  const handleChangePointsPerPromo = (e: any, source = "elm") => {
    if (source === "elm") {
        const targetValue = (e.target as HTMLInputElement).value;
        const targetName: string = (e.target as HTMLInputElement).name;
        setValue(targetName, targetValue);
    } else {
        setValue("pointsPerCoin", e);
    }
    clearErrors("pointsPerCoin");
  }
  const handleChangeValidity = (event: React.SyntheticEvent<Element, Event>, value: any) => {
    setValue("validityDay", value ? value._id : "");
    clearErrors("validityDay");
  }
  
  return (
    <div>
      <Dialog
        open={props.onOpen}
        onClose={props.handleCloseClick}
        aria-labelledby="form-dialog-title"
        className="common-dialog-container org-container confirmation-popup"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <form className="form-container">
          <Grid className="form-grid" container sm={12} spacing={2}>
            <Grid item sm={12}>
              <h5 id="form-dialog-title" className="section-heading pl-10">
                {"CREATE"} DAILY BILLBOARD
              </h5>
            </Grid>
            <Grid
              item
              className="borderR padd-20 pt-0"
              sm={6}
              xs={12}
              spacing={3}
            >
              <Grid container sm={12} spacing={3}>
                <Grid item sm={6} className="pl-0">
                  <div className="input-group">
                    <label className="input-label">Organization</label>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                        // pattern: /[0-9]/,
                        // validate: (value) => value.length > 0,
                      }}
                      name="orgId"
                      type="number"
                      render={({ value, onChange }) => (
                        <Autocomplete
                          options={data?.orgsAllSponserShip}
                          id="clear-on-escape"
                          clearOnEscape
                          classes={{ paper: classes.paper }}
                          getOptionLabel={(option: any) => option.name}
                          className={`form-fields ${errors.orgId && "error"}`}
                          // onChange={(event: any, newValue: any) => {
                          //   if (newValue) {
                          //     setValue("orgId", newValue.id);
                          //     setValue("sponsorId", "");
                          //     clearErrors("orgId");
                          //     handleGetSponsor(newValue.id);
                          //   }
                          // }}
                          onChange={handleChangeOrganization}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": {mr: 1, flexShrink: 0 } }}
                              {...props}
                            >
                              <img
                                loading="lazy"
                                width="20"
                                src={option.logoUrl}
                                alt=""
                                style={{ width: "20px" }}
                              />
                              {option.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=""
                              variant="standard"
                              name="orgId"
                              // onChange={(e) => handleChange(e)}
                              placeholder="Select"
                            />
                          )}
                        />
                    )}
                    />
                    {errors.orgId && errors.orgId.type === "required" && (<label className="error-message">Field is required</label>)}
                  </div>
                </Grid>
                <Grid item sm={6} className="pr-0">
                  <div className="input-group">
                    <label className="input-label">Sponsor</label>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                        pattern: /[0-9]/,
                        validate: (value) => value.length > 0,
                      }}
                      name="sponsorId"
                      type="number"
                      render={({ value,onChange }) => (
                    <Autocomplete
                      key={sponsorKey}
                      options={sponsorList ? sponsorList : []}
                      id="clear-on-escape"
                      clearOnEscape
                      classes={{ paper: classes.paper }}
                      getOptionLabel={(option: any) => option.sponsorName}
                      className={`form-fields ${errors.sponsorId && "error"}`}
                      // onChange={(event: any, newValue: any) => {
                      //   if (newValue) {
                      //     setValue("sponsorId", newValue.id);
                      //     setValue("sponsorLogo",newValue.sponsorLogo)
                      //     clearErrors("sponsorId");
                      //   }
                      // }}
                      onChange={handleChangeSponsor}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 1, flexShrink: 0 } }}
                          {...props}
                        >
                          <img
                            loading="lazy"
                            width="20"
                            src={option.sponsorLogo}
                            alt=""
                            style={{ width: "20px" }}
                          />
                          {option.sponsorName}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} label="" value={value} variant="standard" placeholder="Select" />
                      )}
                    />
                    )}
                    />
                    {errors.sponsorId && errors.sponsorId.type === "required" && (<label className="error-message">Field is required</label>)}
                  </div>
                </Grid>
                <Grid item sm={6} className="pl-0">
                  <div className="input-group">
                    <label className="input-label">No. of Coins</label>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                        pattern: /[0-9]/,
                        validate: (value) => value > 0,
                      }}
                      name="noCoinsPerBillboard"
                      type="text"
                      render={({ value }) => (
                        <TextField
                          type="text"
                          value={value}
                          name="noCoinsPerBillboard"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          className={`form-fields ${
                            errors.noCoinsPerBillboard && "error"
                          }`}
                        />
                      )}
                    />
                  {errors.noCoinsPerBillboard && errors.noCoinsPerBillboard.type === "required" && (<label className="error-message">Field is required</label>)}
                    {errors.noCoinsPerBillboard && errors.noCoinsPerBillboard.type === "validate" && (<label className="error-message">Value should be more than 0</label>)}
                    {errors.noCoinsPerBillboard && errors.noCoinsPerBillboard.type === "pattern" && (<label className="error-message">Only number is allow</label>)}
                  </div>
                </Grid>
                <Grid item sm={6} className="pr-0">
                  <div className="input-group">
                    <label className="input-label">Points per Coin</label>
                    <Controller
                      name="pointsPerCoin"
                      control={control}
                      rules={{
                        required: true,
                        pattern: /^[0-9]*$/,
                        validate: (value) => value > 0,
                      }}
                      render={({ value, onChange }) => (
                        <Autocomplete
                          freeSolo
                          id="free-solo-demo"
                          classes={{ paper: classes.paper }}
                          options={pointsOptions}
                          getOptionLabel={(option: any) => option.title}
                          // getOptionSelected={(option, value) => option._id === value._id}
                          className={`form-fields ${errors.pointsPerCoin && "error"}`}
                          // onChange={(event: any, newValue: any) => {
                          //   if (newValue) {
                          //     setValue("pointsPerCoin", newValue._id);
                          //     clearErrors("pointsPerCoin");
                          //   }
                          // }}
                          onChange={handleChangePointsPerCoin}
                          renderInput={(params) => (
                            <TextField {...params} label="" name="pointsPerCoin" value={value} variant="standard" placeholder="Select" onChange={(e) => handleChangePointsPerPromo(e, "elm")} />
                          )}
                        />
                      )}
                    />
                    {errors.pointsPerCoin && errors.pointsPerCoin.type === "required" && (<label className="error-message">Field is required</label>)}
                    {errors.pointsPerCoin && errors.pointsPerCoin.type === "validate" && (<label className="error-message">Value should be more than 0</label>)}
                    {errors.pointsPerCoin && errors.pointsPerCoin.type === "pattern" && (<label className="error-message">Only number is allow</label>)}
                  </div>
                </Grid>

                <Grid item sm={12} className="pr-0 pl-0">
                  <div className="input-group">
                    <label className="input-label">Validity</label>
                    <Controller
                      name="validityDay"
                      control={control}
                      rules={{ required: true }}
                      render={({ value }) => (
                        // <Select
                        //   displayEmpty
                        //   name="validityDay"
                        //   value={value}
                        //   className={`custom-select form-select  ${
                        //     errors.validityDay && "error"
                        //   }`}
                        //   onChange={(e) => handleChange(e)}
                        // >
                        //   <MenuItem value={""}>Select</MenuItem>
                        //   <MenuItem value={"1"}>1 Day</MenuItem>
                        //   <MenuItem value={"7"}>7 Days</MenuItem>
                        //   <MenuItem value={"30"}>30 Days</MenuItem>
                        //   <MenuItem value={"90"}>90 Days</MenuItem>
                        //   <MenuItem value={"180"}>180 Days</MenuItem>
                        //   <MenuItem value={"360"}>360 Days</MenuItem>
                        // </Select>
                        <Autocomplete
                          
                          id="free-solo-demo"
                          classes={{ paper: classes.paper }}
                          options={validityOptions}
                          getOptionLabel={(option: any) => option.title}
                          // getOptionSelected={(option, value) => option._id === value._id}
                          className={`form-fields ${errors.validityDay && "error"}`}
                          // onChange={(event: any, newValue: any) => {
                          //   if (newValue) {
                          //     setValue("pointsPerCoin", newValue._id);
                          //     clearErrors("pointsPerCoin");
                          //   }
                          // }}
                          onChange={handleChangeValidity}
                          renderInput={(params) => (
                            <TextField {...params} label="" value={value} variant="standard" placeholder="Select" />
                            
                          )}
                        />
                      )}
                    />
                    {errors.validityDay && errors.validityDay.type === "required" && (<label className="error-message">Field is required</label>)}
                  </div>
                </Grid>
                <Grid item sm={12} className="pl-0  pr-0">
                  <div className="input-group mt-0">
                    <label className="input-label">
                      Bill Board Image (Recommended Size: 430px by 185px)
                    </label>
                    <div className="bb-disp-flex">
                      {billBoardImgUrl.length > 0 ? (
                        // Display bill board image
                        <div
                          className="bb-thumbnail-block"
                          style={{ display: "block" }}
                        >
                          {loader && <Loader size="60px" />}
                          <>
                            <div
                              className={
                                avatarImageToCrop.length > 0
                                  ? "avatar-block crop"
                                  : "avatar-block border"
                              }
                            >
                              <AvatarEditor
                                ref={setEditorRef}
                                className="avatar-make-up"
                                image={avatarImageToCrop[0]}
                                border={10}
                                color={[0, 0, 0, 0.36]} // RGBA
                                scale={zoomValue}
                                // rotate={0}
                                borderRadius={10}
                                onPositionChange={handleMergeImages}
                                onImageReady={handleMergeImages}
                                height={185}
                                style={{ width: "100%", height: "185px" }}
                              />

                              {/* <div className="borderImage"></div> */}
                            </div>

                            <div className="avatar-default-block zero-width">
                              <img
                                className="camera-img"
                                src={CamerLogo}
                                alt="Avatar default logo"
                              />
                              <input
                                type="file"
                                className="default-file-type zero-height"
                                onChange={(e) => uploadImage(e)}
                                accept="image/jpeg, image/png"
                              />
                            </div>
                          </>
                        </div>
                      ) : (
                        <div className="bb-img-block">
                          {/* create bill board image */}
                          <>
                            <img
                              className="default-avatar-img"
                              src={AvatarDefault}
                              alt="Avatar default logo"
                            />
                            <div className="title">Upload Image</div>
                            <input
                              type="file"
                              className="default-file-type"
                              onChange={(e) => uploadImage(e)}
                              accept="image/jpeg, image/png"
                            />
                          </>
                        </div>
                      )}
                    </div>
                    {billBoardImgUrl.length > 0 ? (
                      <div className="zoom-container">
                        <Typography
                          variant="caption"
                          id="range-slider"
                          gutterBottom
                        >
                          Zoom
                        </Typography>
                        <Slider
                          value={zoomValue}
                          onChange={handleZoomChange}
                          step={0.1}
                          min={0.5}
                          max={5}
                          aria-labelledby="continuous-slider"
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6} xs={12} className="pt-0">
              <Grid item sm={12} className="pl-0  pr-0">
                <div className="input-group">
                  <label className="input-label">
                    URL for Android devices (Optional)
                  </label>
                  <Controller
                    control={control}
                    rules={{
                      required: false,
                      pattern:
                        /(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                    }}
                    name="billboardUrl"
                    render={({ value }) => (
                      <TextField
                        type="text"
                        value={value}
                        name="billboardUrl"
                        onChange={(e) => handleChange(e)}
                        placeholder="https://www.pointsville.com/"
                        className={`form-fields  ${
                          errors.billboardUrl && "error"
                        }`}
                      />
                    )}
                  />
                </div>
              </Grid>
              <Grid item sm={12} className="pl-0  pr-0">
                <div className="input-group">
                  <label className="input-label">
                    URL for Apple devices (Optional)
                  </label>
                  <Controller
                    control={control}
                    rules={{
                      required: false,
                      pattern:
                        /(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/,
                    }}
                    name="billboardUrl_ios"
                    render={({ value }) => (
                      <TextField
                        type="text"
                        value={value}
                        name="billboardUrl_ios"
                        onChange={(e) => handleChange(e)}
                        placeholder="https://www.pointsville.com/"
                        className={`form-fields  ${
                          errors.billboardUrl_ios && "error"
                        }`}
                      />
                    )}
                  />
                  {/* {errors.promoUrl_ios && errors.promoUrl_ios.type === "pattern" && (<label className="error-message">Field should be in url format like https://www.pointsville.com/</label>)} */}
                </div>
              </Grid>
              <label className="section-heading sub-heading mrgb-20 txt-center  mt-0">
                Promotion Preview
              </label>
              <div className="map-preview">
                <Grid container sm={12}>
                  {mergedImage ? (
                    <img
                      src={mergedImage}
                      alt="sponsorlogo"
                      className="sponsor-logo"
                      style={{ paddingTop: "5%" }}
                    />
                  ) : (
                    ""
                  )}
                </Grid>
              </div>
            </Grid>
          </Grid>
          <DialogActions>
            <Button className="btn btn-secondary" color="primary" onClick={()=> { props.handleCloseClick(); resetForm(); }}>
              CANCEL
            </Button>
            <Button color="primary" className="btn btn-primary button" onClick={handleSubmit(onSubmit)}>
              Add
            </Button>
          </DialogActions>
        </form>
        <Snackbar open={openToast} autoHideDuration={3000} onClose={handleClosed}>
          <Alert onClose={handleClosed} severity="error">{errorMessage}</Alert>
        </Snackbar>
        <Backdrop className="loader-signup" open={load}>
                  <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    </div>
  );
}