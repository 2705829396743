import React ,{useEffect,useState}from 'react';
import { useHistory,useLocation ,RouteComponentProps} from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import DoneIcon from '@material-ui/icons/Done';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditMemberProfile from './editmemberprofile';
import { useQuery, gql } from '@apollo/client';
import moment from 'moment';
import '../../constant.css';
import './memberprofile.css';
import ProfileImg from '../../assets/profile-img.png';
import { getDefaultValues } from '@apollo/client/utilities';
import TransactionTabs from "./TransactionTabs"
interface LocationState extends RouteComponentProps {
    state ?: string;
}

const GET_USER_BY_ID = gql`
  query getUsers($username: String!) {
    getUserWithIdAdmin(username: $username) {
        id
        username
        givenName
        email
        dob
        walletId
        address
        phoneNumber
        walletPoints
        walletBalance
        isActive
        profileImage
        createdAt
        updatedAt
        tdc_patronid
        tdc_walletbalance
        nickName
    }
  }
`;

const WALLET_SUMMARY_USER_BY_ID = gql`
  query walletSummaryByUserId($userId: String!) {
    walletSummaryByUserId(userId: $userId) {
        walletId
        totalPoints
        totalAmount
        hunt
        promo
        received
    }
  }
`;

export default function MemberProfile() {
    const {state}:any = useLocation<LocationState>();
    const [userAddr,setUserAddr] = useState<any>('');
    const [userDetails,setUserDetails] = useState<any>({});
    const [userId,setUserId] =useState<any>();
    const [walletBalance,setWalletBalance] =useState<any>(0);
    const [userName , setUserName] = useState("");
    const { loading, data, refetch } = useQuery<any>(GET_USER_BY_ID, { variables: { username: state.userId ? state.userId : state } });
    const history = useHistory();
    const backClick = () => history.push('/managemembers');
    const [open, setOpen] = React.useState(false);
    const { loading: walletLoading, data: walletData } = useQuery<any>(WALLET_SUMMARY_USER_BY_ID, { variables: { userId: state.userId ? state.userId : state } });
    useEffect(() => {
        if(!loading){
            if (data.getUserWithIdAdmin) {
                setUserDetails(data.getUserWithIdAdmin);

                let userAddress:any = ''
                if (data.getUserWithIdAdmin.address.length === 0) {
                    userAddress = data.getUserWithIdAdmin.address;
                } else {
                    userAddress = JSON.parse(data.getUserWithIdAdmin.address);

                    let tempUserAddr:any = ''
                    tempUserAddr = userAddress.address1.trim().length > 0 ? userAddress.address1 + ', ' : ''
                    tempUserAddr = tempUserAddr + (userAddress.address2.trim().length > 0 ? userAddress.address2 + ', ' : '')
                    tempUserAddr = tempUserAddr + (userAddress.address3.trim().length > 0 ? userAddress.address3 + ', ' : '')
                    tempUserAddr = tempUserAddr + (userAddress.address4.trim().length > 0 ? userAddress.address4 + '-' : '')
                    tempUserAddr = tempUserAddr + (userAddress.address5.trim().length > 0 ? userAddress.address5 : '')
                    userAddress = tempUserAddr;
                }
                setUserAddr(userAddress);

                const username:string = data.getUserWithIdAdmin.givenName;
                setUserName(username.replace("&"," "));
            }
        }
    },[data])
    
    const handleClickOpen = (userId:string, walletBalance: any) => {
        setOpen(true);
        setUserId(userId);
        setWalletBalance(walletBalance);
    };

    const handleClose = () => {
        setOpen(false);
    };
    
    useEffect(() => {
        refetch();
    },[open]);

    return (
    <div className="detail-page member-profile">
        <div className="page-head p-relative padl-40">
            <Grid container sm={12}>
                <Grid item sm={6}>
                    <span className="back-icon pointer" onClick={backClick}></span>
                    <h2 className="page-title">MEMBER PROFILE</h2>
                </Grid>
                <Grid className="align-right" item sm={6}>
                    <button onClick={()=>handleClickOpen(userDetails.username, state.walletbalance ? state.walletbalance : walletBalance)} className="btn btn-secondary edit-btn"><span className="btn-icon"></span> <label className="btn-caption pointer">Edit Profile</label></button>
                </Grid>
            </Grid>
        </div>
        <div className="page-body" >
            <Grid container className="rm-right" spacing={3} sm={12}>
                <Grid item sm={3} spacing={1}>
                    <Paper className="">
                        <div className="profile-details">
                            <img className="profile-img" src={userDetails.profileImage && userDetails.profileImage.length > 5 ? userDetails.profileImage : "https://png.pngtree.com/png-vector/20190321/ourmid/pngtree-vector-users-icon-png-image_856952.jpg"} alt='profile avatar' />
                            <h5 className="profile-name">{userName}</h5>
                            <label className="label-txt">Wallet ID :{userDetails.walletId}</label>
                            <label className="label-txt-small">Member since:{moment(parseInt(userDetails.createdAt)).format('D MMM YYYY HH:MM')}</label>
                            <div className="chip-section">
                                <Chip className={userDetails.isActive?"status green":"status pending"} variant="outlined" label={userDetails.isActive?"Active":"InActive"} />
                                {/* <Chip className="status green" icon={<DoneIcon />} variant="outlined" label="Inactive" /> */}
                            </div>
                            <div className="revenue-section">
                                <div className="revenue-parent">
                                    <span className="data-bold" >{state.walletbalance ? state.walletbalance : walletBalance}</span>
                                    <label className="sub-caption">Wallet Balance</label>
                                </div>
                                {/* <div className="revenue-parent">
                                    <span className="data-bold" >3000</span>
                                    <label className="sub-caption">Redeemed</label>
                                </div> */}
                            </div>
                            <br />
                            <div className="revenue-section">
                                <div className="revenue-parent">
                                    <span className="data-bold" style={{fontSize:"14px"}}>{!walletLoading && walletData && walletData.walletSummaryByUserId && walletData.walletSummaryByUserId.hunt ? walletData.walletSummaryByUserId.hunt : 0}</span>
                                    <label className="sub-caption">Hunt</label>
                                </div>
                                <div className="revenue-parent">
                                <span className="data-bold" style={{fontSize:"14px"}}>{!walletLoading && walletData && walletData.walletSummaryByUserId && walletData.walletSummaryByUserId.promo ? walletData.walletSummaryByUserId.promo : 0}</span>
                                    <label className="sub-caption">Promo</label>
                                </div>
                                <div className="revenue-parent">
                                <span className="data-bold" style={{fontSize:"14px"}}>{!walletLoading && walletData && walletData.walletSummaryByUserId && walletData.walletSummaryByUserId.received ? walletData.walletSummaryByUserId.received : 0}</span>
                                    <label className="sub-caption">Received</label>
                                </div>
                            </div>
                        </div>
                        
                    </Paper>
                </Grid>
                <Grid item sm={9} spacing={1}>
                    <Paper>
                        <div className="info-section personal-info-section">
                            <h5 className="section-heading">Personal Info</h5>
                            <Grid container sm={12} spacing={3}>
                                <Grid container sm={12} spacing={3}>
                                    <Grid item sm={3}>
                                        <label className="label-txt">Full Name</label>
                                        <label className="field-txt">{userName}</label>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <label className="label-txt">Phone Number</label>
                                        <label className="field-txt">{userDetails.phoneNumber}</label>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <label className="label-txt">Address</label>
                                        <address className="field-txt address-field">{userAddr}</address>
                                    </Grid>
                                </Grid>
                                <Grid container sm={12} spacing={3}>
                                    <Grid item sm={3}>
                                        <label className="label-txt">Nick Name</label>
                                        <label className="field-txt">{userDetails.nickName}</label>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <label className="label-txt">Email ID</label>
                                        <label className="field-txt">{userDetails.email}</label>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <label className="label-txt">Last Updated</label>
                                        <label className="field-txt">{moment(parseInt(userDetails.updatedAt)).format("MM/DD/YY")}</label>
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                        </div>
                    </Paper>
                    <Paper className="kyc-paper" style={{display:"none"}}>
                        <div className="info-section kyc-info-section">
                             <h5 className="section-heading">Kyc Info</h5>
                             <div className="coming-soon-section">
                                <h5 className="page-title">Coming soon</h5>
                             </div>
                             <Grid className="display-none" container sm={12} spacing={3}>
                                     <Grid className="kyc-verification-container" container sm={7}>
                                         <Grid container sm={6}>
                                             <h5 className="section-title">Photo Id verification</h5>
                                            <Grid item sm={6}>
                                                <label className="label-txt">Full Name</label>
                                                <label className="field-txt">Katherine Walter</label>
                                            </Grid>
                                            <Grid item sm={6}>
                                                <label className="label-txt">Full Name</label>
                                                <label className="field-txt">Katherine Walter</label>
                                            </Grid>
                                         </Grid>
                                         <Grid item sm={6} className="align-right">
                                            <CheckCircleIcon/>
                                         </Grid>
                                     </Grid>
                                     <Grid className="kyc-verification-container" container sm={7}>
                                        <Grid container sm={6}>
                                            <h5 className="section-title">Photo Id verification</h5>
                                            <Grid item sm={6}>
                                                <label className="label-txt">Full Name</label>
                                                <label className="field-txt">Katherine Walter</label>
                                            </Grid>
                                            <Grid item sm={6}>
                                                <label className="label-txt">Full Name</label>
                                                <label className="field-txt">Katherine Walter</label>
                                            </Grid>
                                        </Grid>
                                        <Grid item sm={6} className="align-right">
                                            <CheckCircleIcon/>
                                         </Grid>
                                     </Grid>
                                     <Grid className="kyc-verification-container" container sm={7}>
                                        <Grid container sm={6}>
                                            <h5 className="section-title">Photo Id verification</h5>
                                            <Grid item sm={6}>
                                                <label className="label-txt">Full Name</label>
                                                <label className="field-txt">Katherine Walter</label>
                                            </Grid>
                                            <Grid item sm={6}>
                                                <label className="label-txt">Full Name</label>
                                                <label className="field-txt">Katherine Walter</label>
                                            </Grid>
                                        </Grid>
                                        <Grid item sm={6} className="align-right">
                                            <CheckCircleIcon/>
                                         </Grid>
                                     </Grid>               
                             </Grid>
                        </div>
                    </Paper>
                    <Paper className="kyc-paper">
                        <div className="info-section kyc-info-section">
                             <TransactionTabs userId={state.userId ? state.userId : state} />
                        </div>     
                    </Paper>
                </Grid> 
            </Grid>
        </div>

        <EditMemberProfile onOpen={open} handleCloseClick={handleClose} memberId ={userId} />
        
    </div>
    )
}
