import React, { useEffect, useState , useRef }from 'react';
import { useHistory, useParams} from 'react-router-dom';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import SignUpForm from './signupForm'

import Logo from '../../assets/logo-big.png';

import './signup.css';

export default function SignUp() {

    const [open, setOpen] = React.useState(true);

    const handleActiveStep = (activeStep: any) => {
        if (activeStep === 1) {
            setOpen(false);
        }
    }

    return (
        <div className='singup-container'>
            <div className='flex-block'>
                {/* pv logo */}
                <div className='pv-logo-block'>
                    <img src={Logo} alt='logo' className='signin-logo-img'/>
                </div>

                {/* form */}
                <div className='form-block'>
                    <div className='title-block'>SIGN UP</div>

                    <SignUpForm handleActiveStep={handleActiveStep} />

                    {open && <div className='footer-block'>Already a member? <a href='/'>SIGN IN</a></div> }
                </div>
            </div>
        </div>
    )
}
