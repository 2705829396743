import React, { useState, useRef, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
// import { Autocomplete } from '@material-ui/lab';
import {Backdrop, CircularProgress, Snackbar} from '@material-ui/core';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Select, MenuItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "../../constant.css";
import { useQuery, gql, useLazyQuery,useMutation } from "@apollo/client";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
const formatCurrency = require("format-currency");
const opt = { minFraction: 0 };
interface Props {
  onOpen: boolean;
  refetch: () => void;
  handleCloseClick: () => void;
}

export const GET_ORGS_QUERY = gql`
  query GetClubInventory($orgStatus: String!, $orgTypes: String!) {
    orgsAllSponserShip(orgStatus: $orgStatus, orgTypes: $orgTypes) {
      id
      name
      rate
      orgCode
      logoUrl
    }
  }
`;
export const GET_SPONSOR_QUERY = gql`
  query GetSponsorInventory($clubId: String!) {
    getSponsorWithOrgId(clubId: $clubId) {
      id
      sponsorName
      sponsorLogo
    }
  }
`;
const CREATE_DAILY_POI = gql`
    mutation createDailyPoiConfig($data: PoiConfigInput!) {
        createDailyPoiConfig(data: $data) {
            id
        }
    }
`;
interface IFormAddSponsor {
  orgId: string;
  sponsorId: string;
  sponsorLogo: string;
  validityDay: string;
  keywords: string;
  pointsPerCoin: string;
  noCoinsPerHunt: number;
  isActive:boolean;
}

let defaultValues: any = {
  orgId: "",
  sponsorId: "",
  sponsorLogo: "",
  validityDay: "",
  keywords:"",
  pointsPerCoin: "",
  noCoinsPerHunt: 0,
  isActive: true
};
const useStyles = makeStyles({
  paper: {
    padding: "0",
    fontFamily: "Graphik Regular !important",
    fontSize: "14px !important",
    color: "#8A8D9D !important",
  },
});
export default function AddDailyPoiConfig(props: Props) {
  const [openToast, setOpenToast] = React.useState<any>(false);
  const [errorMessage, setErrorMessage] = useState<any>("");
  const [sponsorList,setSponsorList] = useState<any>([]);
  const [sponsorKey,setSponsorKey] = useState(Math.random());
  const {register,handleSubmit,errors,control,reset,formState,setValue,clearErrors,} = useForm<IFormAddSponsor>({ defaultValues, shouldUnregister: false });
  const classes = useStyles();
  const [load, setLoad] = useState(false);
  const [pointsOptions, setPointsOptions] = useState([
    { title: "1", _id: "1" },
    { title: "10", _id: "10" },
    { title: "20", _id: "20" },
    { title: "100", _id: "100" },
    { title: "500", _id: "500" },
    { title: "1000", _id: "1000" },
  ]);
  const [validityOptions, setValidityOptions] = useState([
    { title: "1 Day", _id: "1" },
    { title: "7 Day", _id: "7" },
    { title: "30 Day", _id: "30" },
    { title: "90 Day", _id: "90" },
    { title: "180 Day", _id: "180" },
    { title: "360 Day", _id: "360" },
  ]);
  const {  data } = useQuery<any>(GET_ORGS_QUERY, {variables: { orgStatus: "Approved", orgTypes: "Points" },});
  const [getSponsor, { loading: orderLoading, data: sponsorData }] =useLazyQuery(GET_SPONSOR_QUERY,{fetchPolicy: "network-only"});
  const [createDailyPOI, { error, loading: resLoading }] = useMutation(CREATE_DAILY_POI, {
    onCompleted: () => {props.handleCloseClick(); props.refetch(); reset();setLoad(false)},
    onError: (data) => { console.log('data erorr',data); setErrorMessage(data.message);  setOpenToast(true); }
  });
  const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  useEffect(()=> {
    if(sponsorData && sponsorData.getSponsorWithOrgId) {
      setSponsorList(sponsorData.getSponsorWithOrgId)
    }
  },[sponsorData])
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if(name === "keywords") {
      let regex_allowed2 = /^[a-zA-Z]+$/
      if (value.length && regex_allowed2.test(value) === false) {
        return false;
      }
    }
    if(name === "noCoinsPerHunt") {
      let regex_allowed2 = /^[0-9/]+$/
      if (value.length && regex_allowed2.test(value) === false) {
        return false;
      }
    }
    setValue(name, value);
    clearErrors(name);
  };
  const handleGetSponsor = (clubId: string) => {
    getSponsor({ variables: { clubId: clubId } });
  };

  const handleClosed = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
        return;
    }
    setOpenToast(false);
  };
  
  const resetForm = () => {
    // reset form
    reset();

    // set default values
    setValue("orgId",defaultValues.orgId)
    setValue("sponsorId",defaultValues.sponsorId)
    setValue("sponsorLogo",defaultValues.sponsorLogo)
    setValue("validityDay",defaultValues.validityDay)
    setValue("pointsPerCoin",defaultValues.pointsPerCoin)
    setValue("noCoinsPerHunt",defaultValues.noCoinsPerHunt)
    setValue("keywords",defaultValues.keywords)

    // clear errors
    clearErrors("orgId");
    clearErrors("sponsorId");
    clearErrors("sponsorLogo");
    clearErrors("validityDay");
    clearErrors("pointsPerCoin");
    clearErrors("noCoinsPerHunt");
    clearErrors("keywords");
    
    // reset sponsor list
    setSponsorList([]);
  }
  const onSubmit: SubmitHandler<IFormAddSponsor> = fieldData => {
    setLoad(true);
    console.log("fieldData",fieldData) 
    createDailyPOI({
     variables:
     {
         data:
         {
             orgId: fieldData.orgId,
             sponsorId: fieldData.sponsorId,
             sponsorLogo: fieldData.sponsorLogo,
             validity: fieldData.validityDay,

             noCoinsPerHunt: parseInt(fieldData.noCoinsPerHunt.toString()),
             pointsPerCoin: parseInt(fieldData.pointsPerCoin.toString()),
             keywords: fieldData.keywords,
             isActive: true
 
         }
     }
    });
   }
   const handleChangeOrganization = (event: React.SyntheticEvent<Element, Event>, value: any) => {
    setValue("orgId", value ? value.id : "");
    clearErrors("orgId");
    setSponsorList([]);
    setValue("sponsorId",defaultValues.sponsorId);
    setSponsorKey(Math.random());
    if(value) {
      handleGetSponsor(value.id);
    }
  }
  const handleChangeSponsor = (event: React.SyntheticEvent<Element, Event>, value: any) => {
    setValue("sponsorId", value ? value.id : "");
    clearErrors("sponsorId");
  }
  const handleChangePointsPerCoin = (event: React.SyntheticEvent<Element, Event>, value: any) => {
    setValue("pointsPerCoin", value ? value._id : "");
    clearErrors("pointsPerCoin");
  }
  const handleChangePointsPerPromo = (e: any, source = "elm") => {
    if (source === "elm") {
        const targetValue = (e.target as HTMLInputElement).value;
        const targetName: string = (e.target as HTMLInputElement).name;
        setValue(targetName, targetValue);
    } else {
        setValue("pointsPerCoin", e);
    }
    clearErrors("pointsPerCoin");
  }
  const handleChangeValidity = (event: React.SyntheticEvent<Element, Event>, value: any) => {
    setValue("validityDay", value ? value._id : "");
    clearErrors("validityDay");
  }

  

  return (
    <div>
      <Dialog
        open={props.onOpen}
        onClose={props.handleCloseClick}
        aria-labelledby="form-dialog-title"
        className="common-dialog-container org-container confirmation-popup"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <form className="form-container">
          <Grid className="form-grid" container sm={12} spacing={2}>
            <Grid item sm={12}>
              <h5 id="form-dialog-title" className="section-heading pl-10">
                {"CREATE"} DAILY POI
              </h5>
            </Grid>
            <Grid
              item
              className=" padd-20 pt-0"
              sm={12}
              xs={12}
              spacing={3}
            >
              <Grid container sm={12} spacing={3}>
                <Grid item sm={6} className="pl-0">
                  <div className="input-group">
                    <label className="input-label">Organization</label>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                        // pattern: /[0-9]/,
                        // validate: (value) => value.length > 0,
                      }}
                      name="orgId"
                      type="number"
                      render={({ value, onChange }) => (
                        <Autocomplete
                          options={data?.orgsAllSponserShip}
                          id="clear-on-escape"
                          clearOnEscape
                          classes={{ paper: classes.paper }}
                          getOptionLabel={(option: any) => option.name}
                          className={`form-fields ${errors.orgId && "error"}`}
                          // onChange={(event: any, newValue: any) => {
                          //   if (newValue) {
                          //     setValue("orgId", newValue.id);
                          //     setValue("sponsorId", "");
                          //     clearErrors("orgId");
                          //     handleGetSponsor(newValue.id);
                          //   }
                          // }}
                          onChange={handleChangeOrganization}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": {mr: 1, flexShrink: 0 } }}
                              {...props}
                            >
                              <img
                                loading="lazy"
                                width="20"
                                src={option.logoUrl}
                                alt=""
                                style={{ width: "20px" }}
                              />
                              {option.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=""
                              variant="standard"
                              name="orgId"
                              // onChange={(e) => handleChange(e)}
                              placeholder="Select"
                            />
                          )}
                        />
                    )}
                    />
                    {errors.orgId && errors.orgId.type === "required" && (<label className="error-message">Field is required</label>)}
                  </div>
                </Grid>
                <Grid item sm={6} className="pr-0">
                  <div className="input-group">
                    <label className="input-label">Sponsor</label>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                        pattern: /[0-9]/,
                        validate: (value) => value.length > 0,
                      }}
                      name="sponsorId"
                      type="number"
                      render={({ value, onChange }) => (
                        <Autocomplete
                          key={sponsorKey}
                          options={sponsorList ? sponsorList : []}
                          id="clear-on-escape"
                          clearOnEscape
                          classes={{ paper: classes.paper }}
                          getOptionLabel={(option: any) => option.sponsorName}
                          className={`form-fields ${errors.sponsorId && "error"}`}
                          // onChange={(event: any, newValue: any) => {
                          //   if (newValue) {
                          //     setValue("sponsorId", newValue.id);
                          //     clearErrors("sponsorId");
                          //   }
                          // }}
                          onChange={handleChangeSponsor}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 1, flexShrink: 0 } }}
                              {...props}
                            >
                              <img
                                loading="lazy"
                                width="20"
                                src={option.sponsorLogo}
                                alt=""
                                style={{ width: "20px" }}
                              />
                              {option.sponsorName}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label="" variant="standard" placeholder="Select"  />
                          )}
                        />
                      )}
                    />
                    {errors.sponsorId && errors.sponsorId.type === "required" && (<label className="error-message">Field is required</label>)}
                  </div>
                </Grid>
                <Grid item sm={6} className="pl-0">
                  <div className="input-group">
                    <label className="input-label">No. of Coins</label>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                        pattern: /[0-9]/,
                        validate: (value) => value > 0,
                      }}
                      name="noCoinsPerHunt"
                      type="text"
                      render={({ value }) => (
                        <TextField
                          type="text"
                          value={value}
                          name="noCoinsPerHunt"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          className={`form-fields ${errors.noCoinsPerHunt && "error"
                            }`}
                        />
                      )}
                    />
                    {errors.noCoinsPerHunt && errors.noCoinsPerHunt.type === "required" && (<label className="error-message">Field is required</label>)}
                    {errors.noCoinsPerHunt && errors.noCoinsPerHunt.type === "validate" && (<label className="error-message">Value should be more than 0</label>)}
                    {errors.noCoinsPerHunt && errors.noCoinsPerHunt.type === "pattern" && (<label className="error-message">Only number is allow</label>)}
                  </div>
                </Grid>
                <Grid item sm={6} className="pr-0">
                  <div className="input-group">
                    <label className="input-label">Points per Coin</label>
                    <Controller
                      name="pointsPerCoin"
                      control={control}
                      rules={{
                        required: true,
                        pattern: /^[0-9]*$/,
                        validate: (value) => value > 0,
                      }}
                      render={({ value, onChange }) => (
                        <Autocomplete
                          freeSolo
                          id="free-solo-demo"
                          classes={{ paper: classes.paper }}
                          options={pointsOptions}
                          getOptionLabel={(option: any) => option.title}
                          // getOptionSelected={(option, value) => option._id === value._id}
                          className={`form-fields ${errors.pointsPerCoin && "error"}`}
                          // onChange={(event: any, newValue: any) => {
                          //   if (newValue) {
                          //     setValue("pointsPerCoin", newValue._id);
                          //     clearErrors("pointsPerCoin");
                          //   }
                          // }}
                          onChange={handleChangePointsPerCoin}
                          renderInput={(params) => (
                            <TextField {...params} label="" name="pointsPerCoin" value={value} variant="standard" placeholder="Select" onChange={(e) => handleChangePointsPerPromo(e, "elm")} />
                          )}
                        />
                      )}
                    />
                    {errors.pointsPerCoin && errors.pointsPerCoin.type === "required" && (<label className="error-message">Field is required</label>)}
                    {errors.pointsPerCoin && errors.pointsPerCoin.type === "validate" && (<label className="error-message">Value should be more than 0</label>)}
                    {errors.pointsPerCoin && errors.pointsPerCoin.type === "pattern" && (<label className="error-message">Only number is allow</label>)}
                  </div>
                </Grid>

                <Grid item sm={6} className="pr-0 pl-0">
                  <div className="input-group">
                    <label className="input-label">Validity</label>
                    <Controller
                      name="validityDay"
                      control={control}
                      rules={{ required: true }}
                      render={({ value }) => (
                        <Autocomplete
                          
                          id="free-solo-demo"
                          classes={{ paper: classes.paper }}
                          options={validityOptions}
                          getOptionLabel={(option: any) => option.title}
                          // getOptionSelected={(option, value) => option._id === value._id}
                          className={`form-fields ${errors.validityDay && "error"}`}
                          // onChange={(event: any, newValue: any) => {
                          //   if (newValue) {
                          //     setValue("pointsPerCoin", newValue._id);
                          //     clearErrors("pointsPerCoin");
                          //   }
                          // }}
                          onChange={handleChangeValidity}
                          renderInput={(params) => (
                            <TextField {...params} label="" value={value} variant="standard" placeholder="Select" />
                            
                          )}
                        />
                      )}
                    />
                    {errors.validityDay && errors.validityDay.type === "required" && (<label className="error-message">Field is required</label>)}
                  </div>
                </Grid>
                <Grid item sm={6} className="pr-0">
                  <div className="input-group">
                    <label className="input-label">Keywords</label>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                        pattern: /[a-zA-Z]/,
                      }}
                      name="keywords"
                      render={({ value }) => (
                        <TextField
                          value={value}
                          name="keywords"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          className={`form-fields ${errors.keywords && "error"
                            }`}
                          placeholder="Enter keyword..."
                        />
                      )}
                    />
                    {errors.keywords && errors.keywords.type === "required" && (<label className="error-message">Field is required</label>)}
                    {errors.keywords && errors.keywords.type === "pattern" && (<label className="error-message">Keyword should be alphabetically</label>)}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <DialogActions>
            <Button className="btn btn-secondary" color="primary" onClick={()=> { props.handleCloseClick(); resetForm(); }} >
              CANCEL
            </Button>
            <Button color="primary" className="btn btn-primary button" onClick={handleSubmit(onSubmit)}>
              Add
            </Button>
          </DialogActions>
        </form>
        <Backdrop className="loader-signup" open={load}>
                    <CircularProgress color="inherit" />
          </Backdrop>
      </Dialog>
      <Snackbar open={openToast} autoHideDuration={3000} onClose={handleClosed}>
            <Alert onClose={handleClosed} severity="error">{errorMessage}</Alert>
        </Snackbar>
    </div>
  );
}