import React, { useState } from "react";
import { Dialog,DialogContentText } from "@material-ui/core";
import "./userGuide.css"
import MinitingToken from  './minitingtoken';
interface Props {
    onOpen: boolean,
    handleCloseClick: () => void,
    handleCancleGuide: () => void,
    orgDataToken: any
}

export default function UserGuide({onOpen,handleCloseClick,orgDataToken,handleCancleGuide }: Props){
    const [page,setPage] = useState(0);
    const handleNex = () => {
        setPage(1);
    }
    return(

            <Dialog
            open={onOpen}
            onClose={handleCloseClick}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={page === 0 ? "sm" : "md"}
            fullWidth={page === 0 ? false : true}
            disableBackdropClick 
            disableEscapeKeyDown
        >
            {
                page === 0 ?
                    <DialogContentText>

                    <p className="div"><b>Token</b></p>
                        <p className="p">You haven't created any token. Please create a token to proceed further.</p><br/>

                    <div className="align-right">
                    <button  className="btn btn-secondary add-btn guide-btn" onClick={handleCancleGuide}><label className="btn-caption">Cancel</label></button>
                    <button  className="btn btn-primary add-btn guide-btn" onClick={handleNex}><label className="btn-caption">Next</label></button>
                    </div>
                </DialogContentText>
                :
                <DialogContentText>
                    <MinitingToken refetch={handleCloseClick} handleClosePopup={handleCloseClick} orgDataToken={orgDataToken} />
                </DialogContentText>

            }
        </Dialog>


        )
}












