import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import { useQuery, gql } from '@apollo/client';
import Loader from '../../components/Loader/loader';
import moment from 'moment';
import Pirates from '../../assets/Pirates.png';
import Pnc from '../../assets/PNC.png';
import Highmark from '../../assets/Highmark.png';
import Nasdaq from '../../assets/Nasdaq.png';
import Van from '../../assets/van.png';
import Veterans from '../../assets/Veterans.png';
import MUIDataTable from "mui-datatables";


interface transactionInventory {
  id: string;
  sponsor_name: string;
  contact_name: string;
  contact_email: string;
}

interface transactionInventoryData {
  transactionInventory: transactionInventory[];
}

interface TransactionInventoryVars {
  year: number;
}
interface ParamTypes {
  orgId: string;
}

export const GET_TRANSACTION_QUERY = gql`
query getTransactions($offset: Int, $limit: Int) {
  getAllTransactions(offset: $offset, limit: $limit) {
    data {
      id
      username
      userProfilePic
      walletId
      orgName
      organization
      orgLogo
      price
      sponsorName
      sponsorLogo
      transactionDate
      transactionType
      points
    }
    count
  }
}
`;
export default function TransactionOverview() {
  let { orgId } = useParams<ParamTypes>();
  const [transDate, setTransDate] = useState<any>("");
  const [transfer, setTransfer] = useState(0);
  const [redeem, setRedeem] = useState(0);
  const [promotions, setPromotions] = useState(0);
  const [hunt, setHunt] = useState(0);



  const columns:any = [
  {
    name:"orgLogo",
    label: " ",
    width: 1,
    options:{
        filter:false,
        sortThirdClickReset: true,
        sort: false,
        customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
            return (<img className="profile-img " src={value || "https://png.pngtree.com/png-vector/20190321/ourmid/pngtree-vector-users-icon-png-image_856952.jpg"} />)
        }

    }
  },
  {
    name:"orgName",
    label:"Orgs",
    options:{
        filter:false,
        sortThirdClickReset: true,
        // sort: false,
        customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
            let orderName = (sortOrder.name == "orgName")?sortOrder.direction:"";

            return(
            <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={0} onClick={() => updateDirection(1)} >
              <a className="sort-by">Orgs</a>
            </th>
            )
        }
    }
  },
{
    name:"walletId",
    options:{
        filter:false,
        sortThirdClickReset: true,

        customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
            let orderName = (sortOrder.name == "walletId")?sortOrder.direction:"";

            return(
            <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={2} onClick={() => updateDirection(2)} >
              <a className="sort-by">Wallet ID</a>
            </th>
            )
        }
    }
  },
  {
      name:"points",
      options:{
          filter:false,
          sortThirdClickReset: true,

          customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
              let orderName = (sortOrder.name == "points")?sortOrder.direction:"";

              return(
              <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={3} onClick={() => updateDirection(3)} >
                <a className="sort-by">Amount</a>
              </th>
              )
          }
      }
  },
{
      name:"id",
      options:{
          filter:false,
          sortThirdClickReset: true,

          customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
              let orderName = (sortOrder.name == "id")?sortOrder.direction:"";

              return(
              <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={4} onClick={() => updateDirection(4)} >
                <a className="sort-by">Transaction ID</a>
              </th>
              )
          }
      }
  },
{
    name: 'transactionDate',
    label:"Date & Time",
    options:{
        filter:false,
        sortThirdClickReset: true,
        customHeadRender: (columnMeta:any, updateDirection:any,sortOrder:any) => {
            let orderName = (sortOrder.name == "transactionDate")?sortOrder.direction:"";

            return(
            <th className={"table-header-sorting " + orderName} style={{ cursor: 'pointer' }} key={5} onClick={() => updateDirection(5)} >
              <a className="sort-by">Date & Time</a>
            </th>
            )
        },
        customBodyRender:(value:any, tableMeta:any, updateValue:any) => {
            return (<div><label className="membername">{moment(parseInt(value)).format('DD MMM YYYY HH:mm')}</label></div>)
        }
    }
  }
 ];

    let offset = 0;
    let limit = 10;

    const {refetch, loading, data } = useQuery<any>(
        GET_TRANSACTION_QUERY,{variables : {offset: offset, limit: limit}}
    );


  // const { refetch, loading, data } = useQuery<any>(GET_TRANSACTION_QUERY);

  const [ageFilterChecked, setAgeFilterChecked] = React.useState(false);
  const history = useHistory();
  const transactionListAll = () => history.push('/transactions');
  useEffect(() => {
    refetch();
    if (!loading) {
      var promoTransactions = data.getAllTransactions.data.filter(function (transaction: any) { return transaction.transactionType == "PROMO"; });
      let promoTotal = promoTransactions.reduce((total: any, current: any) => { return total + current.points }, 0);
      setPromotions(promoTotal);
      var transactions = data.getAllTransactions.data.filter(function (transaction: any) { return transaction.transactionType == "RECEIVED"; });
      let transferTotal = transactions.reduce((total: any, current: any) => { return total + current.points }, 0);
      setTransfer(transferTotal);
      var redeemTransactions = data.getAllTransactions.data.filter(function (transaction: any) { return transaction.transactionType == "REDEEM"; });
      let redeemTotal = redeemTransactions.reduce((total: any, current: any) => { return total + current.points }, 0);
      setRedeem(redeemTotal);
      var huntTransactions = data.getAllTransactions.data.filter(function (transaction: any) { return transaction.transactionType == "HUNT"; });
      let huntTotal = huntTransactions.reduce((total: any, current: any) => { return total + current.points }, 0);
      setHunt(huntTotal);

    }
  }, [data]);


    const options:any = {
        filter: false,
        count: data && data.getAllTransactions && data.getAllTransactions.count ? data.getAllTransactions.count : 10,
        filterType: "dropdown",
        responsive:"standard",
        print:false,
        download : false,
        pagination : true,
        serverSide: true,
        onTableChange: (action:any, tableState:any) => {

        switch (action) {
          case 'changePage':
            refetch({offset: tableState.page * tableState.rowsPerPage, limit: tableState.rowsPerPage})
            // count = data && data.getPointsHolders && data.getPointsHolders[0] && data.getPointsHolders[0].userCount ? data.getPointsHolders[0].userCount : 10;
            // changePage(tableState);
            break;
            case 'changeRowsPerPage':
                    refetch({offset: 0, limit: tableState.rowsPerPage})
                    break;
          case 'sort':
            if (tableState.sortOrder.direction !== 'none')
            {
                refetch({sort: tableState.sortOrder})
            }
            else
            {
                refetch({sort: null})
            }
            // this.sort(tableState.page, tableState.sortOrder);
            // count = data && data.getPointsHolders && data.getPointsHolders[0] && data.getPointsHolders[0].userCount ? data.getPointsHolders[0].userCount : 10;
            break;
          default:
            console.log('action not handled.');
        }

            //     let {refetch, loading, data } = useQuery<any>(
            //     GET_POINTHOLDERS_QUERY,{variables : {clubId : orgId, offset: offset, limit: limit}}
            // );

        // this.xhrRequest('my.api.com/tableData', result => {
        //   this.setState({ data: result });
        },
        viewColumns : false,
        selectableRowsHideCheckboxes: true,
        fixedSelectColumn: false,
        selectToolbarPlacement: "none",
        selectableRowsHeader: false,
        selectableRows: "none",
        selectableRowsOnClick: false,
        fixedHeader : true,
        tableBodyHeight:'242px',
        search:false,
    }
    // return (
    // <div className="table-wrapper pad0">
    //  <div>
    //     <Grid container className="transaction-status-wrapper" xs={12}>
    //       <Grid item sm={1} xs={2}>
    //       <Paper className="table-wrapper">
    //         <span className="data-bold promotions">{promotions}</span><label className="sub-caption">Promotions</label>
    //       </Grid>
    //       <Grid item sm={1} xs={2}>
    //         <span className="data-bold hunt">{hunt}</span><label className="sub-caption">Hunt</label>
    //       </Grid>

    //     </Grid>
    //   </div>
    //                 <Paper className="table-wrapper">
    //                     <div className="table-container muidata-table">
    //                         {loading
    //                             ? <Loader size="90px"/>
    //                             : <MUIDataTable title={"POINT HOLDER LIST"} data={data.getPointsHolders} columns={columns} options={options} />
    //                         }
    //                     </div>
    //                 </Paper>
    //             </Grid>
    //             </Paper>
    //         </Grid>
    //     </Grid>
    // </div>
    // )


    return (
      <div>
    <div className="table-wrapper pad0">
          <div>
        <Grid container className="transaction-status-wrapper" xs={12}>
          <Grid item sm={1} xs={2}>
            <span className="data-bold promotions">{promotions}</span><label className="sub-caption">Promotions</label>
          </Grid>
          <Grid item sm={1} xs={2}>
            <span className="data-bold hunt">{hunt}</span><label className="sub-caption">Hunt</label>
          </Grid>

        </Grid>
      </div>

        {/*<Grid container className="transaction-status-wrapper" xs={12}>*/}
                {/*<Grid  item sm={12} xs={12}>*/}
                    {/*<Paper className="table-wrapper">*/}
                    {/*<Grid container className="table-head-section" sm={12}>

                      <Grid item sm={1} xs={2}>
                        <span className="data-bold promotions">{promotions}</span><label className="sub-caption">Promotions</label>
                      </Grid>
                      <Grid item sm={1} xs={2}>
                        <span className="data-bold hunt">{hunt}</span><label className="sub-caption">Hunt</label>
                      </Grid>
                    </Grid>*/}
                {/*<Grid  item sm={12} xs={12}>*/}
                    {/*<Paper className="table-wrapper pad0">*/}
                        <div className="table-container muidata-table pad0" style={{ height: 325, width: '100%' }}>
                            {loading
                                ? <Loader size="90px"/>
                                : <MUIDataTable title={""} data={data.getAllTransactions.data} columns={columns} options={options} />
                            }
                        </div>
                    {/*</Paper>    */}
                {/*</Grid>*/}
                {/*</Paper>    */}
            {/*</Grid>*/}
        {/*</Grid>*/}
    </div>
    </div>
    )



  // return (
  //   <div className="table-wrapper pad0">
  //     <div>
  //       <Grid container className="transaction-status-wrapper" xs={12}>
  //         <Grid item sm={1} xs={2}>
  //           <span className="data-bold promotions">{promotions}</span><label className="sub-caption">Promotions</label>
  //         </Grid>
  //         <Grid item sm={1} xs={2}>
  //           <span className="data-bold hunt">{hunt}</span><label className="sub-caption">Hunt</label>
  //         </Grid>

  //       </Grid>
  //     </div>
  //     <TableContainer className="table-container" style={{ height: 270, width: '100%' }} component={Paper}>
  //       <Table stickyHeader className="" aria-label="simple table">
  //         <TableHead>
  //           <TableRow>
  //             <TableCell>Organizations</TableCell>
  //             <TableCell align="left">Wallet ID</TableCell>
  //             <TableCell align="left">Points</TableCell>
  //             <TableCell align="left">Transaction ID</TableCell>
  //             <TableCell align="left">Date & Time</TableCell>
  //             {/* <TableCell align="left">Status</TableCell> */}
  //           </TableRow>
  //         </TableHead>
  //         {loading ? (
  //           <TableBody>
  //             <TableCell colSpan={6}>
  //               <Loader size="90px" />
  //             </TableCell>
  //           </TableBody>
  //         ) : (
  //           <TableBody>
  //             {data.getAllTransactions.data.map((transaction: any) => {
  //               let orgDetails = JSON.parse(transaction.organization);
  //               return (
  //                 <TableRow >
  //                   <TableCell className="label-parent" padding="none" >
  //                     <img className="profile-img " src={orgDetails.orgLogo || "https://png.pngtree.com/png-vector/20190321/ourmid/pngtree-vector-users-icon-png-image_856952.jpg"} />
  //                     <label className="name">{orgDetails.orgName}</label>
  //                   </TableCell>
  //                   <TableCell className="label-parent" padding="none" >
  //                     <label className="name text-wrapper">{transaction.walletId || "-"}</label>
  //                   </TableCell>
  //                   <TableCell className="label-parent" padding="none" >
  //                     <label className="name">{transaction.points}</label>
  //                   </TableCell>
  //                   <TableCell className="label-parent" padding="none" >
  //                     <label className="name text-wrapper">{transaction.id}</label>
  //                   </TableCell>
  //                   <TableCell className="label-parent" padding="none" >
  //                     <label className="name">{moment(parseInt(transaction.transactionDate)).format('DD MMM YYYY HH:mm')}</label>
  //                   </TableCell>
  //                   {/* <TableCell className="label-parent" padding="none" >
  //               <label className="name">{transaction.status}</label>
  //             </TableCell> */}
  //                 </TableRow>
  //               )
  //             })}
  //           </TableBody>
  //         )}
  //       </Table>
  //     </TableContainer>
  //     <Grid container sm={12} >
  //       <Grid className="btn-section view-all-btn" item xs={12}>
  //         <Button onClick={transactionListAll} className="btn" variant="contained">View All</Button>
  //       </Grid>
  //     </Grid>
  //   </div>
  // );
}