import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import PointOverview from './pointsoverview';
import PromotionOverview from './promotionoverview'
import OrgOverview from './orgoverview';
import MemberOverview from './memberoverview';
import TransactionOverview from './transactionview';
import KYCOverview from './kycoverview';
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";
import '../../constant.css';
import './dashboard.css';
import { useQuery, gql } from '@apollo/client';

// const GET_CLUB_INVENTORY = gql`
//   query GetClubInventory {
//     getPromo{
//       id
//       promocode
//     }
//   }
// `;

export default function Dashboard() {
    // const { loading, data } = useQuery<any>(
    //     GET_CLUB_INVENTORY
    // );
    // const classes = useStyles();
    // const { loading, data } = useQuery<ClubInventoryData[]>(
    //     GET_CLUB_INVENTORY
    //   );

    return (
        <div className="details-page dashboard-details-page">
            <h2 className="page-title padd-left8">DashBoard</h2>
            <div className="dashboard-container">
                <Grid container>
                    <Grid container  xs={12} spacing={2}>
                        <Grid className="overview-wrapper" item sm={2} spacing={3}>
                            <OrgOverview/>
                            <MemberOverview/>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <Paper className="padd-10">
                            <label className="table-heading">DISTRIBUTION PER ORGANIZATION</label>
                            <PointOverview/>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <Paper className="padd-10">
                                <label className="table-heading">PROMOTIONS</label>
                                <PromotionOverview/>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} spacing={2}>
                        <Grid item xs={12} sm={7}>
                            <Paper className="padd-10">
                            <label className="table-heading">TRANSACTIONS</label>
                            <TransactionOverview/>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <Paper className="padd-10">
                                <label className="table-heading">MEMBERS</label>
                                <KYCOverview/>
                            </Paper>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </div>
        </div>
    )
}
